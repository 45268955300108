<template>
	<div id="app">
		<!-- 子路由 -->
		<Header />
		<router-view />
		<div class="main">
			<div class="banner">
				<div>
					<p>Darwin AI </p>
					<p>专精特新企业价值 生态系统 </p>
					<p>
						<span>
							<img src="../assets/img/specialization/youjiantou-2.svg" alt="专精特新">
							<i>融资帮手</i>
						</span>
						<span>
							<img src="../assets/img/specialization/youjiantou-2.svg" alt="专精特新">
							<i>投资助手</i>
						</span>
						<span>
							<img src="../assets/img/specialization/youjiantou-2.svg" alt="专精特新">
							<i>服务能手</i>
						</span>
					</p>
				</div>
			</div>
			<div class="selectService">
				<p class="headline">精选服务</p>
				<p class="subtitle">助力专精特新企业价值管理，让企业茁壮成长</p>
				<ul class="service_content">
					<li><img src="../assets/img/specialization/service_diagnose.png" alt="企业价值诊断"></li>
					<li>
						<div>
							<p>企业价值量化
								<!-- <span><em>￥</em>9800 </span> -->
							</p>
							<p style="margin-top: 17px;">七维量化、提升价值</p>
							<div class="type_describes">
								<div>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">客观分析企业发展实力</p>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">量身定制企业估值建议</p>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">融资谈判占据主导地位</p>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">便于合理制定融资、股权激励计划
									</p>
								</div>
								<div>
									<p>经营能力分析</p>
									<p>企业多维评价</p>
									<p>估值建议</p>
									<p>多种估值模型</p>
									<p>未来年度业绩及财务预测</p>
								</div>
							</div>
							<div class="valation_btn valuation-container">
								<a data-index="1" data-flag="false" data-stage="7" data-type="2" data-versions="2"
									@click="information(7,2,2)">立即估值</a>
								<a class="exam" @click="pdf(2)" target="_blank">查看估值报告示例</a>
							</div>
						</div>

					</li>
					<li>
						<p></p>
					</li>
				</ul>
				<ul class="service_content">
					<li>
						<div>
							<p>企业价值诊断
								<!-- <span><em>￥</em>9800 </span> -->
							</p>
							<p style="margin-top: 17px;">全面体检、发现价值</p>
							<div class="type_describes">
								<div>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">全方位解析企业经营管理状况</p>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">发现影响企业价值的内外部因素
									</p>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">提出专业改进发展建议</p>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">针对性提高企业核心竞争力</p>
								</div>
								<div>
									<p>七维价值诊断</p>
									<p>专业价值诊断和建议</p>
									<p>企业成长经营的全面分析</p>
									<p>财务状况全方位体检</p>
									<p>未来年度业绩及财务预测</p>
								</div>
							</div>
							<div class="valation_btn valuation-container">
								<a data-index="1" data-flag="false" data-stage="7" data-type="7" data-versions="3"
									@click="information(7,7,3)">立即诊断</a>
								<a class="exam" @click="pdf(7)" target="_blank">查看诊断报告示例</a>
							</div>
						</div>
					</li>
					<li>
						<p></p>
						<img src="../assets/img/specialization/service_valuation.png" alt="企业估值">
					</li>
					<li>
						<div>
							<p>企业价值追踪
								<!-- <span><em>￥</em>9800 </span> -->
							</p>
							<p style="margin-top: 17px;">跟踪价值、保价护航</p>
							<div class="type_describes">
								<div>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">股权价值动态追踪</p>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">专精特新各个维度变化综合展示</p>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">综合竞争力跟踪对比</p>
									<p><img class="tick" src="../assets/img/specialization/duigou.svg"
											alt="企业价值诊断-icon">财务状况可视化解析</p>
								</div>
								<div>
									<p>多期估值结果对比分析</p>
									<p>多期二级市场的价值比率变化</p>
									<p>企业综合竞争力多期变化分析</p>
									<p>财务状况多期对比</p>
									<p>杜邦分析多期对比</p>
								</div>
							</div>
							<div class="valation_btn valuation-container">
								<a data-index="6" data-type="5" data-stage="7" @click="information(7,5,2)">立即填写</a>
								<a class="exam" @click="pdf(5)" target="_blank">查看追踪报告示例</a>
							</div>
						</div>
					</li>
				</ul>

			</div>
			<div class="mobileSelectService">
				<p class="mobile_headline">精选服务</p>
				<p class="mobile_subtitle">助力专精特新企业价值管理，让企业茁壮成长</p>
				<ul class="mobile_service_type">
					<li class="active">企业价值诊断</li>
					<li>企业价值量化</li>
					<li>企业价值追踪</li>
				</ul>
				<ul class="mobile_service_content">
					<li>
						<p>您的企业健康状况如何？</p>
						<img src="../assets/img/specialization/service_diagnose.svg">
						<p>企业价值诊断
							<!-- <span><em>￥</em>9800 </span> -->
						</p>
						<p>全面体检、发现价值</p>
						<div class="type_describes">
							<div>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">全方位解析企业经营管理状况</p>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">发现影响企业价值的内外部因素</p>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">提出专业改进发展建议
								</p>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">针对性提高企业核心竞争力</p>
							</div>
						</div>
						<div class="valation_btn valuation-container">
							<a data-index="1" data-flag="false" data-stage="7" data-type="7" data-versions="3"
								@click="information(7,7,3)">立即诊断</a>
							<a class="exam" href="/data/specialization_diagnose.pdf" target="_blank">查看诊断报告示例</a>
						</div>
					</li>
					<li class="hide">
						<p>您的企业值多少钱？</p>
						<img src="../assets/img/specialization/service_valuation.svg" alt="企业价值诊断">
						<p>企业价值量化
							<!-- <span><em>￥</em>9800 </span> -->
						</p>
						<p>七维量化、提升价值</p>
						<div class="type_describes">
							<div>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">客观分析企业发展实力</p>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">量身定制企业估值建议</p>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">融资谈判占据主导地位</p>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">便于合理制定融资、股权激励计划
								</p>
							</div>
						</div>
						<div class="valation_btn valuation-container">
							<a data-index="1" data-flag="false" data-stage="7" data-type="2" data-versions="2"
								@click="information(7,2,2)">立即估值</a>
							<a class="exam" @click="pdf(2)" target="_blank">查看估值报告示例</a>
						</div>
					</li>
					<li class="hide">
						<p>您的企业近三年价值变化如何？</p>
						<img src="../assets/img/specialization/service_track.svg" alt="企业价值诊断">
						<p>企业价值追踪
							<!-- <span><em>￥</em>9800 </span> -->
						</p>
						<p>跟踪价值、保价护航</p>
						<div class="type_describes">
							<div>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">股权价值动态追踪</p>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">专精特新各个维度变化综合展示</p>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">综合竞争力跟踪对比</p>
								<p><img class="tick" src="../assets/img/specialization/duigou.svg"
										alt="企业价值诊断-icon">财务状况可视化解析</p>
							</div>
						</div>
						<div class="valation_btn valuation-container">
							<a data-index="6" data-type="5" data-stage="7" @click="information(7,5,2)">立即填写</a>
							<a class="exam" @click="pdf(5)" target="_blank">查看追踪报告示例</a>
						</div>
					</li>
				</ul>
			</div>

			<div class="mask hide">
				<!--价值追踪-->
				<!-- 公司名称选择 -->
				<Modal v-model="selectCompany" class-name="vertical-center-modal selectCompany"
					@on-visible-change="modelSaveclose">
					<p>请选择公司名称</p>
					<ul class="company-contain">
						<li v-for="(item, i) in companyList" @click="companyName(i)" :class="{blue:i==currentCompany}">
							{{item.company}}
						</li>
					</ul>
					<div slot="footer" class="btncenter">
						<span class="btn-trace" @click="trace_add">添加公司</span>
						<span class="btn-trace" @click="trace_confirm">确定</span>
					</div>
				</Modal>

				<Modal v-model="noCompany" class-name="vertical-center-modal">
					<img class='warning' src="../assets/img/remind-01@2x.png">
					<span>请选择公司名称</span>
					<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
				</Modal>

				<!-- 勾选价值追踪年份弹窗 -->
				<Modal v-model="valuationYear" class-name="vertical-center-modal select-valuation-year">
					<p>请勾选价值追踪的年份</p>
					<div class="years-info">
						<p class="top-tip" v-if="traceyear.length>0">{{selectYear}}</p>
						<ul>
							<li v-for="(item, i) in yearList">
								<div :class="['img-status', item.checked?'selectedYes':'selectedNo']">
									<img :src="item.checked?require('../assets/img/trace/year_selected.png'):require('../assets/img/trace/year_selected_no.png')"
										alt="icon" @click="checkcDate($event,i)">
								</div>
								<div class="checkboxes">
									<input type="checkbox" name="" :id="`year${i}`"
										:class="[item.checked?'checkselected':'checkselectedno']"
										:checked="item.checked?'checked':''" @change="checkcDate($event,i)">
									<label :for="`year${i}`">{{item.valYear}}年</label>
								</div>
								<p class="report-type">{{item.reportId?'成长期专业估值':''}}</p>
								<p class="valuation-result">{{item.reportId?item.valResult:'暂无估值结果'}}</p>
								<p class="view-report" data-reportid="">{{item.reportId?'查看估值报告':'暂无估值结果'}}</p>
							</li>
						</ul>
					</div>
					<div slot="footer" :class="['ivu-sure',!createTrace?'grey':'']" @click="trace">价值追踪</div>
				</Modal>

			</div>



		</div>

		<Footer />


	</div>
</template>

<script>
	import Header from '../common/header/flow_header.vue';
	import Footer from '../common/footer.vue';
	import '../assets/libs/css/swiper.min.css';
	import '../assets/libs/css/animate.css';
	import '../assets/libs/css/myAlert.min.css';
	import '../assets/libs/js/jquery-1.11.1.min.js';
	import '../assets/js/index.js';

	import qs from 'qs'
	import axios from 'axios';
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper';
	import API_CONFIG from "../../vue.config.js"
	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		data() {
			return {
				url: '',
				isSpinShow: true,
				selectCompany: false,
				companyList: [],
				yearList: [],
				traceyear: [], //价值追踪已选的年份
				currentCompany: null, //价值追踪选择第几个公司
				currentCompanyName: '', //值追踪当前选择哪个公司的名称
				noCompany: false,
				valuationYear: false, //价值追踪年份选择框的显示隐藏，默认隐藏
				selectYear: '',
				createTrace: false,
				isClickTrace: false,
				swiperOption: {
					slidesPerView: "auto", //一屏展示几个  （loop: true模式下slidesPerView设置为auto）
					loopedSlides: 8, //swiper-slide  的总个数加2
					loop: true, // 设置图片循环
					speed: 1000, //自动播放的速度，每隔1秒滑动一次
					prevButton: ".swiper-button-prev",
					nextButton: ".swiper-button-next",
				},
				train: [ //助企培训
					{
						id: '',
						imgPath: '../img/specialization/helpCompanies.jpg',
						caseName: '',
						caseSummary: ''
					},
					{
						id: '',
						imgPath: '../img/specialization/helpCompanies.jpg',
						caseName: '',
						caseSummary: ''
					}
				],
				institution: [], //制度法规
				policy: [] //政策补贴

			}
		},
		components: {
			Header,
			Footer
		},
		computed: {
			...mapState(['traceYear'])
		},
		created() {

		},
		mounted() {
			sessionStorage.removeItem("isHistoryCache")
			sessionStorage.removeItem('baseDate'); //清空诊断基准日
			this.url = API_CONFIG.devServer.proxy['/api'].target;
			// console.log(this.$store.state.channel)

			//  serviceType：1：制度法规 2:政策补贴   3：助企培训
			this.$post('news/policyService/getHomePolicyService', qs.stringify({
					serviceType: '3'
				}))
				.then((res) => {
					this.train = res.data.content.slice(0, 2);
				});
			this.$post('news/policyService/getHomePolicyService', qs.stringify({
					serviceType: '1'
				}))
				.then((res) => {
					this.institution = res.data.content.filter(item => {
						return item.showHome == '1';
					});
					if (this.institution.length > 5) {
						this.institution = this.institution.slice(0, 5)
					}
				});
			this.$post('news/policyService/getHomePolicyService', qs.stringify({
					serviceType: '2'
				}))
				.then((res) => {
					this.policy = res.data.content.filter(item => {
						return item.showHome == '1';
					});
					if (this.policy.length > 5) {
						this.policy = this.policy.slice(0, 5)
					}
				});

			var date = new Date;
			var currentYear = date.getFullYear();
			this.yearList = [{
				valYear: (currentYear - 2).toString(),
				valResult: '',
				reportId: '',
				checked: false
			}, {
				valYear: (currentYear - 1).toString(),
				valResult: '',
				reportId: '',
				checked: false
			}, {
				valYear: currentYear.toString(),
				valResult: '',
				reportId: '',
				checked: false
			}]

		},
		methods: {
			...mapMutations(['changeTraceYear']),
			helpCompanies: function(e) {
				this.$router.push({
					path: '/enterpriseTrainDes',
					query: {
						id: e
					}
				});
			},
			systemDetails: function(e) {
				this.$router.push({
					path: '/policyDetails',
					query: {
						serviceType: 1,
						id: e
					}
				});
			},
			policyDetails: function(e) {
				this.$router.push({
					path: '/policyDetails',
					query: {
						serviceType: 1,
						id: e
					}
				});
			},
			helpEnterpriseTraining: function() {
				this.$router.push({
					path: '/enterpriseTrain'
				});
			},
			systemMore: function(serviceType) {
				this.$router.push({
					path: '/policyService',
					query: {
						serviceType: serviceType,
						current: 1
					}
				});
			},
			pdf: function(e) {
				if (e == 7) {
					window.open('/data/specialization_diagnose.pdf')
				} else if (e == 2) {
					window.open('/data/specialization_profession.pdf')
				} else {
					window.open('/data/specialization_trace.pdf')
				}
			},
			information: function(stage, type, versions) { //点击立即填写的跳转功能
				sessionStorage.stage = stage;
				sessionStorage.reportType = type;
				sessionStorage.versions = versions;
				if (type == 5) {
					this.selectCompany = true;
					this.firm(stage)
				} else {
					this.$router.push({
						path: '/basicInformation',
						query: {
							stage: stage,
							type: type
						}
					});
				}
			},
			firm: function(stage, company) { //价值追踪估值公司的列表
				this.$Spin.show(); //全局加载中
				this.$post('trace/valuationReport/qryCandidateCompanies', qs.stringify({
						stage: stage
					}))
					.then((res) => {
						this.$Spin.hide();
						if (res.data.content.companyAndValYears && res.data.content.companyAndValYears.length >
							0) {
							var companyAndValYears = res.data.content.companyAndValYears;
							this.companyList = res.data.content.companyAndValYears;
							for (var i = 0; i < companyAndValYears.length; i++) {
								if (company == companyAndValYears[i].company) {
									for (var j = 0; j < companyAndValYears[i].yearAndValuations.length; j++) {
										for (var y = 0; y < this.yearList.length; y++) {
											if (companyAndValYears[i].yearAndValuations[j].valYear == this
												.yearList[y].valYear) {
												this.$set(this.yearList, y, {
													valYear: companyAndValYears[i].yearAndValuations[j]
														.valYear,
													valResult: companyAndValYears[i].yearAndValuations[j]
														.valResult,
													reportId: companyAndValYears[i].yearAndValuations[j]
														.reportId,
													checked: false
												})
											}
										}
									}
								}

							}

						}
					});
			},
			companyName: function(i) {
				this.currentCompany = i;
				this.currentCompanyName = this.companyList[i].company;
			},
			modelSaveclose: function() { //监听弹框关闭事件
				var date = new Date;
				var currentYear = date.getFullYear();
				this.currentCompany = null;
				this.yearList = [{
					valYear: (currentYear - 2).toString(),
					valResult: '',
					reportId: '',
					checked: false
				}, {
					valYear: (currentYear - 1).toString(),
					valResult: '',
					reportId: '',
					checked: false
				}, {
					valYear: currentYear.toString(),
					valResult: '',
					reportId: '',
					checked: false
				}];
			},
			trace_confirm: function() { //价值追踪估值公司的列表确定按钮
				if (this.currentCompany != null) { //说明选中了公司名称
					this.valuationYear = true;
					this.selectCompany = false;
					this.firm(sessionStorage.stage, this.currentCompanyName)
				} else {
					this.noCompany = true;
				}
			},
			submitDialog: function() {
				this.noCompany = false;
			},
			trace_add: function() {
				this.valuationYear = true;
				this.selectCompany = false;
				this.firm(sessionStorage.stage)
			},
			checkcDate: function(e, i) {
				this.$set(this.yearList[i], 'checked', !this.yearList[i].checked)
				this.traceyear = [];
				for (var j = 0; j < this.yearList.length; j++) {
					if (this.yearList[j].checked) {
						this.traceyear.push(this.yearList[j].valYear)
					}
				}
				if (this.traceyear.length == 1) {
					this.createTrace = false;
					this.selectYear = '本次价值追踪为：' + this.traceyear[0] + '年，请再选择一年或两年';
				} else if (this.traceyear.length == 2) {
					this.createTrace = true;
					this.selectYear = '本次价值追踪为：' + this.traceyear[0] + '年、' + this.traceyear[1] + '年两年价值变化';
				} else if (this.traceyear.length == 3) {
					this.createTrace = true;
					this.selectYear = '本次价值追踪为：' + this.traceyear[0] + '年、' + this.traceyear[1] + '年、' + this
						.traceyear[2] + '年三年价值变化';
				}

			},
			trace: function() {
				var this_ = this
				if (this.createTrace && !this.isClickTrace) {
					this.isClickTrace = true;
					this.$post('trace/valuationReport/createValuationReport', qs.stringify({
							companyName: this.currentCompanyName,
							yearStr: this.traceyear.toString(),
							stage: sessionStorage.stage
						}))
						.then((res) => {
							this.valuationYear = false;
							this.changeTraceYear(res.data.content.yearAndvalIds[0].year)
							setTimeout(function() {
								this_.$router.push({
									path: '/basicInformation',
									query: {
										stage: sessionStorage.stage,
										type: 5,
										reportId: res.data.content.yearAndvalIds[0].reportId,
										traceReportId: res.data.content.traceId,
										year: res.data.content.yearAndvalIds[0].year
									}
								})
							}, 500)
						});
				}

			}

		}
	}
</script>

<style scoped>
	.grey {
		background: rgb(205, 205, 205);
		color: #fff;
	}

	.banner {
		min-width: 1200px;
		width: 100% !important;
		height: 550px;
		background: url(../assets/img/index4.0/specialization.jpg) center center no-repeat;
		display: flex;
		align-items: center;
		background-size: cover;
	}

	.banner>div {
		width: 1200px;
		margin: 0 auto;
	}

	.banner p:nth-of-type(1) {
		font-size: 36px;
		font-weight: bold;
		color: #000;
		line-height: 49px;
	}

	.banner p:nth-of-type(2) {
		width: 420px;
		font-size: 51px;
		font-weight: bold;
		color: #000;
		line-height: 62px;
		margin: 19px 0 44px 0;
	}

	.banner p img {
		width: 21px;
		vertical-align: middle;
		margin-right: 7px;
	}

	.banner p span i {
		font-size: 18px;
		font-weight: bold;
		color: #2F6FF4;
		line-height: 12px;
	}

	.banner p span:nth-of-type(1),
	.banner p span:nth-of-type(2) {
		margin-right: 45px;
	}

	.train {
		width: 1200px;
		margin: 0 auto;
	}

	p.headline {
		font-size: 28px;
		font-weight: bold;
		color: #0D1631;
		padding: 74px 0 15px 0;
		text-align: center;
		margin-bottom: 0;
	}

	.subtitle {
		font-size: 16PX;
		font-weight: 400;
		color: #282828;
		text-align: center;
		padding-bottom: 25px;
	}

	.selectService {
		overflow: hidden;
		padding-bottom: 87px;
	}

	.selectService>ul {
		width: 50%;
		float: left;
	}


	.service_content:nth-of-type(1) li:nth-child(n+1) {
		display: flex;
		justify-content: flex-end;
	}

	.service_content:nth-of-type(1) li:nth-child(1) img {
		width: 622px;
	}

	.service_content:nth-of-type(1) li:nth-child(3) {
		display: flex;
		justify-content: flex-start;
	}

	.service_content:nth-of-type(1) li:nth-child(3)>p {
		width: 98%;
		height: 430px;
		background: url(../assets/img/specialization/service_track.jpg) center center no-repeat;
		background-size: cover;
		border: 2px #fff solid;
		box-shadow: 2px 2px 13px 0px rgba(32, 76, 151, 0.23);
		margin-top: 10px;
		z-index: 3;
	}

	.service_content li {
		position: relative;
	}

	.service_content li>div {
		width: 610px;
		padding-bottom: 40px;
	}

	.service_content:nth-of-type(1) li:nth-of-type(2)>div {
		padding-top: 80px;
	}

	.service_content:nth-of-type(2) li:nth-of-type(1)>div {
		padding-top: 25px;
		margin-left: 69px
	}

	.service_content:nth-of-type(2) li:nth-of-type(3)>div {
		padding-top: 70px;
		margin-left: 50px
	}

	.service_content:nth-of-type(2) li:nth-of-type(2)>img {
		position: relative;
		left: -21px;
	}

	.service_content:nth-of-type(2) li:nth-of-type(2)>p {
		width: 97%;
		height: 430px;
		background: linear-gradient(0deg, #1459F4 0%, #2B69EF 100%);
		box-shadow: 0px 16px 47px 21px rgba(21, 61, 185, 0.07);
		position: absolute;
		top: 45px;
		margin-left: 3%;
	}

	.service_content li>div>p:first-child {
		font-size: 20px;
		font-weight: bold;
		color: #282828;
		line-height: 14px;
		margin-bottom: 11px;
	}

	.service_content li>div>p span {
		font-size: 26px;
		color: #0478FC;
		margin-left: 15px;
	}

	.service_content li>div>p span em {
		font-size: 18px;
		color: #0478FC;
	}

	.service_content li>div>p span img {
		width: 25px;
		position: relative;
		top: -15px;
		left: -5px;
	}

	.service_content li>div>p:last-child {
		font-size: 14px;
		font-weight: 400;
		color: #282828;
	}

	.type_describes {
		margin-top: 20px;
		overflow: hidden;
	}

	.type_describes>div {
		float: left;
	}

	.type_describes>div:first-child {
		margin-right: 75px;
	}

	.type_describes>div:nth-of-type(1) p {
		font-size: 16PX;
		font-weight: 400;
		color: #484848;
		line-height: 38px;
	}

	.type_describes>div:nth-of-type(2) p {
		font-size: 14px;
		font-weight: 400;
		color: #484848;
		line-height: 29px;
	}

	.tick {
		width: 22px;
		height: 22px;
		vertical-align: middle;
		margin-right: 15px;
	}

	.valation_btn {
		margin-top: 20px;
	}

	.valation_btn a {
		outline: none;
		width: 160px;
		height: 40px;
		background: #C8E2FF;
		border: 1px solid #0478FC;
		cursor: pointer;
		font-size: 14PX;
		font-weight: 400;
		color: #0478FC;
		line-height: 40px;
		text-align: center;
	}

	.valation_btn a:first-child {
		margin-right: 52px;
		background: linear-gradient(0deg, #1E4FD9, #2F6FF4);
		color: #fff;
	}

	.valation_btn a:first-child:hover {
		background: #1D71E3;
	}

	.valation_btn a:last-child:hover {
		background: #a9d2ff;
	}



	.bigtitle {
		font-size: 20px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 18px;
		text-align: center;
		margin-top: 20%;
		margin-bottom: 22px;
	}

	.smalltitle {
		width: 90%;
		margin: 0 auto;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 18px;
		text-align: center;
	}

	.mobileSelectService {
		display: none;
	}

	@media screen and (max-width: 750px) {
		#app {
			height: auto;
			overflow: hidden;
		}

		.banner {
			/* min-width: 100%;
			width: 100% !important; */
			min-width: auto;
			width: auto !important;
			height: 360px;
			background: url(../assets/img/mobileClient/specialization.jpg) center center no-repeat;
			background-size: auto 100%;
		}

		.banner>div {
			display: none;
		}

		.selectService {
			width: 100%;
		}

		p.headline {
			padding: 35px 0 13px 0;
		}

		.selectService {
			display: none;
		}

		.mobile_headline {
			font-size: 20PX;
			color: #272727;
			letter-spacing: 0;
			line-height: 30px;
			font-weight: 700;
		}

		.mobile_subtitle {
			font-size: 12PX;
			color: #4e4e4e;
			letter-spacing: 0;
			line-height: 18px;
			margin-bottom: 25px;
			margin-top: 20px;
		}

		.mobileSelectService {
			width: 93%;
			margin: 20px auto;
			display: block;
			padding: 30px 20px;
			box-shadow: 0 4px 16px 0 rgb(0 0 0 / 23%);
			border-radius: 7.32px;
			background-color: #fff;
		}

		.mobile_service_type {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
		}

		.mobile_service_type li {
			width: 32%;
			height: 50px;
			line-height: 50px;
			border-radius: 25px;
			background: #e5e5e5;
			font-size: 13PX;
			text-align: center;
			cursor: pointer;
		}

		.mobile_service_type li.active {
			background: #0478FC;
			color: #fff;
		}

		.mobile_service_content li>img {
			width: 90%;
			margin: 0 auto 25px;
			display: block;
		}

		.mobile_service_content li>p:first-child {
			font-size: 14PX;
			margin-bottom: 15px;
		}

		.mobile_service_content li>p:nth-of-type(2) {
			font-size: 18PX;
			color: #272727;
			line-height: 24px;
			margin-bottom: 5px;
			font-weight: 700;
		}

		.mobile_service_content li>p:nth-of-type(2) span {
			font-size: 26px;
			color: #0478FC;
			margin-left: 15px;
		}

		.mobile_service_content li>p:nth-of-type(3) {
			margin-top: 17px;
		}

		.tick {
			width: 32px;
		}

		.valuation-container {
			width: 100%;
			overflow: hidden;
		}

		.valation_btn a {
			width: 200px;
			height: 50px;
			line-height: 50px;
		}

		.valation_btn a:first-child {
			margin-right: 0;
			float: left;
		}

		.valation_btn a:last-child {
			float: right;
		}

		.swiper-wrapper>div {
			width: 50%;
		}

		.bigtitle {
			font-size: 18PX;
		}

		.smalltitle,
		.advisory_introduce ul li {
			font-size: 12PX;
			line-height: 25px;
		}

		.train {
			width: 93%;
			margin: 20px auto;
			padding: 30px 20px;
			box-shadow: 0 4px 16px 0 rgb(0 0 0 / 23%);
			border-radius: 7.32px;
			background-color: #fff;
		}

		.train ul li {
			width: 100%;
			float: none;
		}

		.train ul li div>p:last-child {
			width: 89%;
		}

		.train ul li div>p:first-child {
			font-size: 18PX;
			padding-top: 25px;
		}

		.train ul li div>p:last-child {
			font-size: 12PX;
			line-height: 30px;
		}

		.train a {
			width: 200px;
			height: 50px;
			line-height: 50px;
			margin-bottom: 0;
		}

		.policyService {
			min-width: 100%;
			height: auto;
			padding-bottom: 50px;
			background: #f0f2f5;
			position: relative;
		}

		.policyService>img {
			width: 200px;
			display: block;
			position: absolute;
			top: 10px;
			right: 10px;
		}

		.subtitle {
			font-size: 14PX;
			padding: 0 50px 39px;
		}

		.policyService ul {
			width: 93%;
			margin: 0 auto;
			padding: 0 20px;
			background: transparent;

		}

		.policyService ul li {
			float: none;
			margin: 0;
			margin-left: 10px;
		}

		.policyService ul li:first-child {
			width: 94%;
			border: none;
		}

		.policyService ul li:last-child {
			width: 94%;
			margin-top: 20px;
			margin-left: 10px;
		}

		.policyService ul li p:first-child>span {
			font-size: 14PX;
		}

		.policyService ul li p:last-child span {
			width: 100%;
			margin-top: 10px;
		}

	}
</style>
