<template>
	<div>
		<Header />
		<AuditStatusTitle />
		<div class="information viewContent">
			<ExamineNav />
			<!-- 资产负债表 -->
			<div class="table-main major-balance">
				<!-- 表名-->
				<div class="table-name">
					<span>资产负债表</span>
				</div>
				<!--单位简介-->
				<span class="unit">单位：元</span>
				<div :class="['table-container',this.$route.query.type==7?'diagnoseFinance':'']">
					<table class="table" id="tableOne" border="0" style="border-collapse: collapse;">
						<thead>
							<tr class="noInput">
								<th>项目</th>
								<th v-for="(item, index) in balanceSheetYearList">{{item}}</th>
							</tr>
						</thead>
						<tbody>
							<tr class="trOne even noInput">
								<td>流动资产:</td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trTwo" data-class="monetaryCapital">
								<td>货币资金</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.monetaryCapital[i]"
										@blur="((ev)=>{blur(ev,liabilities,'monetaryCapital',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.monetaryCapital[i]?formatCurrency.formateNum(liabilities.monetaryCapital[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="tradingAssets">
								<td>交易性金融资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.tradingAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'tradingAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.tradingAssets[i]?formatCurrency.formateNum(liabilities.tradingAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="derivativeAssets">
								<td>衍生金融资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.derivativeAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'derivativeAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.derivativeAssets[i]?formatCurrency.formateNum(liabilities.derivativeAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="billReceivable">
								<td>应收票据</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.billReceivable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'billReceivable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.billReceivable[i]?formatCurrency.formateNum(liabilities.billReceivable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo tipTerm" data-class="receivables">
								<td>应收账款</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.receivables[i]"
										@blur="((ev)=>{blur(ev,liabilities,'receivables',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.receivables[i]?formatCurrency.formateNum(liabilities.receivables[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="accountReceivable">
								<td>应收账款融资</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.accountReceivable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'accountReceivable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.accountReceivable[i]?formatCurrency.formateNum(liabilities.accountReceivable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="prepayment">
								<td>预付款项</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.prepayment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'prepayment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.prepayment[i]?formatCurrency.formateNum(liabilities.prepayment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="otherReceivables">
								<td>其他应收款</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherReceivables[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherReceivables',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherReceivables[i]?formatCurrency.formateNum(liabilities.otherReceivables[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo tipTerm" data-class="inventory">
								<td>存货</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.inventory[i]"
										@blur="((ev)=>{blur(ev,liabilities,'inventory',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.inventory[i]?formatCurrency.formateNum(liabilities.inventory[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="contractualAssets">
								<td>合同资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.contractualAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'contractualAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.contractualAssets[i]?formatCurrency.formateNum(liabilities.contractualAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="saleForAssets">
								<td>持有待售资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.saleForAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'saleForAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.saleForAssets[i]?formatCurrency.formateNum(liabilities.saleForAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="illiquidAssetsOne">
								<td>一年内到期的非流动资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.illiquidAssetsOne[i]"
										@blur="((ev)=>{blur(ev,liabilities,'illiquidAssetsOne',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.illiquidAssetsOne[i]?formatCurrency.formateNum(liabilities.illiquidAssetsOne[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="otherCurrentAssets">
								<td>
									其他流动资产
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>此处应填写未在本会计报表内统计的所有流动资产科目汇总金额。</p>
										</div>
									</span>
								</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherCurrentAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherCurrentAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherCurrentAssets[i]?formatCurrency.formateNum(liabilities.otherCurrentAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne total totalCurrentAssets" data-class="totalCurrentAssets">
								<td>流动资产合计</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalCurrentAssets[i]" disabled />
									<span class="showInput">
										{{liabilities.totalCurrentAssets[i]?formatCurrency.formateNum(liabilities.totalCurrentAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne noInput">
								<td>非流动资产:</td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trTwo" data-class="debtInvestment">
								<td>债权投资</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.debtInvestment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'debtInvestment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.debtInvestment[i]?formatCurrency.formateNum(liabilities.debtInvestment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="otherDebtInvestment">
								<td>其他债权投资</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherDebtInvestment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherDebtInvestment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherDebtInvestment[i]?formatCurrency.formateNum(liabilities.otherDebtInvestment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="longAeceivables">
								<td>长期应收款</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.longAeceivables[i]"
										@blur="((ev)=>{blur(ev,liabilities,'longAeceivables',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.longAeceivables[i]?formatCurrency.formateNum(liabilities.longAeceivables[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="longInvestment">
								<td>长期股权投资</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.longInvestment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'longInvestment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.longInvestment[i]?formatCurrency.formateNum(liabilities.longInvestment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="otherEquityInvestment">
								<td>其他权益工具投资</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherEquityInvestment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherEquityInvestment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherEquityInvestment[i]?formatCurrency.formateNum(liabilities.otherEquityInvestment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="otherIlliquidAssets">
								<td>其他非流动金融资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherIlliquidAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherIlliquidAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherIlliquidAssets[i]?formatCurrency.formateNum(liabilities.otherIlliquidAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="" investmentProperty>
								<td>投资性房地产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.investmentProperty[i]"
										@blur="((ev)=>{blur(ev,liabilities,'investmentProperty',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.investmentProperty[i]?formatCurrency.formateNum(liabilities.investmentProperty[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo tipTerm" data-class="fixedAssets">
								<td>
									固定资产
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>此处指固定资产期末净值。</p>
										</div>
									</span>
								</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.fixedAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'fixedAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.fixedAssets[i]?formatCurrency.formateNum(liabilities.fixedAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="constructionProjects">
								<td>在建工程</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.constructionProjects[i]"
										@blur="((ev)=>{blur(ev,liabilities,'constructionProjects',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.constructionProjects[i]?formatCurrency.formateNum(liabilities.constructionProjects[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="productiveAsset">
								<td>生产性生物资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.productiveAsset[i]"
										@blur="((ev)=>{blur(ev,liabilities,'productiveAsset',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.productiveAsset[i]?formatCurrency.formateNum(liabilities.productiveAsset[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="oilAndGasAssets">
								<td>油气资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.oilAndGasAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'oilAndGasAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.oilAndGasAssets[i]?formatCurrency.formateNum(liabilities.oilAndGasAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="rightAssets">
								<td>使用权资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.rightAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'rightAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.rightAssets[i]?formatCurrency.formateNum(liabilities.rightAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="netWorth">
								<td>无形资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.netWorth[i]"
										@blur="((ev)=>{blur(ev,liabilities,'netWorth',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.netWorth[i]?formatCurrency.formateNum(liabilities.netWorth[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="developExpense">
								<td>开发支出</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.developExpense[i]"
										@blur="((ev)=>{blur(ev,liabilities,'developExpense',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.developExpense[i]?formatCurrency.formateNum(liabilities.developExpense[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="goodwill">
								<td>商誉</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.goodwill[i]"
										@blur="((ev)=>{blur(ev,liabilities,'goodwill',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.goodwill[i]?formatCurrency.formateNum(liabilities.goodwill[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="longExpenses">
								<td>长期待摊费用</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.longExpenses[i]"
										@blur="((ev)=>{blur(ev,liabilities,'longExpenses',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.longExpenses[i]?formatCurrency.formateNum(liabilities.longExpenses[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="deferredAssets">
								<td>递延所得税资产</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'deferredAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.deferredAssets[i]?formatCurrency.formateNum(liabilities.deferredAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="otherNonCurrentAssets">
								<td>
									其他非流动资产
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>此处应填写未在本会计报表内统计的所有非流动资产科目汇总金额。</p>
										</div>
									</span>
								</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherNonCurrentAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherNonCurrentAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherNonCurrentAssets[i]?formatCurrency.formateNum(liabilities.otherNonCurrentAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne total totalAssets grey">
								<td></td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trOne total totalAssets grey">
								<td></td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trOne total totalAssets grey">
								<td></td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trOne total totalAssets grey">
								<td></td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trOne total totalAssets grey">
								<td></td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trOne total totalAssets grey">
								<td></td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trOne total totalNonCurrentAssets" data-class="totalNonCurrentAssets">
								<td>非流动资产合计</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalNonCurrentAssets[i]" disabled />
									<span class="showInput">
										{{liabilities.totalNonCurrentAssets[i]?formatCurrency.formateNum(liabilities.totalNonCurrentAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne total totalAssets grey">
								<td></td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<!--资产合计-->
							<tr class="trOne total totalAssets grey" data-class="totalAssets">
								<td>资产总计</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalAssets[i]" disabled>
									<span class="showInput">
										{{liabilities.totalAssets[i]?formatCurrency.formateNum(liabilities.totalAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
					<table class="table" id="tableTwo" border="0" style="border-collapse: collapse;">
						<thead>
							<tr class="noInput">
								<th>项目</th>
								<th v-for="(item, index) in balanceSheetYearList">{{item}}</th>
							</tr>
						</thead>
						<tbody>
							<tr class="trOne even noInput">
								<td>流动负债:</td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trTwo" data-class="shortLoan">
								<td>短期借款</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.shortLoan[i]"
										@blur="((ev)=>{blur(ev,liabilities,'shortLoan',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.shortLoan[i]?formatCurrency.formateNum(liabilities.shortLoan[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="transactionLiabilities">
								<td>交易性金融负债</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.transactionLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'transactionLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.transactionLiabilities[i]?formatCurrency.formateNum(liabilities.transactionLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="derivativeLiabilities">
								<td>衍生金融负债</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.derivativeLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'derivativeLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.derivativeLiabilities[i]?formatCurrency.formateNum(liabilities.derivativeLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="notesPayable">
								<td>应付票据</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.notesPayable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'notesPayable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.notesPayable[i]?formatCurrency.formateNum(liabilities.notesPayable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="accountsPayable">
								<td>应付账款</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.accountsPayable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'accountsPayable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.accountsPayable[i]?formatCurrency.formateNum(liabilities.accountsPayable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="advanceReceipt">
								<td>预收款项</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.advanceReceipt[i]"
										@blur="((ev)=>{blur(ev,liabilities,'advanceReceipt',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.advanceReceipt[i]?formatCurrency.formateNum(liabilities.advanceReceipt[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="contractualLiabilities">
								<td>合同负债</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.contractualLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'contractualLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.contractualLiabilities[i]?formatCurrency.formateNum(liabilities.contractualLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="payrollPayable">
								<td>应付职工薪酬</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.payrollPayable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'payrollPayable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.payrollPayable[i]?formatCurrency.formateNum(liabilities.payrollPayable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="taxPayable">
								<td>应交税费</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.taxPayable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'taxPayable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.taxPayable[i]?formatCurrency.formateNum(liabilities.taxPayable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="otherPayables">
								<td>其他应付款</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherPayables[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherPayables',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherPayables[i]?formatCurrency.formateNum(liabilities.otherPayables[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="saleForLiabilities">
								<td>持有待售负债</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.saleForLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'saleForLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.saleForLiabilities[i]?formatCurrency.formateNum(liabilities.saleForLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="illiquidLiabilitiesOne">
								<td>一年内到期的非流动负债</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.illiquidLiabilitiesOne[i]"
										@blur="((ev)=>{blur(ev,liabilities,'illiquidLiabilitiesOne',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.illiquidLiabilitiesOne[i]?formatCurrency.formateNum(liabilities.illiquidLiabilitiesOne[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="otherNonLiabilities">
								<td>
									其他流动负债
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>此处应填写未在本会计报表内统计的所有流动负债科目汇总金额。</p>
										</div>
									</span>
								</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherNonLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherNonLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherNonLiabilities[i]?formatCurrency.formateNum(liabilities.otherNonLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne total totalCurrentAssets" data-class="totalNonLiabilities">
								<td>流动负债合计</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalNonLiabilities[i]" disabled />
									<span class="showInput">
										{{liabilities.totalNonLiabilities[i]?formatCurrency.formateNum(liabilities.totalNonLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne noInput">
								<td>非流动负债:</td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trTwo" data-class="longLoan">
								<td>长期借款</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.longLoan[i]"
										@blur="((ev)=>{blur(ev,liabilities,'longLoan',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.longLoan[i]?formatCurrency.formateNum(liabilities.longLoan[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="bondsPayable">
								<td>应付债券</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPayable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'bondsPayable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.bondsPayable[i]?formatCurrency.formateNum(liabilities.bondsPayable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trThtrr" data-class="bondsPreferredStock">
								<td>其中：优先股</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPreferredStock[i]"
										@blur="((ev)=>{blur(ev,liabilities,'bondsPreferredStock',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.bondsPreferredStock[i]?formatCurrency.formateNum(liabilities.bondsPreferredStock[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trFour" data-class="bondsPerpetuaDebt">
								<td>永续债</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPerpetuaDebt[i]"
										@blur="((ev)=>{blur(ev,liabilities,'bondsPerpetuaDebt',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.bondsPerpetuaDebt[i]?formatCurrency.formateNum(liabilities.bondsPerpetuaDebt[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="leaseLiabilities">
								<td>租赁负债</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.leaseLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'leaseLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.leaseLiabilities[i]?formatCurrency.formateNum(liabilities.leaseLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="longPayment">
								<td>长期应付款</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.longPayment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'longPayment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.longPayment[i]?formatCurrency.formateNum(liabilities.longPayment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="estimatedLiabilities">
								<td>预计负债</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.estimatedLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'estimatedLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.estimatedLiabilities[i]?formatCurrency.formateNum(liabilities.estimatedLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="deferredIncome">
								<td>递延收益</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredIncome[i]"
										@blur="((ev)=>{blur(ev,liabilities,'deferredIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.deferredIncome[i]?formatCurrency.formateNum(liabilities.deferredIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="deferredLiability">
								<td>递延所得税负债</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredLiability[i]"
										@blur="((ev)=>{blur(ev,liabilities,'deferredLiability',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.deferredLiability[i]?formatCurrency.formateNum(liabilities.deferredLiability[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="otherFixedLiabilities">
								<td>
									其他非流动负债
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>此处应填写未在本会计报表内统计的所有非流动负债科目汇总金额。</p>
										</div>
									</span>
								</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherFixedLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherFixedLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherFixedLiabilities[i]?formatCurrency.formateNum(liabilities.otherFixedLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne" data-class="totalFixedLiabilities">
								<td>非流动负债合计</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalFixedLiabilities[i]" disabled />
									<span class="showInput">
										{{liabilities.totalFixedLiabilities[i]?formatCurrency.formateNum(liabilities.totalFixedLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne" data-class="totalLiabilities">
								<td>负债总计</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalLiabilities[i]" disabled />
									<span class="showInput">
										{{liabilities.totalLiabilities[i]?formatCurrency.formateNum(liabilities.totalLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne">
								<td>
									所有者权益(或股东权益):
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>是指所有者向企业投入的资本总和。</p>
										</div>
									</span>
								</td>
								<td v-for="(item, i) in balanceSheetYearList"></td>
							</tr>
							<tr class="trTwo" data-class="paidUpCapital">
								<td>实收资本(或股本)</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.paidUpCapital[i]"
										@blur="((ev)=>{blur(ev,liabilities,'paidUpCapital',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.paidUpCapital[i]?formatCurrency.formateNum(liabilities.paidUpCapital[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="otherEquity">
								<td>其他权益工具</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherEquity[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherEquity',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherEquity[i]?formatCurrency.formateNum(liabilities.otherEquity[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="equityPreferredStock">
								<td>其中：优先股</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.equityPreferredStock[i]"
										@blur="((ev)=>{blur(ev,liabilities,'equityPreferredStock',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.equityPreferredStock[i]?formatCurrency.formateNum(liabilities.equityPreferredStock[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="equityPerpetuaDebt">
								<td>永续债</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.equityPerpetuaDebt[i]"
										@blur="((ev)=>{blur(ev,liabilities,'equityPerpetuaDebt',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.equityPerpetuaDebt[i]?formatCurrency.formateNum(liabilities.equityPerpetuaDebt[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="capitalReserve">
								<td>资本公积</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.capitalReserve[i]"
										@blur="((ev)=>{blur(ev,liabilities,'capitalReserve',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.capitalReserve[i]?formatCurrency.formateNum(liabilities.capitalReserve[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="treasuryShare">
								<td>减：库存股</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.treasuryShare[i]"
										@blur="((ev)=>{blur(ev,liabilities,'treasuryShare',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.treasuryShare[i]?formatCurrency.formateNum(liabilities.treasuryShare[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="">
								<td>其他综合收益</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherIncome[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherIncome[i]?formatCurrency.formateNum(liabilities.otherIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="appropriativeReserve">
								<td>专项储备</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.appropriativeReserve[i]"
										@blur="((ev)=>{blur(ev,liabilities,'appropriativeReserve',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.appropriativeReserve[i]?formatCurrency.formateNum(liabilities.appropriativeReserve[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="earnedSurplus">
								<td>盈余公积</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.earnedSurplus[i]"
										@blur="((ev)=>{blur(ev,liabilities,'earnedSurplus',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.earnedSurplus[i]?formatCurrency.formateNum(liabilities.earnedSurplus[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="undistributedProfit">
								<td>未分配利润</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.undistributedProfit[i]"
										@blur="((ev)=>{blur(ev,liabilities,'undistributedProfit',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.undistributedProfit[i]?formatCurrency.formateNum(liabilities.undistributedProfit[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trTwo" data-class="totalParentEquity">
								<td>
									归属于母公司所有者权益合计
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>是指公司集团的所有者权益中归属于母公司所有者权益的部分。</p>
										</div>
									</span>
								</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalParentEquity[i]" disabled />
									<span class="showInput">
										{{liabilities.totalParentEquity[i]?formatCurrency.formateNum(liabilities.totalParentEquity[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne" data-class="minorityEquity">
								<td>
									少数股东权益
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>是指母公司以外的的人拥有的权益，此项针对合并报表企业，如果无少数股东权益或非合并报表企业，则填零。</p>
										</div>
									</span>
								</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.minorityEquity[i]"
										@blur="((ev)=>{blur(ev,liabilities,'minorityEquity',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.minorityEquity[i]?formatCurrency.formateNum(liabilities.minorityEquity[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne" data-class="totalOwnershipInterest">
								<td>所有者权益（或股东权益)合计</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalOwnershipInterest[i]" disabled />
									<span class="showInput">
										{{liabilities.totalOwnershipInterest[i]?formatCurrency.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr class="trOne" data-class="totalLiabilitiesEquity">
								<td>负债和所有者权益(或股东权益)总计</td>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalLiabilitiesEquity[i]" disabled />
									<span class="showInput">
										{{liabilities.totalLiabilitiesEquity[i]?formatCurrency.formateNum(liabilities.totalLiabilitiesEquity[i],2):'-'}}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!-- 移动端资产负债表 -->
				<div class="mobileBalanceSheet">
					<ul>
						<li v-for="(item, index) in balanceSheetYearList">{{item}}</li>
						<!-- <li v-if="this.$route.query.type==7"></li> -->
					</ul>
					<table border="0" style="border-collapse: collapse;">
						<tbody>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">流动资产:</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">货币资金</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.monetaryCapital[i]"
										@blur="((ev)=>{blur(ev,liabilities,'monetaryCapital',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.monetaryCapital[i]?formatCurrency.formateNum(liabilities.monetaryCapital[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">交易性金融资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.tradingAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'tradingAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.tradingAssets[i]?formatCurrency.formateNum(liabilities.tradingAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">衍生金融资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.derivativeAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'derivativeAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.derivativeAssets[i]?formatCurrency.formateNum(liabilities.derivativeAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">应收票据</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.billReceivable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'billReceivable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.billReceivable[i]?formatCurrency.formateNum(liabilities.billReceivable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">应收账款</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.receivables[i]"
										@blur="((ev)=>{blur(ev,liabilities,'receivables',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.receivables[i]?formatCurrency.formateNum(liabilities.receivables[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">应收账款融资</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.accountReceivable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'accountReceivable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.accountReceivable[i]?formatCurrency.formateNum(liabilities.accountReceivable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">预付款项</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.prepayment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'prepayment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.prepayment[i]?formatCurrency.formateNum(liabilities.prepayment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其他应收款</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherReceivables[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherReceivables',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherReceivables[i]?formatCurrency.formateNum(liabilities.otherReceivables[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">存货</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.inventory[i]"
										@blur="((ev)=>{blur(ev,liabilities,'inventory',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.inventory[i]?formatCurrency.formateNum(liabilities.inventory[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">合同资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.contractualAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'contractualAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.contractualAssets[i]?formatCurrency.formateNum(liabilities.contractualAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">持有待售资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.saleForAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'saleForAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.saleForAssets[i]?formatCurrency.formateNum(liabilities.saleForAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">一年内到期的非流动资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.illiquidAssetsOne[i]"
										@blur="((ev)=>{blur(ev,liabilities,'illiquidAssetsOne',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.illiquidAssetsOne[i]?formatCurrency.formateNum(liabilities.illiquidAssetsOne[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">
									其他流动资产
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>此处应填写未在本会计报表内统计的所有流动资产科目汇总金额。</p>
										</div>
									</span>
								</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherCurrentAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherCurrentAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherCurrentAssets[i]?formatCurrency.formateNum(liabilities.otherCurrentAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">流动资产合计</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalCurrentAssets[i]" disabled />
									<span class="showInput">
										{{liabilities.totalCurrentAssets[i]?formatCurrency.formateNum(liabilities.totalCurrentAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">非流动资产:</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">债权投资</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.debtInvestment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'debtInvestment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.debtInvestment[i]?formatCurrency.formateNum(liabilities.debtInvestment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其他债权投资</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherDebtInvestment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherDebtInvestment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherDebtInvestment[i]?formatCurrency.formateNum(liabilities.otherDebtInvestment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">长期应收款</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.longAeceivables[i]"
										@blur="((ev)=>{blur(ev,liabilities,'longAeceivables',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.longAeceivables[i]?formatCurrency.formateNum(liabilities.longAeceivables[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">长期股权投资</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.longInvestment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'longInvestment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.longInvestment[i]?formatCurrency.formateNum(liabilities.longInvestment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其他权益工具投资</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherEquityInvestment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherEquityInvestment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherEquityInvestment[i]?formatCurrency.formateNum(liabilities.otherEquityInvestment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其他非流动金融资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherIlliquidAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherIlliquidAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherIlliquidAssets[i]?formatCurrency.formateNum(liabilities.otherIlliquidAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">投资性房地产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.investmentProperty[i]"
										@blur="((ev)=>{blur(ev,liabilities,'investmentProperty',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.investmentProperty[i]?formatCurrency.formateNum(liabilities.investmentProperty[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">
									固定资产
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>此处指固定资产期末净值。</p>
										</div>
									</span>
								</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.fixedAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'fixedAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.fixedAssets[i]?formatCurrency.formateNum(liabilities.fixedAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">在建工程</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.constructionProjects[i]"
										@blur="((ev)=>{blur(ev,liabilities,'constructionProjects',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.constructionProjects[i]?formatCurrency.formateNum(liabilities.constructionProjects[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">生产性生物资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.productiveAsset[i]"
										@blur="((ev)=>{blur(ev,liabilities,'productiveAsset',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.productiveAsset[i]?formatCurrency.formateNum(liabilities.productiveAsset[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">油气资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.oilAndGasAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'oilAndGasAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.oilAndGasAssets[i]?formatCurrency.formateNum(liabilities.oilAndGasAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">使用权资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.rightAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'rightAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.rightAssets[i]?formatCurrency.formateNum(liabilities.rightAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">无形资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.netWorth[i]"
										@blur="((ev)=>{blur(ev,liabilities,'netWorth',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.netWorth[i]?formatCurrency.formateNum(liabilities.netWorth[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">开发支出</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.developExpense[i]"
										@blur="((ev)=>{blur(ev,liabilities,'developExpense',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.developExpense[i]?formatCurrency.formateNum(liabilities.developExpense[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">商誉</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.goodwill[i]"
										@blur="((ev)=>{blur(ev,liabilities,'goodwill',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.goodwill[i]?formatCurrency.formateNum(liabilities.goodwill[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">长期待摊费用</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.longExpenses[i]"
										@blur="((ev)=>{blur(ev,liabilities,'longExpenses',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.longExpenses[i]?formatCurrency.formateNum(liabilities.longExpenses[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">递延所得税资产</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'deferredAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.deferredAssets[i]?formatCurrency.formateNum(liabilities.deferredAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">
									其他非流动资产
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>此处应填写未在本会计报表内统计的所有非流动资产科目汇总金额。</p>
										</div>
									</span>
								</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherNonCurrentAssets[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherNonCurrentAssets',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherNonCurrentAssets[i]?formatCurrency.formateNum(liabilities.otherNonCurrentAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">非流动资产合计</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalNonCurrentAssets[i]" disabled />
									<span class="showInput">
										{{liabilities.totalNonCurrentAssets[i]?formatCurrency.formateNum(liabilities.totalNonCurrentAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">资产总计</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.totalAssets[i]" disabled>
									<span class="showInput">
										{{liabilities.totalAssets[i]?formatCurrency.formateNum(liabilities.totalAssets[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">流动负债:</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">短期借款</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.shortLoan[i]"
										@blur="((ev)=>{blur(ev,liabilities,'shortLoan',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.shortLoan[i]?formatCurrency.formateNum(liabilities.shortLoan[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">交易性金融负债</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.transactionLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'transactionLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.transactionLiabilities[i]?formatCurrency.formateNum(liabilities.transactionLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">衍生金融负债</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.derivativeLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'derivativeLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.derivativeLiabilities[i]?formatCurrency.formateNum(liabilities.derivativeLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">应付票据</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.notesPayable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'notesPayable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.notesPayable[i]?formatCurrency.formateNum(liabilities.notesPayable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">应付账款</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.accountsPayable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'accountsPayable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.accountsPayable[i]?formatCurrency.formateNum(liabilities.accountsPayable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">预收款项</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.advanceReceipt[i]"
										@blur="((ev)=>{blur(ev,liabilities,'advanceReceipt',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.advanceReceipt[i]?formatCurrency.formateNum(liabilities.advanceReceipt[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">合同负债</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.contractualLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'contractualLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.contractualLiabilities[i]?formatCurrency.formateNum(liabilities.contractualLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">应付职工薪酬</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.payrollPayable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'payrollPayable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.payrollPayable[i]?formatCurrency.formateNum(liabilities.payrollPayable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">应交税费</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.taxPayable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'taxPayable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.taxPayable[i]?formatCurrency.formateNum(liabilities.taxPayable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其他应付款</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherPayables[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherPayables',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherPayables[i]?formatCurrency.formateNum(liabilities.otherPayables[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">持有待售负债</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.saleForLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'saleForLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.saleForLiabilities[i]?formatCurrency.formateNum(liabilities.saleForLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">一年内到期的非流动负债</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.illiquidLiabilitiesOne[i]"
										@blur="((ev)=>{blur(ev,liabilities,'illiquidLiabilitiesOne',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.illiquidLiabilitiesOne[i]?formatCurrency.formateNum(liabilities.illiquidLiabilitiesOne[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">
									其他流动负债
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>此处应填写未在本会计报表内统计的所有流动负债科目汇总金额。</p>
										</div>
									</span>
								</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherNonLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherNonLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherNonLiabilities[i]?formatCurrency.formateNum(liabilities.otherNonLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">流动负债合计</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalNonLiabilities[i]" disabled />
									<span class="showInput">
										{{liabilities.totalNonLiabilities[i]?formatCurrency.formateNum(liabilities.totalNonLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">非流动负债:</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">长期借款</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.longLoan[i]"
										@blur="((ev)=>{blur(ev,liabilities,'longLoan',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.longLoan[i]?formatCurrency.formateNum(liabilities.longLoan[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">应付债券</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPayable[i]"
										@blur="((ev)=>{blur(ev,liabilities,'bondsPayable',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.bondsPayable[i]?formatCurrency.formateNum(liabilities.bondsPayable[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其中：优先股</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPreferredStock[i]"
										@blur="((ev)=>{blur(ev,liabilities,'bondsPreferredStock',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.bondsPreferredStock[i]?formatCurrency.formateNum(liabilities.bondsPreferredStock[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">永续债</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPerpetuaDebt[i]"
										@blur="((ev)=>{blur(ev,liabilities,'bondsPerpetuaDebt',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.bondsPerpetuaDebt[i]?formatCurrency.formateNum(liabilities.bondsPerpetuaDebt[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">租赁负债</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.leaseLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'leaseLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.leaseLiabilities[i]?formatCurrency.formateNum(liabilities.leaseLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">长期应付款</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.longPayment[i]"
										@blur="((ev)=>{blur(ev,liabilities,'longPayment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.longPayment[i]?formatCurrency.formateNum(liabilities.longPayment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">预计负债</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.estimatedLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'estimatedLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.estimatedLiabilities[i]?formatCurrency.formateNum(liabilities.estimatedLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">递延收益</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredIncome[i]"
										@blur="((ev)=>{blur(ev,liabilities,'deferredIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.deferredIncome[i]?formatCurrency.formateNum(liabilities.deferredIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">递延所得税负债</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredLiability[i]"
										@blur="((ev)=>{blur(ev,liabilities,'deferredLiability',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.deferredLiability[i]?formatCurrency.formateNum(liabilities.deferredLiability[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">
									其他非流动负债
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>此处应填写未在本会计报表内统计的所有非流动负债科目汇总金额。</p>
										</div>
									</span>
								</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherFixedLiabilities[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherFixedLiabilities',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherFixedLiabilities[i]?formatCurrency.formateNum(liabilities.otherFixedLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">非流动负债合计</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalFixedLiabilities[i]" disabled />
									<span class="showInput">
										{{liabilities.totalFixedLiabilities[i]?formatCurrency.formateNum(liabilities.totalFixedLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">负债总计</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalLiabilities[i]" disabled />
									<span class="showInput">
										{{liabilities.totalLiabilities[i]?formatCurrency.formateNum(liabilities.totalLiabilities[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">
									所有者权益(或股东权益):
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>是指所有者向企业投入的资本总和。</p>
										</div>
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">实收资本(或股本)</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.paidUpCapital[i]"
										@blur="((ev)=>{blur(ev,liabilities,'paidUpCapital',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.paidUpCapital[i]?formatCurrency.formateNum(liabilities.paidUpCapital[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其他权益工具</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherEquity[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherEquity',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherEquity[i]?formatCurrency.formateNum(liabilities.otherEquity[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其中：优先股</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.equityPreferredStock[i]"
										@blur="((ev)=>{blur(ev,liabilities,'equityPreferredStock',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.equityPreferredStock[i]?formatCurrency.formateNum(liabilities.equityPreferredStock[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">永续债</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.equityPerpetuaDebt[i]"
										@blur="((ev)=>{blur(ev,liabilities,'equityPerpetuaDebt',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.equityPerpetuaDebt[i]?formatCurrency.formateNum(liabilities.equityPerpetuaDebt[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">资本公积</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.capitalReserve[i]"
										@blur="((ev)=>{blur(ev,liabilities,'capitalReserve',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.capitalReserve[i]?formatCurrency.formateNum(liabilities.capitalReserve[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">减：库存股</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.treasuryShare[i]"
										@blur="((ev)=>{blur(ev,liabilities,'treasuryShare',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.treasuryShare[i]?formatCurrency.formateNum(liabilities.treasuryShare[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其他综合收益</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherIncome[i]"
										@blur="((ev)=>{blur(ev,liabilities,'otherIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.otherIncome[i]?formatCurrency.formateNum(liabilities.otherIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">专项储备</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.appropriativeReserve[i]"
										@blur="((ev)=>{blur(ev,liabilities,'appropriativeReserve',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.appropriativeReserve[i]?formatCurrency.formateNum(liabilities.appropriativeReserve[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">盈余公积</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.earnedSurplus[i]"
										@blur="((ev)=>{blur(ev,liabilities,'earnedSurplus',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.earnedSurplus[i]?formatCurrency.formateNum(liabilities.earnedSurplus[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">未分配利润</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.undistributedProfit[i]"
										@blur="((ev)=>{blur(ev,liabilities,'undistributedProfit',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.undistributedProfit[i]?formatCurrency.formateNum(liabilities.undistributedProfit[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">
									归属于母公司所有者权益合计
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>是指公司集团的所有者权益中归属于母公司所有者权益的部分。</p>
										</div>
									</span>
								</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalParentEquity[i]" disabled />
									<span class="showInput">
										{{liabilities.totalParentEquity[i]?formatCurrency.formateNum(liabilities.totalParentEquity[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">
									少数股东权益
									<span class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>是指母公司以外的的人拥有的权益，此项针对合并报表企业，如果无少数股东权益或非合并报表企业，则填零。</p>
										</div>
									</span>
								</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" ref="forbid" v-model="liabilities.minorityEquity[i]"
										@blur="((ev)=>{blur(ev,liabilities,'minorityEquity',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{liabilities.minorityEquity[i]?formatCurrency.formateNum(liabilities.minorityEquity[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">所有者权益（或股东权益)合计</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalOwnershipInterest[i]" disabled />
									<span class="showInput">
										{{liabilities.totalOwnershipInterest[i]?formatCurrency.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">负债和所有者权益(或股东权益)总计</td>
							</tr>
							<tr>
								<td v-for="(item, i) in balanceSheetYearList">
									<input type="number" data-type="number" v-model="liabilities.totalLiabilitiesEquity[i]" disabled />
									<span class="showInput">
										{{liabilities.totalLiabilitiesEquity[i]?formatCurrency.formateNum(liabilities.totalLiabilitiesEquity[i],2):'-'}}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- 利润表 -->
			<div :class="['table-main','major-profit',this.$route.query.type==7?'diagnoseProfit':'']">
				<!-- 表名-->
				<div class="table-name">
					<span>利润表</span>
				</div>
				<!--单位简介-->
				<span class="unit">单位：元</span>
				<table class="table" border="0" style="border-collapse: collapse;">
					<thead>
						<tr class="noInput">
							<th>项目</th>
							<th v-for="(item, index) in profitYearList">{{item}}</th>
						</tr>
					</thead>
					<tbody>
						<tr class="trOne tipTerm" data-class="income">
							<td>一、营业收入</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.income[i]"
									@blur="((ev)=>{blur(ev,profit,'income',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.income[i]?formatCurrency.formateNum(profit.income[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo tipTerm" data-class="cost">
							<td>减:营业成本</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.cost[i]"
									@blur="((ev)=>{blur(ev,profit,'cost',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.cost[i]?formatCurrency.formateNum(profit.cost[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree tipTerm" data-class="taxAdditional">
							<td>税金及附加</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.taxAdditional[i]"
									@blur="((ev)=>{blur(ev,profit,'taxAdditional',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.taxAdditional[i]?formatCurrency.formateNum(profit.taxAdditional[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree tipTerm" data-class="saleCost">
							<td>销售费用</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.saleCost[i]"
									@blur="((ev)=>{blur(ev,profit,'saleCost',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.saleCost[i]?formatCurrency.formateNum(profit.saleCost[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree tipTerm" data-class="manageCost">
							<td>管理费用</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.manageCost[i]"
									@blur="((ev)=>{blur(ev,profit,'manageCost',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.manageCost[i]?formatCurrency.formateNum(profit.manageCost[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree" data-class="researchCost">
							<td>研发费用</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.researchCost[i]"
									@blur="((ev)=>{blur(ev,profit,'researchCost',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.researchCost[i]?formatCurrency.formateNum(profit.researchCost[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree tipTerm" data-class="financeCost">
							<td>财务费用</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.financeCost[i]"
									@blur="((ev)=>{blur(ev,profit,'financeCost',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.financeCost[i]?formatCurrency.formateNum(profit.financeCost[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour tipTerm" data-class="interestCost">
							<td>其中:利息支出</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.interestCost[i]"
									@blur="((ev)=>{blur(ev,profit,'interestCost',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.interestCost[i]?formatCurrency.formateNum(profit.interestCost[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="tipTerm" data-class="interestIncome">
							<td class="interestIncome">利息收入</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.interestIncome[i]"
									@blur="((ev)=>{blur(ev,profit,'interestIncome',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.interestIncome[i]?formatCurrency.formateNum(profit.interestIncome[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo" data-class="otherBenefits">
							<td>加:其他收益</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.otherBenefits[i]"
									@blur="((ev)=>{blur(ev,profit,'otherBenefits',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.otherBenefits[i]?formatCurrency.formateNum(profit.otherBenefits[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree" data-class="investmentIncome">
							<td>投资收益(损失以"-"号填列)</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.investmentIncome[i]"
									@blur="((ev)=>{blur(ev,profit,'investmentIncome',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.investmentIncome[i]?formatCurrency.formateNum(profit.investmentIncome[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="enterpriseIncome">
							<td>其中:对联营企业和合营企业的投资收益</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.enterpriseIncome[i]"
									@blur="((ev)=>{blur(ev,profit,'enterpriseIncome',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.enterpriseIncome[i]?formatCurrency.formateNum(profit.enterpriseIncome[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr data-class="financialAssetsBenefits">
							<td class="financialAssetsBenefits">以摊余成本计量的金融资产终止确认收益（损失以"-"号填列）</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.financialAssetsBenefits[i]"
									@blur="((ev)=>{blur(ev,profit,'financialAssetsBenefits',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.financialAssetsBenefits[i]?formatCurrency.formateNum(profit.financialAssetsBenefits[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree" data-class="netExposureHedgeIncome">
							<td>净敞口套期收益（损失以"-"号填列）</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.netExposureHedgeIncome[i]"
									@blur="((ev)=>{blur(ev,profit,'netExposureHedgeIncome',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.netExposureHedgeIncome[i]?formatCurrency.formateNum(profit.netExposureHedgeIncome[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree" data-class="fairValueIncome">
							<td>公允价值变动收益(损失以"-"号填列)</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.fairValueIncome[i]"
									@blur="((ev)=>{blur(ev,profit,'fairValueIncome',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.fairValueIncome[i]?formatCurrency.formateNum(profit.fairValueIncome[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree" data-class="creditImpairmentLoss">
							<td>信用减值损失(损失以"-"号填列)</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.creditImpairmentLoss[i]"
									@blur="((ev)=>{blur(ev,profit,'creditImpairmentLoss',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.creditImpairmentLoss[i]?formatCurrency.formateNum(profit.creditImpairmentLoss[i],2):'-'}}
								</span>
							</td>
						</tr>

						<tr class="trThree" data-class="assetLoss">
							<td>资产减值损失(损失以"-"号填列)</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.assetLoss[i]"
									@blur="((ev)=>{blur(ev,profit,'assetLoss',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.assetLoss[i]?formatCurrency.formateNum(profit.assetLoss[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree" data-class="assetDisposalIncome">
							<td>资产处置收益（损失以“-”号填列）</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.assetDisposalIncome[i]"
									@blur="((ev)=>{blur(ev,profit,'assetDisposalIncome',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.assetDisposalIncome[i]?formatCurrency.formateNum(profit.assetDisposalIncome[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trOne" data-class="businessProfit">
							<td>二、营业利润(亏损以"-"号填列)</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" v-model="profit.businessProfit[i]" disabled>
								<span class="showInput">
									{{profit.businessProfit[i]?formatCurrency.formateNum(profit.businessProfit[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo" data-class="outsideIncome">
							<td>加:营业外收入</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.outsideIncome[i]"
									@blur="((ev)=>{blur(ev,profit,'outsideIncome',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.outsideIncome[i]?formatCurrency.formateNum(profit.outsideIncome[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo" data-class="outsideExpenditure">
							<td>减:营业外支出</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.outsideExpenditure[i]"
									@blur="((ev)=>{blur(ev,profit,'outsideExpenditure',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.outsideExpenditure[i]?formatCurrency.formateNum(profit.outsideExpenditure[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trOne" data-class="totalProfit">
							<td>三、利润总额（亏损总额以“-”号填列)</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" v-model="profit.totalProfit[i]" disabled>
								<span class="showInput">
									{{profit.totalProfit[i]?formatCurrency.formateNum(profit.totalProfit[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo tipTerm" data-class="incomeTax">
							<td>减:所得税费用</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.incomeTax[i]"
									@blur="((ev)=>{blur(ev,profit,'incomeTax',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.incomeTax[i]?formatCurrency.formateNum(profit.incomeTax[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trOne" data-class="netProfit">
							<td>四、净利润(净亏损以"-"号填列)</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" v-model="profit.netProfit[i]" disabled>
								<span class="showInput">
									{{profit.netProfit[i]?formatCurrency.formateNum(profit.netProfit[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo even noInput">
							<td>(一)按经营持续性分类</td>
							<td v-for="(item, i) in profitYearList"></td>
						</tr>
						<tr class="trThree" data-class="goingConcernNetProfit">
							<td>1.持续经营净利润（净亏损以"-"号填列）</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.goingConcernNetProfit[i]"
									@blur="((ev)=>{blur(ev,profit,'goingConcernNetProfit',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.goingConcernNetProfit[i]?formatCurrency.formateNum(profit.goingConcernNetProfit[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree" data-class="discontinuedOperationsNetProfit">
							<td>2.终止经营净利润（净亏损以"-"号填列)</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.discontinuedOperationsNetProfit[i]"
									@blur="((ev)=>{blur(ev,profit,'discontinuedOperationsNetProfit',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.discontinuedOperationsNetProfit[i]?formatCurrency.formateNum(profit.discontinuedOperationsNetProfit[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo even noInput">
							<td>(二)按所有权归属分类</td>
							<td v-for="(item, i) in profitYearList"></td>
						</tr>
						<tr class="trThree" data-class="parentCompanyNetProfit">
							<td>1.归属于母公司股东的净利润（净亏损以“"-"号填列）</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.parentCompanyNetProfit[i]"
									@blur="((ev)=>{blur(ev,profit,'parentCompanyNetProfit',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.parentCompanyNetProfit[i]?formatCurrency.formateNum(profit.parentCompanyNetProfit[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree" data-class="minorityStockLegalRight">
							<td>2.少数股东损益（净亏损以“"-"号填列）</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.minorityStockLegalRight[i]"
									@blur="((ev)=>{blur(ev,profit,'minorityStockLegalRight',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.minorityStockLegalRight[i]?formatCurrency.formateNum(profit.minorityStockLegalRight[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trOne" data-class="ociNetTax">
							<td>五、其他综合收益的税后净额</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" v-model="profit.ociNetTax[i]" disabled>
								<span class="showInput">
									{{profit.ociNetTax[i]?formatCurrency.formateNum(profit.ociNetTax[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo" data-class="parentCompanyOciNetTax">
							<td>(一)归属于母公司所有者的其他综合收益的税后净额</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" v-model="profit.parentCompanyOciNetTax[i]" disabled>
								<span class="showInput">
									{{profit.parentCompanyOciNetTax[i]?formatCurrency.formateNum(profit.parentCompanyOciNetTax[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree" data-class="notReclassifiedOci">
							<td>1.不能重分类进损益的其他综合收益</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" v-model="profit.notReclassifiedOci[i]" disabled>
								<span class="showInput">
									{{profit.notReclassifiedOci[i]?formatCurrency.formateNum(profit.notReclassifiedOci[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="remeasureBenefitAmount">
							<td>(1)重新计量设定受益计划变动额</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.remeasureBenefitAmount[i]"
									@blur="((ev)=>{blur(ev,profit,'remeasureBenefitAmount',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.remeasureBenefitAmount[i]?formatCurrency.formateNum(profit.remeasureBenefitAmount[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="notTransferOci">
							<td>(2)权益法下不能转损益的其他综合收益</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.notTransferOci[i]"
									@blur="((ev)=>{blur(ev,profit,'notTransferOci',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.notTransferOci[i]?formatCurrency.formateNum(profit.notTransferOci[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="otherEquityFairValue">
							<td>(3)其他权益工具投资公允价值变动</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.otherEquityFairValue[i]"
									@blur="((ev)=>{blur(ev,profit,'otherEquityFairValue',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.otherEquityFairValue[i]?formatCurrency.formateNum(profit.otherEquityFairValue[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="enterpriseCreditRiskFairValue">
							<td>(4)企业自身信用风险公允价值变动</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.enterpriseCreditRiskFairValue[i]"
									@blur="((ev)=>{blur(ev,profit,'enterpriseCreditRiskFairValue',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.enterpriseCreditRiskFairValue[i]?formatCurrency.formateNum(profit.enterpriseCreditRiskFairValue[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trThree" data-class="reclassifiedOci">
							<td>2.将重分类进损益的其他综合收益</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" v-model="profit.reclassifiedOci[i]" disabled>
								<span class="showInput">
									{{profit.reclassifiedOci[i]?formatCurrency.formateNum(profit.reclassifiedOci[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="transferOci">
							<td>(1)权益法下可转损益的其他综合收益</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.transferOci[i]"
									@blur="((ev)=>{blur(ev,profit,'transferOci',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.transferOci[i]?formatCurrency.formateNum(profit.transferOci[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="otherClaimsFairValue">
							<td>(2)其他债权投资公允价值变动</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.otherClaimsFairValue[i]"
									@blur="((ev)=>{blur(ev,profit,'otherClaimsFairValue',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.otherClaimsFairValue[i]?formatCurrency.formateNum(profit.otherClaimsFairValue[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="financialAssetsOciAmount">
							<td>(3)金融资产重分类计入其他综合收益的金额</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.financialAssetsOciAmount[i]"
									@blur="((ev)=>{blur(ev,profit,'financialAssetsOciAmount',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.financialAssetsOciAmount[i]?formatCurrency.formateNum(profit.financialAssetsOciAmount[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="otherClaimsCreditImpairment">
							<td>(4)其他债权投资信用减值准备</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.otherClaimsCreditImpairment[i]"
									@blur="((ev)=>{blur(ev,profit,'otherClaimsCreditImpairment',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.otherClaimsCreditImpairment[i]?formatCurrency.formateNum(profit.otherClaimsCreditImpairment[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="cashFlowHedgingReserve">
							<td>(5)现金流量套期储备</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.cashFlowHedgingReserve[i]"
									@blur="((ev)=>{blur(ev,profit,'cashFlowHedgingReserve',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.cashFlowHedgingReserve[i]?formatCurrency.formateNum(profit.cashFlowHedgingReserve[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trFour" data-class="foreignCurrencyBalance">
							<td>(6)外币财务报表折算差额</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.foreignCurrencyBalance[i]"
									@blur="((ev)=>{blur(ev,profit,'foreignCurrencyBalance',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.foreignCurrencyBalance[i]?formatCurrency.formateNum(profit.foreignCurrencyBalance[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo" data-class="minorityStockOciNetTax">
							<td>(二)归属于少数股东的其他综合收益的税后净额</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.minorityStockOciNetTax[i]"
									@blur="((ev)=>{blur(ev,profit,'minorityStockOciNetTax',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.minorityStockOciNetTax[i]?formatCurrency.formateNum(profit.minorityStockOciNetTax[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trOne" data-class="totalComprehensiveIncome">
							<td>六、综合收益总额</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" v-model="profit.totalComprehensiveIncome[i]" disabled>
								<span class="showInput">
									{{profit.totalComprehensiveIncome[i]?formatCurrency.formateNum(profit.totalComprehensiveIncome[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo" data-class="parentCompanyTci">
							<td>(一)归属于母公司所有者的综合收益总额</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" v-model="profit.parentCompanyTci[i]" disabled>
								<span class="showInput">
									{{profit.parentCompanyTci[i]?formatCurrency.formateNum(profit.parentCompanyTci[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo" data-class="minorityStockTci">
							<td>(二)归属于少数股东的综合收益总额</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" v-model="profit.minorityStockTci[i]" disabled>
								<span class="showInput">
									{{profit.minorityStockTci[i]?formatCurrency.formateNum(profit.minorityStockTci[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trOne" data-class="earningsPerShare">
							<td>七、每股收益</td>
							<td v-for="(item, i) in profitYearList"></td>
						</tr>
						<tr class="trTwo" data-class="basicEps">
							<td>(一)基本每股收益</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.basicEps[i]"
									@blur="((ev)=>{blur(ev,profit,'basicEps',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.basicEps[i]?formatCurrency.formateNum(profit.basicEps[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr class="trTwo" data-class="dilutedEps">
							<td>(二)稀释每股收益</td>
							<td v-for="(item, i) in profitYearList">
								<input type="number" data-type="number" ref="forbid" v-model="profit.dilutedEps[i]"
									@blur="((ev)=>{blur(ev,profit,'dilutedEps',i)})" @focus="focus($event)" />
								<span class="showInput" @click="inputFocus($event)">
									{{profit.dilutedEps[i]?formatCurrency.formateNum(profit.dilutedEps[i],2):'-'}}
								</span>
							</td>
						</tr>
					</tbody>
				</table>

				<!-- 移动端利润表 -->
				<div class="mobileProfit">
					<ul>
						<li v-for="(item, index) in profitYearList">{{item}}</li>
						<!-- <li v-if="this.$route.query.type==7"></li> -->
					</ul>
					<table border="0" style="border-collapse: collapse;">
						<tbody>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">一、营业收入</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.income[i]"
										@blur="((ev)=>{blur(ev,profit,'income',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.income[i]?formatCurrency.formateNum(profit.income[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">减:营业成本</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.cost[i]"
										@blur="((ev)=>{blur(ev,profit,'cost',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.cost[i]?formatCurrency.formateNum(profit.cost[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">税金及附加</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.taxAdditional[i]"
										@blur="((ev)=>{blur(ev,profit,'taxAdditional',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.taxAdditional[i]?formatCurrency.formateNum(profit.taxAdditional[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">销售费用</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.saleCost[i]"
										@blur="((ev)=>{blur(ev,profit,'saleCost',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.saleCost[i]?formatCurrency.formateNum(profit.saleCost[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">管理费用</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.manageCost[i]"
										@blur="((ev)=>{blur(ev,profit,'manageCost',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.manageCost[i]?formatCurrency.formateNum(profit.manageCost[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">研发费用</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.researchCost[i]"
										@blur="((ev)=>{blur(ev,profit,'researchCost',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.researchCost[i]?formatCurrency.formateNum(profit.researchCost[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">财务费用</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.financeCost[i]"
										@blur="((ev)=>{blur(ev,profit,'financeCost',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.financeCost[i]?formatCurrency.formateNum(profit.financeCost[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其中:利息支出</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.interestCost[i]"
										@blur="((ev)=>{blur(ev,profit,'interestCost',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.interestCost[i]?formatCurrency.formateNum(profit.interestCost[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">利息收入</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.interestIncome[i]"
										@blur="((ev)=>{blur(ev,profit,'interestIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.interestIncome[i]?formatCurrency.formateNum(profit.interestIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">加:其他收益</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.otherBenefits[i]"
										@blur="((ev)=>{blur(ev,profit,'otherBenefits',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.otherBenefits[i]?formatCurrency.formateNum(profit.otherBenefits[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">投资收益(损失以"-"号填列)</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.investmentIncome[i]"
										@blur="((ev)=>{blur(ev,profit,'investmentIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.investmentIncome[i]?formatCurrency.formateNum(profit.investmentIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">其中:对联营企业和合营企业的投资收益</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.enterpriseIncome[i]"
										@blur="((ev)=>{blur(ev,profit,'enterpriseIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.enterpriseIncome[i]?formatCurrency.formateNum(profit.enterpriseIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">以摊余成本计量的金融资产终止确认收益（损失以"-"号填列）</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.financialAssetsBenefits[i]"
										@blur="((ev)=>{blur(ev,profit,'financialAssetsBenefits',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.financialAssetsBenefits[i]?formatCurrency.formateNum(profit.financialAssetsBenefits[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">净敞口套期收益（损失以"-"号填列）</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.netExposureHedgeIncome[i]"
										@blur="((ev)=>{blur(ev,profit,'netExposureHedgeIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.netExposureHedgeIncome[i]?formatCurrency.formateNum(profit.netExposureHedgeIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">公允价值变动收益(损失以"-"号填列)</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.fairValueIncome[i]"
										@blur="((ev)=>{blur(ev,profit,'fairValueIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.fairValueIncome[i]?formatCurrency.formateNum(profit.fairValueIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">信用减值损失(损失以"-"号填列)</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.creditImpairmentLoss[i]"
										@blur="((ev)=>{blur(ev,profit,'creditImpairmentLoss',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.creditImpairmentLoss[i]?formatCurrency.formateNum(profit.creditImpairmentLoss[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">资产减值损失(损失以"-"号填列)</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.assetLoss[i]"
										@blur="((ev)=>{blur(ev,profit,'assetLoss',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.assetLoss[i]?formatCurrency.formateNum(profit.assetLoss[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">资产处置收益（损失以“-”号填列）</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.assetDisposalIncome[i]"
										@blur="((ev)=>{blur(ev,profit,'assetDisposalIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.assetDisposalIncome[i]?formatCurrency.formateNum(profit.assetDisposalIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">二、营业利润(亏损以"-"号填列)</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" v-model="profit.businessProfit[i]" disabled>
									<span class="showInput">
										{{profit.businessProfit[i]?formatCurrency.formateNum(profit.businessProfit[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">加:营业外收入</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.outsideIncome[i]"
										@blur="((ev)=>{blur(ev,profit,'outsideIncome',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.outsideIncome[i]?formatCurrency.formateNum(profit.outsideIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">减:营业外支出</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.outsideExpenditure[i]"
										@blur="((ev)=>{blur(ev,profit,'outsideExpenditure',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.outsideExpenditure[i]?formatCurrency.formateNum(profit.outsideExpenditure[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">三、利润总额（亏损总额以“-”号填列)</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" v-model="profit.totalProfit[i]" disabled>
									<span class="showInput">
										{{profit.totalProfit[i]?formatCurrency.formateNum(profit.totalProfit[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">减:所得税费用</td>
							</tr>
							<tr class="tipTerm">
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.incomeTax[i]"
										@blur="((ev)=>{blur(ev,profit,'incomeTax',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.incomeTax[i]?formatCurrency.formateNum(profit.incomeTax[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">四、净利润(净亏损以"-"号填列)</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" v-model="profit.netProfit[i]" disabled>
									<span class="showInput">
										{{profit.netProfit[i]?formatCurrency.formateNum(profit.netProfit[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(一)按经营持续性分类</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">1.持续经营净利润（净亏损以"-"号填列）</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.goingConcernNetProfit[i]"
										@blur="((ev)=>{blur(ev,profit,'goingConcernNetProfit',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.goingConcernNetProfit[i]?formatCurrency.formateNum(profit.goingConcernNetProfit[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">2.终止经营净利润（净亏损以"-"号填列)</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.goingConcernNetProfit[i]"
										@blur="((ev)=>{blur(ev,profit,'goingConcernNetProfit',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.goingConcernNetProfit[i]?formatCurrency.formateNum(profit.goingConcernNetProfit[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(二)按所有权归属分类</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">1.归属于母公司股东的净利润（净亏损以“"-"号填列）</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.parentCompanyNetProfit[i]"
										@blur="((ev)=>{blur(ev,profit,'parentCompanyNetProfit',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.parentCompanyNetProfit[i]?formatCurrency.formateNum(profit.parentCompanyNetProfit[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">2.少数股东损益（净亏损以“"-"号填列）</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.minorityStockLegalRight[i]"
										@blur="((ev)=>{blur(ev,profit,'minorityStockLegalRight',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.minorityStockLegalRight[i]?formatCurrency.formateNum(profit.minorityStockLegalRight[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">五、其他综合收益的税后净额</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" v-model="profit.ociNetTax[i]" disabled>
									<span class="showInput">
										{{profit.ociNetTax[i]?formatCurrency.formateNum(profit.ociNetTax[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(一)归属于母公司所有者的其他综合收益的税后净额</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" v-model="profit.parentCompanyOciNetTax[i]" disabled>
									<span class="showInput">
										{{profit.parentCompanyOciNetTax[i]?formatCurrency.formateNum(profit.parentCompanyOciNetTax[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">1.不能重分类进损益的其他综合收益</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" v-model="profit.notReclassifiedOci[i]" disabled>
									<span class="showInput">
										{{profit.notReclassifiedOci[i]?formatCurrency.formateNum(profit.notReclassifiedOci[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(1)重新计量设定受益计划变动额</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.remeasureBenefitAmount[i]"
										@blur="((ev)=>{blur(ev,profit,'remeasureBenefitAmount',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.remeasureBenefitAmount[i]?formatCurrency.formateNum(profit.remeasureBenefitAmount[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(2)权益法下不能转损益的其他综合收益</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.notTransferOci[i]"
										@blur="((ev)=>{blur(ev,profit,'notTransferOci',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.notTransferOci[i]?formatCurrency.formateNum(profit.notTransferOci[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(3)其他权益工具投资公允价值变动</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.otherEquityFairValue[i]"
										@blur="((ev)=>{blur(ev,profit,'otherEquityFairValue',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.otherEquityFairValue[i]?formatCurrency.formateNum(profit.otherEquityFairValue[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(4)企业自身信用风险公允价值变动</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.enterpriseCreditRiskFairValue[i]"
										@blur="((ev)=>{blur(ev,profit,'enterpriseCreditRiskFairValue',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.enterpriseCreditRiskFairValue[i]?formatCurrency.formateNum(profit.enterpriseCreditRiskFairValue[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">2.将重分类进损益的其他综合收益</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" v-model="profit.reclassifiedOci[i]" disabled>
									<span class="showInput">
										{{profit.reclassifiedOci[i]?formatCurrency.formateNum(profit.reclassifiedOci[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(1)权益法下可转损益的其他综合收益</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.transferOci[i]"
										@blur="((ev)=>{blur(ev,profit,'transferOci',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.transferOci[i]?formatCurrency.formateNum(profit.transferOci[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(2)其他债权投资公允价值变动</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.otherClaimsFairValue[i]"
										@blur="((ev)=>{blur(ev,profit,'otherClaimsFairValue',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.otherClaimsFairValue[i]?formatCurrency.formateNum(profit.otherClaimsFairValue[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(3)金融资产重分类计入其他综合收益的金额</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.financialAssetsOciAmount[i]"
										@blur="((ev)=>{blur(ev,profit,'financialAssetsOciAmount',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.financialAssetsOciAmount[i]?formatCurrency.formateNum(profit.financialAssetsOciAmount[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(4)其他债权投资信用减值准备</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.otherClaimsCreditImpairment[i]"
										@blur="((ev)=>{blur(ev,profit,'otherClaimsCreditImpairment',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.otherClaimsCreditImpairment[i]?formatCurrency.formateNum(profit.otherClaimsCreditImpairment[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(5)现金流量套期储备</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.cashFlowHedgingReserve[i]"
										@blur="((ev)=>{blur(ev,profit,'cashFlowHedgingReserve',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.cashFlowHedgingReserve[i]?formatCurrency.formateNum(profit.cashFlowHedgingReserve[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(6)外币财务报表折算差额</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.foreignCurrencyBalance[i]"
										@blur="((ev)=>{blur(ev,profit,'foreignCurrencyBalance',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.foreignCurrencyBalance[i]?formatCurrency.formateNum(profit.foreignCurrencyBalance[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(二)归属于少数股东的其他综合收益的税后净额</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.minorityStockOciNetTax[i]"
										@blur="((ev)=>{blur(ev,profit,'minorityStockOciNetTax',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.minorityStockOciNetTax[i]?formatCurrency.formateNum(profit.minorityStockOciNetTax[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">六、综合收益总额</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" v-model="profit.totalComprehensiveIncome[i]" disabled>
									<span class="showInput">
										{{profit.totalComprehensiveIncome[i]?formatCurrency.formateNum(profit.totalComprehensiveIncome[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(一)归属于母公司所有者的综合收益总额</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" v-model="profit.parentCompanyTci[i]" disabled>
									<span class="showInput">
										{{profit.parentCompanyTci[i]?formatCurrency.formateNum(profit.parentCompanyTci[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(二)归属于少数股东的综合收益总额</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" v-model="profit.minorityStockTci[i]" disabled>
									<span class="showInput">
										{{profit.minorityStockTci[i]?formatCurrency.formateNum(profit.minorityStockTci[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle courseBold">
								<td colspan="3">七、每股收益</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(一)基本每股收益</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.basicEps[i]"
										@blur="((ev)=>{blur(ev,profit,'basicEps',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.basicEps[i]?formatCurrency.formateNum(profit.basicEps[i],2):'-'}}
									</span>
								</td>
							</tr>
							<tr colspan="3" class="courseTitle">
								<td colspan="3">(二)稀释每股收益</td>
							</tr>
							<tr>
								<td v-for="(item, i) in profitYearList">
									<input type="number" data-type="number" ref="forbid" v-model="profit.dilutedEps[i]"
										@blur="((ev)=>{blur(ev,profit,'dilutedEps',i)})" @focus="focus($event)" />
									<span class="showInput" @click="inputFocus($event)">
										{{profit.dilutedEps[i]?formatCurrency.formateNum(profit.dilutedEps[i],2):'-'}}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<!-- 营业收入分析预测表 -->
			<div class="table-main diagnoseProfit"
				v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
				<!-- 表名-->
				<div class="table-name">
					<span>营业收入分析预测表</span>
				</div>
				<!--单位简介-->
				<span class="unit">单位：元</span>
				<table class="table" border="0" style="border-collapse: collapse;">
					<thead>
						<tr>
							<th v-for="(item, index) in incomeYearList" :style="{width:(index==0?'13%':'auto')}">
								{{type==2&&index!=0?item.replace('年','E'):item}}
							</th>
						</tr>
					</thead>
					<tbody v-if="type!=2">
						<tr v-for="(operation,index) in incomeJson.products">
							<td v-for="(item, i) in operation">
								<input type="text" v-if="i==0" v-model="operation[i]" disabled />
								<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]" ref="forbid" />
								<span class="showInput" v-if="i!=0">
									{{incomeJson.products[index][i]?formatCurrency.formateNum(incomeJson.products[index][i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr>
							<td v-for="(item, i) in incomeJson.productOther">
								<div v-if="i==0">其他</div>
								<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productOther[i]" ref="forbid" />
								<span class="showInput" v-if="i!=0">
									{{incomeJson.productOther[i]?formatCurrency.formateNum(incomeJson.productOther[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr>
							<td v-for="(item, i) in incomeJson.productSum">
								<div v-if="i==0">合计</div>
								<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productSum[i]" disabled />
								<span class="showInput" v-if="i!=0">
									{{incomeJson.productSum[i]?formatCurrency.formateNum(incomeJson.productSum[i],2):'-'}}
								</span>
							</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr>
							<td v-for="(item, i) in incomeJson.productSum" style="pointer-events: none;">
								<div v-if="i==0">营业收入</div>
								<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productSum[i]" />
								<span class="showInput" v-if="i!=0">
									{{incomeJson.productSum[i]?formatCurrency.formateNum(incomeJson.productSum[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr>
							<td v-for="(item, i) in netProfitInfo.productSum" style="pointer-events: none;">
								<div v-if="i==0">净利润</div>
								<input type="number" data-type="number" v-if="i!=0" v-model="netProfitInfo.productSum[i]" />
								<span class="showInput" v-if="i!=0">
									{{netProfitInfo.productSum[i]?formatCurrency.formateNum(netProfitInfo.productSum[i],2):'-'}}
								</span>
							</td>
						</tr>
						<tr>
							<td style="vertical-align: top;">预测依据描述</td>
							<td colspan="3">
								<div class="forecastingBasis">
									<textarea cols="20" rows="4" maxlength="500" v-model="predict" style="pointer-events: none;"
										placeholder="请根据公司当前业务类型、经营状况和未来发展规划，结合公司目前已签约订单（含未执行完部分）、已签订的意向/框架协议、正在接洽中的合作伙伴的情况，对未来数据进行预测，并对预测依据进行简要描述。"></textarea>
									<p class="storyDescLength">{{predict.length}}/500</p>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- 移动端营业收入分析预测表 -->
				<div class="mobileIncome">
					<ul v-if="type!=2">
						<li v-for="(operation,index) in incomeJson.products">
							<div v-for="(item, i) in operation">
								<p>{{incomeYearList[i]}}</p>
								<input type="text" v-if="i==0" v-model="operation[i]" disabled />
								<div>
									<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]" ref="forbid" />
									<span class="showInput" v-if="i!=0">
										{{incomeJson.products[index][i]?formatCurrency.formateNum(incomeJson.products[index][i],2):'-'}}
									</span>
								</div>
							</div>
						</li>
						<li>
							<div v-for="(item, i) in incomeJson.productOther">
								<p v-if="i==0">其他</p>
								<p v-if="i!=0">{{incomeYearList[i]}}</p>
								<div>
									<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productOther[i]"
										ref="forbid" />
									<span class="showInput" v-if="i!=0">
										{{incomeJson.productOther[i]?formatCurrency.formateNum(incomeJson.productOther[i],2):'-'}}
									</span>
								</div>
							</div>
						</li>
						<li>
							<div v-for="(item, i) in incomeJson.productSum">
								<p v-if="i==0">合计</p>
								<p v-if="i!=0">{{incomeYearList[i]}}</p>
								<div>
									<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productSum[i]" disabled />
									<span class="showInput" v-if="i!=0">
										{{incomeJson.productSum[i]?formatCurrency.formateNum(incomeJson.productSum[i],2):'-'}}
									</span>
								</div>
							</div>
						</li>
					</ul>
					<ul v-else>
						<li>
							<div v-for="(item, i) in incomeJson.productSum">
								<p v-if="i==0">营业收入</p>
								<p v-if="i!=0">{{incomeYearList[i]}}</p>
								<div>
									<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productSum[i]" disabled />
									<span class="showInput" v-if="i!=0">
										{{incomeJson.productSum[i]?formatCurrency.formateNum(incomeJson.productSum[i],2):'-'}}
									</span>
								</div>
							</div>
						</li>
						<li>
							<div v-for="(item, i) in netProfitInfo.productSum">
								<p v-if="i==0">净利润</p>
								<p v-if="i!=0">{{incomeYearList[i]}}</p>
								<div>
									<input type="number" data-type="number" v-if="i!=0" v-model="netProfitInfo.productSum[i]" disabled />
									<span class="showInput" v-if="i!=0">
										{{netProfitInfo.productSum[i]?formatCurrency.formateNum(netProfitInfo.productSum[i],2):'-'}}
									</span>
								</div>
							</div>
						</li>
						<li>
							<div>
								<p>预测依据描述</p>
							</div>
							<div style="width: 100%;">
								<textarea class="predict" cols="20" rows="4" maxlength="500" v-model="predict" style="pointer-events: none;"
									placeholder="请根据公司当前业务类型、经营状况和未来发展规划，结合公司目前已签约订单（含未执行完部分）、已签订的意向/框架协议、正在接洽中的合作伙伴的情况，对未来数据进行预测，并对预测依据进行简要描述。"></textarea>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<!-- 未来年度资本性支出计划表 -->
			<div class="table-main major-policy"
				v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
				<div class="table-name">
					<span>未来年度资本性支出计划表</span>
				</div>
				<!--单位简介-->
				<span class="unit">单位：元</span>
				<!-- 旧表 -->
				<div v-if="!newold">
					<table class="table depreciation mytable2" border="0" style="border-collapse: collapse;">
						<thead>
							<tr>
								<th v-for="(item, index) in OldExpenditureYearList">
									{{item}}
									<span v-if="index==1" class="desc-container">
										<span></span>
										<div class="desc-main">
											<span></span>
											<p>公司未来3年内计划购置的，将在经营活动中产生效益的，并在多个会计期间转销为费用的资产（如固定资产、无形资产、递延资产等）金额合计。</p>
										</div>
									</span>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(operation,index) in expenditure2.expenditure">
								<td v-for="(item, i) in operation">
									<!-- <input type="text" v-if="i==0" value="0" /> -->
									<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]" ref="forbid" />
									<span class="showInput" v-if="i!=0">
										<!-- {{expenditure2.expenditure[index][i]?formatCurrency.formateNum(expenditure2.expenditure[index][i],2):'-'}} -->
										{{expenditure2.payAmount[i]===''?'-':formatCurrency.formateNum(expenditure2.payAmount[i],2)}}
									</span>
								</td>
							</tr>
							<tr class="majorTotal">
								<td v-for="(item, i) in expenditure2.payAmount">
									<div v-if="i==0">合计</div>
									<input type="number" data-type="number" v-if="i!=0" v-model="expenditure2.payAmount[i]" disabled />
									<span class="showInput" v-if="i!=0">
										<!-- {{(expenditure2.payAmount[i]&&expenditure2.payAmount[i]!='-')?formatCurrency.formateNum(expenditure2.payAmount[i],2):'-'}} -->
										{{expenditure2.payAmount[i]===''?'-':formatCurrency.formateNum(expenditure2.payAmount[i],2)}}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
					<!-- 移动端 -->
					<div class="mobileAccounting">
						<ul class="accountant">
							<li v-for="(operation,index) in expenditure2.expenditure">
								<div v-for="(item, i) in operation">
									<p v-if="i==0">项目</p>
									<p v-else>{{OldExpenditureYearList[i]}}</p>
									<div>
										<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]" ref="forbid" />
										<span class="showInput" v-if="i!=0">
											{{expenditure2.payAmount[i]===''?'-':formatCurrency.formateNum(expenditure2.payAmount[i],2)}}

										</span>
									</div>
								</div>
							</li>
							<li>
								<div v-for="(item, i) in expenditure2.payAmount">
									<p v-if="i==0">合计</p>
									<p v-if="i!=0">{{OldExpenditureYearList[i]}}</p>
									<div>
										<input type="number" data-type="number" v-if="i!=0" v-model="expenditure2.payAmount[i]" disabled />
										<span class="showInput" v-if="i!=0">
											{{expenditure2.payAmount[i]===''?'-':formatCurrency.formateNum(expenditure2.payAmount[i],2)}}

										</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<!-- 新表 -->
				<div v-if="newold">
					<table class="table mytable depreciation" border="0" style="border-collapse: collapse;" else>
						<thead>
							<tr class="noInput">
								<th rowspan="2">未来改扩产项目投资的支出和基准日在建项目的后续投资项目</th>
								<th>预测期第一期</th>
								<th>预测期第二期</th>
								<th>预测期第三期</th>
							</tr>
							<tr>
								<th v-for="(item, index) in ExpenditureYearList.slice(1,4)">
									{{item}}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(operation,index) in expenditure.expenditure">
								<td v-for="(item, i) in operation">
									<input type="text" v-if="i==0" v-model="operation[i]" style="border:0" />
									<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]" ref="forbid" />
									<span class="showInput" v-if="i!=0">
										{{expenditure.expenditure[index][i]?formatCurrency.formateNum(expenditure.expenditure[index][i],2):'-'}}
										<!-- {{expenditure.payAmount[i]===''?'-':formatCurrency.formateNum(expenditure.payAmount[i],2)}} -->
									</span>
								</td>
							</tr>
							<tr class="majorTotal">
								<td v-for="(item, i) in expenditure.payAmount">
									<div v-if="i==0">合计</div>
									<input type="number" data-type="number" v-if="i!=0" v-model="expenditure.payAmount[i]" disabled />
									<span class="showInput" v-if="i!=0">
										{{(expenditure.payAmount[i]&&expenditure.payAmount[i]!='-')?formatCurrency.formateNum(expenditure.payAmount[i],2):'-'}}
										<!-- {{expenditure.payAmount[i]===''?'-':formatCurrency.formateNum(expenditure.payAmount[i],2)}} -->
									</span>
								</td>
							</tr>
						</tbody>
					</table>
					<!-- 移动 -->
					<div class="mobileAccounting">
						<ul class="accountant">
							<li v-for="(operation,index) in expenditure.expenditure">
								<div v-for="(item, i) in operation">
									<p v-if="i==0">项目</p>
									<p v-else>{{ExpenditureYearList[i]}}</p>
									<input type="text" v-if="i==0" v-model="operation[i]" />
									<div>
										<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]" ref="forbid" />
										<span class="showInput" v-if="i!=0">
											{{expenditure.expenditure[index][i]?formatCurrency.formateNum(expenditure.expenditure[index][i],2):'-'}}
											<!-- {{expenditure.payAmount[i]===''?'-':formatCurrency.formateNum(expenditure.payAmount[i],2)}} -->
										</span>
									</div>
								</div>
							</li>
							<li>
								<div v-for="(item, i) in expenditure.payAmount">
									<p v-if="i==0">合计</p>
									<p v-if="i!=0">{{ExpenditureYearList[i]}}</p>
									<div>
										<input type="number" data-type="number" v-if="i!=0" v-model="expenditure.payAmount[i]" disabled />
										<span class="showInput" v-if="i!=0">
											{{(expenditure.payAmount[i]&&expenditure.payAmount[i]!='-')?formatCurrency.formateNum(expenditure.payAmount[i],2):'-'}}
											<!-- {{expenditure.payAmount[i]===''?'-':formatCurrency.formateNum(expenditure.payAmount[i],2)}} -->
										</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<!-- 折旧摊销政策统计表 -->
			<div class="table-main major-policy"
				v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
				<div class="table-name">
					<span>折旧摊销政策统计表</span>
				</div>
				<!--单位简介-->
				<span class="unit">单位：元</span>
				<table class="table depreciation" border="0" style="border-collapse: collapse;" v-if="amortized">
					<thead>
						<tr>
							<th v-for="(item,index) in AmortizaYearList">{{item}}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(operation,index) in amortiza.amortiza">
							<td v-for="(item, i) in operation">
								<input type="text" v-if="i==0" v-model="operation[i]" style="border:0" />
								<input type="number" data-type="number" v-else-if="i==4&&index==10" v-model="operation[i]"
									style="background-color: #B9D5FF !important;" />
								<input type="number" data-type="number" v-else v-model="operation[i]" />
								<span class="showInput" v-if="i!==0">
									{{amortiza.amortiza[index][i].toString()?formatCurrency.formateNum(amortiza.amortiza[index][i].toString(),2):'-'}}
								</span>
								<span class="showInput" v-if="i==4&&index==10" style="background-color: #B9D5FF !important;">
									{{amortiza.amortiza[index][i].toString()?formatCurrency.formateNum(amortiza.amortiza[index][i].toString(),0):'-'}}
								</span>
								<span class="showInput" v-if="i==3" style="background-color: #F0F6FF !important;">
									{{amortiza.amortiza[index][i].toString()?formatCurrency.formateNum(amortiza.amortiza[index][i].toString(),0):'-'}}
								</span>
							</td>
						</tr>
						<tr class="majorTotal">
							<td v-for="(item, i) in amortiza.payAmount">
								<div v-if="i==0">合计</div>
								<input type="number" data-type="number" v-if="i!=0" v-model="amortiza.payAmount[i]" disabled />
								<span class="showInput" v-if="i!=0">
									<!-- {{(amortiza.payAmount[i]&&expenditure2.payAmount[i]!='-')?formatCurrency.formateNum(amortiza.payAmount[i],2):'-'}} -->
									{{amortiza.payAmount[i]===''?'-':formatCurrency.formateNum(amortiza.payAmount[i],2)}}
								</span>
							</td>
						</tr>
					</tbody>
				</table>

				<table class="table depreciation" border="0" style="border-collapse: collapse;" v-else>
					<thead>
						<tr>
							<th>项目</th>
							<th>当期（{{amortizationYear.replace('12月31日', '')}}全年）折旧/摊销额</th>
							<th>折旧摊销年限（年）</th>
							<th>残值率（%）</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(operation,index) in amortiza.amortiza">
							<td v-for="(item, i) in operation">
								<input type="text" v-if="i==0" v-model="operation[i]" style="border:0" />
								<input type="number" data-type="number" v-else v-model="operation[i]" />
								<span class="showInput" v-if="i!==0">
									{{amortiza.amortiza[index][i].toString()?formatCurrency.formateNum(amortiza.amortiza[index][i].toString(),2):'-'}}
								</span>
								<span class="showInput" v-if="i==2" style="background-color: #F0F6FF !important;">
									{{amortiza.amortiza[index][i].toString()?formatCurrency.formateNum(amortiza.amortiza[index][i].toString(),0):'-'}}
								</span>
							</td>
						</tr>
						<tr class="majorTotal">
							<td v-for="(item, i) in amortiza.payAmount">
								<div v-if="i==0">合计</div>
								<input type="number" data-type="number" v-if="i!=0" v-model="amortiza.payAmount[i]" disabled />
								<span class="showInput" v-if="i!=0">
									<!-- {{(amortiza.payAmount[i]&&expenditure2.payAmount[i]!='-')?formatCurrency.formateNum(amortiza.payAmount[i],2):'-'}} -->
									{{amortiza.payAmount[i]===''?'-':formatCurrency.formateNum(amortiza.payAmount[i],2)}}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- 移动端折旧摊销政策统计表 -->
				<div class="mobileAccounting move">
					<ul class="accountant" v-if="amortized">
						<li v-for="(operation,index) in amortiza.amortiza">
							<div v-for="(item, i) in operation">
								<p>{{AmortizaYearList[i]}}</p>
								<input type="text" v-if="i==0" v-model="operation[i]" />
								<div>
									<input type="number" data-type="number" v-if="i!=0&&i!=3" v-model="operation[i]" ref="forbid" />
									<span class="showInput" v-if="i!==0">
										{{amortiza.amortiza[index][i].toString()?formatCurrency.formateNum(amortiza.amortiza[index][i].toString(),2):'-'}}
									</span>
									<span class="showInput" v-if="i==4&&index==10"
										style="background-color: #E5E5E5!important;border:1px solid #D5D5D5 ; weight:3.733333rem !important;height:1.2rem !important;">
										{{amortiza.amortiza[index][i].toString()?formatCurrency.formateNum(amortiza.amortiza[index][i].toString(),0):'-'}}
									</span>
									<span class="showInput" v-if="i==3"
										style="background-color: #fff!important;border: 1px solid #D5D5D5 !important;weight:3.733333rem !important;height:1.2rem !important;">
										{{amortiza.amortiza[index][i].toString()?formatCurrency.formateNum(amortiza.amortiza[index][i].toString(),0):'-'}}
									</span>
								</div>
							</div>
						</li>
						<li class="moveli">
							<div v-for="(item, i) in amortiza.payAmount">
								<p v-if="i==0">合计</p>
								<p v-if="i!=0">{{AmortizaYearList[i]}}</p>
								<div>
									<input type="number" data-type="number" v-if="i!=0" v-model="amortiza.payAmount[i]" disabled />
									<span class="showInput" v-if="i!=0">
										<!-- {{(amortiza.payAmount[i]&&expenditure2.payAmount[i]!='-')?formatCurrency.formateNum(amortiza.payAmount[i],2):'-'}} -->
										{{amortiza.payAmount[i]===''?'-':formatCurrency.formateNum(amortiza.payAmount[i],2)}}

									</span>
								</div>
							</div>
						</li>
					</ul>

					<ul class="accountant amortizaNew" v-else>
						<li v-for="(operation,index) in amortiza.amortiza">
							<div v-for="(item, i) in operation">
								<p>{{AmortizaYearList[i]}}</p>
								<input type="text" v-if="i==0" v-model="operation[i]" />
								<div>
									<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]" ref="forbid" />
									<span class="showInput" v-if="i!==0">
										{{amortiza.amortiza[index][i].toString()?formatCurrency.formateNum(amortiza.amortiza[index][i].toString(),2):'-'}}
									</span>

								</div>
							</div>
						</li>
						<li class="moveli">
							<div v-for="(item, i) in amortiza.payAmount">
								<p v-if="i==0">合计</p>
								<p v-if="i!=0">{{AmortizaYearList[i]}}</p>
								<div>
									<input type="number" data-type="number" v-if="i!=0" v-model="amortiza.payAmount[i]" disabled />
									<span class="showInput" v-if="i!=0">
										<!-- {{(amortiza.payAmount[i]&&expenditure2.payAmount[i]!='-')?formatCurrency.formateNum(amortiza.payAmount[i],2):'-'}} -->
										{{amortiza.payAmount[i]===''?'-':formatCurrency.formateNum(amortiza.payAmount[i],2)}}
									</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>



			<!-- 税收政策统计表 -->
			<div class="table-main major-policy"
				v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
				<div class="table-name">
					<span>{{type!=2?'税收政策统计表':'企业所得税税率及加计扣除政策统计表'}}</span>
				</div>
				<!--单位简介-->
				<table class="table taxTable" border="0" style="border-collapse: collapse;">
					<thead>
						<tr class="noInput">
							<th>{{type==2?'税种':'项目'}}</th>
							<th>{{type==2?'适用税率%':'比率(%)'}}</th>
						</tr>
					</thead>
					<tbody v-if="type!=2">
						<tr class="tax" v-for="(item, i) in taxRevenue">
							<td v-if="i==0">企业所得税<span class="must">*</span></td>
							<td v-if="i==1||i==2||i==3||i==4">销项税{{i}}</td>
							<td v-if="i==5">城建税</td>
							<td v-if="i==6">教育费附加税</td>
							<td v-if="i==7">地方教育费附加税</td>
							<td v-if="i==8">其他税项</td>
							<td>
								<input type="number" data-type="number" v-model="taxRevenue[i]" disabled>
								<!-- <span style="background-color: #F0F6FF !important;"></span> -->
							</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr class="tax" v-for="(item, i) in taxRevenue.slice(0,1)">
							<td>企业所得税<span class="must">*</span></td>
							<td>
								<input type="number" data-type="number" v-model="taxRevenue[i]" disabled>
							</td>
						</tr>
					</tbody>
				</table>

				<table class="table taxTable" border="0" style="border-collapse: collapse;" v-if="type==2">
					<thead>
						<tr class="noInput">
							<th>项目</th>
							<th>加计扣除比例(%)</th>
						</tr>
					</thead>
					<tbody>
						<tr class="tax">
							<td>研发费用<span class="must">*</span></td>
							<td>
								<input type="number" data-type="number" v-model="researchDeduction" disabled />
							</td>
						</tr>
					</tbody>
				</table>

			</div>

		</div>

		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import AuditStatusTitle from './auditStatusTitle.vue';
	import ExamineNav from './examineNav.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import '../../assets/css/myGuZhi.css';

	export default {
		data() {
			return {
				balanceSheetYearList: [],
				profitYearList: [],
				incomeYearList: [],
				ExpenditureYearList: [],
				// 旧表
				OldExpenditureYearList: [],
				// 控制未来新旧表
				newold: '',
				amortized: true, //true是旧版折旧摊销表      false:是2023.10.26改版的新版
				AmortizaYearList: [],
				status: 1, // 判断当前页面是否可以修改  1代表不能修改   (1 审核通过  2 未填写完成  3 填写完成未支付)
				liabilities: {
					monetaryCapital: [],
					tradingAssets: [],
					derivativeAssets: [],
					billReceivable: [],
					receivables: [],
					accountReceivable: [],
					prepayment: [],
					otherReceivables: [],
					inventory: [],
					contractualAssets: [],
					saleForAssets: [],
					illiquidAssetsOne: [],
					otherCurrentAssets: [],
					debtInvestment: [],
					otherDebtInvestment: [],
					longAeceivables: [],
					longInvestment: [],
					otherEquityInvestment: [],
					otherIlliquidAssets: [],
					investmentProperty: [],
					fixedAssets: [],
					constructionProjects: [],
					productiveAsset: [],
					oilAndGasAssets: [],
					rightAssets: [],
					netWorth: [],
					developExpense: [],
					goodwill: [],
					longExpenses: [],
					deferredAssets: [],
					otherNonCurrentAssets: [],
					shortLoan: [],
					transactionLiabilities: [],
					derivativeLiabilities: [],
					notesPayable: [],
					accountsPayable: [],
					advanceReceipt: [],
					contractualLiabilities: [],
					payrollPayable: [],
					taxPayable: [],
					otherPayables: [],
					saleForLiabilities: [],
					illiquidLiabilitiesOne: [],
					otherNonLiabilities: [],
					longLoan: [],
					bondsPayable: [],
					bondsPreferredStock: [],
					bondsPerpetuaDebt: [],
					leaseLiabilities: [],
					longPayment: [],
					estimatedLiabilities: [],
					deferredIncome: [],
					deferredLiability: [],
					otherFixedLiabilities: [],
					paidUpCapital: [],
					otherEquity: [],
					equityPreferredStock: [],
					equityPerpetuaDebt: [],
					capitalReserve: [],
					treasuryShare: [],
					otherIncome: [],
					appropriativeReserve: [],
					earnedSurplus: [],
					undistributedProfit: [],
					totalParentEquity: [],
					minorityEquity: [],
					totalCurrentAssets: [], //流动资产合计
					totalNonCurrentAssets: [], //非流动资产合计
					totalAssets: [], //资产总计
					totalNonLiabilities: [], //流动负债合计
					totalFixedLiabilities: [], //非流动负债合计
					totalLiabilities: [], //负债总计
					totalOwnershipInterest: [], //所有者权益（或股东权益)合计
					totalLiabilitiesEquity: [] //负债和所有者权益(或股东权益)总计
				},
				profit: {
					income: [],
					cost: [],
					taxAdditional: [],
					saleCost: [],
					manageCost: [],
					researchCost: [],
					financeCost: [],
					interestCost: [],
					interestIncome: [],
					otherBenefits: [],
					investmentIncome: [],
					enterpriseIncome: [],
					financialAssetsBenefits: [],
					netExposureHedgeIncome: [],
					fairValueIncome: [],
					creditImpairmentLoss: [],
					assetLoss: [],
					assetDisposalIncome: [],
					businessProfit: [],
					outsideIncome: [],
					outsideExpenditure: [],
					totalProfit: [],
					incomeTax: [],
					netProfit: [],
					goingConcernNetProfit: [],
					discontinuedOperationsNetProfit: [],
					parentCompanyNetProfit: [],
					minorityStockLegalRight: [],
					totalComprehensiveIncome: [],
					parentCompanyTci: [],
					minorityStockTci: [],
					ociNetTax: [],
					parentCompanyOciNetTax: [],
					notReclassifiedOci: [],
					remeasureBenefitAmount: [],
					notTransferOci: [],
					otherEquityFairValue: [],
					enterpriseCreditRiskFairValue: [],
					reclassifiedOci: [],
					transferOci: [],
					otherClaimsFairValue: [],
					financialAssetsOciAmount: [],
					otherClaimsCreditImpairment: [],
					cashFlowHedgingReserve: [],
					foreignCurrencyBalance: [],
					minorityStockOciNetTax: [],
					basicEps: [],
					dilutedEps: []
				},
				incomeJson: {
					productOther: ['other', '', '', '', '', '', ''],
					productSum: this.$route.query.type == 2 ? ['sum', '', '', ''] : ['sum', '', '', '', '', '', ''],
					products: []
				},
				netProfitInfo: {
					productSum: ['sum', '', '', '']
				},
				predict: '',
				// 新
				expenditure: {
					expenditure: [],
					payAmount: ['sum', '', '', '', '']
				},
				// 旧
				expenditure2: {
					expenditure: [],
					payAmount: ['sum', '', '', '', '']
				},
				// 折旧
				amortiza: {
					amortiza: [],
					payAmount: ['sum', '', '', '', '']
				},
				taxRevenue: ['', '', '', '', '', '', '', '', ''],
				researchDeduction: '', //研发费用
				versions: '',
				type: this.$route.query.type,
				amortizationYear: 'xxxx年xx月xx日', //折旧摊销政策统计表年份



			}
		},
		components: {
			Header,
			Footer,
			AuditStatusTitle,
			ExamineNav
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			this.getData();
			this.versions = sessionStorage.versions;
		},
		updated() {
			// console.log(this.$refs.forbid)
			if (this.$refs.forbid) {
				this.$refs.forbid.forEach((item, i) => {
					item.disabled = "true";
					item.style.backgroundColor = '#fff';
					item.nextSibling.style.backgroundColor = '#fff';
					item.nextSibling.style.pointerEvents = 'none';
				})
			}
		},
		methods: {
			focus: function(e) {

			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			getData: function() {
				var this_ = this;
				if (this.$route.query.type == 5) {
					var url = 'trace/auditInfoShow/qryFinanceInfo';
					var params = {
						reportId: this.$route.query.reportId,
						traceReportId: this.$route.query.traceReportId,
						year: this.$route.query.year
					}
				} else {
					var params = {
						reportId: this.$route.query.reportId
					}
					if (this.$route.query.type == 7) {
						if (sessionStorage.versions == 3) {
							var url = this.projectName + 'auditInfoShow/qryFinanceInfoV3';
						} else {
							var url = this.projectName + 'auditInfoShow/qryFinanceInfo';
						}
					} else {
						var url = this.projectName + 'auditInfoShow/qryFinanceInfo';
					}
				}

				this.$post(url, qs.stringify(params))
					.then((res) => {
						if (res.data.code == 200) {
							var content = res.data.content;
							var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
							var profitKey = Object.keys(this.profit); //获取利润表对象的 key
							var returnliabilitiesKey = Object.keys(JSON.parse(content.financeInfo
								.liabilities)); //后台数据返回的资产负债表对象的 key
							var returnProfit = Object.keys(JSON.parse(content.profitInfo
								.profit)) //后台数据返回的利润表对象的 key

							if (this.$route.query.type == 2 || (this.$route.query.type == 7 && sessionStorage
									.versions != 3)) {
								for (var i = 0; i < content.financeInfo.year.length; i++) {
									content.financeInfo.year[i] = content.financeInfo.year[i].replace("年",
										"-12-31");
								}

								for (var i = 0; i < content.profitInfo.year.length; i++) {
									content.profitInfo.year[i] = content.profitInfo.year[i] + '度';
								}
							} else if (this.$route.query.type == 5) {
								for (var i = 0; i < content.financeInfo.year.length; i++) {
									content.financeInfo.year[i] = content.financeInfo.year[i] + '-12-31';
								}

								for (var i = 0; i < content.profitInfo.year.length; i++) {
									content.profitInfo.year[i] = content.profitInfo.year[i] + '年度';
								}
							}

							this.balanceSheetYearList = content.financeInfo.year;
							this.profitYearList = content.profitInfo.year;

							if (content.financeInfo.liabilities) { //资产负债表
								liabilitiesKey.forEach((elem, index) => {
									returnliabilitiesKey.forEach((item, i) => {
										this_.balanceSheetYearList.forEach((yearItem, y) => {
											if (elem == item) {
												this_.liabilities[elem][y] = JSON.parse(
													content.financeInfo.liabilities)[
													elem][y];
											} else if (!this_.liabilities[elem]) {
												if (this_.balanceSheetYearList.length ==
													2) {
													this_.liabilities[elem] = ['', '']
												} else if (this_.balanceSheetYearList
													.length == 3) {
													this_.liabilities[elem] = ['', '', '']
												}
											}
										})
									})
								})
							} else {
								liabilitiesKey.forEach((key, idx) => {
									this.balanceSheetYearList.forEach((item, i) => {
										this.liabilities[key].push("")
									})
								})
							}

							if (content.profitInfo.profit) { //利润表
								profitKey.forEach((elem, index) => {
									returnProfit.forEach((item, i) => {
										this_.profitYearList.forEach((yearItem, y) => {
											if (elem == item) {
												this_.profit[elem][y] = JSON.parse(content
													.profitInfo.profit)[elem][y];
											} else if (!this_.profit[elem]) {
												if (this_.profitYearList.length == 2) {
													this_.profit[elem] = ['', '']
												} else if (this_.profitYearList.length ==
													3) {
													this_.profit[elem] = ['', '', '']
												}
											}
										})
									})
								})

							} else {
								profitKey.forEach((key, idx) => {
									this.profitYearList.forEach((item, i) => {
										this.profit[key].push("")
									})
								})
							}

							if (this.$route.query.type == 2 || this.$route.query.type == 5 || (this.$route.query
									.type == 7 && sessionStorage.versions != 3)) {
								if (this.$route.query.type == 5) {
									for (var i = 0; i < content.incomeInfo.years.length; i++) {
										content.incomeInfo.years[i] = content.incomeInfo.years[i] + '年';
									}
								}
								this.incomeYearList = content.incomeInfo.years;
								if (this.$route.query.type != 2) {
									this.incomeYearList.unshift('主营业务收入');
									this.ExpenditureYearList = ['项目', this.incomeYearList[4], this.incomeYearList[5], this
										.incomeYearList[6]
									]
								} else {
									this.incomeYearList.unshift('项目');
									this.ExpenditureYearList = ['项目', this.incomeYearList[1], this.incomeYearList[2], this
										.incomeYearList[3]
									]
								}


								this.OldExpenditureYearList = [this.incomeYearList[4], this.incomeYearList[5], this
									.incomeYearList[6]
								]
								this.OldExpenditureYearList.unshift('支出项目', '支出金额(账面原值)');
								// 折旧
								this.AmortizaYearList.unshift('项目', '原值（元）', '净值（元）', '折旧摊销年限（年）', '残值率（%）');

								if (content.incomeInfo.income) { //营业收入分析预测表
									this.incomeJson = JSON.parse(content.incomeInfo.income);
								} else {
									for (var i = 0; i < 2; i++) {
										this.incomeJson.products.push([])
										for (var j = 0; j < 7; j++) {
											this.incomeJson.products[i].push("")
										}
									}
								}

								if (content.netProfitInfo.netProfit) {
									this.netProfitInfo = JSON.parse(content.netProfitInfo.netProfit)
								}

								if (content.predict) {
									this.predict = content.predict
								}

								// 新表
								if (content.accStatisticsInfo && content.accStatisticsInfo.expenditure) {
									if (content.accStatisticsInfo
										.amortiza !== "" && content.accStatisticsInfo
										.amortiza !== null) {
										this.newold = true
										this.expenditure = JSON.parse(content.accStatisticsInfo.expenditure);
										console.log(this.expenditure, '可视对讲更好看手机都改好')
									} else {
										this.newold = false
										this.expenditure2 = JSON.parse(content.accStatisticsInfo.expenditure);
									}
								} else {
									for (var i = 0; i < 2; i++) {
										this.expenditure.expenditure.push([])
										for (var j = 0; j < 5; j++) {
											this.expenditure.expenditure[i].push("")
										}
									}
								}
								// if (content.accStatisticsInfo && content.accStatisticsInfo
								// 	.expenditure) { //未来年度资本性支出计划表		
								// } else {
								// 	for (var i = 0; i < 2; i++) {
								// 		this.expenditure.expenditure.push([])
								// 		for (var j = 0; j < 5; j++) {
								// 			this.expenditure.expenditure[i].push("")
								// 		}
								// 	}
								// }
								if (this.$route.query.type == 5) {
									this.amortizationYear = content.year - 1 + '年12月31日';
								} else {
									this.amortizationYear = (content.accStatisticsInfo.years[0].replace('年', '') -
											1) +
										'年12月31日'
								}

								// 折旧摊销统计表
								if (content.accStatisticsInfo && content.accStatisticsInfo
									.amortiza !== '' && content.accStatisticsInfo
									.amortiza !== null) {
									this.amortiza = JSON.parse(content.accStatisticsInfo.amortiza);
									console.log(this.amortiza, 'this.amortiza')
									if (this.amortiza.amortiza[0].length == 4) {
										this.amortized = false;
										this.AmortizaYearList.unshift('项目', '当期（' + this.amortizationYear.replace('12月31日', '') +
											'全年）折旧/摊销额', '折旧摊销年限（年）', '残值率（%）')
									}

								} else {
									var defaultdata = [
										['房屋建筑物', '', '', '40', ''],
										['房屋构筑物', '', '', '20', ''],
										['车辆', '', '', '12', ''],
										['机械设备', '', '', '10', ''],
										['办公家具', '', '', '8', ''],
										['电子设备', '', '', '6', ''],
										['住宅用地', '', '', '22', ''],
										['商业用地', '', '', '50', ''],
										['工业用地', '', '', '40', ''],
										['其他无形资产', '', '', '10', ''],
										['长期待摊费用', '', '', '', '0']
									]
									// var sumdata = [['sum','','','','']]
									this.amortiza.amortiza = defaultdata
									// this.amortiza.payAmount = sumdata
								}

								if (content.accStatisticsInfo && content.accStatisticsInfo.taxRevenue) {
									this.taxRevenue = JSON.parse(content.accStatisticsInfo.taxRevenue);
								}

								if (content.accStatisticsInfo.researchDeduction) {
									this.researchDeduction = Math.round(content.accStatisticsInfo.researchDeduction * 100)
								}

							}
						}
					})

			}
		}


	}
</script>

<style scoped>
	.mobileBalanceSheet,
	.mobileProfit,
	.mobileIncome,
	.mobileAccounting {
		display: none;
	}

	.mobileBalanceSheet>ul li,
	.mobileBalanceSheet table td {
		font-size: 14PX;
	}

	.mobileBalanceSheet table {
		margin-top: 20px;
	}

	.mobileBalanceSheet .courseTitle td {
		/* width: 100%; */
		padding-left: 20px;
	}

	.mobileBalanceSheet .courseBold td {
		font-weight: bold;
	}

	.major-balance table>tbody>tr.trThtrr>td:first-of-type {
		padding-left: 53px;
	}

	.major-balance table>tbody>tr.trFour>td:first-of-type {
		padding-left: 94px;
	}

	.mobileProfit {
		display: none;
	}

	.mobileProfit>ul li,
	.mobileProfit table td {
		font-size: 14PX;
	}

	.mobileProfit table {
		margin-top: 20px;
	}

	.mobileProfit .courseTitle td {
		/* width: 100%; */
		padding-left: 20px;
	}

	.mobileProfit .courseBold td {
		font-weight: bold;
	}

	.interestIncome,
	.financialAssetsBenefits {
		padding-left: 108px;
	}

	.major-policy table td span:not(.showInput):not(.must) {
		display: block;
	}

	.major-balance table#tableOne>tbody>tr>td:first-child {
		width: 187px;
	}

	.major-balance table#tableTwo>tbody>tr>td:first-child {
		width: 255px;
	}

	.major-profit>table>tbody>tr>td:first-child {
		width: 420px;
	}

	.mytable2 tr td:first-child input {
		border: 1px solid #4393FF !important;
		background-color: #f0f6ff !important;
	}

	table.taxTable>tbody>tr>td:nth-child(2),
	table.taxTable>tbody>tr>td:nth-child(1) {
		padding-left: 0.8rem;
	}

	.table-main {
		padding: 0 40px;
	}

	.table-main:last-child {
		padding-bottom: 40px;
	}

	@media screen and (max-width: 750px) {

		.table-container,
		.major-profit>table,
		.major-income>table,
		.major-policy>table,
		.diagnoseProfit>table,
		.mytable,
		.mytable2 {
			display: none;
		}

		.major-policy:last-child>table {
			display: block;
		}

		.mobileBalanceSheet,
		.mobileProfit,
		.mobileIncome,
		.mobileAccounting {
			display: block;
		}

		div.table-name>span {
			font-size: 16PX;
		}

		span.unit {
			font-size: 12PX;
		}

		table input[type='text'],
		table input[type='number'] {
			height: 100%;
			text-align: right;
			padding-right: 10px;
		}

		.desc-container>span {
			width: 20px;
			height: 20px;
			background-size: 100%;
		}

		.desc-container .desc-main>p {
			font-size: 12PX;
		}

		.mobileIncome ul>li>div:nth-child(n+2) {
			display: inline-block;
			margin-right: 15px;
			position: relative;
		}

		.mobileIncome ul>li>div p {
			margin: 5px 0;
		}

		.mobileIncome ul>li>div>div {
			margin: 10px 0;
			position: relative;
		}

		.mobileIncome ul>li>div input {
			width: 140px;
			height: 45px;
			line-height: 45px;
			color: #282828;
			display: block;
			border: 1px solid #D5D5D5;
			text-align: right;
			padding-right: 8px;
			font-size: 14PX;
		}

		.mobileIncome ul>li>div:first-child input {
			text-align: left;
			display: inline-block;
		}

		.mobileIncome span.showInput {
			width: 134px;
			height: 40px;
			line-height: 45px;
			font-size: 14PX;
			background: #fff;
			position: absolute;
			top: 2px;
			left: 3px;
			text-align: right;
		}


		.mobileAccounting ul.accountant>li>div:nth-child(n+2) {
			display: inline-block;
			margin-right: 15px;
			position: relative;
		}

		.mobileAccounting ul.accountant>li>div:nth-child(n+2) p {
			margin: 5px 0;
		}

		.mobileAccounting ul.accountant>li>div>div {
			margin: 10px 0;
			position: relative;
		}

		.mobileAccounting ul.accountant>li>div input {
			width: 140px;
			height: 45px;
			line-height: 45px;
			color: #282828;
			display: block;
			border: 1px solid #D5D5D5;
			text-align: right;
			padding-right: 8px;
			font-size: 14PX;
		}

		.mobileAccounting ul.accountant>li>div:first-child input {
			text-align: left;
			display: inline-block;
		}

		.mobileAccounting span.showInput {
			width: 134px;
			height: 40px;
			line-height: 45px;
			font-size: 14PX;
			background: #fff;
			position: absolute;
			top: 2px;
			left: 3px;
			text-align: right;
		}

		.mobileAccounting ul.accountant>li>div:first-child input.add {
			margin: 15px auto;
			text-align: center;
			border-color: #4393FF;
			color: #4393FF;
			background: #fff;
			display: block;
		}

		.mobileAccounting ul.accountant>li>div:first-child input.delete {
			margin-left: 30%;
			margin-top: 10px;
			background: #fff;
			border-color: #FFA14C;
			color: #FFA14C;
			text-align: center;
		}

		.major-policy table.taxTable {
			margin-bottom: 50px;
		}




		table.taxTable>tbody>tr>td>input[type='number'],
		table.taxTable>tbody>tr>td>input[type='text'] {
			width: 70%;
		}

		.major-policy table.taxTable>thead th:nth-child(3) {
			padding-right: 0;
		}

		.major-policy table td span:not(.showInput):not(.must) {
			display: none;
		}

		.move ul.accountant>li:nth-child(11)>div:nth-child(5) input {
			border: none;
		}

		.move ul.accountant>li>div:nth-child(4) {
			transform: translateY(-50px);
		}

		.move ul.amortizaNew>li>div:nth-child(4) {
			transform: translateY(-0px);
		}

		.moveli>div:nth-child(4) {
			transform: translateY(-0px) !important;
		}

	}
</style>