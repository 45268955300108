<template>
    <div>
        <div class="titleBox">
            <span>附件1</span>
            <div class="title">资产负债表</div>
            <span>单位：人民币元</span>
        </div>
        <table class="table liaTableInfo" border="0" style="border-collapse: collapse;">
            <thead>
                <tr class="noInput">
                    <th style="width: 31%;">项目</th>
                    <th style="width: 23%;" v-for="(item,index) in year" :key="index - 1">{{item + dateFormate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="trOne even">
                    <td>流动资产:</td>
                    <td v-for="(item,index) in liaTableInfo.monetaryCapital" :key="index"></td>
                </tr>
                <tr class="trTwo odd">
                    <td>货币资金</td>
                    <td v-for="(item,index) in liaTableInfo.monetaryCapital" :key="index">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>交易性金融资产</td>
                    <td v-for="(item,index) in liaTableInfo.tradingAssets" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>衍生金融资产</td>
                    <td v-for="(item,index) in (liaTableInfo.derivativeAssets ? liaTableInfo.derivativeAssets : ['','',''])" :key="index + 2">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>应收票据</td>
                    <td v-for="(item,index) in liaTableInfo.billReceivable" :key="index + 3">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>应收账款</td>
                    <td v-for="(item,index) in liaTableInfo.receivables" :key="index + 4">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>应收账款融资</td>
                    <td v-for="(item,index) in (liaTableInfo.accountReceivable ? liaTableInfo.accountReceivable : ['','',''])" :key="index + 5">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>预付款项</td>
                    <td v-for="(item,index) in liaTableInfo.prepayment" :key="index + 6">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>其他应收款</td>
                    <td v-for="(item,index) in liaTableInfo.otherReceivables" :key="index + 7">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>存货</td>
                    <td v-for="(item,index) in liaTableInfo.inventory" :key="index + 8">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>合同资产</td>
                    <td v-for="(item,index) in (liaTableInfo.contractualAssets ? liaTableInfo.contractualAssets : ['','',''])" :key="index + 9">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>持有待售资产</td>
                    <td v-for="(item,index) in (liaTableInfo.saleForAssets ? liaTableInfo.saleForAssets : ['','',''])" :key="index + 10">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>一年内到期的非流动资产</td>
                    <td v-for="(item,index) in liaTableInfo.illiquidAssetsOne" :key="index + 11">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd other receivBillList grey">
                    <td>其他流动资产</td>
                    <td v-for="(item,index) in liaTableInfo.otherCurrentAssets" :key="index + 12">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne total totalCurrentAssets">
                    <td>流动资产合计</td>
                    <td v-for="(item,index) in liaTableInfo.totalCurrentAssets" :key="index + 13">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne even">
                    <td>非流动资产:</td>
                    <td v-for="(item,index) in liaTableInfo.monetaryCapital" :key="index"></td>
                </tr>
                <tr class="trTwo odd">
                    <td>债权投资</td>
                    <td v-for="(item,index) in (liaTableInfo.debtInvestment ? liaTableInfo.debtInvestment : ['','',''])" :key="index + 14">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>其他债权投资</td>
                    <td v-for="(item,index) in (liaTableInfo.otherDebtInvestment ? liaTableInfo.otherDebtInvestment : ['','',''])" :key="index + 15">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>长期应收款</td>
                    <td v-for="(item,index) in liaTableInfo.longAeceivables" :key="index + 16">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>长期股权投资</td>
                    <td v-for="(item,index) in liaTableInfo.longInvestment" :key="index + 17">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>其他权益工具投资</td>
                    <td v-for="(item,index) in (liaTableInfo.otherEquityInvestment ? liaTableInfo.otherEquityInvestment : ['','',''])" :key="index + 18">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>其他非流动金融资产</td>
                    <td v-for="(item,index) in (liaTableInfo.otherIlliquidAssets ? liaTableInfo.otherIlliquidAssets : ['','',''])" :key="index + 19">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>投资性房地产</td>
                    <td v-for="(item,index) in liaTableInfo.investmentProperty" :key="index + 20">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>固定资产</td>
                    <td v-for="(item,index) in (liaTableInfo.fixedAssets ? liaTableInfo.fixedAssets : ['','',''])" :key="index + 21">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>在建工程</td>
                    <td v-for="(item,index) in liaTableInfo.constructionProjects" :key="index + 22">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>生产性生物资产</td>
                    <td v-for="(item,index) in liaTableInfo.productiveAsset" :key="index + 23">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>油气资产</td>
                    <td v-for="(item,index) in liaTableInfo.oilAndGasAssets" :key="index + 24">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>使用权资产</td>
                    <td v-for="(item,index) in (liaTableInfo.rightAssets ? liaTableInfo.rightAssets : ['','',''])" :key="index + 25">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>无形资产</td>
                    <td v-for="(item,index) in liaTableInfo.netWorth" :key="index + 26">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>开发支出</td>
                    <td v-for="(item,index) in liaTableInfo.developExpense" :key="index + 27">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>商誉</td>
                    <td v-for="(item,index) in liaTableInfo.goodwill" :key="index + 28">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>长期待摊费用</td>
                    <td v-for="(item,index) in liaTableInfo.longExpenses" :key="index + 29">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>递延所得税资产</td>
                    <td v-for="(item,index) in liaTableInfo.deferredAssets" :key="index + 30">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd grey">
                    <td>其他非流动资产</td>
                    <td v-for="(item,index) in (liaTableInfo.otherNonCurrentAssets ? liaTableInfo.otherNonCurrentAssets : ['','',''])" :key="index + 31">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne total totalNonCurrentAssets">
                    <td>非流动资产合计</td>
                    <td v-for="(item,index) in liaTableInfo.totalNonCurrentAssets" :key="index + 32">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne total totalAssets grey">
                    <td>资产总计</td>
                    <td v-for="(item,index) in liaTableInfo.totalAssets" :key="index + 33">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import formatCurrency from '../../assets/js/numberToCurrency'
export default ({
    props: [
        'liaTableInfo', 'year', 'versions'
    ],
    data() {
        return {
            dateFormate: "12月31日"
        }
    },
    mounted(){
        // console.log(this.liaTableInfo)
        // if()
        if(this.versions == 1){
            this.dateFormate = "";
        } else if(this.versions == 'trace'){
            this.dateFormate = "年12月31日"
        }
    },
    methods: {
    }
})
</script>
<style scoped>
    .titleBox {
        margin: 130px 0 0 0;
        padding: 0px 110px;
        display: flex;
        justify-content: space-between;
    }
    .titleBox span {
        line-height: 100%;
        font-size: 20px;
    }
    
    .titleBox .title {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #282828;
        margin: 130px 0 0 0;
        line-height: 100%;
    }
    table {
        padding: 0 110px;
        width: calc(100% - 220px);
        text-align: center;
        border-collapse: collapse;
        margin: 47px auto 0;
        border: 1px solid #000;
    }
    table>tbody>tr>td:first-of-type {
        padding-left: 30px;
        text-align: left;
    }
    .title {
        margin: 0 !important;
    }
    table tr td,
    table tr th {
        border: 1px solid #333;
        height: auto;
    }
    table>tbody>tr:first-of-type>td {
        border-top: none;
    }
    table tr th {
        border-bottom-color:#4393ff;
    }
    table>tbody>tr {
        height: 34px !important;
    }
    table>thead>tr>th {
        font-size: 18px;
        color: #fff;
        line-height: 44px;
    }
    
</style>
