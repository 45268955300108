<template>
	<div>
		<div class="finance-nav major-finance-nav">
			<ul :class="navShow==7&&versions==3?'financeDiagnose':''">
				<li v-if="navShow!=7" v-for="(item, index) in financeNavList" :class="{active:index==current}"
					@click="navigation(index)">
					<div>
						<span></span>
						<img :src="current==index?item.selectedIcon:item.icon" alt="财务指标模块-心流">
						<span></span>
					</div>
					<span>{{item.text}}</span>
				</li>
				<li v-if="navShow==7" v-for="(item, index) in financeNavList" :class="{active:index==current}"
					@click="navigation(index)">
					<div>
						<span></span>
						<img :src="current==index?item.selectedIcon:item.icon" alt="财务指标模块-心流">
						<span></span>
					</div>
					<span>{{item.text}}</span>
				</li>

			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				current: 0,
				navShow: sessionStorage.reportType,
				versions: sessionStorage.versions,
				financeNavList: [{
						text: '资产负债表',
						icon: require('../../assets/img/navIcon/major-balance-no@2x.png'),
						selectedIcon: require('../../assets/img/navIcon/major-balance@2x.png'),
						show: true,
						path: '/balanceSheet'
					}, {
						text: '利润表',
						icon: require('../../assets/img/navIcon/major-profit-no@2x.png'),
						selectedIcon: require('../../assets/img/navIcon/major-profit@2x.png'),
						show: true,
						path: '/profitStatement'
					},
					{
						text: sessionStorage.reportType == 2 ? '预测表(选填)' : '营业收入分析预测表(选填)',
						icon: require('../../assets/img/navIcon/major-income-no@2x.png'),
						selectedIcon: require('../../assets/img/navIcon/major-income@2x.png'),
						show: true,
						path: '/majorIncomeAnalysis'
					}, {
						text: '会计政策统计表(选填)',
						icon: require('../../assets/img/navIcon/major-policy-no@2x.png'),
						selectedIcon: require('../../assets/img/navIcon/major-policy@2x.png'),
						show: true,
						path: '/majorPolicyStatis'
					}, {
						text: '财务分析',
						icon: require('../../assets/img/navIcon/major-anily-no@2x.png'),
						selectedIcon: require('../../assets/img/navIcon/major-anily@2x.png'),
						show: true,
						path: '/finanAnalisis'
					}
				],

			}
		},
		mounted() {
			if (sessionStorage.reportType == 7 && sessionStorage.versions == 3) {
				this.financeNavList = [{
					text: '资产负债表',
					icon: require('../../assets/img/navIcon/major-balance-no@2x.png'),
					selectedIcon: require('../../assets/img/navIcon/major-balance@2x.png'),
					show: true,
					path: '/balanceSheet'
				}, {
					text: '利润表',
					icon: require('../../assets/img/navIcon/major-profit-no@2x.png'),
					selectedIcon: require('../../assets/img/navIcon/major-profit@2x.png'),
					show: true,
					path: '/profitStatement'
				}]
			}
			if (this.$route.path == "/profitStatement") {
				this.current = 1;
			} else if (this.$route.path == "/majorIncomeAnalysis") {
				this.current = 2;
				document.getElementsByClassName('finance-nav')[0].scrollLeft = 98;
			} else if (this.$route.path == "/majorPolicyStatis") {
				this.current = 3;
				document.getElementsByClassName('finance-nav')[0].scrollLeft = 200;
			} else if (this.$route.path == "/finanAnalisis") {
				this.current = 4;
				document.getElementsByClassName('finance-nav')[0].scrollLeft = 330;
			}

		},
		methods: {
			navigation: function(i) {
				this.current = i;
				if (this.$route.query.reportId) {
					if (this.$route.query.type == 5) {
						this.$router.push({
							path: this.financeNavList[i].path,
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId,
								traceReportId: this.$route.query.traceReportId,
								year: this.$route.query.year
							}
						})
					} else {
						this.$router.push({
							path: this.financeNavList[i].path,
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					}
				} else {
					this.$router.push({
						path: this.financeNavList[i].path,
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}
					})
				}
			}
		}

	}
</script>

<style scoped>
	@media screen and (max-width: 750px) {
		.major-finance-nav {
			width: 100%;
			height: 150px;
			overflow-x: scroll;
		}

	}
</style>