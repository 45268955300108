<template>
	<div :id="id"></div>
</template>
<script>
	import * as echarts from 'echarts/lib/echarts.js'
	import formatCurrency from '../../assets/js/numberToCurrency'


	export default ({
		props: [
			'chartData', 'id'
		],
		data() {
			return {
				chartName: [],
				chartValue: []
			}
		},
		mounted() {
			for (var i in this.chartData) {
				this.chartName.push(this.setName(this.chartData[i].name));
				this.chartValue.push(this.chartData[i].value);
			};

			console.log(this.chartName)

			this.initChart()
		},
		methods: {
			setName: function(data) {
				var str = ''
				switch (data) {
					case "市盈率":
						str = "P/E倍数法";
						break;
					case "市净率":
						str = "P/B倍数法";
						break;
					case "市销率":
						str = "P/S倍数法";
						break;
					case "市现率":
						str = "EV/EBITDA倍数法";
						break;
					case "市盈率倍数法":
						str = "P/E倍数法";
						break;
					case "市净率倍数法":
						str = "P/B倍数法";
						break;
					case "市销率倍数法":
						str = "P/S倍数法";
						break;
					case "企业价值/息税折旧摊销前利润倍数法":
						str = "EV/EBITDA倍数法";
						break;

					default:
						str = data
				}

				return str
			},
			initChart: function() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id, this.chartName, this.chartValue)
			},
			setOptions: function(id, dataxAxis, datayAxis) {
				var fontSize = 14;
				var labelObj = {},
					normalObj = {};
				var xAxis = [],
					yAxis = [],
					position;
				var xObj = {},
					yObj = {
						axisLabel: {
							textStyle: {
								fontSize: fontSize
							}
						}
					},
					splitLine = {},
					axisTick = {
						alignWithLabel: true
					},
					data = [],
					axisLabel = {
						interval: 0,
						color: "#282828"
					},
					textStyle = {
						fontSize: fontSize
					};
				xObj.type = "category";
				xObj.data = dataxAxis;
				xObj.axisTick = axisTick;
				xObj.axisLabel = axisLabel;
				axisTick.show = false;
				axisLabel.formatter = function(params) {
					var str = ""; // 最终拼接成的字符串
					if (params == 'P/E倍数法') {
						str = 'P/E' + "\n" + '倍数法'
					} else if (params == 'P/B倍数法') {
						str = 'P/B' + "\n" + '倍数法'
					} else if (params == 'P/S倍数法') {
						str = 'P/S' + "\n" + '倍数法'
					} else if (params == 'EV/EBITDA倍数法') {
						str = 'EV/EBITDA' + "\n" + '倍数法'
					}
					return str;
				};
				axisLabel.textStyle = textStyle;
				xAxis.push(xObj);

				yObj.type = "value";
				// yObj.name = "人民币万元";
				yObj.nameTextStyle = {
					padding: [0, 0, 0, -50]
				}
				yObj.splitLine = splitLine;
				yObj.axisLine = {
					show: true
				}
				yObj.axisTick = {
					show: true
				}
				splitLine.show = false;
				yAxis.push(yObj);

				position = "top";
				normalObj.show = true;
				normalObj.position = position;
				var textObj = {};
				textObj.color = "black";
				normalObj.textStyle = textObj;
				normalObj.formatter = '{c}';
				labelObj.normal = normalObj;
				var option = {
					title: {
						show: false,
						// text: '相对估值',
						// textStyle: {
						//     color: '#282828',
						//     align: 'center',
						//     fontSize: 16
						// },
						// left: 'center',
						// top: 'top'
					},
					tooltip: {
						trigger: 'axis',
						confine: true,
						axisPointer: {
							type: 'none'
						},
						textStyle: {
							fontSize: 12,
							fontWeight: 500
						},
						formatter(params) {
							// 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
							return params.reduce((pre, i) => {
								if (i.componentSubType === "line") {
									i.marker = i.marker.replace(/\[object Object\]/, i.color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${formatCurrency.formateNum(i.value.toString(),2)}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								if (i.componentSubType === 'bar') {
									i.marker = i.marker.replace(/\[object Object\]/, i.color.colorStops[1].color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${formatCurrency.formateNum(i.value.toString(),2)}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								return pre;
							}, '');
						},
					},
					grid: { // 控制图的大小，调整下面这些值就可以，
						left: '4%',
						right: '8%',
						bottom: '10%',
						top: '15%',
						containLabel: true
					},
					animation: false,
					xAxis: xAxis,
					yAxis: yAxis,

					series: [{
							// name: incomeType,

							type: 'bar',
							barMaxWidth: 31,
							barCategoryGap: 12,
							z: 12,
							data: datayAxis,
							itemStyle: { // 柱体渐变色
								// color: function(params) {
								//     var colorList = [
								//         ['#336DD2', '#6094EC'],
								//         // ['#C0C9DB', '#DAE3F3'],
								//         // ['#8065FE', '#A18DFF'],
								//         // ['#49C9C9', '#74E7E7'],
								//         // ['#9BB4C4', '#B6CFE0'],
								//         // ['#398FF1', '#7CC1F5'],
								//     ]
								//     var index = params.dataIndex;
								//     if (params.dataIndex >= colorList.length) {
								//         index = params.dataIndex - colorList.length;
								//     }
								//     return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
								//         { offset: 0, color: colorList[index][0] },
								//         { offset: 1, color: colorList[index][1] }
								//     ])
								// }
								color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
									offset: 0,
									color: '#336DD2'
								}, {
									offset: 1,
									color: '#6094EC'
								}]),
							},
						},
						{
							// name: incomeType,
							type: 'pictorialBar',
							symbolSize: [31, 16],
							// 这个属性很重要，直接决定了顶部跟柱子是否契合
							// symbolOffset: [0, -10],
							z: 13,
							itemStyle: {

								normal: {
									label: {
										show: true,
										position: 'top', //在上方显示
										formatter: function(p) {
											return formatCurrency.formateNum(p.value.toString(), 2);
										}
									},
									color: function(params) {
										// var colorList = ['#6094EC',];
										// var colorList = ['#6094EC', '#DAE3F3', '#A18DFF', '#74E7E7', '#B6CFE0', '#7CC1F5'];
										// console.log(params)
										var index = params.dataIndex;
										return params.color = "#6094EC"
									}
								}
							},
							// symbolPosition: 'end',
							// 要给成武汉这两个柱子的值，这是圆柱顶部，值不给对高度会对不上
							data: this.pictorialBar1(datayAxis),
						},
					]
				};
				var myChart = echarts.getInstanceByDom(document.getElementById(id));
				if (myChart == undefined) {
					myChart = echarts.init(document.getElementById(id));
				}
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
			},
			pictorialBar1: function(data) {
				var arr = [];
				data.forEach(function(ele, i) {
					if (ele < 0) {
						arr[i] = {
							value: ele,
							symbolOffset: [0, 10],
							symbolPosition: 'end'
						}
					} else {
						arr[i] = {
							value: ele,
							symbolOffset: [0, -10],
							symbolPosition: 'end'
						}
					}
				});
				return arr
			}
		}
	})
</script>
<style scoped>
</style>