<template>
	<div>
		<Header />
		<uploadData />
		<navigation />
		<div class="information major-policy">
			<div>
				<p class="moduleName">
					<img src="../../assets/img/navIcon/financeSele.png" alt="心流">
					<span>{{this.$route.query.type==5?this.$route.query.year+'年财务指标':'财务指标'}}</span>
					<span v-if="this.$route.query.type==2" class="division">|</span>
					<span v-if="this.$route.query.type==2" class="">专业估值</span>
				</p>
				<!-- <div v-if="this.$route.query.type==2" class="down-up-container">
					<a href="/data/财务报表模板.xlsx" class="down-container">下载模板</a>
					<span class="up-container" @click="uploadInformation">
						<font>上传信息表</font>
					</span>
				</div> -->
			</div>

			<Modal v-model="myAlert" class-name="vertical-center-modal uploadFiles" :mask-closable="false">
				<p slot="header">
					<em></em>
					<span>上传文件</span>
				</p>
				<div class="file-container">
					<span class="up-container">
						<input type="file" name="file" id="fileupload" ref="file" @change="uploadFiles($event)">
						<font>上传信息表</font>
					</span>
					<p class="up-file">支持excel格式文件，填写财务数据时，<span class="red">请勿使用剪切（Ctrl+X）功能</span>，会导致表格公式出现错误。</p>
				</div>
				<div slot="footer"></div>
			</Modal>
			<Modal v-model="success" class-name="vertical-center-modal file_uccess" :mask-closable="false">
				<p slot="header">
					<em></em>
					<span>上传文件</span>
				</p>
				<div class="file-container">
					<img src="../../assets/img/up-success@2x.png">
					<p>报表上传成功，请再次确定财务信息！</p>
				</div>
				<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
			</Modal>

			<Modal v-model="error" class-name="vertical-center-modal" :mask-closable="false">
				<img class='warning' src="../../assets/img/remind-01@2x.png">
				<span>您选择的模板有误，请上传心流网提供的专业模板</span>
				<div slot="footer" class="ivu-sure" @click="wrongBtn">确定</div>
			</Modal>

			<div class="moduleMain">
				<financeNav />
				<div class="inputTabDesc">
					<p class="table-desc-tit">
						<img src="../../assets/img/guzhi/desc@2x.png" alt="财务指标模块之填表说明-心流" />
						<span>填表说明</span>
					</p>
					<ul v-if="type!=2">
						<li>
							<span>1、此表为企业会计政策统计表，包括各环节发生的税金等。</span>
						</li>
						<li>
							<span>2、此表为选填表，请按照实际情况尽量填写，填报情况将影响到估值结果的准确性。</span>
						</li>
						<li>
							<span>3、所得税比率最大值为25%。</span>
						</li>
					</ul>
					<ul v-else>
						<li>
							<span>1、此表为企业会计政策统计表，请按照实际情况尽量填写，填报情况将影响到估值结果的准确性。</span>
						</li>
						<li>
							<span>2、所得税比率最大值为25%。</span>
						</li>
					</ul>
				</div>
				<div class="table-main">
					<!-- 表名-->
					<div class="table-name">
						<span>未来年度资本性支出计划表</span>
					</div>
					<span class="unit">单位：元</span>
					<div class="table-container">
						<table class="table depreciation" border="0" style="border-collapse: collapse;">
							<thead>
								<tr class="noInput">
									<th rowspan="2">未来改扩产项目投资的支出和基准日在建项目的后续投资项目</th>
									<th>预测期第一期</th>
									<th>预测期第二期</th>
									<th>预测期第三期</th>
								</tr>
								<tr>
									<th v-for="(item, index) in yearList">
										{{item}}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(operation,index) in expenditure.expenditure">
									<td v-for="(item, i) in operation">
										<input type="text" v-if="i==0" disabled v-model="operation[i]"
											@blur="sensitiveWordBlur($event,index)" />
										<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]"
											@blur="blur($event,'expenditure',i,index)" ref="forbid" />
										<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
											{{judgingCondition('expenditure',i,index)?formatCurrency.formateNum(expenditure.expenditure[index][i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="majorTotal">
									<td v-for="(item, i) in expenditure.payAmount">
										<div v-if="i==0">合计</div>
										<input type="number" data-type="number" v-if="i!=0" v-model="expenditure.payAmount[i]" disabled />
										<span class="showInput" v-if="i!=0">
											{{judgingCondition('payAmount',i,'','expenditure')?formatCurrency.formateNum(expenditure.payAmount[i],2):'-'}}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
						<span
							class="tipRed">提示：资本性支出由两部分组成，一部分是未来改扩产项目投资的支出和基准日在建项目的后续投资，另一部分是维持现有经营规模对固定资产、无形资产的更换支出，此处仅需对未来改扩产项目投资的支出和基准日在建项目的后续投资的资本性支出金额进行预测。</span>
						<div class="tableXian"></div>

						<div class="table-title">折旧摊销政策统计表</div>
						<table class="table depreciation" border="0" style="border-collapse: collapse;box-sizing: content-box;"
							v-if="amortiza.amortiza[0]&&amortiza.amortiza[0].length==5">
							<thead>
								<tr>
									<th>项目</th>
									<th>原值（元）</th>
									<th>净值（元）</th>
									<th>折旧摊销年限（年）</th>
									<th>残值率（%）</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(operation,index) in amortiza.amortiza">
									<td v-for="(item, i) in operation">
										<input type="text" v-if="i==0" disabled :value="operation[i]" />
										<input type="number" data-type="number" v-else-if="i==4&&index==10" disabled v-model="operation[i]"
											class="ageLimit" />
										<input type="number" data-type="number" v-else-if="i==3" v-model="operation[i]"
											@blur="blur($event,'amortiza',i,index)" ref="forbid"
											onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" />
										<input type="number" data-type="number" v-else v-model="operation[i]"
											@blur="blur($event,'amortiza',i,index)" ref="forbid" />
										<span class="showInput" v-if="i==0" style="display: none;">
											{{amortiza.amortiza[index][i]}}
										</span>
										<span class="showInput" v-else-if="i==3" @click="inputFocus($event)">
											{{judgingCondition('amortiza',i,index)?amortiza.amortiza[index][i]:'-'}}
										</span>
										<span class="showInput" v-else-if="i==4&&index==10" style="display: none;"></span>
										<span class="showInput" v-else @click="inputFocus($event)">
											{{judgingCondition('amortiza',i,index)?formatCurrency.formateNum(amortiza.amortiza[index][i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="majorTotal">
									<td v-for="(item, i) in amortiza.payAmount">
										<div v-if="i==0">合计</div>
										<input type="number" data-type="number" value="-" v-if="i==3||i==4" disabled>
										<input type="number" data-type="number" v-else-if="i!=0" v-model="amortiza.payAmount[i]" disabled />
										<span class="showInput" v-if="i==3||i==4">-</span>
										<span class="showInput" v-else-if="i!=0">
											{{judgingCondition('payAmount',i,'','amortiza')?formatCurrency.formateNum(amortiza.payAmount[i],2):'-'}}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
						<table class="table depreciation" border="0" style="border-collapse: collapse;box-sizing: content-box;"
							v-else>
							<thead>
								<tr>
									<th>项目</th>
									<th>当期（{{amortizationYear.replace('12月31日', '')}}全年）折旧/摊销额</th>
									<th>折旧摊销年限（年）</th>
									<th>残值率（%）</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(operation,index) in amortiza.amortiza">
									<td v-for="(item, i) in operation">
										<input type="text" v-if="i==0" disabled :value="operation[i]" />
										<input type="number" data-type="number" v-else-if="i==2" v-model="operation[i]"
											@blur="blur($event,'amortiza',i,index)" ref="forbid"
											onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" />
										<input type="number" data-type="number" v-else v-model="operation[i]"
											@blur="blur($event,'amortiza',i,index)" ref="forbid" />

										<span class="showInput" v-if="i==0" style="display: none;">
											{{amortiza.amortiza[index][i]}}
										</span>
										<span class="showInput" v-else @click="inputFocus($event)">
											{{judgingCondition('amortiza',i,index)?formatCurrency.formateNum(amortiza.amortiza[index][i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="majorTotal">
									<td v-for="(item, i) in amortiza.payAmount">
										<div v-if="i==0">合计</div>
										<input type="number" data-type="number" value="-" v-if="i==2||i==3" disabled>
										<input type="number" data-type="number" v-else-if="i==1" v-model="amortiza.payAmount[i]"
											style="background: #F0F6FF !important;" @blur="amortized($event,'amortiza',i)" />
										<span class="showInput" v-if="i==2||i==3">-</span>
										<span v-else-if="i==1" style="display: none;"></span>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="tableXian"></div>

						<div class="table-title">{{type!=2?'税收政策统计表':'企业所得税税率及加计扣除政策统计表'}}</div>
						<table class="table taxTable" border="0" style="border-collapse: collapse;">
							<thead>
								<tr class="noInput">
									<th>{{type==2?'税种':'项目'}}</th>
									<th>{{type==2?'适用税率%':'比率(%)'}}</th>
								</tr>
							</thead>
							<tbody v-if="type!=2">
								<tr class="tax" v-for="(item, i) in taxRevenue">
									<td v-if="i==0">企业所得税<span class="must">*</span></td>
									<td v-if="i==1||i==2||i==3||i==4">销项税{{i}}</td>
									<td v-if="i==5">城建税</td>
									<td v-if="i==6">教育费附加税</td>
									<td v-if="i==7">地方教育费附加税</td>
									<td v-if="i==8">其他税项</td>
									<td>
										<input type="number" data-type="number" ref="forbid" v-model="taxRevenue[i]"
											@blur="((ev)=>{percentage(ev,item,i)})" />
										<span></span>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr class="tax" v-for="(item, i) in taxRevenue.slice(0,1)">
									<td>企业所得税<span class="must">*</span></td>
									<td>
										<input type="number" data-type="number" ref="forbid" v-model="taxRevenue[i]"
											@blur="((ev)=>{percentage(ev,item,i)})" />
										<span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table class="table taxPolicy" border="0" style="border-collapse: collapse;" v-if="type==2">
							<thead>
								<tr class="noInput">
									<th>项目</th>
									<th>加计扣除比例(%)</th>
								</tr>
							</thead>
							<tbody>
								<tr class="tax">
									<td>研发费用<span class="must">*</span></td>
									<td>
										<input class="RDCost" type="number" data-type="number" ref="forbid" v-model="researchDeduction"
											@blur="((ev)=>{percentageRD()})" :disabled="!deduction" />
										<span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="tableXian"></div>
					</div>
				</div>

				<div class="mobileAccounting">
					<ul class="accountant">
						<li v-for="(operation,index) in expenditure.expenditure">
							<div v-for="(item, i) in operation">
								<p v-if="i==0">项目</p>
								<p v-if="i==1">{{yearList[0]}}</p>
								<p v-if="i==2">{{yearList[1]}}</p>
								<p v-if="i==3">{{yearList[2]}}</p>
								<input type="text" v-if="i==0" :value="operation[i]" disabled />
								<div>
									<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]"
										@blur="blur($event,'expenditure',i,index)" ref="forbid" />
									<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
										{{judgingCondition('expenditure',i,index)?formatCurrency.formateNum(expenditure.expenditure[index][i],2):'-'}}
									</span>
								</div>
							</div>
						</li>
						<li>
							<div v-for="(item, i) in expenditure.payAmount">
								<p v-if="i==0">合计</p>
								<p v-if="i==1">{{yearList[0]}}</p>
								<p v-if="i==2">{{yearList[1]}}</p>
								<p v-if="i==3">{{yearList[2]}}</p>
								<div>
									<input type="number" data-type="number" v-if="i!=0" v-model="expenditure.payAmount[i]" disabled />
									<span class="showInput" v-if="i!=0">
										{{judgingCondition('payAmount',i,'','expenditure')?formatCurrency.formateNum(expenditure.payAmount[i],2):'-'}}
									</span>
								</div>
							</div>
						</li>
					</ul>
					<div class="table-title">折旧摊销政策统计表</div>
					<ul class="accountant" v-if="type!=2">
						<li v-for="(operation,index) in amortiza.amortiza">
							<div v-for="(item, i) in operation">
								<p>{{amortizeHeader[i]}}</p>
								<input type="text" v-if="i==0" :value="operation[i]" disabled />
								<div>
									<input type="number" data-type="number" v-if="i==4&&index==10" disabled v-model="operation[i]"
										class="ageLimit" />
									<input type="number" data-type="number" v-else-if="i==3" v-model="operation[i]"
										@blur="blur($event,'amortiza',i,index)" ref="forbid"
										onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" />
									<input type="number" data-type="number" v-else-if="i!=0" v-model="operation[i]"
										@blur="blur($event,'amortiza',i,index)" ref="forbid" />
									<span class="showInput" v-if="i==0" style="display: none;">
										{{amortiza.amortiza[index][i]}}
									</span>
									<span class="showInput" v-else-if="i==3" @click="inputFocus($event)">
										{{judgingCondition('amortiza',i,index)?amortiza.amortiza[index][i]:'-'}}
									</span>
									<span class="showInput" v-else-if="i==4&&index==10" style="display: none;"></span>
									<span class="showInput" v-else @click="inputFocus($event)">
										{{judgingCondition('amortiza',i,index)?formatCurrency.formateNum(amortiza.amortiza[index][i],2):'-'}}
									</span>
								</div>
							</div>
						</li>
						<li>
							<div v-for="(item, i) in amortiza.payAmount">
								<p v-if="i==0">合计</p>
								<p v-if="i!=0">{{amortizeHeader[i]}}</p>
								<div>
									<input type="number" data-type="number" value="-" v-if="i==3||i==4" disabled>
									<input type="number" data-type="number" v-else-if="i!=0" v-model="amortiza.payAmount[i]" disabled />
									<span class="showInput" v-if="i==3||i==4">-</span>
									<span class="showInput" v-else-if="i!=0">
										{{judgingCondition('payAmount',i,'','amortiza')?formatCurrency.formateNum(amortiza.payAmount[i],2):'-'}}
									</span>
								</div>
							</div>
						</li>
					</ul>

					<ul class="accountant" v-else>
						<li v-for="(operation,index) in amortiza.amortiza">
							<div v-for="(item, i) in operation">
								<p>{{i==1?'当期（'+amortizationYear.replace('12月31日', '')+'年全年）折旧/摊销额':amortizeHeader[i]}}</p>
								<input type="text" v-if="i==0" :value="operation[i]" disabled />
								<div>
									<input type="number" data-type="number" v-if="i==2" v-model="operation[i]"
										@blur="blur($event,'amortiza',i,index)" ref="forbid"
										onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" />
									<input type="number" data-type="number" v-else-if="i!=0" v-model="operation[i]"
										@blur="blur($event,'amortiza',i,index)" ref="forbid" />

									<span class="showInput" v-if="i==0" style="display: none;">
										{{amortiza.amortiza[index][i]}}
									</span>
									<span class="showInput" v-else @click="inputFocus($event)">
										{{judgingCondition('amortiza',i,index)?formatCurrency.formateNum(amortiza.amortiza[index][i],2):'-'}}
									</span>
								</div>
							</div>
						</li>
						<li>
							<div v-for="(item, i) in amortiza.payAmount">
								<p v-if="i==0">合计</p>
								<p v-if="i!=0">{{i==1?'当期（'+amortizationYear.replace('12月31日', '')+'年全年）折旧/摊销额':amortizeHeader[i]}}</p>
								<div>
									<input type="number" data-type="number" value="-" v-if="i==2||i==3" disabled>
									<input type="number" data-type="number" v-else-if="i==1" v-model="amortiza.payAmount[i]"
										@blur="amortized($event,'amortiza',i)" />
									<span class="showInput" v-if="i==2||i==3">-</span>
									<span v-else-if="i==1" style="display: none;"></span>
								</div>
							</div>
						</li>
					</ul>

					<div class="table-title">{{type!=2?'税收政策统计表':'企业所得税税率及加计扣除政策统计表'}}</div>
					<!--单位简介-->
					<span class="unit">单位：元</span>
					<table class="table taxTable" border="0" style="border-collapse: collapse;">
						<thead>
							<tr class="noInput">
								<th>{{type==2?'税种':'项目'}}</th>
								<th>{{type==2?'适用税率%':'比率(%)'}}</th>
							</tr>
						</thead>
						<tbody v-if="type!=2">
							<tr class="tax" v-for="(item, i) in taxRevenue">
								<td v-if="i==0">企业所得税<span class="must">*</span></td>
								<td v-if="i==1||i==2||i==3||i==4">销项税{{i}}</td>
								<td v-if="i==5">城建税</td>
								<td v-if="i==6">教育费附加税</td>
								<td v-if="i==7">地方教育费附加税</td>
								<td v-if="i==8">其他税项</td>
								<td>
									<input type="number" data-type="number" ref="forbid" v-model="taxRevenue[i]"
										@blur="((ev)=>{percentage(ev,item,i)})" />
									<span></span>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr class="tax" v-for="(item, i) in taxRevenue.slice(0,1)">
								<td>企业所得税<span class="must">*</span></td>
								<td>
									<input type="number" data-type="number" ref="forbid" v-model="taxRevenue[i]"
										@blur="((ev)=>{percentage(ev,item,i)})" />
									<span></span>
								</td>
							</tr>
						</tbody>
					</table>
					<table class="table taxPolicy" border="0" style="border-collapse: collapse;" v-if="type==2">
						<thead>
							<tr class="noInput">
								<th>项目</th>
								<th>加计扣除比例(%)</th>
							</tr>
						</thead>
						<tbody>
							<tr class="tax">
								<td>研发费用<span class="must">*</span></td>
								<td>
									<input class="RDCost" type="number" data-type="number" ref="forbid" v-model="researchDeduction"
										@blur="((ev)=>{percentageRD()})" :disabled="!deduction" />
									<span></span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="saveBox">
				<span class="last lastStep" @click="lastStep">上一步</span>
				<span class="save saveNext" id="saveButton" @click="saveButton">保 存</span>
			</div>

			<Modal v-model="hint" class-name="vertical-center-modal" :mask-closable="false">
				<img class='warning' src="../../assets/img/remind-01@2x.png">
				<span>{{markedWords}}</span>
				<div slot="footer" class="ivu-sure" @click="close">确定</div>
			</Modal>
			<Modal v-model="judgingInformation" class-name="vertical-center-modal judgingInformation" :mask-closable="false">
				<img class='warning' src="../../assets/img/remind-01@2x.png">
				<span>财务分析是根据您填写的基本信息、资产负债表、利润表自动计算得出结果。请先完善基本信息！</span>
				<div slot="footer" class="ivu-sure" @click="jumpBasicInformation">确定</div>
			</Modal>

			<Modal v-model="sensitiveWord" class-name="vertical-center-modal" :mask-closable="false">
				<img class='warning' src="../../assets/img/remind-01@2x.png">
				<span>{{markedWords}}</span>
				<div slot="footer" class="ivu-sure" @click="sensitiveWordOk">确定</div>
			</Modal>



			<!-- 缓存提示框 -->
			<Modal v-model="cacheAlert" class-name="vertical-center-modal" :mask-closable="false"
				@on-cancel="clickCacheMaskJump">
				<img class='warning-cache' src="../../assets/img/caution.png">
				<span class="cache-title">您上次填写过估值报告，是否载入缓存信息？</span>
				<div slot="footer" class="ivu-no" @click="clickCacheTrueMaskJump">是</div>
				<div slot="footer" class="ivu-sure-cache" @click="clickCacheMaskJump">否</div>
			</Modal>
		</div>



		<Footer />
		<router-view />

	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import navigation from './navigation.vue';
	import uploadData from './uploadData.vue';
	import financeNav from './financeNav.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import '../../assets/css/myGuZhi.css';

	export default {
		data() {
			return {
				yearList: [],
				status: 2, // 判断当前页面是否可以修改  1代表不能修改   (1 审核通过  2 未填写完成  3 填写完成未支付)
				id: '',
				expenditure: {
					expenditure: [],
					payAmount: ['sum', '', '', '']
				},
				amortiza: {
					amortiza: [],
					payAmount: this.$route.query.type == 2 ? ['sum', '', '', ''] : ['sum', '', '', '', '']
				},
				taxRevenue: ['', '', '', '', '', '', '', '', ''],
				researchDeduction: '0',//研发费用比例
				deduction: false, //研发费用 true 适用   false  不适用(研发费用为0)
				myAlert: false, //上传模板的弹框
				success: false, //上传模板成功的弹框
				error: false, //上传模板错误的弹框
				reportId: '',
				hint: false,
				markedWords: '',
				judgingInformation: false,
				sensitiveWord: false,
				markedWords: '', //弹框提示语
				amortizationYear: 'xxxx年xx月xx日', //折旧摊销政策统计表年份
				amortizeHeader: this.$route.query.type == 2 ? ['项目', '当期（xxxx年全年）折旧/摊销额', '折旧摊销年限（年）', '残值率（%）'] : [
					'项目', '原值（元）', '净值（元）', '折旧摊销年限（年）', '残值率（%）'
				],
				projectName: '',
				cacheAlert: false,
				type: this.$route.query.type
			}
		},
		components: {
			Header,
			Footer,
			navigation,
			uploadData,
			financeNav
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			this.pageModify('false');
			this.getAccountingPolicy();



		},
		updated() {
			if (this.status == 1) {
				document.querySelector('.uploading').setAttribute("class", "forbidden"); //禁止上传模板
				this.$refs.forbid.forEach((item, i) => {
					item.disabled = "true";
					item.style.backgroundColor = '#fff';
					item.nextSibling.style.backgroundColor = '#fff';
					item.nextSibling.style.pointerEvents = 'none';
				})
			} else {
				this.$refs.forbid.forEach((item, i) => {
					item.disabled = "";
				})
			}
		},
		methods: {
			...mapMutations(['pageModify']),
			percentage: function(e, val, i) {
				this.pageModify('true');
				if (val > 100) {
					this.hint = true;
					this.markedWords = '请输入不大于100的数字';
					this.taxRevenue[i] = '';
				}



				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.taxRevenueContent) || (this
							.$route.query.reportId && this.taxRevenueContent && this.isCacheValue == '0')) {
						this.method.caching("taxRevenue", JSON.stringify(this.taxRevenue), this.$route.query.type, this
							.$route.query.stage, this)
					}
				}


			},
			percentageRD: function() { //研发费用
				this.pageModify('true');
				if (this.researchDeduction > 100) {
					this.hint = true;
					this.markedWords = '请输入不大于100的数字';
					this.researchDeduction = '';
				}

				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.taxRevenueContent) || (this
							.$route.query.reportId && this.taxRevenueContent && this.isCacheValue == '0')) {
						this.method.caching("researchDeduction", this.researchDeduction, this.$route.query.type, this
							.$route.query.stage, this)
					}
				}

			},
			close: function() {
				this.hint = false;
			},
			jumpBasicInformation: function() {
				var this_ = this;
				this.judgingInformation = false;

				if (this.$route.query.type == 5) {
					setTimeout(function() {
						this_.$router.push({
							path: '/basicInformation',
							query: {
								stage: this_.$route.query.stage,
								type: this_.$route.query.type,
								reportId: this_.$route.query.reportId,
								traceReportId: this_.$route.query.traceReportId,
								year: this_.$route.query.year
							}
						})
					})
				} else {
					setTimeout(function() {
						this_.$router.push({
							path: '/basicInformation',
							query: {
								stage: this_.$route.query.stage,
								type: this_.$route.query.type,
								reportId: this_.reportId
							}
						})
					})
				}
			},
			judgingCondition: function(key, i, index, data) {
				if (key == 'expenditure') {
					if (this.expenditure[key][index][i] === 0) {
						return true
					} else if (this.expenditure[key][index][i] != 0 && this.expenditure[key][index][i] != '-') {
						return true
					} else {
						return false
					}
				} else if (key == 'amortiza') {
					if (this.amortiza[key][index][i] === 0 || this.amortiza[key][index][i] === '0') {
						return true
					} else if (this.amortiza[key][index][i] != 0 && this.amortiza[key][index][i] != '-') {
						return true
					} else {
						return false
					}
				} else if (key == 'payAmount') {
					if (this[data][key][i] === 0) {
						return true
					} else if (this[data][key][i] != 0 && this[data][key][i] != '-') {
						return true
					} else {
						return false
					}
				}
			},
			sensitiveWordBlur: function(e, index) { //  index：products中第几个的下标
				if (this.method.filterSensitiveWords(e.target, this.expenditure.expenditure[index], 'statement')) {
					e.currentTarget.style.borderColor = "red";
					this.sensitiveWord = true;
					this.markedWords = '您输入的内容存在敏感词';
				} else {
					e.currentTarget.style.borderColor = "#CACACA";
				}
			},
			amortized: function(e, name, i) {
				var data = this.amortiza.payAmount[i];
				var totalNum = 0;
				for (var j = 0; j < this[name][name].length; j++) {
					totalNum += Number(this[name][name][j][i])
				}

				if (totalNum != 0) {
					if (data != totalNum) {
						this.$set(this.amortiza.payAmount, i, '')
					}
				} else {
					this.$set(this.amortiza.payAmount, i, Number(data).toFixed(2)) //保留两位
				}

			},
			blur: function(e, name, i, index) { //name：具体的科目名称  i：科目名称中自己所处的下标   index：products中第几个的下标
				this.pageModify('true');
				// if (name == 'amortiza' && index == 10 && i == 2) {
				// 	e.currentTarget.nextSibling.style.display = 'none';
				// } else {
				e.currentTarget.nextSibling.style.display = 'block';
				// }
				var data = this[name][name][index];

				var y = String(data[i]).indexOf("."); //获取小数点的位置
				var count = String(data[i]).length - y; //获取小数点后的个数
				var totalNum = 0;
				if (data[i]) {
					if (String(data[i]).indexOf(".") != -1) {
						this.$set(data, i, Math.round(Number(data[i]) * 100) / 100) //保留两位
					} else {
						if (data[i].length > 15) { //判断数据的合理性
							this.$Message.error({
								background: true,
								content: '请输入合理数据'
							});
							this.$set(data, i, '')
							for (var j = 0; j < this[name][name].length; j++) {
								if (name == 'expenditure') {
									totalNum += Number(this.expenditure.expenditure[j][i])
									this.expenditure.payAmount[i] = Number(totalNum)
								}
							}
						} else {
							this.$set(data, i, Number(data[i]))
							for (var j = 0; j < this[name][name].length; j++) {
								totalNum += Number(this[name][name][j][i])
								if (name == 'expenditure') {
									this.expenditure.payAmount[i] = Number(totalNum)
								} else if (name == 'amortiza') {
									if (this.type == 2) {
										if (i != 2 && i != 3) {
											this.amortiza.payAmount[i] = Number(totalNum)
										}
									} else {
										if (i != 3 && i != 4) {
											this.amortiza.payAmount[i] = Number(totalNum)
										}
									}
								}
							}
						}
					}
				} else {
					this.$set(data, i, '')
					for (var j = 0; j < this[name][name].length; j++) {
						totalNum += Number(this[name][name][j][i])
						if (name == 'expenditure') {
							this.expenditure.payAmount[i] = Number(totalNum)
						} else if (name == 'amortiza') {
							if (this.type == 2) {
								if (i != 2 && i != 3) {
									this.amortiza.payAmount[i] = Number(totalNum)
								}
							} else {
								if (i != 3 && i != 4) {
									this.amortiza.payAmount[i] = Number(totalNum)
								}
							}
						}
					}
				}


				//调用缓存共用方法
				if (name == 'expenditure') {
					//调用缓存共用方法
					if (this.$route.query.type == 7 || this.$route.query.type == 2) {
						if (!this.$route.query.reportId || (this.$route.query.reportId && !this.expenditureContent) ||
							(this.$route.query.reportId && this.expenditureContent && this.isCacheValue == '0')) {
							this.method.caching("expenditure", JSON.stringify(this.expenditure), this.$route.query
								.type, this.$route.query.stage, this)
						}
					}
				}

				//调用缓存共用方法
				if (name == 'amortiza') {
					//调用缓存共用方法
					if (this.$route.query.type == 7 || this.$route.query.type == 2) {
						if (!this.$route.query.reportId || (this.$route.query.reportId && !this.amortizaContent) || (
								this.$route.query.reportId && this.amortizaContent && this.isCacheValue == '0')) {
							this.method.caching("amortiza", JSON.stringify(this.amortiza), this.$route.query.type, this
								.$route.query.stage, this)
						}
					}
				}


			},
			focus: function(e) {

			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			lastStep: function() {
				if (this.$route.query.reportId) {
					if (this.$route.query.type == 5) {
						this.$router.push({
							path: '/majorIncomeAnalysis',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId,
								traceReportId: this.$route.query.traceReportId,
								year: this.$route.query.year
							}
						})
					} else {
						this.$router.push({
							path: '/majorIncomeAnalysis',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					}
				} else {
					this.$router.push({
						path: '/majorIncomeAnalysis',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}
					})
				}
			},
			getAccountingPolicy: function(close) {
				var this_ = this;
				this.$Spin.show(); //全局加载中
				if (this.$route.query.type == 5) {
					var params = {
						reportId: this.$route.query.reportId,
						traceReportId: this.$route.query.traceReportId,
						year: this.$route.query.year
					}
				} else {
					var params = {
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						versions: sessionStorage.versions,
						stage: this.$route.query.stage,
						reportType: this.$route.query.type,
					}
				}
				this.$post(this.projectName + 'financeAccStatistics/queryFinanceAS', qs.stringify(params))
					.then((res) => {
						this.$Spin.hide();
						var expenditureProduct = ["房屋建筑物", "房屋构筑物", "车辆", "机器设备", "办公家具", "电子设备", "住宅用地",
							"商业用地", "工业用地", "其他无形资产", "长期待摊费用"
						];
						var ageLimit = ['40', '20', '12', '10', '8', '6', '70', '50', '40', '10',
							''
						]; //折旧摊销政策统计表年限
						if (this.$route.query.type == 5) {
							for (var i = 0; i < res.data.content.financeAccStatistics.years.length; i++) {
								res.data.content.financeAccStatistics.years[i] = res.data.content
									.financeAccStatistics.years[i] + '年';
							}
							this.yearList = res.data.content.financeAccStatistics.years;
							this.status = res.data.content.traceRelation.status;
							this.amortizationYear = res.data.content.traceRelation.year - 1 + '年12月31日';
							this.id = res.data.content.financeAccStatistics.id;
							if (res.data.content.financeAccStatistics.expenditure) {
								this.taxRevenue = JSON.parse(res.data.content.financeAccStatistics.taxRevenue);
								this.expenditure = JSON.parse(res.data.content.financeAccStatistics
									.expenditure);
								this.expenditure.payAmount.forEach(function(item, i) {
									if (item == '-') {
										this_.$set(this_.expenditure.payAmount, i, '')
									}
								})
								this.amortiza = JSON.parse(res.data.content.financeAccStatistics.amortiza)
							} else {
								for (var i = 0; i < 11; i++) {
									this.expenditure.expenditure.push([])
									for (var j = 0; j < 4; j++) {
										if (j == 0) {
											this.expenditure.expenditure[i].push(expenditureProduct[i])
										} else {
											this.expenditure.expenditure[i].push("")
										}
									}
								}

								for (var i = 0; i < 11; i++) {
									this.amortiza.amortiza.push([])
									for (var j = 0; j < 5; j++) {
										if (j == 0) {
											this.amortiza.amortiza[i].push(expenditureProduct[i])
										} else if (j == 3) {
											this.amortiza.amortiza[i].push(ageLimit[i])
										} else if (j == 4 && i == 10) {
											this.amortiza.amortiza[i].push('0')
										} else {
											this.amortiza.amortiza[i].push("")
										}
									}
								}

							}
						} else {
							this.yearList = res.data.content.years;
							this.amortizationYear = (res.data.content.years[0].replace('年', '') - 1) +
								'年12月31日';
							this.id = res.data.content.id;

							this.expenditureContent = res.data.content.expenditure
							this.amortizaContent = res.data.content.amortiza
							this.taxRevenueContent = res.data.content.taxRevenue
							this.isCacheValue = res.data.content.isCache;
							// 研发费用
							if (res.data.content.deduction) {
								this.deduction = true
								this.researchDeduction = Math.round(res.data.content.researchDeduction * 100)
							} else {
								this.researchDeduction = 0
							}

							if (this.isCacheValue == '0' && close == '0') {
								this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this)
							}

							if (res.data.content.expenditure) {
								if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
									this.cacheAlert = true;
									this.defaultData();
								} else { //正式数据
									this.expenditure = JSON.parse(res.data.content.expenditure);
								}

							} else {
								this.expenditure.expenditure = [];
								for (var i = 0; i < 11; i++) {
									this.expenditure.expenditure.push([])
									for (var j = 0; j < 4; j++) {
										if (j == 0) {
											this.expenditure.expenditure[i].push(expenditureProduct[i])
										} else {
											this.expenditure.expenditure[i].push("")
										}
									}
								}

							}
							if (res.data.content.amortiza) {
								if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
									this.cacheAlert = true;
									this.defaultData();
								} else { //正式数据
									this.amortiza = JSON.parse(res.data.content.amortiza)
								}
							} else {
								this.amortiza.amortiza = [];
								for (var i = 0; i < 11; i++) {
									this.amortiza.amortiza.push([])
									for (var j = 0; j < 4; j++) {
										if (j == 0) {
											this.amortiza.amortiza[i].push(expenditureProduct[i])
										} else {
											this.amortiza.amortiza[i].push("")
										}
									}
								}
							}

							if (res.data.content.taxRevenue) {
								if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
									this.cacheAlert = true;
									this.defaultData();
								} else { //正式数据
									this.taxRevenue = JSON.parse(res.data.content.taxRevenue);
								}
							}

						}
					})
				// if (this.$route.query.reportId) {


				// }
			},
			defaultData: function() {
				var date = new Date;
				var year = date.getFullYear();
				var expenditureProduct = ["房屋建筑物", "房屋构筑物", "车辆", "机器设备", "办公家具", "电子设备", "住宅用地", "商业用地", "工业用地",
					"其他无形资产", "长期待摊费用"
				];
				var ageLimit = ['40', '20', '12', '10', '8', '6', '70', '50', '40', '10', '']; //折旧摊销政策统计表年限
				this.yearList = [year + '年', year + 1 + '年', year + 2 + '年'];
				this.amortizationYear = year - 1 + '年12月31日';
				this.expenditure.expenditure = [];
				for (var i = 0; i < 11; i++) {
					this.expenditure.expenditure.push([])
					for (var j = 0; j < 4; j++) {
						if (j == 0) {
							this.expenditure.expenditure[i].push(expenditureProduct[i])
						} else {
							this.expenditure.expenditure[i].push("")
						}
					}
				}
				this.amortiza.amortiza = []
				for (var i = 0; i < 11; i++) {
					this.amortiza.amortiza.push([])
					for (var j = 0; j < 5; j++) {
						if (j == 0) {
							this.amortiza.amortiza[i].push(expenditureProduct[i])
						} else if (j == 3) {
							this.amortiza.amortiza[i].push(ageLimit[i])
						} else if (j == 4 && i == 10) {
							this.amortiza.amortiza[i].push('0')
						} else {
							this.amortiza.amortiza[i].push("")
						}
					}
				}
			},
			saveButton: function() {
				console.log('点击了')
				if (this.status == 1) {
					this.$router.push({
						path: '/finanAnalisis',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type,
							reportId: this.$route.query.reportId,
							traceReportId: this.$route.query.traceReportId,
							year: this.$route.query.year
						}
					})
				} else {
					var params = {
						id: this.id,
						reportId: this.$route.query.reportId,
						stage: sessionStorage.stage,
						expenditure: JSON.stringify(this.expenditure),
						amortiza: JSON.stringify(this.amortiza),
						taxRevenue: JSON.stringify(this.taxRevenue)
					}
					console.log(this.expenditure)
					console.log(this.amortiza)
					if (this.$route.query.type == 5) {
						params.traceReportId = this.$route.query.traceReportId;
						params.year = this.$route.query.year;
						var url = 'trace/financeAccStatistics/insertFinanceAS';
					} else {
						params.reportType = this.$route.query.type;
						params.versions = sessionStorage.versions;
						if (this.type == 2) {
							params.researchDeduction = this.researchDeduction;
						}
						var url = 'flow/financeAccStatistics/insertFinanceAS';
					}

					if (!this.taxRevenue[0]) {
						this.hint = true;
						this.markedWords = '企业所得税不能为空';
						return false;
					} else if (this.taxRevenue[0] > 25) {
						this.hint = true;
						this.markedWords = '请正确填写所得税税率，上限为25%';
						return false;
					}

					if (this.type == 2 && this.researchDeduction==='') {
						console.log(this.researchDeduction)
						this.hint = true;
						this.markedWords = '研发费用不能为空';
						return false;
					}

					this.$Spin.show(); //全局加载中
					this.$post(url, qs.stringify(params))
						.then((res) => {
							if (res.data.code == 200) {
								this.$Spin.hide();

								if (this.$route.query.type == 5) {
									if (!res.data.content.baseInfoFlag) {
										this.judgingInformation = true;
									} else {
										this.$router.push({
											path: '/finanAnalisis',
											query: {
												stage: this.$route.query.stage,
												type: this.$route.query.type,
												reportId: this.$route.query.reportId,
												traceReportId: this.$route.query.traceReportId,
												year: this.$route.query.year
											}
										})
									}
								} else {
									var content = JSON.parse(res.data.content)
									this.reportId = content.reportId;
									if (!content.baseTemp) {
										this.judgingInformation = true;
									} else {
										this.$router.push({
											path: '/finanAnalisis',
											query: {
												stage: this.$route.query.stage,
												type: this.$route.query.type,
												reportId: content.reportId
											}
										})
									}
								}
							}
						})
				}

			},
			del: function(e, index) {
				var this_ = this;
				this.expenditure.expenditure[index].forEach(function(item, i) {
					if (i != 0) {
						this_.expenditure.payAmount[i] = this_.expenditure
							.payAmount[i] - item;
					}
				})
				this.expenditure.expenditure.splice(index, 1)
			},
			add: function() {
				this.expenditure.expenditure.push(['', '', '', '', ''])
			},
			uploadInformation: function() {
				this.myAlert = true;
			},
			uploadFiles: function(e) {
				var formData = new FormData();
				formData.append("file", e.target.files[0]);
				formData.append('reportId', this.$route.query.reportId ? this.$route.query
					.reportId : '');
				formData.append("stage", sessionStorage.getItem("stage"));
				if (sessionStorage.reportType == 7) {
					formData.append("reportType", sessionStorage.getItem("reportType"));
				}
				formData.append("versions", sessionStorage.getItem("versions") ?
					sessionStorage.getItem("versions") :
					1);
				this.$post(this.projectName + 'upload/uploadFile', formData)
					.then((res) => {
						if (res.data.code == 200) {
							this.myAlert = false;
							this.reportId = res.data.content;
							if (res.data.content != "非法的excel" && res.data.content !=
								"模板已更新，请重新下载最新模板再上传！") {
								this.success = true;
							} else if (res.data.content == "非法的excel") {
								this.error = true;
							}
						}
					})
			},
			submitDialog: function() {
				this.success = false;
				this.$router.push({
					path: '/majorPolicyStatis',
					query: {
						stage: sessionStorage.stage,
						type: sessionStorage.reportType,
						reportId: this.reportId
					}
				})
				this.$router.go(0)
			},
			wrongBtn: function() {
				this.error = false;
			},
			sensitiveWordOk: function() {
				this.sensitiveWord = false;
			},

			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.getAccountingPolicy();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this, 'getAccountingPolicy')
			}

		}


	}
</script>

<style scoped>
	.major-policy table.taxTable td span:not(.must),
	.major-policy table.taxPolicy td span:not(.must) {
		/* width: 460px;
		right: 32%; */
		display: none;
	}

	.mobileAccounting {
		display: none;
	}

	@media screen and (max-width: 750px) {

		.inputTabDesc,
		.table-container,
		.tableXian,
		.down-up-container {
			display: none;
		}

		.mobileAccounting {
			display: block;
		}

		div.table-name>span,
		.table-title {
			font-size: 16PX;
		}

		span.unit {
			font-size: 12PX;
		}

		.mobileAccounting ul.accountant>li>div:nth-child(n+2) {
			display: inline-block;
			margin-right: 15px;
			position: relative;
		}

		.mobileAccounting ul.accountant>li>div:nth-child(n+2) p {
			margin: 5px 0;
		}

		.mobileAccounting ul.accountant>li>div>div {
			margin: 10px 0;
			position: relative;
		}

		.mobileAccounting ul.accountant>li>div input {
			width: 140px;
			height: 45px;
			line-height: 45px;
			color: #282828;
			display: block;
			border: 1px solid #D5D5D5;
			text-align: right;
			padding-right: 8px;
			font-size: 14PX;
		}

		.mobileAccounting ul.accountant>li>div:first-child input {
			text-align: left;
			display: inline-block;
		}

		.mobileAccounting span.showInput {
			width: 134px;
			height: 40px;
			line-height: 45px;
			font-size: 14PX;
			background: #fff;
			position: absolute;
			top: 2px;
			left: 3px;
			text-align: right;
		}

		.mobileAccounting ul.accountant>li>div:first-child input.add {
			margin: 15px auto;
			text-align: center;
			border-color: #4393FF;
			color: #4393FF;
			background: #fff;
			display: block;
		}

		.mobileAccounting ul.accountant>li>div:first-child input.delete {
			margin-left: 30%;
			margin-top: 10px;
			background: #fff;
			border-color: #FFA14C;
			color: #FFA14C;
			text-align: center;
		}

		.major-policy table.taxTable {
			margin-bottom: 50px;
		}

		.major-policy table.taxTable>thead th:nth-child(1) {
			width: 20%;
		}

		.major-policy table.taxTable>thead th:nth-child(2) {
			width: 40%;
		}

		.major-policy table.taxTable>thead th:nth-child(2) {
			width: 40%;
		}

		table.taxTable>tbody>tr:first-of-type>td:nth-child(1),
		table.taxTable>tbody>tr:nth-child(n+2)>td:nth-child(1) {
			padding-left: 30px;
		}

		table.taxTable>tbody>tr>td>input[type='number'],
		table.taxTable>tbody>tr>td>input[type='text'] {
			width: 70%;
		}

		.major-policy table.taxTable>thead th:nth-child(3) {
			padding-right: 0;
		}

		.major-policy table td span:not(.showInput):not(.must) {
			display: none;
		}


	}
</style>