<template>
    <div>
        <div class="titleBox">
            <span>附件1</span>
            <div class="title">资产负债表</div>
            <span>单位：人民币元</span>
        </div>
        <table class="table liaTableInfo" border="0" style="border-collapse: collapse;">
            <thead>
                <tr class="noInput">
                    <th style="width: 31%;">项目</th>
                    <th style="width: 23%;" v-for="(item,index) in year" :key="index - 1">{{item + dateFormate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="trOne even noInput">
                    <td>流动负债:</td>
                    <td v-for="(item,index) in liaTableInfo.oilAndGasAssets" :key="index + 1"></td>
                </tr>
                <tr class="trTwo odd">
                    <td>短期借款</td>
                    <td v-for="(item,index) in liaTableInfo.shortLoan" :key="index + 2">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>交易性金融负债</td>
                    <td v-for="(item,index) in liaTableInfo.transactionLiabilities" :key="index + 3">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>衍生金融负债</td>
                    <td v-for="(item,index) in (liaTableInfo.derivativeLiabilities ? liaTableInfo.derivativeLiabilities : ['','',''])" :key="index + 4">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>应付票据</td>
                    <td v-for="(item,index) in liaTableInfo.notesPayable" :key="index + 5">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>应付账款</td>
                    <td v-for="(item,index) in liaTableInfo.accountsPayable" :key="index + 6">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>预收款项</td>
                    <td v-for="(item,index) in liaTableInfo.advanceReceipt" :key="index + 7">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>合同负债</td>
                    <td v-for="(item,index) in (liaTableInfo.contractualLiabilities ? liaTableInfo.contractualLiabilities : ['','',''])" :key="index + 8">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>应付职工薪酬</td>
                    <td v-for="(item,index) in liaTableInfo.payrollPayable" :key="index + 9">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>应交税费</td>
                    <td v-for="(item,index) in liaTableInfo.taxPayable" :key="index + 10">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>其他应付款</td>
                    <td v-for="(item,index) in liaTableInfo.otherPayables" :key="index + 11">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>持有待售负债</td>
                    <td v-for="(item,index) in (liaTableInfo.saleForLiabilities ? liaTableInfo.saleForLiabilities : ['','',''])" :key="index + 12">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>一年内到期的非流动负债</td>
                    <td v-for="(item,index) in liaTableInfo.illiquidLiabilitiesOne" :key="index + 13">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>其他流动负债</td>
                    <td v-for="(item,index) in liaTableInfo.otherNonLiabilities" :key="index + 14">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne total totalNonLiabilities">
                    <td>流动负债合计</td>
                    <td v-for="(item,index) in liaTableInfo.totalNonLiabilities" :key="index + 15">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne even noInput">
                    <td>非流动负债:</td>
                    <td v-for="(item,index) in liaTableInfo.oilAndGasAssets" :key="index + 16"></td>
                </tr>
                <tr class="trTwo odd">
                    <td>长期借款</td>
                    <td v-for="(item,index) in liaTableInfo.longLoan" :key="index + 17">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>应付债券</td>
                    <td v-for="(item,index) in liaTableInfo.bondsPayable" :key="index + 18">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td style="padding-left: 58px;">其中：优先股</td>
                    <td v-for="(item,index) in (liaTableInfo.bondsPreferredStock ? liaTableInfo.bondsPreferredStock : ['','',''])" :key="index + 19">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td style="padding-left: 94px;">永续债</td>
                    <td v-for="(item,index) in (liaTableInfo.bondsPerpetuaDebt ? liaTableInfo.bondsPerpetuaDebt : ['','',''])" :key="index + 20">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>租赁负债</td>
                    <td v-for="(item,index) in (liaTableInfo.leaseLiabilities ? liaTableInfo.leaseLiabilities : ['','',''])" :key="index + 21">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>长期应付款</td>
                    <td v-for="(item,index) in liaTableInfo.longPayment" :key="index + 22">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>预计负债</td>
                    <td v-for="(item,index) in liaTableInfo.estimatedLiabilities" :key="index + 23">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>递延收益</td>
                    <td v-for="(item,index) in (liaTableInfo.deferredIncome ? liaTableInfo.deferredIncome : ['','',''])" :key="index + 24">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>递延所得税负债</td>
                    <td v-for="(item,index) in liaTableInfo.deferredLiability" :key="index + 25">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>其他非流动负债</td>
                    <td v-for="(item,index) in liaTableInfo.otherFixedLiabilities" :key="index + 26">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne total totalNonLiabilities">
                    <td>非流动负债合计</td>
                    <td v-for="(item,index) in liaTableInfo.totalFixedLiabilities" :key="index + 27">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne total totalLiabilities grey">
                    <td>负债合计</td>
                    <td v-for="(item,index) in liaTableInfo.totalLiabilities" :key="index + 28">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne even noInput">
                    <td>所有者权益（或股东权益）:</td>
                    <td v-for="(item,index) in liaTableInfo.oilAndGasAssets" :key="index + 43"></td>
                </tr>
                <tr class="trTwo odd">
                    <td>实收资本（或股本）</td>
                    <td v-for="(item,index) in liaTableInfo.paidUpCapital" :key="index + 29">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>其他权益工具</td>
                    <td v-for="(item,index) in (liaTableInfo.otherEquity ? liaTableInfo.otherEquity : ['','',''])" :key="index + 30">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trThree odd">
                    <td style="padding-left: 58px;">其中：优先股</td>
                    <td v-for="(item,index) in (liaTableInfo.equityPreferredStock ? liaTableInfo.equityPreferredStock : ['','',''])" :key="index + 31">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trThreeOther even">
                    <td style="padding-left: 99px;font-size: 14px;">永续债</td>
                    <td v-for="(item,index) in (liaTableInfo.equityPerpetuaDebt ? liaTableInfo.equityPerpetuaDebt : ['','',''])" :key="index + 32">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>资本公积</td>
                    <td v-for="(item,index) in liaTableInfo.capitalReserve" :key="index + 33">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>减：库存股</td>
                    <td v-for="(item,index) in (liaTableInfo.treasuryShare ? liaTableInfo.treasuryShare : ['','',''])" :key="index + 34">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>其他综合收益</td>
                    <td v-for="(item,index) in liaTableInfo.otherIncome" :key="index + 35">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>专项储备</td>
                    <td v-for="(item,index) in (liaTableInfo.appropriativeReserve ? liaTableInfo.appropriativeReserve : ['','',''])" :key="index + 36">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>盈余公积</td>
                    <td v-for="(item,index) in liaTableInfo.earnedSurplus" :key="index + 37">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo even">
                    <td>未分配利润</td>
                    <td v-for="(item,index) in liaTableInfo.undistributedProfit" :key="index + 38">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trTwo odd">
                    <td>归属于母公司股东权益合计</td>
                    <td v-for="(item,index) in liaTableInfo.totalParentEquity" :key="index + 39">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne total">
                    <td>少数股东权益</td>
                    <td v-for="(item,index) in liaTableInfo.minorityEquity" :key="index + 40">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne total totalOwnershipInterest">
                    <td>所有者权益（或股东权益）合计</td>
                    <td v-for="(item,index) in liaTableInfo.totalOwnershipInterest" :key="index + 41">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trOne total totalLiabilitiesEquity grey">
                    <td>负债和所有者权益（或股东权益）总计</td>
                    <td v-for="(item,index) in liaTableInfo.totalLiabilitiesEquity" :key="index + 42">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>

            </tbody>
        </table>
    </div>
</template>
<script>
import formatCurrency from '../../assets/js/numberToCurrency'
export default ({
    props: [
        'liaTableInfo', 'year', 'versions'
    ],
    data() {
        return {
            dateFormate: "12月31日"
        }
    },
    mounted(){
        if(this.versions == 1){
            this.dateFormate = "";
        } else if(this.versions == 'trace'){
            this.dateFormate = "年12月31日"
        }
    },
    methods: {

    }
})
</script>
<style scoped>
    .titleBox {
        margin: 130px 0 0 0;
        padding: 0px 110px;
        display: flex;
        justify-content: space-between;
    }
    .titleBox span {
        line-height: 100%;
        font-size: 20px;
    }
    
    .titleBox .title {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #282828;
        margin: 130px 0 0 0;
        line-height: 100%;
    }
    table {
        padding: 0 110px;
        width: calc(100% - 220px);
        text-align: center;
        border-collapse: collapse;
        margin: 10px auto 0;
        border: 1px solid #000;
    }
    table>tbody>tr>td:first-of-type {
        padding-left: 30px;
        text-align: left;
    }
    .title {
        margin: 0 !important;
    }
    table tr td,
    table tr th {
        border: 1px solid #333;
        height: auto;
    }
    table>tbody>tr:first-of-type>td {
        border-top: none;
    }
    table tr th {
        border-bottom-color:#4393ff;
    }
    table>tbody>tr {
        height: 34px !important;
    }
    table>thead>tr>th {
        font-size: 18px;
        color: #fff;
        line-height: 44px;
    }
</style>
