<template>
	<div>
		<div class="title">财务比率表</div>
		<table class="table financeRatio" border="0" style="border-collapse: collapse;margin-top:47px;">
			<thead>
				<tr class="tableT">
					<th style="width: 25%;">财务比率</th>
					<th style="width: 25%;" v-for="(item,index) in year" :key="index">{{item}}年</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>资产负债率（%）</td>
					<td v-for="(item,index) in financeRatio.liabilitiesRatio.slice(-2)" :key="index + 1">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr>
					<td>流动比率</td>
					<td v-for="(item,index) in financeRatio.curAssetsRatio.slice(-2)" :key="index + 1">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr>
					<td>EBITl利息保障倍数</td>
					<td v-for="(item,index) in financeRatio.ebitMulRatio.slice(-2)" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr>
					<td>毛利率（%）</td>
					<td v-for="(item,index) in financeRatio.grossProfitRatio.slice(-2)" :key="index + 1">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr>
					<td>净利润率（%）</td>
					<td v-for="(item,index) in financeRatio.netProfitRatio.slice(-2)" :key="index + 1">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr>
					<td>主营业务收入增长率（%）</td>
					<td v-for="(item,index) in financeRatio.incomeRatio.slice(-2)" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr>
					<td>净资产收益率（%）</td>
					<td v-for="(item,index) in financeRatio.netAssetsRatio.slice(-2)" :key="index + 1">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr>
					<td>应收账款周转率</td>
					<td v-for="(item,index) in financeRatio.receivableRatio.slice(-2)" :key="index + 1">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr>
					<td>存货周转率</td>
					<td v-for="(item,index) in financeRatio.inventoryRatio.slice(-2)" :key="index + 1">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
			</tbody>

		</table>

	</div>
</template>

<script>
	export default ({
		props: {
			year: {
				type: Array
			},
			financeRatio: {
				type: Object
			},
			versions: {
				type: [String, Number],
				required: false
			},


		},
		data() {
			return {
				dateFormate: "度"
			}
		},
		mounted() {
			if (this.versions == 1) {
				this.dateFormate = "";
			} else if (this.versions == 'trace') {
				this.dateFormate = "年度"
			}
		},
		methods: {

		}
	})
</script>

<style scoped>
	.titleBox {
		margin: 130px 0 0 0;
		padding: 0px 110px;
		display: flex;
		justify-content: space-between;
	}

	.titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.titleBox .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table {
		padding: 0 110px;
		width: calc(100% - 220px);
		text-align: center;
		border-collapse: collapse;
		margin: 10px auto 0;
		border: 1px solid #000;
	}

	table.active>tbody>tr {
		/* text-align: left; */
		height: 38px;
	}

	table>tbody>tr>td:first-of-type {
		padding-left: 30px;
		text-align: left;
	}

	.title {
		margin: 0 !important;
	}

	.title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table tr td,
	table tr th {
		border: 1px solid #333;
		height: auto;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table tr th {
		border-bottom-color: #4393ff;
	}

	table>thead>tr>th {
		font-size: 18px;
		color: #fff;
		line-height: 44px;
	}

	tbody.tbodyNewEdtion>tr {
		height: 43px;
	}
</style>