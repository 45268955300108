<template>
	<div>
		<Header />
		<AuditStatusTitle />
		<div class="viewContent">
			<ExamineNav />
			<div class="baseInfo">
				<ul class="ul_left">
					<li v-if="this.$route.query.type == 7&&versions==3">
						<span>诊断基准日</span>
						<span>{{basicInformation.baseDate}}</span>
					</li>
					<li>
						<span>企业名称</span>
						<span id="companyName">{{basicInformation.companyName}}</span>
					</li>
					<li>
						<span>简称</span>
						<span>{{basicInformation.companyShort}}</span>
					</li>
					<li>
						<span>邮箱</span>
						<span>{{basicInformation.email}}</span>
					</li>
					<li>
						<span>成立日期</span>
						<span>{{basicInformation.establishDate}}</span>
					</li>
					<li>
						<span>法定代表人</span>
						<span>{{basicInformation.legalPeople}}</span>
					</li>
					<li>
						<span>创始人学历</span>
						<span>{{basicInformation.founderEdu}}</span>
					</li>
					<li>
						<span>注册资本</span>
						<span>{{basicInformation.regCapital?this.formatCurrency.formateNum(basicInformation.regCapital,2)+'万元':''}}</span>
					</li>
					<li>
						<span>企业所在地</span>
						<span>{{basicInformation.area}}</span>
					</li>
					<li>
						<span>详细地址</span>
						<span>{{basicInformation.address}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>登记机关</span>
						<span>{{basicInformation.regDepart}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>统一社会信用代码</span>
						<span>{{basicInformation.creditCode}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>产品是否已推广到市场</span>
						<span>{{basicInformation.extendMarketFlag}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>拟上市地点</span>
						<span>{{basicInformation.position}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>所属行业</span>
						<span id="industryName">{{basicInformation.industryName}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>拟融资额度</span>
						<span>{{basicInformation.finance?this.formatCurrency.formateNum(basicInformation.finance,2)+'万元':''}}</span>
					</li>
				</ul>
				<ul class="ul_right">
					<li v-if="this.$route.query.type == 7&&versions==3">
						<span>登记机关</span>
						<span>{{basicInformation.regDepart}}</span>
					</li>
					<li v-if="this.$route.query.type == 7&&versions==3">
						<span>统一社会信用代码</span>
						<span>{{basicInformation.creditCode}}</span>
					</li>
					<li v-if="this.$route.query.type == 7&&versions==3">
						<span>所属行业</span>
						<span id="industryName">{{basicInformation.industryName}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>计划出让股权比例</span>
						<span>{{basicInformation.ratio?basicInformation.ratio+'%':''}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>企业价值（自评）</span>
						<span>{{basicInformation.selfValue?this.formatCurrency.formateNum(basicInformation.selfValue,2)+'万元':''}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>公司营业收入(预测)</span>
						<span>{{basicInformation.income?this.formatCurrency.formateNum(basicInformation.income,2)+'万元':''}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>公司净资产(预测)</span>
						<span>{{basicInformation.netAsset?this.formatCurrency.formateNum(basicInformation.netAsset,2)+'万元':''}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>公司净利润（预测）</span>
						<span>{{basicInformation.netProfit?this.formatCurrency.formateNum(basicInformation.netProfit,2)+'万元':''}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==5">
						<span>公司息税折旧摊销前利润(预测)</span>
						<span>{{basicInformation.amortizeProfit?this.formatCurrency.formateNum(basicInformation.amortizeProfit,2)+'万元':''}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>估值目的</span>
						<span>{{basicInformation.valuationPurpose}}</span>
					</li>
					<li
						v-if="(this.$route.query.type==7&&versions!=3)||this.$route.query.type==2||this.$route.query.type==5">
						<span>填写人与受评企业关系</span>
						<span>{{basicInformation.writerEnterpriseRelative}}</span>
					</li>
					<li>
						<span>第一股东持股比例</span>
						<span>{{basicInformation.firstHolderRatio?basicInformation.firstHolderRatio+'%':''}}</span>
					</li>
					<li>
						<span>第二股东持股比例</span>
						<span>{{basicInformation.secHolderRatio?basicInformation.secHolderRatio+'%':''}}</span>
					</li>
					<li>
						<span>第三股东持股比例</span>
						<span>{{basicInformation.thirdHolderRatio?basicInformation.thirdHolderRatio+'%':''}}</span>
					</li>
					<li>
						<span>第四股东持股比例</span>
						<span>{{basicInformation.fourHolderRatio?basicInformation.fourHolderRatio+'%':''}}</span>
					</li>
					<li>
						<span>其他股东持股比例</span>
						<span>{{basicInformation.otherHolderRatio?basicInformation.otherHolderRatio+'%':''}}</span>
					</li>
					<li>
						<span>公司主营业务概述</span>
						<span id="bussinessDesc">{{basicInformation.bussinessDesc}}</span>
					</li>
				</ul>
			</div>
		</div>

		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import AuditStatusTitle from './auditStatusTitle.vue';
	import ExamineNav from './examineNav.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';

	export default {
		data() {
			return {
				basicInformation: {
					companyName: '',
					companyShort: '', // 公司简称
					email: '', // 邮件
					establishDate: '', //成立时间
					legalPeople: '', //法定代表人
					founderEdu: '', //创始人学历
					regCapital: '', //注册资本
					area: '', //企业所在地
					address: '', // 详细地址
					regDepart: '', // 登记机关
					creditCode: '', // 统一社会信用代码
					bussinessDesc: '', // 公司主营业务概述
					extendMarketFlag: '', //产品是否已推广到市场
					industry: '', // 一级行业标识码
					industryChild: '', //二级行业标识码
					industryName: '', //一级行业名称
					industryChildName: '', //二级行业名称
					finance: '', // 拟融资额度
					ratio: '', // 计划出让股权比例
					selfValue: '', //企业价值（自评）
					income: '', // 公司营业收入(预测)
					netAsset: '', // 公司净资产(预测)
					netProfit: '', //公司净利润（预测）
					amortizeProfit: '', // 公司息税折旧摊销前利润(预测)
					valuationPurpose: '', // 估值目的
					writerEnterpriseRelative: '', // 填写人与受评企业关系
					firstHolderRatio: '', // 第一股东持股比例
					secHolderRatio: '', // 第二股东持股比例
					thirdHolderRatio: '', // 第三股东持股比例
					fourHolderRatio: '', // 第四股东持股比例
					otherHolderRatio: '', // 其他股东持股比例
					baseDate: '',
					position: '',
					valuationReportId: '',
					id: ''
				},
				versions: '',
				projectName: ''
			}
		},
		components: {
			Header,
			Footer,
			AuditStatusTitle,
			ExamineNav
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			this.getBaseInfo()
			this.versions = sessionStorage.versions;
		},
		methods: {
			getBaseInfo: function() {
				if (this.$route.query.type == 5) {
					var url = this.projectName + 'baseInfo/getAuditBaseInfo';
					var params = {
						traceReportId: this.$route.query.traceReportId,
						majorReportId: this.$route.query.reportId,
						year: this.$route.query.year
					}
				} else {
					var url = this.projectName + 'auditInfoShow/getBaseInfo';
					var params = {
						reportId: this.$route.query.reportId
					}
				}
				this.$post(url, qs.stringify(params))
					.then((res) => {
						if (res.data.content) {
							var content = res.data.content;
							var dataKey = Object.keys(content); //获取对象的 key
							var defaultDataKey = Object.keys(this.basicInformation)
							this.assignment(dataKey, defaultDataKey, content, 'getBaseInfo')
						}
					});
			},
			assignment: function(param, data, content, describe) {
				//param:参数key的数组   data：主要数据key的数组  content：接口返回的数据      describe：表示是填充调入的数据还是回显的数据
				data.forEach((elem, index) => {
					param.forEach((item, idx) => {
						if (elem == item) {

							if (item == 'ratio' || item == 'firstHolderRatio' ||
								item == 'secHolderRatio' || item ==
								'thirdHolderRatio' || item == 'fourHolderRatio' ||
								item == 'otherHolderRatio') {
								this.basicInformation[item] = content[item] ?
									Number(content[item]).toFixed(2) : '';
							} else if (item == 'extendMarketFlag') {
								if (content[item] == '0') {
									this.basicInformation[item] = '否';
								} else {
									this.basicInformation[item] = '是';
								}
							} else if (item == 'position') {
								switch (content.position) {
									case "0":
										var contentPosition = "深圳A股";
										break;
									case "1":
										var contentPosition = "新三板";
										break;
									case "2":
										var contentPosition = "纳斯达克";
										break;
									case "3":
										var contentPosition = "港股";
										break;
									case "4":
										var contentPosition = "上海A股";
										break;
									case "5":
										var contentPosition = "区域股权市场";
										break;
									case "6":
										var contentPosition = "专精特新";
										break;
								}
								this.basicInformation[item] = contentPosition;
							} else {
								this.basicInformation[item] = content[item] ? content[item] : '';
							}

						}
					});
				});
				console.log(this.basicInformation)
			}
		}


	}
</script>

<style scoped>
	/* 基本信息 */

	.baseInfo {
		padding-top: 30px;
		/*padding-left: 4.3%;*/
		background: #fff;
		overflow: hidden;
	}

	.baseInfo>ul {
		float: left;
		width: 50%;
	}

	.baseInfo>ul>li {
		width: 100%;
		margin-bottom: 20px;
	}

	.baseInfo>ul>li>span {
		font-size: 14PX;
		line-height: 20px;
		vertical-align: top;
	}

	.baseInfo>ul>li>span:first-child {
		width: 30%;
		padding-right: 2%;
		color: rgba(88, 88, 88, 1);
	}

	.baseInfo>ul>li>span:last-child {
		width: 60%;
		color: rgba(40, 40, 40, 1);
	}

	.valuationTopNav {
		background: #fff;
	}

	.valuationTopNav>ul {
		width: 100%;
		overflow: hidden;
		height: 54px;
		border-bottom: 1px solid #E0E0E0;
	}

	.valuationTopNav>ul>li {
		float: left;
		font-size: 16px;
		height: 36px;
		line-height: 36px;
		margin: 0 4% 0;
		color: #696969;
		cursor: pointer;
	}

	.valuationTopNav>ul>li:first-of-type {
		margin-left: 0;
	}


	/* 主要事迹导航 */

	.demonstration-nav {
		background: #fff;
		margin-top: 61px;
	}

	.demonstration-nav>ul {
		margin: 0 auto;
		overflow: hidden;
	}

	.demonstration-nav>ul {
		width: 950px;
		margin-bottom: 59px;
	}

	.demonstration-nav>ul>li {
		float: left;
		width: 20%;
		text-align: center;
		cursor: pointer;
	}

	.demonstration-nav>ul>li.active p {
		background: #0478FC;
	}

	.demonstration-nav>ul.fast {
		display: none;
	}

	.demonstration-nav>ul.major {
		display: block;
	}

	.demonstration-nav>ul>li>div,
	.online-finance-list>ul>li>div {
		display: block;
		margin-top: 24px;
		position: relative;
	}

	.demonstration-nav>ul>li>div>p>img {
		display: block;
		margin: 0 auto;
		width: 27px;
		height: 27px;
	}



	.demonstration-nav>ul>li>div>p {
		width: 53px;
		padding: 13px 0;
		margin: 0 auto;
		/* background: #0478FC; */
		border-radius: 50%;
		border: 2px solid #0478FC;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
	}

	.online-finance-list>ul>li>div>span {
		height: 1px;
		width: 70px;
		position: absolute;
		border-bottom: 1px solid #4393FF;
	}

	.demonstration-nav>ul>li>div>span {
		height: 1px;
		width: 68px;
		position: absolute;
		border-bottom: 2px solid #4393FF;
	}

	.demonstration-nav>ul>li>div>span:first-of-type,
	.online-finance-list>ul>li>div>span:first-of-type {
		top: 50%;
		left: 0;
	}

	.demonstration-nav>ul>li>div>span:last-of-type,
	.online-finance-list>ul>li>div>span:last-of-type {
		top: 50%;
		right: 0;
	}

	.demonstration-nav>ul>li:first-of-type>div>span:first-of-type,
	.online-finance-list>ul>li:first-of-type>div>span:first-of-type {
		display: none;
	}

	.demonstration-nav>ul>li:last-of-type>div>span:last-of-type,
	.online-finance-list>ul>li:last-of-type>div>span:last-of-type {
		display: none;
	}

	.demonstration-nav>ul>li>span,
	.online-finance-list>ul>li>span {
		font-size: 14px;
		margin-top: 18px;
		line-height: 1;
	}

	.demonstration-nav>ul>li.active>span {
		color: #4393FF;
	}

	@media screen and (max-width: 750px) {
		.baseInfo>ul {
			float: none;
			width: auto;
		}

		.baseInfo>ul>li>span {
			line-height: 30px;
		}

		.baseInfo>ul>li>span:first-child {
			width: 40%;
			margin-right: 20px;
		}

		.baseInfo>ul>li>span:last-child {
			width: 55%;
		}

	}
</style>