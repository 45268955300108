<template>
	<div>
		<Header />
		<uploadData />
		<navigation />
		<div class="information">
			<div>
				<p class="moduleName">
					<img src="../../assets/img/navIcon/financeSele.png" alt="心流">
					<span>{{this.$route.query.type==5?this.$route.query.year+'年财务指标':'财务指标'}}</span>
					<span v-if="this.$route.query.type==2" class="division">|</span>
					<span v-if="this.$route.query.type==2" class="">专业估值</span>
				</p>
				<!-- <div v-if="this.$route.query.type==2" class="down-up-container">
					<a href="/data/财务报表模板.xlsx" class="down-container">下载模板</a>
					<span class="up-container" @click="uploadInformation">
						<font>上传信息表</font>
					</span>
				</div> -->
			</div>

			<Modal v-model="myAlert" class-name="vertical-center-modal uploadFiles" :mask-closable="false">
				<p slot="header">
					<em></em>
					<span>上传文件</span>
				</p>
				<div class="file-container">
					<span class="up-container">
						<input type="file" name="file" id="fileupload" ref="file" @change="uploadFiles($event)">
						<font>上传信息表</font>
					</span>
					<p class="up-file">支持excel格式文件，填写财务数据时，<span class="red">请勿使用剪切（Ctrl+X）功能</span>，会导致表格公式出现错误。</p>
				</div>
				<div slot="footer"></div>
			</Modal>
			<Modal v-model="success" class-name="vertical-center-modal file_uccess" :mask-closable="false">
				<p slot="header">
					<em></em>
					<span>上传文件</span>
				</p>
				<div class="file-container">
					<img src="../../assets/img/up-success@2x.png">
					<p>报表上传成功，请再次确定财务信息！</p>
				</div>
				<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
			</Modal>

			<Modal v-model="error" class-name="vertical-center-modal" :mask-closable="false">
				<img class='warning' src="../../assets/img/remind-01@2x.png">
				<span>您选择的模板有误，请上传心流网提供的专业模板</span>
				<div slot="footer" class="ivu-sure" @click="wrongBtn">确定</div>
			</Modal>

			<div class="moduleMain">
				<financeNav />
				<div class="inputTabDesc">
					<p class="table-desc-tit">
						<img src="../../assets/img/guzhi/desc@2x.png" alt="营业收入预测表的填表说明-心流" />
						<span>填表说明</span>
					</p>
					<ul v-if="type!=2">
						<li>
							<span>1、此表为企业未来年度收入预测表，建议按照实际业务分类或产品分类填报。</span>
						</li>
						<li>
							<span>2、此表为选填表，请按照实际情况尽量填写，填报情况将影响到估值结果的准确性。</span>
						</li>
						<li>
							<span>3、如果收入很难分类，或收入结构比较单一，可以填报一个数据。</span>
						</li>
					</ul>
					<ul v-else>
						<li>
							<span>此表为企业未来年度收入预测表，请按照实际情况尽量填写，填报情况将影响到估值结果的准确性。</span>
						</li>
					</ul>
				</div>
				<div :class="['table-main',type==2?'diagnoseProfit':'']">
					<!-- 表名-->
					<div class="table-name">
						<span>营业收入分析预测表</span>
					</div>
					<span class="unit">单位：元</span>
					<div class="table-container">
						<table class="table" border="0" style="border-collapse: collapse;">
							<thead>
								<tr>
									<th style="width: 13%;">{{this.$route.query.type==5?'主营业务收入':'项目'}}</th>
									<th v-for="(item, index) in yearList">{{type==2?item.replace('年','E'):item}}
									</th>
									<th v-if="this.$route.query.type!=2"></th>
								</tr>
							</thead>
							<tbody v-if="this.$route.query.type!=2">
								<tr v-for="(operation,index) in incomeJson.products">
									<td v-for="(item, i) in operation">
										<input type="text" v-if="i==0" v-model="operation[i]" @blur="sensitiveWordBlur($event,index)" />
										<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]"
											@blur="blur($event,'products',i,index)" ref="forbid" />
										<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
											{{judgingCondition('products',i,index)?formatCurrency.formateNum(incomeJson.products[index][i],2):'-'}}
										</span>
									</td>
									<td><input class="delete" type="button" value="删除" @click="del($event,index)"
											:disabled="status==1?true:false" /></td>
								</tr>
								<tr>
									<td colspan="8"><input class="add" type="button" value="添加" @click="add"
											:disabled="status==1?true:false" /></td>
								</tr>
								<tr>
									<td v-for="(item, i) in incomeJson.productOther">
										<div v-if="i==0">其他</div>
										<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productOther[i]"
											@blur="blur($event,'productOther',i)" ref="forbid" />
										<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
											{{judgingCondition('productOther',i)?formatCurrency.formateNum(incomeJson.productOther[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr>
									<td v-for="(item, i) in incomeJson.productSum">
										<div v-if="i==0">合计</div>
										<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productSum[i]" disabled />
										<span class="showInput" v-if="i!=0">
											{{judgingCondition('productSum',i)?formatCurrency.formateNum(incomeJson.productSum[i],2):'-'}}
										</span>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td v-for="(item, i) in incomeJson.productSum">
										<div v-if="i==0">营业收入</div>
										<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productSum[i]"
											@blur="blur($event,'productSum',i)" @change="change(i,'incomeJson')" ref="forbid" />
										<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
											{{judgingCondition('productSum',i)?formatCurrency.formateNum(incomeJson.productSum[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr>
									<td v-for="(item, i) in netProfit.productSum">
										<div v-if="i==0">净利润</div>
										<input type="number" data-type="number" v-if="i!=0" v-model="netProfit.productSum[i]"
											@blur="netProfitBlur($event,'netProfit','productSum',i)" @change="change(i,'netProfit')"
											ref="forbid" />
										<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
											{{netProfit.productSum[i]?formatCurrency.formateNum(netProfit.productSum[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr>
									<td style="vertical-align: top;">预测依据描述</td>
									<td colspan="3">
										<div class="forecastingBasis">
											<textarea cols="20" rows="4" maxlength="500" v-model="predict"
												placeholder="请根据公司当前业务类型、经营状况和未来发展规划，结合公司目前已签约订单（含未执行完部分）、已签订的意向/框架协议、正在接洽中的合作伙伴的情况，对未来数据进行预测，并对预测依据进行简要描述。"></textarea>
											<p class="storyDescLength">{{predict.length}}/500</p>
										</div>
									</td>
								</tr>
							</tbody>


						</table>
					</div>

					<div class="mobileIncome">
						<ul v-if="type==2">
							<li>
								<div v-for="(item, i) in incomeJson.productSum">
									<p v-if="i==0">营业收入</p>
									<p v-else>{{yearList[i-1]}}</p>
									<div>
										<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productSum[i]"
											@blur="blur($event,'productSum',i)" ref="forbid" />
										<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
											{{judgingCondition('productSum',i)?formatCurrency.formateNum(incomeJson.productSum[i],2):'-'}}
										</span>
									</div>
								</div>
							</li>
							<li>
								<div v-for="(item, i) in netProfit.productSum">
									<p v-if="i==0">净利润</p>
									<p v-else>{{yearList[i-1]}}</p>
									<div>
										<input type="number" data-type="number" v-if="i!=0" v-model="netProfit.productSum[i]"
											@blur="netProfitBlur($event,'netProfit','productSum',i)" ref="forbid" />
										<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
											{{netProfit.productSum[i]?formatCurrency.formateNum(netProfit.productSum[i],2):'-'}}
										</span>
									</div>
								</div>
							</li>
							<li>
								<div>
									<p>预测依据描述</p>
								</div>
								<div style="width: 100%;">
									<textarea class="predict" cols="20" rows="4" maxlength="500" v-model="predict"
										placeholder="请根据公司当前业务类型、经营状况和未来发展规划，结合公司目前已签约订单（含未执行完部分）、已签订的意向/框架协议、正在接洽中的合作伙伴的情况，对未来数据进行预测，并对预测依据进行简要描述。"></textarea>
								</div>
							</li>
						</ul>
						<ul v-else>
							<li v-for="(operation,index) in incomeJson.products">
								<div v-for="(item, i) in operation">
									<p>{{yearList[i]}}</p>
									<input type="text" v-if="i==0" v-model="operation[i]" @blur="sensitiveWordBlur($event,index)" />
									<input class="delete" type="button" v-if="i==0" value="删除" @click="del($event,index)"
										:disabled="status==1?true:false" />

									<div>
										<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]"
											@blur="blur($event,'products',i,index)" ref="forbid" />
										<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
											{{judgingCondition('products',i,index)?formatCurrency.formateNum(incomeJson.products[index][i],2):'-'}}
										</span>
									</div>
								</div>
							</li>
							<li v-if="type!=2">
								<div>
									<input class="add" type="button" value="添加" @click="add" :disabled="status==1?true:false" />
								</div>
							</li>
							<li>
								<div v-for="(item, i) in incomeJson.productOther">
									<p v-if="i==0">其他</p>
									<p v-if="i!=0">{{yearList[i]}}</p>
									<div>
										<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productOther[i]"
											@blur="blur($event,'productOther',i)" ref="forbid" />
										<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
											{{judgingCondition('productOther',i)?formatCurrency.formateNum(incomeJson.productOther[i],2):'-'}}
										</span>
									</div>
								</div>
							</li>
							<li>
								<div v-for="(item, i) in incomeJson.productSum">
									<p v-if="i==0">合计</p>
									<p v-if="i!=0">{{yearList[i]}}</p>
									<div>
										<input type="number" data-type="number" v-if="i!=0" v-model="incomeJson.productSum[i]" disabled />
										<span class="showInput" v-if="i!=0">
											{{judgingCondition('productSum',i)?formatCurrency.formateNum(incomeJson.productSum[i],2):'-'}}
										</span>
									</div>
								</div>
							</li>
						</ul>

					</div>

					<span
						class="tipRed">提示：{{yearList[0]+'营业收入预测等于基本信息中公司营业收入（预测）'+(type==2?'，'+yearList[0]+'净利润预测等于基本信息中公司净利润（预测）':'')}}</span>
					<div class="tableXian"></div>
				</div>
			</div>

			<div class="saveBox">
				<span class="last lastStep" @click="lastStep">上一步</span>
				<span class="save saveNext" id="saveButton" @click="saveButton">保 存</span>
			</div>

			<Modal v-model="sensitiveWord" class-name="vertical-center-modal" :mask-closable="false">
				<img class='warning' src="../../assets/img/remind-01@2x.png">
				<span>{{markedWords}}</span>
				<div slot="footer" class="ivu-sure" @click="sensitiveWordOk">确定</div>
			</Modal>

			<!-- 缓存提示框 -->
			<Modal v-model="cacheAlert" class-name="vertical-center-modal" :mask-closable="false"
				@on-cancel="clickCacheMaskJump">
				<img class='warning-cache' src="../../assets/img/caution.png">
				<span class="cache-title">您上次填写过估值报告，是否载入缓存信息？</span>
				<div slot="footer" class="ivu-no" @click="clickCacheTrueMaskJump">是</div>
				<div slot="footer" class="ivu-sure-cache" @click="clickCacheMaskJump">否</div>
			</Modal>
		</div>

		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import navigation from './navigation.vue';
	import uploadData from './uploadData.vue';
	import financeNav from './financeNav.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';

	export default {
		data() {
			return {
				type: this.$route.query.type,
				yearList: [],
				status: 2, // 判断当前页面是否可以修改  1代表不能修改   (1 审核通过  2 未填写完成  3 填写完成未支付)
				id: '',
				incomeJson: {
					id: '',
					productOther: ['other', '', '', '', '', '', ''],
					productSum: this.$route.query.type == 2 ? ['sum', '', '', ''] : ['sum', '', '', '', '', '', ''],
					products: [],
					year: []
				},
				netProfit: {
					id: '',
					productSum: ['sum', '', '', ''],
					year: []
				},
				predict: '', //预测依据描述
				baseInfoIncome: '',
				myAlert: false, //上传模板的弹框
				success: false, //上传模板成功的弹框
				error: false, //上传模板错误的弹框
				reportId: '',
				sensitiveWord: false,
				markedWords: '', //弹框提示语
				projectName: '',
				cacheAlert: false
			}
		},
		components: {
			Header,
			Footer,
			navigation,
			uploadData,
			financeNav
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			this.pageModify('false');
			this.getOperatingReceipt()
		},
		updated() {
			if (this.status == 1) {
				document.querySelector('.uploading').setAttribute("class", "forbidden"); //禁止上传模板
				this.$refs.forbid.forEach((item, i) => {
					item.disabled = "true";
					item.style.backgroundColor = '#fff';
					item.nextSibling.style.backgroundColor = '#fff';
					item.nextSibling.style.pointerEvents = 'none';
				})
			} else {
				this.$refs.forbid.forEach((item, i) => {
					item.disabled = "";
				})
			}
		},
		methods: {
			...mapMutations(['pageModify']),
			judgingCondition: function(key, i, index) {
				if (key == 'products') {
					if (this.incomeJson[key][index][i] === 0) {
						return true
					} else if (this.incomeJson[key][index][i] != 0 && this.incomeJson[key][index][i] != '-') {
						return true
					} else {
						return false
					}
				} else {
					if (this.incomeJson[key][i] === 0) {
						return true
					} else if (this.incomeJson[key][i] != 0 && this.incomeJson[key][i] != '-') {
						return true
					} else {
						return false
					}
				}
			},
			sensitiveWordBlur: function(e, index) { //  index：products中第几个的下标
				this.pageModify('true');
				if (this.method.filterSensitiveWords(e.target, this.incomeJson.products[index], 'statement')) {
					e.currentTarget.style.borderColor = "red";
					this.sensitiveWord = true;
					this.markedWords = '您输入的内容存在敏感词';
				} else {
					e.currentTarget.style.borderColor = "#CACACA";
				}
			},
			change(j, name) {
				if (j != 1 && Number(this[name].productSum[j]) < Number(this[name].productSum[j - 1])) {
					this.sensitiveWord = true
					this.markedWords = this.yearList[j - 1] + '预测数据较前一年度呈下降趋势，请核实是否符合公司实际经营情况。'
					return
				}
			},
			blur: function(e, name, i, index) { //name：具体的科目名称  i：科目名称中自己所处的下标   index：products中第几个的下标
				this.pageModify('true');
				e.currentTarget.nextSibling.style.display = 'block';
				if (name == 'products') {
					var data = this.incomeJson[name][index];
				} else {
					var data = this.incomeJson[name];
				}
				var y = String(data[i]).indexOf("."); //获取小数点的位置
				var count = String(data[i]).length - y; //获取小数点后的个数
				var totalNum = 0;

				if (data[i]) {
					if (String(data[i]).indexOf(".") != -1) {
						this.$set(data, i, Math.round(Number(data[i]) * 100) / 100) //保留两位
					} else {
						if (data[i].length > 15) { //判断数据的合理性
							this.$Message.error({
								background: true,
								content: '请输入合理数据'
							});
							this.$set(data, i, '')
						} else {
							this.$set(data, i, Number(data[i]))
							if (this.type != 2) {
								for (var j = 0; j < this.incomeJson.products.length; j++) {
									totalNum += Number(this.incomeJson.products[j][i])
									this.incomeJson.productSum[i] = Number(totalNum) + Number(this.incomeJson.productOther[
										i])
								}
							}

						}
					}
				} else {
					this.$set(data, i, '')
					for (var j = 0; j < this.incomeJson.products.length; j++) {
						totalNum += Number(this.incomeJson.products[j][i])
						this.incomeJson.productSum[i] = Number(totalNum) + Number(this.incomeJson.productOther[
							i])
					}
				}

			},
			netProfitBlur(e, key, name, i) {
				this.pageModify('true');
				e.currentTarget.nextSibling.style.display = 'block';
				var data = this[key][name];

				if (data[i]) {
					if (data[i].length > 15) {
						this.$Message.error({
							background: true,
							content: '请输入合理数据'
						});
						this.$set(data, i, '')
					} else {
						this.$set(data, i, Math.round(Number(data[i]) * 100) / 100) //保留两位
					}

				} else {
					this.$set(data, i, '')
				}

			},
			focus: function(e) {

			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			lastStep: function() {
				if (this.$route.query.reportId) {
					if (this.$route.query.type == 5) {
						this.$router.push({
							path: '/profitStatement',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId,
								traceReportId: this.$route.query.traceReportId,
								year: this.$route.query.year
							}
						})
					} else {
						this.$router.push({
							path: '/profitStatement',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					}
				} else {
					this.$router.push({
						path: '/profitStatement',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}
					})
				}
			},
			getOperatingReceipt: function(close) {
				if (this.$route.query.type == 2) {
					this.init(close)
				} else {
					this.queryIncome(close)
				}

			},
			queryIncome(close) {
				this.$Spin.show(); //全局加载中
				if (this.$route.query.type == 5) {
					var params = {
						reportId: this.$route.query.reportId,
						traceReportId: this.$route.query.traceReportId,
						year: this.$route.query.year
					}
				}
				this.$post(this.projectName + 'income/queryIncomeByReportId', qs.stringify(params))
					.then((res) => {
						this.$Spin.hide();
						if (this.$route.query.type == 5) {
							for (var i = 0; i < res.data.content.income.years.length; i++) {
								if (i == 3 || i == 4 || i == 5) {
									res.data.content.income.years[i] = res.data.content.income.years[i] + 'E';
								} else {
									res.data.content.income.years[i] = res.data.content.income.years[i] + '年';
								}
							}
							this.yearList = res.data.content.income.years;
							this.incomeJson.year = res.data.content.income.years.slice(1, 7);
							this.status = res.data.content.traceRelation.status;
							this.id = res.data.content.income.id;
							if (res.data.content.income.income) {
								this.incomeJson = JSON.parse(res.data.content.income.income);
							} else {
								for (var i = 0; i < 2; i++) {
									this.incomeJson.products.push([])
									for (var j = 0; j < 7; j++) {
										this.incomeJson.products[i].push("")
									}
								}
							}
						}
					})
			},
			init(close) {
				this.$Spin.show(); //全局加载中
				let params = {
					reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					stage: this.$route.query.stage,
					reportType: this.$route.query.type
				}

				this.$post(this.projectName + 'financeForecast/queryFinanceForecast', qs.stringify(params))
					.then(res => {
						this.$Spin.hide();
						// console.log('预测表-----', res.data)
						this.yearList = res.data.content.income.years
						this.isCacheValue = res.data.content.income.isCache;
						if (this.isCacheValue == '0' && close == '0') {
							this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this)
						}

						if (res.data.content.income.income) {
							this.incomeJson = JSON.parse(res.data.content.income.income)
							this.netProfit = JSON.parse(res.data.content.netProfit.netProfit)
							this.predict = res.data.content.predict ? res.data.content.predict : ''
							this.incomeJson.id = res.data.content.income.id
							this.netProfit.id = res.data.content.netProfit.id
						}

					})
			},
			saveButton: function() {
				if (this.status == 1) {
					this.$router.push({
						path: '/majorPolicyStatis',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type,
							reportId: this.$route.query.reportId,
							traceReportId: this.$route.query.traceReportId,
							year: this.$route.query.year
						}
					})
				} else {
					if (this.type == 2) {
						this.saveFinanceForecast()
					} else {
						this.addIncome()
					}

				}
			},
			addIncome() {
				var incomeJson = {
					year: this.yearList,
					productSum: this.incomeJson.productSum
				}
				var params = {
					id: this.id,
					reportId: this.$route.query.reportId,
					incomeJson: JSON.stringify(this.incomeJson),
					stage: sessionStorage.stage,
					traceReportId: this.$route.query.traceReportId,
					year: this.$route.query.year
				}

				console.log(params)

				this.$post(this.projectName + 'income/addIncome', qs.stringify(params))
					.then((res) => {
						if (res.data.code == 200) {
							this.$router.push({
								path: '/majorPolicyStatis',
								query: {
									stage: this.$route.query.stage,
									type: this.$route.query.type,
									reportId: this.$route.query.reportId,
									traceReportId: this.$route.query.traceReportId,
									year: this.$route.query.year
								}
							})

						}
					});
			},
			saveFinanceForecast() {
				if (!this.incomeJson.productSum[1]) {
					this.sensitiveWord = true
					this.markedWords = "请填写" + this.yearList[0] + "营业收入预测"
					return
				}

				if (!this.netProfit.productSum[1]) {
					this.sensitiveWord = true
					this.markedWords = "请填写" + this.yearList[0] + "净利润预测"
					return
				}


				let netProfitJson = {
					productSum: this.netProfit.productSum,
					year: this.yearList
				}

				let incomeJson = {
					productSum: this.incomeJson.productSum,
					year: this.yearList
				}

				let params = {
					incomeId: this.incomeJson.id,
					netProfitId: this.netProfit.id,
					predict: this.predict,
					incomeJson: JSON.stringify(incomeJson),
					netProfitJson: JSON.stringify(netProfitJson),
					reportId: this.$route.query.reportId,
					stage: this.$route.query.stage,
					reportType: this.type,
					versions: sessionStorage.versions
				}

				console.log('可以保存-------', params)

				this.$post(this.projectName + 'financeForecast/saveFinanceForecast', qs.stringify(params))
					.then((res) => {
						if (res.data.code == 200) {
							this.$router.push({
								path: '/majorPolicyStatis',
								query: {
									stage: this.$route.query.stage,
									type: this.$route.query.type,
									reportId: JSON.parse(res.data.content).reportId
								}
							})

						}
					})

			},

			del: function(e, index) {
				var this_ = this;
				this.incomeJson.products[index].forEach(function(item, i) {
					if (i != 0) {
						this_.incomeJson.productSum[i] = this_.incomeJson.productSum[i] - item;
					}
				})
				this.incomeJson.products.splice(index, 1)
			},
			add: function() {
				this.incomeJson.products.push(['', '', '', '', '', '', ''])
			},
			uploadInformation: function() {
				this.myAlert = true;
			},
			uploadFiles: function(e) {
				var formData = new FormData();
				formData.append("file", e.target.files[0]);
				formData.append('reportId', this.$route.query.reportId ? this.$route.query.reportId : '');
				formData.append("stage", sessionStorage.getItem("stage"));
				if (sessionStorage.reportType == 7) {
					formData.append("reportType", sessionStorage.getItem("reportType"));
				}
				formData.append("versions", sessionStorage.getItem("versions") ? sessionStorage.getItem("versions") :
					1);
				this.$post(this.projectName + 'upload/uploadFile', formData)
					.then((res) => {
						if (res.data.code == 200) {
							this.myAlert = false;
							this.reportId = res.data.content;
							if (res.data.content != "非法的excel" && res.data.content != "模板已更新，请重新下载最新模板再上传！") {
								this.success = true;
							} else if (res.data.content == "非法的excel") {
								this.error = true;
							}
						}
					})
			},
			submitDialog: function() {
				this.success = false;
				this.$router.push({
					path: '/majorIncomeAnalysis',
					query: {
						stage: sessionStorage.stage,
						type: sessionStorage.reportType,
						reportId: this.reportId
					}
				})
				this.$router.go(0)
			},
			wrongBtn: function() {
				this.error = false;
			},
			sensitiveWordOk: function() {
				this.sensitiveWord = false;
			},

			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.getOperatingReceipt();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this, 'getOperatingReceipt')
			}
		}

	}
</script>

<style scoped>
	table>tbody>tr.handle>td:last-child input {
		display: inline-block !important;
		margin-left: 15px;
	}

	table>tbody>tr.handle>td:last-child span.showInput {
		margin-right: 3%;
	}

	.mobileIncome {
		display: none;
	}

	@media screen and (max-width: 750px) {

		.inputTabDesc,
		.table-container,
		.tableXian,
		.down-up-container {
			display: none;
		}

		.mobileIncome {
			display: block;
		}

		div.table-name>span {
			font-size: 16PX;
		}

		span.unit {
			font-size: 12PX;
		}

		.mobileIncome ul>li>div:nth-child(n+2) {
			display: inline-block;
			margin-right: 15px;
			position: relative;
		}

		.mobileIncome ul>li>div:nth-child(n+2) p {
			margin: 5px 0;
		}

		.mobileIncome ul>li>div>div {
			margin: 10px 0;
			position: relative;
		}

		.mobileIncome ul>li>div input {
			width: 140px;
			height: 45px;
			line-height: 45px;
			color: #282828;
			display: block;
			border: 1px solid #D5D5D5;
			text-align: right;
			padding-right: 8px;
			font-size: 14PX;
		}

		.mobileIncome ul>li>div:first-child input {
			text-align: left;
			display: inline-block;
		}

		.mobileIncome span.showInput {
			width: 134px;
			height: 40px;
			line-height: 45px;
			font-size: 14PX;
			background: #fff;
			position: absolute;
			top: 2px;
			left: 3px;
			text-align: right;
		}

		.mobileIncome ul>li>div:first-child input.add {
			margin: 15px auto;
			text-align: center;
			border-color: #4393FF;
			color: #4393FF;
			background: #fff;
			display: block;
		}

		.mobileIncome ul>li>div:first-child input.delete {
			margin-left: 30%;
			margin-top: 10px;
			background: #fff;
			border-color: #FFA14C;
			color: #FFA14C;
			text-align: center;
		}


	}
</style>