<template>
	<div style="background:#f3f3f3;">
		<div class="tools">
			<button @click="pdf()" v-if="pageInfo.isTestAccount==0||this.$route.query.admin == 1">导出PDF</button>
		</div>
		<div class="" id="pageContent">
			<!-- 封面 -->
			<div class="homePage">
				<div class="content" id="homePage">
					<div class="logoBox">
						<img src="../../assets/img/logo/darwinBlack.png" alt="北京心流慧估科技有限公司logo-心流" class="logoImage" />
						<!-- <div class="stock-logo hide">
							<img src="" alt="股交所logo-心流" />
						</div> -->
					</div>
					<p class="companyName" v-text="pageInfo.baseInfo.companyName">北京AAAA科技有限公司</p>
					<div class="homePageLine"></div>
					<h2>价值诊断报告</h2>
					<img src="../../assets/img/specialization/artFont.png" alt="" class="artFont">

					<h3 class="reportId" v-text="pageInfo.reportNumber"></h3>
					<h4>（专精特新版）</h4>
					<img src="../../assets/img/growthDiagnosis/pageIndex_bg.png" alt="" class="pageIndex_bg">
					<i>北京心流慧估科技有限公司保留最终解释权</i>
				</div>
			</div>
			<!-- 目录 -->
			<div class="cataloguePage">
				<div class="content" id="cataloguePage">
					<h2>目录</h2>
					<h3>CONTECT</h3>
					<div class="generalize">
						<div>
							<div></div>
							<p>一、</p>
							<p>综合得分</p>
							<span class="titleActive"></span>
							<span class="pageNumber pageNumActive fontBold">01</span>
						</div>
						<i class="titleLine"></i>
						<ul>
							<li>
								<p>综合得分</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">01</span>
							</li>
							<li>
								<p>六大得分指标</p>
								<span style="width:804px" class="dottedLine"></span>
								<span class="pageNumber">01</span>
							</li>
							<li>
								<p>相关建议</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">01</span>
							</li>
						</ul>
						<div>
							<div></div>
							<p>二、</p>
							<p>行业研究</p>
							<span class="titleActive"></span>
							<span class="pageNumber pageNumActive fontBold">02</span>
						</div>
						<i class="titleLine"></i>
						<div>
							<div></div>
							<p>三、</p>
							<p>基本信息</p>
							<span class="titleActive"></span>
							<span class="pageNumber pageNumActive fontBold">03</span>
						</div>
						<i class="titleLine"></i>
						<div>
							<div></div>
							<p>四、</p>
							<p>诊断图谱</p>
							<span class="titleActive"></span>
							<span class="pageNumber pageNumActive fontBold">04</span>
						</div>
						<i class="titleLine"></i>
						<ul class="flowChart">
							<li>
								<p>专业化（主营业务专业专注）</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">05</span>
							</li>
							<li>
								<p>精细化（经营管理精细高效）</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">08</span>
							</li>
							<li>
								<p>特色化（产品服务独具特色）</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">09</span>
							</li>
							<li>
								<p>新颖化（创新能力成果显著）</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">10</span>
							</li>
						</ul>
						<div>
							<div></div>
							<p>五、</p>
							<p>财务分析</p>
							<span class="titleActive"></span>
							<span class="pageNumber pageNumActive fontBold">11</span>
						</div>
						<i class="titleLine"></i>
						<ul>
							<li>
								<p>杜邦分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">11</span>
							</li>
							<li>
								<p>盈利分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">12</span>
							</li>
							<li>
								<p>成长分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">15</span>
							</li>
							<li>
								<p>偿债分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">17</span>
							</li>
							<li>
								<p>营运分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">19</span>
							</li>
						</ul>
						<div>
							<div></div>
							<p>六、</p>
							<p>体检结果</p>
							<span class="titleActive"></span>
							<span class="pageNumber pageNumActive fontBold">21</span>
						</div>
						<i class="titleLine"></i>
						<div>
							<div></div>
							<p>七、</p>
							<p>附件</p>
							<span class="titleActive"></span>
							<span class="pageNumber pageNumActive fontBold">22</span>
						</div>
						<i class="titleLine"></i>
						<div>
							<div></div>
							<p>八、</p>
							<p>计算公式</p>
							<span class="titleActive"></span>
							<span class="pageNumber pageNumActive fontBold">26</span>
						</div>
						<i class="titleLine"></i>
						<div class="stockIntroduceCatalogue hide">
							<div></div>
							<p>九、</p>
							<p class="stockName">股交所简介</p>
							<span class="titleActive"></span>
							<span class="pageNumber pageNumActive fontBold">27</span>
						</div>
						<i class="titleLine stockTitleLine" style="display: none;"></i>
						<div class="isAnd_catalogue">
							<div></div>
							<p class="toTen">九、</p>
							<p>免责声明</p>
							<span class="titleActive"></span>
							<span class="pageNumber pageNumActive fontBold isAnd_">27</span>
						</div>
						<i class="titleLine"></i>
					</div>
					<div class="generalize">


					</div>
				</div>
			</div>
			<!-- 第一页 综合得分 -->
			<div class="synthesisScore">
				<div class="content" id="synthesisScore">
					<h2>一、综合得分</h2>
					<h3>诊断说明</h3>
					<p>本诊断结果以心流七维价值理论为基础，以企业输入的真实数据为依据，运用曼殊AI系统对一、二、三级指标进行智能分析，得出以下单项和综合分值（满分为100分）。 </p>
					<div class="synthesisScorePic">
						<p v-text="initStatus? pageInfo.totalScore.toFixed(2) : '00.00'">00.00</p>
					</div>
					<div class="reportDate" v-text="'诊断基准日：' + pageInfo.year[1]"></div>
					<h3 style="margin-top:51px;">五大指标得分</h3>
					<ul>
						<li class="synthesisScore_industry" v-for="(item,index) in pageInfo.question_" :key="index">
							<div class="scoreTop">
								<!-- <img src="../../assets/img/growthDiagnosis/radiaIcon1.svg" class="radiaIcon0" alt=""> -->
								<!-- <div class="spendingForecast spendingForecast_0"></div> -->
								<i-circle :stroke-width="6" :trail-width="6" trail-color="#CBE1F4" stroke-color="#0478fc" :size="70"
									class="circleComponent" :percent="Number(item.value)">
									<img :src="item.synthesisScore" :class="item.className" alt="">
								</i-circle>
								<div class="scoreCont">
									<p>
										{{item.name}}
										<img src="../../assets/img/growthDiagnosis/scoreLow.svg" alt="" class="scoreLow"
											v-if="minIndex == index" :style="index==4?'left:46px;':''">
									</p>
									<span>{{item.value}}</span>
								</div>
							</div>
							<div class="scoreBottom">
								<div class="indexActive" :style="'width:' + item.value + '%;'"></div>
								<div class="indexIcon0"></div>
								<div class="indexIcon1"></div>
								<div class="indexIcon2"></div>
								<div class="indexIcon3"></div>
							</div>
						</li>
					</ul>
					<h3>相关建议</h3>
					<div class="suggestion">
						<img src="../../assets/img/growthDiagnosis/yinhao.svg" alt="">
						<p v-text="pageInfo.baseInfo.companyName + pageInfo.suggestion"></p>
						<div id="traingle"></div>
					</div>
					<div class="pageNum">
						<span>1</span>
					</div>
				</div>
			</div>
			<!-- 第二页 综合得分内容 -->
			<div class="synthesisScoreCont">
				<div class="content" id="synthesisScoreCont">

					<h3 class="">行业研究</h3>
					<img src="../../assets/img/growthDiagnosis/industryDesc.png" alt="">
					<div class="industryDescLists">
						<p v-for="(item,index) in pageInfo.industryDes" :key="index + 1" v-show="pageInfo.industryDes != ''">
							{{item}}
						</p>
						<p v-show="pageInfo.industryDes">暂无</p>
					</div>
					<div class="pageNum">
						<span>2</span>
					</div>
				</div>
			</div>
			<!-- 第三页 基本信息 -->
			<div class="baseInfo">
				<div class="content" id="baseInfo">
					<!-- 基本信息 -->
					<h2>三、基本信息</h2>
					<div class="basicInfo">
						<div class="basicInfoTitle">
							<h3>企业基本信息</h3>
						</div>
						<i class="titleLine"></i>
						<div class="basicInfoCont">
							<ul>
								<li>
									<span>企业名称：</span>
									<span class="companyName" v-text="pageInfo.baseInfo.companyName"></span>
								</li>

								<li>
									<span>企业所在地：</span>
									<span class="companyLocation" v-text="pageInfo.baseInfo.area ? pageInfo.baseInfo.area : '无数据'"></span>
								</li>
								<li>
									<span>注册资本：</span>
									<span class="registeredCapital"
										v-text="pageInfo.baseInfo.regCapital ? valueFormate(pageInfo.baseInfo.regCapital) : '无数据'"></span>
								</li>
								<li>
									<span>统一社会信用代码：</span>
									<span class="socialCreditCode" v-text="pageInfo.baseInfo.creditCode"></span>
								</li>
								<li>
									<span>法定代表人：</span>
									<span class="corporateRepresentative"
										v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople : '无数据'"></span>
								</li>
								<li>
									<span>邮箱：</span>
									<span class="Email" v-text="pageInfo.baseInfo.email"></span>
								</li>
								<li>
									<span style="vertical-align: top;">所属行业：</span>
									<span class="industry" style="width: 76%;"
										v-text="pageInfo.baseInfo.industryName + '-' + pageInfo.baseInfo.industryChildName"></span>
								</li>
								<li>
									<span>成立日期：</span>
									<span class="establishmentDate" v-text="dateFormate(pageInfo.baseInfo.establishDate)"></span>
								</li>
							</ul>
						</div>
					</div>
					<!-- 主营业务 -->
					<div class="mainBusiness">
						<div class="mainBusinessTitle">
							<h3>主营业务</h3>
						</div>
						<i class="titleLine"></i>
						<p class="bussinessDesc" v-text="pageInfo.baseInfo.bussinessDesc ? pageInfo.baseInfo.bussinessDesc : '无数据'">
						</p>
						<!-- <div class="mainBusinessCont"></div> -->
					</div>
					<!-- 股权结构 -->
					<div class="stockStructure">
						<div class="structureTitle">
							<h3>股权结构</h3>
						</div>
						<i class="titleLine"></i>
						<Draw3DPieCanvas :id="'stockStructurePie'" :chartData="pageInfo.baseInfo.holderRation" v-if="initStatus" />
					</div>
					<div class="pageNum">
						<span>3</span>
					</div>
				</div>
			</div>
			<!-- 第四页 诊断图谱 -->
			<div class="flowAtlas">
				<div class="content" id="flowAtlas">
					<!-- 诊断图谱 -->
					<h2>四、诊断图谱</h2>
					<div class="flowDiagnose">
						<div class="flowDiagnoseTitle">
							<img src="../../assets/img/growthDiagnosis/xinliuPic.svg" alt="" class="icon1">
							<h3>诊断图谱</h3>
						</div>
						<DrawRadarCanvas :chartData="pageInfo.question" :id="'flowDiagnose_BaseInfo'" v-if="initStatus" />
					</div>
					<!-- 财务分析 -->
					<div class="financeAnalyze">
						<div class="financeAnalyzeTitle">
							<img src="../../assets/img/growthDiagnosis/caiwufenxi.svg" alt="" class="icon7">
							<h3>财务分析</h3>
						</div>
						<DrawRadarCanvas :chartData="pageInfo.financial" :id="'financeAnalyze_BaseInfo'" v-if="initStatus" />
					</div>

					<div class="pageNum">
						<span>4</span>
					</div>
				</div>
			</div>
			<!-- 第五页 专业化得分 -->
			<div class="industrySuggest">
				<div class="content" id="industrySuggest">
					<div class="industryScore">
						<div class="industryTitle" style="margin-left: 0;">
							<img src="../../assets/img/growthDiagnosis/hangyedefen.svg" alt="" class="icon2">
							<h3>专业化（主营业务专业专注） 总得分<span v-text="scoreFormate('专业化（主营业务专业专注）')">0.00</span>分</h3>
						</div>
						<div class="targetItem" v-for="(item,index) in industryStatements1" :key="index + 2">
							<div class="industryCont">
								<div class="industryIconBg iconBg">
									<p>{{item.value}}</p>
								</div>
								<div class="industryDes">
									<h5>{{'0' + (index + 1) + '、' + item.name}}</h5>
									<div class="desLine" :style="'width:' + item.width"></div>
									<p>{{item.description}}</p>
								</div>
							</div>
							<div class="industrySuggestion">
								<div>
									<img src="../../assets/img/growthDiagnosis/suggestIcon.svg" class="suggestIcon" alt="">
									<span>建议</span>
								</div>
								<div class="suggestion">
									<img src="../../assets/img/growthDiagnosis/yinhao.svg" alt="">
									<p>{{item.suggestion}}</p>
									<div id="traingle"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="pageNum">
						<span>5</span>
					</div>
				</div>
			</div>
			<!-- 第六页 专业化得分 -->
			<div class="industrySuggest1">
				<div class="content" id="industrySuggest1">
					<div class="industryScore">
						<div class="industryTitle" style="margin-left: 0;">
							<img src="../../assets/img/growthDiagnosis/hangyedefen.svg" alt="" class="icon2">
							<h3>专业化（主营业务专业专注） 总得分<span v-text="scoreFormate('专业化（主营业务专业专注）')">0.00</span>分</h3>
						</div>
						<div class="targetItem" v-for="(item,index) in industryStatements2" :key="index + 3">
							<div class="industryCont">
								<div class="industryIconBg iconBg">
									<p>{{item.value}}</p>
								</div>
								<div class="industryDes">
									<h5>{{'0' + (index + 3) + '、' + item.name}}</h5>
									<div class="desLine" :style="'width:' + item.width"></div>
									<p>{{item.description}}</p>
								</div>
							</div>
							<div class="industrySuggestion">
								<div>
									<img src="../../assets/img/growthDiagnosis/suggestIcon.svg" class="suggestIcon" alt="">
									<span>建议</span>
								</div>
								<div class="suggestion">
									<img src="../../assets/img/growthDiagnosis/yinhao.svg" alt="">
									<p>{{item.suggestion}}</p>
									<div id="traingle"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="pageNum">
						<span>6</span>
					</div>
				</div>
			</div>
			<!-- 第七页 专业化得分 -->
			<div class="industrySuggest2">
				<div class="content" id="industrySuggest2">
					<div class="industryScore">
						<div class="industryTitle" style="margin-left: 0;">
							<img src="../../assets/img/growthDiagnosis/hangyedefen.svg" alt="" class="icon2">
							<h3>专业化（主营业务专业专注） 总得分<span v-text="scoreFormate('专业化（主营业务专业专注）')">0.00</span>分</h3>
						</div>
						<div class="targetItem" v-for="(item,index) in industryStatements3" :key="index + 4">
							<div class="industryCont">
								<div class="industryIconBg iconBg">
									<p>{{item.value}}</p>
								</div>
								<div class="industryDes">
									<h5>{{'0' + (index + 5) + '、' + item.name}}</h5>
									<div class="desLine" :style="'width:' + item.width"></div>
									<p>{{item.description}}</p>
								</div>
							</div>
							<div class="industrySuggestion">
								<div>
									<img src="../../assets/img/growthDiagnosis/suggestIcon.svg" class="suggestIcon" alt="">
									<span>建议</span>
								</div>
								<div class="suggestion">
									<img src="../../assets/img/growthDiagnosis/yinhao.svg" alt="">
									<p>{{item.suggestion}}</p>
									<div id="traingle"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="pageNum">
						<span>7</span>
					</div>
				</div>
			</div>
			<!-- 第八页 精细化得分 -->
			<div class="productScore">
				<div class="content" id="productScore">
					<div class="productScoreDesc">
						<div class="productTitle">
							<img src="../../assets/img/growthDiagnosis/tuanduidefen.svg" alt="" class="icon">
							<h3>精细化（经营管理精细高效） 总得分<span v-text="scoreFormate('精细化（经营管理精细高效）')">0.00</span>分</h3>
						</div>
						<div class="targetItem" v-for="(item,index) in pageInfo.competitivePower" :key="index + 5">
							<div class="productScoreCont">
								<div class="productScoreIconBg iconBg">
									<p>{{item.value}}</p>
								</div>
								<div class="productScoreDes">
									<h5>{{'0' + (index + 1) + '、' + item.name}}</h5>
									<div class="desLine" :style="'width:' + item.width"></div>
									<p>{{item.description}}</p>
								</div>
							</div>
							<div class="productScoreSuggestion">
								<div>
									<img src="../../assets/img/growthDiagnosis/suggestIcon.svg" class="suggestIcon" alt="">
									<span>建议</span>
								</div>
								<div class="suggestion">
									<img src="../../assets/img/growthDiagnosis/yinhao.svg" alt="">
									<p>{{item.suggestion}}</p>
									<div id="traingle"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="pageNum">
						<span>8</span>
					</div>
				</div>
			</div>
			<!-- 第九页 特色化得分 -->
			<div class="teamScore">
				<div class="content" id="teamScore">
					<div class="teamScoreDesc">
						<div class="teamTitle">
							<img src="../../assets/img/growthDiagnosis/chanpindefen.svg" alt="" class="icon">
							<h3>特色化（产品服务独具特色） 总得分<span v-text="scoreFormate('特色化（产品服务独具特色）')">0.00</span>分</h3>
						</div>
						<div class="targetItem" v-for="(item,index) in pageInfo.teamManage" :key="index + 6">
							<div class="teamScoreCont">
								<div class="teamScoreIconBg iconBg">
									<p>{{item.value}}</p>
								</div>
								<div class="teamScoreDes">
									<h5>{{'0' + (index + 1) + '、' + item.name}}</h5>
									<div class="desLine" :style="'width:' + item.width"></div>
									<p>{{item.description}}</p>
								</div>
							</div>
							<div class="teamScoreSuggestion">
								<div>
									<img src="../../assets/img/growthDiagnosis/suggestIcon.svg" class="suggestIcon" alt="">
									<span>建议</span>
								</div>
								<div class="suggestion">
									<img src="../../assets/img/growthDiagnosis/yinhao.svg" alt="">
									<p>{{item.suggestion}}</p>
									<div id="traingle"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="pageNum">
						<span>9</span>
					</div>
				</div>
			</div>
			<!-- 第十页 新颖化得分 -->
			<div class="skillScore">
				<div class="content" id="skillScore">
					<div class="skillScoreDesc">
						<div class="skillTitle">
							<img src="../../assets/img/growthDiagnosis/jishudefen.svg" alt="" class="icon">
							<h3>新颖化（创新能力成果显著） 总得分<span v-text="scoreFormate('新颖化（创新能力成果显著）')">0.00</span>分</h3>
						</div>
						<div class="targetItem" v-for="(item,index) in pageInfo.teamManage" :key="index + 6">
							<div class="skillScoreCont">
								<div class="skillScoreIconBg iconBg">
									<p>{{item.value}}</p>
								</div>
								<div class="skillScoreDes">
									<h5>{{'0' + (index + 1) + '、' + item.name}}</h5>
									<div class="desLine" :style="'width:' + item.width"></div>
									<p>{{item.description}}</p>
								</div>
							</div>
							<div class="skillScoreSuggestion">
								<div>
									<img src="../../assets/img/growthDiagnosis/suggestIcon.svg" class="suggestIcon" alt="">
									<span>建议</span>
								</div>
								<div class="suggestion">
									<img src="../../assets/img/growthDiagnosis/yinhao.svg" alt="">
									<p>{{item.suggestion}}</p>
									<div id="traingle"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="pageNum">
						<span>10</span>
					</div>
				</div>
			</div>
			<!-- 第十一页 杜邦分析 -->
			<div class="dupontAnalyze">
				<div class="content" id="dupontAnalyze">
					<h2>五、财务分析</h2>
					<DupontAnalyze :dupontData="pageInfo.dubangAnalysis" v-if="initStatus" :versions="1" />
					<div class="pageNum">
						<span>11</span>
					</div>
				</div>
			</div>
			<!-- 第十二页 盈利分析 -->
			<div class="profitAnalyze">
				<div class="content" id="profitAnalyze">
					<div class="profitTitle">
						<img src="../../assets/img/growthDiagnosis/yinglifenxi.svg" alt="" class="icon">
						<h3>盈利分析</h3>
					</div>
					<!--总资产收益率-->
					<DrawBarFinancialAnalysis2v :id="'totalAssetsReturnOn'" :chartData="pageInfo.totalAssetsReturnOn"
						v-if="initStatus" :gradeStatus="gradeStatus" />
					<!--销售费用率-->
					<DrawBarFinancialAnalysis2v :id="'saleRatio'" :chartData="pageInfo.saleRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<div class="pageNum">
						<span>12</span>
					</div>
				</div>
			</div>
			<!-- 第十三页 盈利分析 -->
			<div class="profitAnalyze1">
				<div class="content" id="profitAnalyze1">
					<div class="profitTitle">
						<img src="../../assets/img/growthDiagnosis/yinglifenxi.svg" alt="" class="icon">
						<h3>盈利分析</h3>
					</div>
					<!--管理费用率-->
					<DrawBarFinancialAnalysis2v :id="'manageRatio'" :chartData="pageInfo.manageRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<!--研发费用率-->
					<DrawBarFinancialAnalysis2v :id="'researchRatio'" :chartData="pageInfo.researchRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<!--毛利率-->
					<!-- <DrawBarFinancialAnalysis2v :id="'grossProfitRatio'" :chartData="pageInfo.grossProfitRatio" v-if="initStatus" :gradeStatus="gradeStatus"/> -->
					<div class="pageNum">
						<span>13</span>
					</div>
				</div>
			</div>
			<!-- 第十四页 盈利分析 -->
			<div class="profitAnalyze2">
				<div class="content" id="profitAnalyze2">
					<div class="profitTitle">
						<img src="../../assets/img/growthDiagnosis/yinglifenxi.svg" alt="" class="icon">
						<h3>盈利分析</h3>
					</div>
					<!--毛利率-->
					<DrawBarFinancialAnalysis2v :id="'grossProfitRatio'" :chartData="pageInfo.grossProfitRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<!--净利润率-->
					<DrawBarFinancialAnalysis2v :id="'netProfiltRatio'" :chartData="pageInfo.netProfiltRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<div class="pageNum">
						<span>14</span>
					</div>
				</div>
			</div>
			<!-- 第十五页 成长分析 -->
			<div class="growthAnalyze">
				<div class="content" id="growthAnalyze">
					<div class="growthTitle">
						<img src="../../assets/img/growthDiagnosis/chengzhangfenxi.svg" alt="" class="icon">
						<h3>成长分析</h3>
					</div>
					<!--主营业务收入增长率-->
					<DrawBarFinancialAnalysis2v :id="'mainIncomeRatio'" :chartData="pageInfo.mainIncomeRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<!--利润总额增长率-->
					<DrawBarFinancialAnalysis2v :id="'totalProfitRatio'" :chartData="pageInfo.totalProfitRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<div class="pageNum">
						<span>15</span>
					</div>
				</div>
			</div>
			<!-- 第十六页 成长分析 -->
			<div class="growthAnalyze1">
				<div class="content" id="growthAnalyze1">
					<div class="growthTitle">
						<img src="../../assets/img/growthDiagnosis/chengzhangfenxi.svg" alt="" class="icon">
						<h3>成长分析</h3>
					</div>
					<!--净资产收益率-->
					<DrawBarFinancialAnalysis2v :id="'netAssetRatio'" :chartData="pageInfo.netAssetRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<div class="pageNum">
						<span>16</span>
					</div>
				</div>
			</div>
			<!-- 第十七页 偿债分析 -->
			<div class="debtPayAnalyze">
				<div class="content" id="debtPayAnalyze">
					<div class="debtPayTitle">
						<img src="../../assets/img/growthDiagnosis/zhaichangfenxi.svg" alt="" class="icon">
						<h3>偿债分析</h3>
					</div>
					<!--资产负债率-->
					<DrawBarFinancialAnalysis2v :id="'liabilitiesRatio'" :chartData="pageInfo.liabilitiesRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<!--流动比率-->
					<DrawBarFinancialAnalysis2v :id="'flowRation'" :chartData="pageInfo.flowRation" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<div class="pageNum">
						<span>17</span>
					</div>
				</div>
			</div>
			<!-- 第十八页 偿债分析 -->
			<div class="debtPayAnalyze1">
				<div class="content" id="debtPayAnalyze1">
					<div class="debtPayTitle">
						<img src="../../assets/img/growthDiagnosis/zhaichangfenxi.svg" alt="" class="icon">
						<h3>偿债分析</h3>
					</div>
					<!--速动比率-->
					<DrawBarFinancialAnalysis2v :id="'quickRatio'" :chartData="pageInfo.quickRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<!--EBIT利息保障倍数-->
					<DrawBarFinancialAnalysis2v :id="'ebitMultiple'" :chartData="pageInfo.ebitMultiple" v-if="initStatus"
						:gradeStatus="ebitMultipleGradeStatus" />
					<div class="pageNum">
						<span>18</span>
					</div>
				</div>
			</div>
			<!-- 第十九页 运营分析 -->
			<div class="operateAnalyze">
				<div class="content" id="operateAnalyze">
					<div class="operateTitle">
						<img src="../../assets/img/growthDiagnosis/yunyingfenxi.svg" alt="" class="icon">
						<h3>运营分析</h3>
					</div>
					<!--应收账款周转率-->
					<DrawBarFinancialAnalysis2v :id="'receivablesTurnoverRatio'" :chartData="pageInfo.receivablesTurnoverRatio"
						v-if="initStatus" :gradeStatus="receivablesTurnoverRatioGradeStatus" />
					<!--总资产周转率-->
					<DrawBarFinancialAnalysis2v :id="'totalAssetsTurnoverRatio'" :chartData="pageInfo.totalAssetsTurnoverRatio"
						v-if="initStatus" :gradeStatus="gradeStatus" />
					<div class="pageNum">
						<span>19</span>
					</div>
				</div>
			</div>
			<!-- 第二十页 运营分析 -->
			<div class="operateAnalyze1">
				<div class="content" id="operateAnalyze1">
					<div class="operateTitle">
						<img src="../../assets/img/growthDiagnosis/yunyingfenxi.svg" alt="" class="icon">
						<h3>运营分析</h3>
					</div>
					<!--存货周转率-->
					<DrawBarFinancialAnalysis2v :id="'inventoryTurnoverRatio'" :chartData="pageInfo.inventoryTurnoverRatio"
						v-if="initStatus" :gradeStatus="inventoryTurnoverRatioGradeStatus" />
					<div class="pageNum">
						<span>20</span>
					</div>
				</div>
			</div>
			<!-- 第二十一页 财务状况 -->
			<div class="financialCondition">
				<div class="content" id="financialCondition">
					<h2>六、体检结果</h2>
					<div class="financialConditionTitle">
						<img src="../../assets/img/growthDiagnosis/caiwuzhuangkuang.svg" alt="" class="icon">
						<h3>财务状况体检报告</h3>
					</div>
					<!--圆环内容-->
					<ul class="imgUl">
						<li v-for="(item,index) in pageInfo.financial" :key="index + 7">
							<div class="liDivImg" id="indicatorContainer1" :style="item.style">
								<p>{{item.value}}</p>
								<h6>{{item.section}}</h6>
							</div>
							<h5>{{item.name}}</h5>
							<img src="../../assets/img/growthDiagnosis/scoreLow.svg" alt="" class="scoreLow"
								v-if="financialIndex == index">
							<p>{{item.description}}</p>
						</li>
						<div class="clear"></div>
					</ul>
					<div class="pageNum">
						<span>21</span>
					</div>
				</div>
			</div>
			<!-- 第二十二页 附件1 -->
			<div class="accessory_1">
				<div class="content" id="accessory_1">
					<LibTableComponent1 :liaTableInfo="pageInfo.liaTableInfo" v-if="initStatus" :year="pageInfo.year"
						:versions="1" />
					<div class="pageNum">
						<span>22</span>
					</div>
				</div>
			</div>
			<!-- 第二十三页 附件1 -->
			<div class="accessory_2">
				<div class="content" id="accessory_2">
					<LibTableComponent2 :liaTableInfo="pageInfo.liaTableInfo" v-if="initStatus" :year="pageInfo.year"
						:versions="1" />
					<div class="pageNum">
						<span>23</span>
					</div>
				</div>
			</div>
			<!-- 第二十四页 附件2 -->
			<div class="accessory_3">
				<div class="content" id="accessory_3">
					<ProfitTableComponent1 :profitTableInfo="pageInfo.profitTableInfo" v-if="initStatus" :year="pageInfo.year"
						:versions="1" />
					<div class="pageNum">
						<span>24</span>
					</div>
				</div>
			</div>
			<!-- 第二十五页 附件2 + 财务比率表 -->
			<div class="accessory_4">
				<div class="content" id="accessory_4">
					<ProfitTableComponent2 :profitTableInfo="pageInfo.profitTableInfo" v-if="initStatus" :year="pageInfo.year"
						:financeRatio="pageInfo.financeRatio" :financeRatioShow="true" :versions="1" />
					<div class="pageNum">
						<span>25</span>
					</div>
				</div>
			</div>
			<!-- 第二十六页 计算公式 -->
			<div class="computational">
				<div class="content" id="computational">
					<h2>八、计算公式</h2>
					<div class="formula-container">
						<h3>1、财务指标公式</h3>
						<ul>
							<li>
								<p class="dian">◆</p>
								<p>资产负债率=负债合计/资产总计×100%</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>流动比率=流动资产合计/流动负债合计</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>EBIT利息保障倍数=（净利润+所得税+利息+折旧摊销）/利息费用</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>毛利率=（营业收入-营业成本）/营业收入×100%</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>净利润率=净利润/营业收入×100%</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>主营业务收入增长率= (本期主营业务收入-上期主营业务收入)/上期主营业务收入*100%</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>净资产收益率=净利润×2/（上期净资产+本期净资产）×100%</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>应收账款周转率=营业收入×2/（本期应收账款+上期应收账款）×100%</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>存货周转率=营业成本×2/（本期存货+上期存货）×100%</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>总资产收益率=净利润×2/（本期资产总额+上期资产总额）×100%</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>销售费用率=销售费用/营业收入</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>管理费用率=管理费用/营业收入</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>研发费用率=研发费用/营业收入</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>速动比率=（流动资产-存货）/流动负债</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>总资产周转率=营业收入×2/（本期资产总额+上期资产总额）×100%</p>
							</li>
							<li>
								<p class="dian">◆</p>
								<p>利润总额增长率=（本期利润总额-上期利润总额）/上期利润总额×100%</p>
							</li>
						</ul>
					</div>
					<div class="pageNum">
						<span>26</span>
					</div>
				</div>
			</div>
			<!-- 第二十七页 免责声明 -->
			<div class="disclaimer">
				<div class="content" id="disclaimer">
					<div class="page-content page-last">

						<img src="../../assets/img/report/map@2x.png" alt="线上估值_初创期-心流" />

						<div class="flow-statement">
							<img src="../../assets/img/logo/darwinBlack.png" alt="线上估值-心流" />
							<ul>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian">◆</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p>诊断声明</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p class="dian dian_">◆</p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										本报告由系统所自动生成。该系统依据企业提供的财务数据和软性指标，结合具备可靠性水平的数
										据库，根据通用的行业业绩及财务预测逻辑和规则，对企业诊断所需要的必要参数进行预测和分析。企业提供资料的完整度和准确性对最终 诊断结果有重要影响。请本报告使用者知悉。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian">◆</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p>免责声明</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p class="dian dian_">◆</p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										系统所采用的数据信息来源于企业自行提供的资料和公开渠道可获取的行业信息和数据，并基于前述资料属实、
										合法的假设出具本报告，网站亦不对前述资料的准确性、完整性负有实质审核的义务，本报告仅作为对企业整体价值衡量的参考，非并
										购、转让等交易的直接证据，网站及其运营方不承担因信赖本报告信息而采取行动所导致的任何损失及一切法律责任。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian">◆</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p>心流慧估</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p class="dian dian_">◆</p>
										<span class="xian"></span>
									</div>
									<div class="statement-main lastStatement-main">
										<p>
											北京心流慧估科技有限公司是国内外领先的投行AIGC公司，专注于用AI技术为中国众多中小企业提供“估值+组合式金融服务”，通过自主研发的曼殊AI（Manju
											AI）系列软件产品，从发现、培育优质股权，再到资本对接，引金融之水精确“滴灌”，低成本、高效率地为中小企业走进资本市场提供定制化解决方案
										</p>
										<p>
											心流慧估运用人工智能技术和多种统计分析方法，将国际通用估值技术结合心流慧估独创软性指标体系，搭建出完善的股权估值模型和算法模型。采用AIGC技术将估值模型、对标企业、指标权重、语句分析自动匹配并实时在线生成完整专业报告，不仅能科学、客观、公允地评估企业价值，还能帮助企业更好地进行融资规划、财务系统建设、企业管理规范，提高企业决策和融资效率。
										</p>
										<div>
											<img src="../../assets/img/wechartCode.jpg" alt="">
											<ul>
												<li><span>网址</span>：www.flcccc.com</li>
												<li><span>客服电话</span>：17610076778</li>
												<li><span>微信号</span>：flcccc888</li>
												<li><span>地址</span>：北京市朝阳区酒仙桥路甲12号电子城科技大厦15层1505</li>
											</ul>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="pageNum">
						<span class="isAnd_">27</span>
					</div>
				</div>
			</div>
		</div>
		<div id="loading" style="display: none;">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>
	</div>
</template>
<script>
	import qs from 'qs'
	import common from '@/components/reportPage/common'
	import '../../assets/css/loading.css'
	import PdfOnload from '../../utils/pdf'
	import Draw3DPieCanvas from '../../common/echarts/draw3DPieCanvas.vue'
	import DrawRadarCanvas from '../../common/echarts/drawRadarCanvas.vue'
	import DupontAnalyze from '../../common/reportComponent/dupontAnalyze.vue'
	import DrawBarFinancialAnalysis2v from '../../common/echarts/drawBarFinancialAnalysis2v.vue'
	import LibTableComponent1 from '../../common/reportComponent/liaTableComponent1.vue'
	import LibTableComponent2 from '../../common/reportComponent/liaTableComponent2.vue'
	import ProfitTableComponent1 from '../../common/reportComponent/profitTableComponent1.vue'
	import ProfitTableComponent2 from '../../common/reportComponent/profitTableComponent2.vue'
	export default ({
		mixins: [common],
		components: {
			Draw3DPieCanvas,
			DrawRadarCanvas,
			DupontAnalyze,
			DrawBarFinancialAnalysis2v,
			LibTableComponent1,
			LibTableComponent2,
			ProfitTableComponent1,
			ProfitTableComponent2,
		},
		name: "diagnoseReport2v",
		data() {
			return {
				reportId: this.$route.query.reportId ? this.$route.query.reportId : "",
				pageInfo: {
					isTestAccount: null, //0:无水印可下载，1：有水印不可下载
					baseInfo: {
						companyName: "",
						establishDate: ""
					},
					year: []
				},
				minIndex: 5,
				financialIndex: 4,
				// 专业化语句
				industryStatements1: [],
				industryStatements2: [],
				industryStatements3: [],
				// 电池展示状态
				gradeStatus: 1,
				ebitMultipleGradeStatus: 1,
				receivablesTurnoverRatioGradeStatus: 1,
				inventoryTurnoverRatioGradeStatus: 1,
				//
				pdfArr: [],
				pdfIndex: 0,
				projectName: ''
			}
		},
		mounted() {
			this.projectName = this.domain.diagnoseProject
			if (this.reportId) {
				this.initPage();
			}
		},
		methods: {
			initPage: function() {
				var that = this;
				var queryObj = {
					reportId: that.reportId
				}
				that.$post(this.projectName + "valuationReport/qryDiagnosticValuationReport3", qs.stringify(queryObj)).then((
					res) => {
					var data = res.data;
					if (data.code == 200 && data.content) {
						that.pageInfo = data.content;
						console.log(that.pageInfo)
						// 指标得分
						that.pageInfo.question_ = JSON.parse(that.pageInfo.question);
						that.pageInfo.question = JSON.parse(that.pageInfo.question);
						that.pageInfo.financial = JSON.parse(that.pageInfo.financial);
						that.pageInfo.question_.push({
							name: "财务",
							value: that.pageInfo.financialValue
						});
						for (var i = 0; i < that.pageInfo.question_.length; i++) {
							that.pageInfo.question_[i].synthesisScore = require('../../assets/img/growthDiagnosis/radiaIcon' +
								(i + 1) + '.svg');
							that.pageInfo.question_[i].className = 'radiaIcon' + i;
						}
						// 获取最小值
						that.minIndex = that.getMinIndex(that.pageInfo.question_);
						// 行研
						that.pageInfo.industryDes = JSON.parse(that.pageInfo.matrix).industryDesc.split("|");
						// 股权
						var holderArr = [];
						for (var i in JSON.parse(that.pageInfo.baseInfo.holderRation)) {
							holderArr.push({
								name: i,
								y: JSON.parse(that.pageInfo.baseInfo.holderRation)[i],
							})
						}
						that.pageInfo.baseInfo.holderRation = holderArr;
						// 专业化语句
						that.pageInfo.industry = JSON.parse(that.pageInfo.industry);
						that.pageInfo.industry[0].width = '200px';
						that.pageInfo.industry[1].width = '224px';
						that.pageInfo.industry[2].width = '180px';
						that.pageInfo.industry[3].width = '154px';
						that.pageInfo.industry[4].width = '130px';
						that.pageInfo.industry[5].width = '152px';
						for (var i = 0; i < 2; i++) {
							that.industryStatements1.push(that.pageInfo.industry[i])
						}
						for (var i = 2; i < 4; i++) {
							that.industryStatements2.push(that.pageInfo.industry[i])
						}
						for (var i = 4; i < 6; i++) {
							that.industryStatements3.push(that.pageInfo.industry[i])
						}
						// 精细化语句
						that.pageInfo.competitivePower = JSON.parse(that.pageInfo.competitivePower);
						that.pageInfo.competitivePower[0].width = '224px';
						that.pageInfo.competitivePower[1].width = '272px';
						// 特色化语句
						that.pageInfo.teamManage = JSON.parse(that.pageInfo.teamManage);
						that.pageInfo.teamManage[0].width = '180px';
						that.pageInfo.teamManage[1].width = '224px';
						// 新颖化语句
						that.pageInfo.technology = JSON.parse(that.pageInfo.technology);
						// 杜邦分析
						that.pageInfo.dubangAnalysis = JSON.parse(that.pageInfo.dubangAnalysis);
						// 总资产收益率
						that.pageInfo.totalAssetsReturnOn = JSON.parse(that.pageInfo.totalAssetsReturnOn);
						that.pageInfo.totalAssetsReturnOn.title = "01、总资产收益率为" + (that.pageInfo.totalAssetsReturnOn.ratio *
							100).toFixed(2) + "%";
						// 销售费用率
						that.pageInfo.saleRatio = JSON.parse(that.pageInfo.saleRatio);
						that.pageInfo.saleRatio.title = "02、销售费用率为" + (that.pageInfo.saleRatio.ratio * 100).toFixed(2) + "%";
						// 管理费用率
						that.pageInfo.manageRatio = JSON.parse(that.pageInfo.manageRatio);
						that.pageInfo.manageRatio.title = "03、管理费用率为" + (that.pageInfo.manageRatio.ratio * 100).toFixed(2) +
							"%";
						// 研发费用率
						that.pageInfo.researchRatio = JSON.parse(that.pageInfo.researchRatio);
						that.pageInfo.researchRatio.title = "04、研发费用率为" + (that.pageInfo.researchRatio.ratio * 100).toFixed(
							2) + "%";
						// 毛利率
						that.pageInfo.grossProfitRatio = JSON.parse(that.pageInfo.grossProfitRatio);
						// that.pageInfo.grossProfitRatio.title = "04、毛利率为" + (that.pageInfo.grossProfitRatio.ratio * 100).toFixed(2) + "%";
						that.pageInfo.grossProfitRatio.title = "05、毛利率为" + (that.pageInfo.grossProfitRatio.ratio * 100)
							.toFixed(2) + "%";
						// 净利润率
						that.pageInfo.netProfiltRatio = JSON.parse(that.pageInfo.netProfiltRatio);
						// that.pageInfo.netProfiltRatio.title = "05、净利润率为" + (that.pageInfo.netProfiltRatio.ratio * 100).toFixed(2) + "%";
						that.pageInfo.netProfiltRatio.title = "06、净利润率为" + (that.pageInfo.netProfiltRatio.ratio * 100)
							.toFixed(2) + "%";
						// 主营业务收入增长率
						that.pageInfo.mainIncomeRatio = JSON.parse(that.pageInfo.mainIncomeRatio);
						that.pageInfo.mainIncomeRatio.title = "01、主营业务收入增长率为" + (that.pageInfo.mainIncomeRatio.ratio * 100)
							.toFixed(2) + "%";
						// 利润总额增长率
						that.pageInfo.totalProfitRatio = JSON.parse(that.pageInfo.totalProfitRatio);
						that.pageInfo.totalProfitRatio.title = "02、利润总额增长率为" + (that.pageInfo.totalProfitRatio.ratio * 100)
							.toFixed(2) + "%";
						// 净资产收益率
						that.pageInfo.netAssetRatio = JSON.parse(that.pageInfo.netAssetRatio);
						that.pageInfo.netAssetRatio.title = "03、净资产收益率为" + (that.pageInfo.netAssetRatio.ratio * 100).toFixed(
							2) + "%";
						// 资产负债率
						that.pageInfo.liabilitiesRatio = JSON.parse(that.pageInfo.liabilitiesRatio);
						that.pageInfo.liabilitiesRatio.title = "01、资产负债率为" + (that.pageInfo.liabilitiesRatio.ratio * 100)
							.toFixed(2) + "%";
						// 流动比率
						that.pageInfo.flowRation = JSON.parse(that.pageInfo.flowRation);
						that.pageInfo.flowRation.title = "02、流动比率为" + that.pageInfo.flowRation.ratio.toFixed(2);
						// 速动比率
						that.pageInfo.quickRatio = JSON.parse(that.pageInfo.quickRatio);
						that.pageInfo.quickRatio.title = "03、速动比率为" + that.pageInfo.quickRatio.ratio.toFixed(2);
						// EBIT利息保障倍数
						that.pageInfo.ebitMultiple = JSON.parse(that.pageInfo.ebitMultiple);
						var interestCost = JSON.parse(that.pageInfo.profitTableInfo).interestCost;
						if (interestCost[1] == "") {
							that.ebitMultipleGradeStatus = 0;
						} else {
							that.ebitMultipleGradeStatus = 1;
						}
						that.pageInfo.ebitMultiple.title = "04、EBIT利息保障倍数为" + that.pageInfo.ebitMultiple.ratio.toFixed(2);
						// 应收账款周转率
						that.pageInfo.receivablesTurnoverRatio = JSON.parse(that.pageInfo.receivablesTurnoverRatio);
						var receivables = JSON.parse(that.pageInfo.liaTableInfo).receivables;
						if (receivables[1] == "") {
							that.receivablesTurnoverRatioGradeStatus = 0;
						} else {
							that.receivablesTurnoverRatioGradeStatus = 1;
						}
						if (that.pageInfo.receivablesTurnoverRatio.batteryLevel == "0%") {
							that.pageInfo.receivablesTurnoverRatio.batteryLevel = "100%";
						}
						that.pageInfo.receivablesTurnoverRatio.title = "01、应收账款周转率为" + that.pageInfo.receivablesTurnoverRatio
							.ratio.toFixed(2);
						// 总资产周转率
						that.pageInfo.totalAssetsTurnoverRatio = JSON.parse(that.pageInfo.totalAssetsTurnoverRatio);
						that.pageInfo.totalAssetsTurnoverRatio.title = "02、总资产周转率为" + that.pageInfo.totalAssetsTurnoverRatio
							.ratio.toFixed(2);
						// 存货周转率
						that.pageInfo.inventoryTurnoverRatio = JSON.parse(that.pageInfo.inventoryTurnoverRatio);
						var inventory = JSON.parse(that.pageInfo.liaTableInfo).inventory
						if (inventory[1] == "") {
							that.inventoryTurnoverRatioGradeStatus = 0;
						} else {
							that.inventoryTurnoverRatioGradeStatus = 1;
						}
						if (that.pageInfo.inventoryTurnoverRatio.batteryLevel == "0%") {
							that.pageInfo.inventoryTurnoverRatio.batteryLevel = "100%";
						}
						that.pageInfo.inventoryTurnoverRatio.title = "03、存货周转率为" + that.pageInfo.inventoryTurnoverRatio.ratio
							.toFixed(2);
						// 财务状况
						that.pageInfo.financial = that.financialFormate(that.pageInfo.financial);
						// 资产负债表
						that.pageInfo.liaTableInfo = JSON.parse(that.pageInfo.liaTableInfo);
						// 利润表
						that.pageInfo.profitTableInfo = JSON.parse(that.pageInfo.profitTableInfo);
						// 财务比率表
						that.pageInfo.financeRatio = JSON.parse(that.pageInfo.financeRatio);
					}
					
					this.$nextTick(() => {
						this.addPageWatermark('#pageContent .content',this.pageInfo.isTestAccount)
					})
					
					
				})
			},
			// 获取最小值
			getMinIndex: function(arr) {
				var min = arr[0].value;
				//声明了个变量 保存下标值
				var index = 0;
				for (var i = 0; i < arr.length; i++) {
					if (min > arr[i].value) {
						min = arr[i].value;
						index = i;
					}
				}

				return index;
			},
			// 日期格式化
			dateFormate: function(date) {
				var dataStr = date.split("-");
				var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
				return newDataStr;
			},
			// 金额单位格式化
			valueFormate: function(val) {
				if (val) {
					if (val >= 10000) {
						val = (val / 10000).toFixed(2) + '亿元';
					} else {
						val = val.toFixed(2) + '万元';
					}
					return val
				} else {
					return '无数据'
				}
			},
			// 得分校对
			scoreFormate: function(score) {
				if (this.pageInfo.baseInfo.companyName) {
					for (var i = 0; i < this.pageInfo.question.length; i++) {
						if (this.pageInfo.question[i].name == score) {
							return this.pageInfo.question[i].value;
						}
					};
				}
			},
			// 财务状况格式化
			financialFormate: function(arr) { // name description value
				for (var i = 0; i < arr.length; i++) {
					if (arr[i].value == 100) {
						arr[i].marginLeft = '88px';
					} else if (arr[i].value > 80) {
						arr[i].style = "background:url(" + require('../../assets/img/growthDiagnosis/financialResult_1.png') +
							") center center no-repeat;background-size:100%;"
						arr[i].section = "良好";
					} else if (80 >= arr[i].value && arr[i].value > 60) {
						arr[i].style = "background:url(" + require('../../assets/img/growthDiagnosis/financialResult_2.png') +
							") center center no-repeat;background-size:100%;"
						arr[i].section = "较好";
					} else if (60 >= arr[i].value && arr[i].value > 40) {
						arr[i].style = "background:url(" + require('../../assets/img/growthDiagnosis/financialResult_3.png') +
							") center center no-repeat;background-size:100%;"
						arr[i].section = "一般";
					} else if (40 >= arr[i].value) {
						arr[i].style = "background:url(" + require('../../assets/img/growthDiagnosis/financialResult_4.png') +
							") center center no-repeat;background-size:100%;"
						arr[i].section = "较弱";
					}
				}
				return arr;
			},
			pdf: function() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + '价值诊断报告.pdf'
				var pageArr = document.getElementsByClassName("content");
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);
			}
		},
		computed: {
			initStatus: function() {
				if (this.pageInfo.baseInfo.companyName) {
					return true;
				} else {
					return false;
				}
			}
		}
	})
</script>
<style scoped lang="less">
	.tools {
		width: 1160px;
		height: 60px;
		margin: 0 auto;
		padding-top: 10px;
		box-sizing: border-box;
	}

	.tools button {
		float: right;
		width: 120px;
		padding: 8px;
		border: 1px #aaa solid;
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		transition: background-color 0.3s;
		background: #B1781E;
		color: #fff;
		margin-right: 20px;
		cursor: pointer;
	}

	.homePage,
	.cataloguePage,
	.synthesisScore,
	.synthesisScoreCont,
	.baseInfo,
	.flowAtlas,
	.industrySuggest,
	.industrySuggest1,
	.industrySuggest2,
	.industryTable,
	.industryPic,
	.teamScore,
	.skillScore,
	.productScore,
	.productSuggest,
	.productPic,
	.riskManagementScore,
	.accessory,
	.accessory_1,
	.accessory_2,
	.accessory_3,
	.disclaimer,
	.debtPayAnalyze,
	.debtPayAnalyze1,
	.profitAnalyze,
	.profitAnalyze1,
	.profitAnalyze2,
	.growthAnalyze,
	.growthAnalyze1,
	.operateAnalyze,
	.operateAnalyze1,
	.dupontAnalyze,
	.financialCondition,
	.computational,
	.accessory_4,
	.stockIntroduce {
		width: 100%;
		height: 1754px;
	}

	.content {
		width: 1240px;
		height: 100%;
		background: #fff;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		border-top: 1px solid #ccc;
	}


	/* 封面 */

	#homePage {
		/* background: url(https://flowmp4.oss-cn-beijing.aliyuncs.com/img/startup/diagnosticBasisBanner.jpg?versionId=CAEQIRiBgIDWuOSq3RciIDlkMWIwNWExNzdjMTRiMzk5YzE1NjQxNjE3NzllNjU3)no-repeat center center; */
		overflow: hidden;
	}
	
	.logoBox>img{
		width: 340px;
	}

	.homePage h1 {
		width: 370px;
		height: 96px;
		background: url(../../assets/img/logo-08.svg)no-repeat center center;
		background-size: 100%;
		margin: 105px 0 0 107px;
	}

	.homePage .content {
		border: none;
	}

	.homePage p {
		font-size: 51px;
		font-weight: 400;
		color: #0478fc;
		line-height: 100%;
		text-align: center;
		margin: 180px 0 42px 0;
	}

	.homePage>div>div.homePageLine {
		width: 914px;
		height: 2px;
		background: #0478fc;
		margin: 0 auto;
	}

	.homePage .pageIndex_bg {
		height: 659px;
		margin-top: 155px;
	}

	.homePage h2 {
		font-size: 114px;
		font-weight: bold;
		color: #0478fc;
		margin: 36px 0 44px 0;
		text-align: center;
		line-height: 100%;
		letter-spacing: 18px;
	}

	.homePage .reportId,
	.homePage h4 {
		font-size: 33px;
		font-weight: 400;
		line-height: 100%;
		color: #282828;
		text-align: center;
	}

	.homePage h4 {
		font-size: 30px;
		margin: 32px 0 0 0;
	}

	.homePage i {
		display: block;
		height: 21px;
		font-size: 21px;
		font-weight: 400;
		color: #282828;
		margin-left: -200px;
		line-height: 100%;
		position: absolute;
		bottom: 59px;
		left: 50%;
	}

	.homePage .artFont {
		/* width: 64px; */
		height: 45px;
		margin: 0;
		position: absolute;
		top: 655px;
		left: 282px;
	}


	/* 目录 */

	.cataloguePage h2 {
		font-size: 48px;
		font-weight: bold;
		color: #0F060B;
		margin: 96px 112px 27px 0px;
		text-align: right;
		line-height: 1;
	}

	.cataloguePage h3 {
		font-size: 28px;
		font-weight: bold;
		color: #0F060B;
		margin: 0 112px 45px 0;
		text-align: right;
		line-height: 1;
	}

	.cataloguePage .generalize>div {
		width: 1020px;
		height: 32px;
		margin: 30px auto 0px;
	}

	.cataloguePage .generalize>div p:first-child {
		font-size: 32px;
	}

	.cataloguePage .generalize p {
		float: left;
		font-size: 24px;
		font-weight: bold;
		color: #0F060B;
		line-height: 32px;
		margin-right: 11px;
	}

	.cataloguePage .generalize>div>div {
		width: 6px;
		height: 32px;
		background: #0478FC;
		float: left;
		margin: 0 28px 0 0;
	}

	.cataloguePage .titleLine {
		/* width: 1020px; */
		height: 2px;
		background: #282828;
		display: block;
		margin: 17px 110px 18px;
	}

	.cataloguePage .generalize .dottedLine {
		float: left;
		height: 2px;
		width: 736px;
		/* padding: 20px; */
		margin: 14px 20px 0;
		background: url(../../assets/img/report/catalogue_.png)left center repeat;
		/* border-top: 0.2px dotted #ccc; */
	}

	.cataloguePage .generalize .pageNumber {
		float: left;
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 32px;
	}

	.cataloguePage .generalize ul {
		margin: 0 0 0 145px;
	}

	.cataloguePage .generalize ul li {
		height: 30px;
		margin: 9px 0 13px 0;
		width: calc(1020px - 34px);
	}

	.cataloguePage .generalize ul li p {
		font-size: 18px;
		font-weight: 400;
		color: #423838;
		line-height: 30px;
	}

	.cataloguePage .generalize ul li .dottedLine {
		width: 840px;
	}

	.cataloguePage .generalize ul.flowChart li .dottedLine {
		width: 678px;
	}


	/* 第一页 综合得分 */

	.synthesisScore {
		margin-top: 0px;
	}

	.computational h2,
	.financialCondition h2,
	.baseInfo h2,
	.flowAtlas h2,
	.dupontAnalyze h2,
	.synthesisScore h2 {
		font-size: 40px;
		font-weight: bold;
		color: #010000;
		line-height: 100%;
		margin: 135px 0 33px 110px;
	}

	.synthesisScore h3 {
		font-size: 29px;
		font-weight: bold;
		color: #010000;
		line-height: 100%;
		margin: 0 0 0 110px;
	}

	.synthesisScore h4 {
		font-size: 29px;
		font-weight: bold;
		color: #010000;
		line-height: 100%;
		margin: 82px 0 0 111px;
	}

	.synthesisScore .reportDate {
		font-weight: 400;
		color: #333333;
		font-size: 16px;
		text-align: center;
		margin-top: 16px;
	}

	.synthesisScore>.content>p {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		padding: 41px 110px 46px;
	}

	.teamScore .suggestion,
	.skillScore .suggestion,
	.productScore .suggestion,
	.productSuggest .suggestion,
	.industrySuggest .suggestion,
	.industrySuggest1 .suggestion,
	.industrySuggest2 .suggestion,
	.riskManagementScore .suggestion,
	.synthesisScore .suggestion {
		width: 1016px;
		background: #DAEBFF;
		padding: 32px 0 35px;
		margin: 35px 0 0 110px;
		position: relative;
	}

	.teamScore .suggestion,
	.productScore .suggestion,
	.productSuggest .suggestion,
	.skillScore .suggestion,
	.riskManagementScore .suggestion,
	.industrySuggest .suggestion,
	.industrySuggest1 .suggestion,
	.industrySuggest2 .suggestion {
		margin: 25px 0 0 0;
	}

	.teamScore .suggestion>p,
	.skillScore .suggestion>p,
	.industrySuggest .suggestion>p,
	.industrySuggest1 .suggestion>p,
	.industrySuggest2 .suggestion>p,
	.riskManagementScore .suggestion>p,
	.productScore .suggestion>p,
	.productSuggest .suggestion>p,
	.synthesisScore .suggestion>p {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		padding: 0 30px 0 30px;
		text-indent: 36px;
		margin: 0;
	}

	.teamScore .suggestion img,
	.productScore .suggestion img,
	.productSuggest .suggestion img,
	.skillScore .suggestion img,
	.industrySuggest .suggestion img,
	.industrySuggest1 .suggestion img,
	.industrySuggest2 .suggestion img,
	.riskManagementScore .suggestion img,
	.synthesisScore .suggestion img {
		/* width: 50px; */
		height: 50px;
		position: absolute;
		left: 18px;
		top: 13px;
	}

	#traingle {
		position: absolute;
		right: 0;
		bottom: -41px;
		width: 0;
		height: 0;
		border-top: 41px solid #0478fc;
		border-right: 51px solid transparent;
	}

	.synthesisScore .synthesisScorePic {
		background: url(../../assets/img/specialization/synthesisScore_bg.jpg)no-repeat center center;
		width: 974px;
		height: 528px;
		background-size: cover;
		margin: 0px auto;
		overflow: hidden;
	}

	.synthesisScore .synthesisScorePic p {
		font-size: 70px;
		font-weight: bold;
		color: #fff;
		line-height: 100%;
		margin: 212px 0 0 398px;
	}

	.synthesisScore .scoreCont p {
		position: relative;
	}

	.synthesisScore .scoreTop .scoreLow {
		position: absolute;
		top: 5px;
		left: 230px;
		width: 22px;
		height: 19px;
	}


	/* 第二页 综合得分内容 */

	.synthesisScoreCont h3 {
		font-size: 30px;
		font-weight: bold;
		color: #010000;
		margin: 60px 0 30px 114px;
		line-height: 100%;
	}

	.earningsForecast ul {
		height: 240px;
		margin: 40px 0 0 0;
	}

	.synthesisScoreCont ul,
	.synthesisScore ul {
		height: 290px;
		padding: 30px 0 0 110px;
	}

	.earningsForecast .spendingForecast,
	.synthesisScore .spendingForecast,
	.synthesisScoreCont .spendingForecast {
		width: 70px;
		height: 70px;
		float: left;
		margin: 0 20px 0 0;
	}

	.earningsForecast .scoreCont,
	.synthesisScore .scoreCont,
	.synthesisScoreCont .scoreCont {
		float: left;
	}

	.earningsForecast ul li,
	.synthesisScore ul li,
	.synthesisScoreCont ul li {
		float: left;
		margin: 0 0px 30px 0;
		width: 374px;
	}

	.earningsForecast .scoreCont p,
	.synthesisScore .scoreCont p,
	.synthesisScoreCont .scoreCont p {
		/* width: 40px; */
		font-size: 18px;
		font-weight: bold;
		color: #060001;
		line-height: 28px;
	}

	.earningsForecast .scoreCont p {
		width: 72px;
	}

	.earningsForecast .incomeCont>p,
	.earningsForecast .expenditureCont>p {
		font-size: 16px;
		font-weight: 400;
		color: #111111;
		line-height: 100%;
		margin: 28px 0 0 0;
		text-indent: 75px;
	}

	.earningsForecast .scoreCont span,
	.synthesisScore .scoreCont span,
	.synthesisScoreCont .scoreCont span {
		font-size: 26px;
		font-weight: bold;
		color: #0487FC;
	}

	.earningsForecast .scoreTop,
	.synthesisScore .scoreTop,
	.synthesisScoreCont .scoreTop {
		height: 88px;
		position: relative;
	}

	.earningsForecast .scoreBottom,
	.synthesisScore .scoreBottom,
	.synthesisScoreCont .scoreBottom {
		width: 194px;
		height: 4px;
		background: #cbe1f4;
		position: relative;
	}

	.earningsForecast .indexActive,
	.synthesisScore .indexActive,
	.scoreBottom .indexActive {
		position: absolute;
		width: 100%;
		height: 4px;
		top: 0;
		left: 0;
		background: #0487Fc;
		z-index: 998;
	}

	.earningsForecast .indexIcon0,
	.earningsForecast .indexIcon1,
	.earningsForecast .indexIcon2,
	.earningsForecast .indexIcon3,
	.scoreBottom .indexIcon0,
	.scoreBottom .indexIcon1,
	.scoreBottom .indexIcon2,
	.scoreBottom .indexIcon3 {
		background: #fff;
		width: 6px;
		height: 4px;
		position: absolute;
		top: 0;
		z-index: 1000;
	}

	.synthesisScore .scoreTop>img {
		position: absolute;
		width: 31px;
		height: 29px;
		left: 19px;
		top: 21px;
	}

	.scoreTop img.radiaIcon0 {
		width: 31px;
		height: 29px;
		left: 19px;
		top: 21px;
	}

	.scoreTop img.radiaIcon1 {
		width: 41px;
		height: 29px;
		left: 16px;
	}

	.scoreTop img.radiaIcon2 {
		width: 40px;
		height: 35px;
		left: 15px;
		top: 18px;
	}

	.scoreTop img.radiaIcon3 {
		width: 41px;
		height: 39px;
		left: 16px;
		top: 16px;
	}

	.scoreTop img.radiaIcon4 {
		width: 33px;
		height: 36px;
		left: 19px;
		top: 18px;
	}

	.indexIcon0 {
		left: 34px;
	}

	.indexIcon1 {
		left: 74px;
	}

	.indexIcon2 {
		left: 114px;
	}

	.indexIcon3 {
		left: 154px;
	}

	.synthesisScoreCont>div>p {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		padding: 0 110px;
		text-indent: 36px;
	}

	.synthesisScoreCont>div>.industryDescLists>p {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		padding: 0 110px;
		text-indent: 36px;
	}

	.synthesisScoreCont img {
		/* width: 830px; */
		/* height: 477px; */
		margin: 30px 308px 40px;
	}

	.teamScore h3 span,
	.productScore h3 span,
	.skillScore h3 span,
	.riskManagementScore h3 span,
	.industryScore h3 span,
	.synthesisScoreCont p span {
		text-indent: 0px;
		line-height: 100%;
		margin-top: -4px;
	}


	/* 第三页 基本信息 */

	.baseInfo {
		margin-top: 0px;
	}

	.baseInfo .titleLine {
		width: 1020px;
		height: 3px;
		background: #282828;
		display: block;
		margin: 17px 0px 18px;
	}

	.baseInfo .titleLineShort {
		width: 460px;
		height: 3px;
		background: #282828;
		display: block;
		margin: 17px 0px 18px;
	}

	.flowAtlas .flowDiagnose,
	.flowAtlas .financeAnalyze {
		margin: 35px 110px 0;
	}

	.financialConditionTitle,
	.baseInfo .basicInfo {
		margin: 35px 0 0 110px;
	}

	.baseInfo .mainBusiness {
		margin: 80px 70px 0 110px;
	}

	.baseInfo .stockStructure {
		margin: 80px 110px 0 110px;
	}

	.skillScore img,
	.riskManagementScore img,
	.productScore img,
	.earningsForecast img,
	.debtPayAnalyze img,
	.debtPayAnalyze1 img,
	.profitAnalyze img,
	.profitAnalyze1 img,
	.profitAnalyze2 img,
	.growthAnalyze img,
	.growthAnalyze1 img,
	.operateAnalyze img,
	.operateAnalyze1 img,
	.dupontAnalyze img,
	.financialCondition img,
	.teamScore img,
	.flowAtlas img,
	.baseInfo img {
		float: left;
		margin: 0 26px 0 0;
	}

	.flowAtlas h3,
	.teamScore h3,
	.earningsForecast h3,
	.debtPayAnalyze h3,
	.debtPayAnalyze1 h3,
	.profitAnalyze h3,
	.profitAnalyze1 h3,
	.profitAnalyze2 h3,
	.growthAnalyze h3,
	.growthAnalyze1 h3,
	.operateAnalyze h3,
	.operateAnalyze1 h3,
	.dupontAnalyze h3,
	.financialCondition h3,
	.productScore h3,
	.skillScore h3,
	.riskManagementScore h3,
	.riskManagementScore h3 span,
	.skillScore h3 span,
	.industryScore h3 span,
	.teamScore h3 span,
	.productScore h3 span,
	.baseInfo h3 {
		font-size: 30px;
		font-weight: bold;
		color: #121212;
		line-height: 100%;
	}

	.industryScore h3,
	.skillScore h3,
	.financeAnalyze h3 {
		line-height: 50px;
	}

	.flowDiagnose h3,
	.earningsForecast h3,
	.debtPayAnalyze h3,
	.debtPayAnalyze1 h3,
	.profitAnalyze h3,
	.profitAnalyze1 h3,
	.profitAnalyze2 h3,
	.growthAnalyze h3,
	.growthAnalyze1 h3,
	.operateAnalyze h3,
	.operateAnalyze1 h3,
	.dupontAnalyze h3,
	.financialCondition h3 {
		line-height: 48px;
	}

	.productScore h3 {
		line-height: 44px;
	}

	.riskManagementScore h3 {
		line-height: 53px;
	}

	.teamScore h3 {
		margin-bottom: 20px;
		line-height: 43px;
	}

	.financingSituation h3 {
		line-height: 46px;
		margin: 0 0 20px 0;
	}

	.baseInfo .basicInfoCont {
		height: 180px;
		margin: 20px 110px 0 0;
	}

	.financingSituation li,
	.productAndGroup li,
	.baseInfo .basicInfoCont li {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
	}

	.baseInfo .basicInfoCont li {
		float: left;
		width: 460px;
	}

	.baseInfo .basicInfoCont li:nth-child(odd) {
		margin-right: 100px;
	}

	.baseInfo .basicInfoCont li span:nth-child(1) {
		vertical-align: top;
	}

	.baseInfo .basicInfoCont li span.industry {
		width: 364px;
	}

	.mainBusiness p {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		/* padding: 0 110px 0 73px; */
		margin: 20px 0 0 0;
	}

	.productAndGroup {
		height: 200px;
		padding: 0 110px;
		margin: 50px 0 0 0;
	}

	.product,
	.group {
		float: left;
		width: 460px;
	}

	.product {
		margin-right: 100px;
	}

	.financingSituation {
		margin: 20px 0 0 110px;
	}

	.debtPayTitle,
	.profitTitle,
	.growthTitle,
	.operateTitle,
	.riskManagementScoreDesc,
	.industryScore,
	.teamScoreDesc,
	.skillScoreDesc,
	.productScoreDesc,
	.expenditureForecast {
		margin: 116px 0 0 110px;
	}

	#riskManagementScorePic,
	#skillScorePic,
	#teamScorePic {
		width: 900px;
		height: 400px;
		margin: 20px auto;
	}

	#stockStructurePie {
		width: 100%;
		height: 700px;
		margin: 20px auto;
	}

	#flowDiagnose {
		width: 800px;
		height: 400px;
		margin: 20px auto;
	}

	#expenditurePic,
	#incomePic {
		width: 1000px;
		height: 500px;
		margin: 20px;
	}

	#productPicDetail,
	#industryPicDetail {
		width: 800px;
		height: 600px;
		/* margin: 110px auto; */
		/* z-index: 800; */
	}

	.synthesisScoreCont .icon1,
	.baseInfo .icon1 {
		width: 48px;
		height: 50px;
	}

	.baseInfo .icon2 {
		width: 54px;
		height: 48px;
	}

	.baseInfo .icon3 {
		width: 48px;
		height: 51px;
	}

	.teamScore .icon,
	.baseInfo .icon4 {
		width: 49px;
		height: 43px;
	}

	.synthesisScoreCont .icon5,
	.baseInfo .icon5 {
		width: 46px;
		height: 46px;
	}

	.skillScore .icon,
	.synthesisScoreCont .icon6,
	.baseInfo .icon6 {
		width: 50px;
		height: 50px;
	}

	.industrySuggest .icon2,
	.industrySuggest1 .icon2,
	.industrySuggest2 .icon2 {
		width: 50px;
		height: 47px;
		float: left;
		margin-right: 25px;
	}

	.flowAtlas .icon1 {
		width: 50ox;
		height: 48px;
	}

	.earningsForecast .icon,
	.debtPayTitle .icon,
	.profitTitle .icon,
	.growthTitle .icon,
	.operateTitle .icon,
	.flowAtlas .icon2 {
		width: 49px;
		height: 49px;
		margin: 0 26px 0 0;
	}

	.productScore .icon {
		width: 49px;
		height: 49px;
	}

	.riskManagementScore .icon {
		width: 46px;
		height: 53px;
	}

	#flowDiagnose_BaseInfo,
	#financeAnalyze_BaseInfo {
		width: 1020px;
		height: 600px;
		margin: 20px auto;
	}


	/* 附件 */

	.computational div.content>span,
	.accessory div.content>span {
		position: absolute;
		left: 110px;
		line-height: 100%;
		top: 130px;
		font-size: 20px;
	}

	.accessory_1 .title,
	.accessory_2 .title,
	.accessory_3 .title,
	.accessory_4 .title,
	.computational .title,
	.accessory .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	.accessory_4 .title {
		margin-top: 50px;
	}

	.accessory_1 .titleBox,
	.accessory_2 .titleBox,
	.accessory_3 .titleBox,
	.accessory_4 .titleBox {
		margin: 130px 0 0 0;
		padding: 0px 110px;
		display: flex;
		justify-content: space-between;
	}

	.accessory_1 .titleBox span,
	.accessory_2 .titleBox span,
	.accessory_3 .titleBox span,
	.accessory_4 .titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.accessory_1 .titleBox .title,
	.accessory_2 .titleBox .title,
	.accessory_3 .titleBox .title,
	.accessory_4 .titleBox .title {
		margin: 0;
	}

	.accessory_1 table,
	.accessory_2 table,
	.accessory_3 table,
	.accessory_4 table,
	.accessory table {
		padding: 0 110px;
		width: calc(100% - 220px);
		text-align: center;
		border-collapse: collapse;
		margin: 47px auto 0;
		border: 1px solid #000;
	}

	.accessory_1 table tr td,
	.accessory_2 table tr td,
	.accessory_3 table tr td,
	.accessory_4 table tr td {
		border: 1px solid #333;
	}

	.accessory_1 table>tbody>tr:first-of-type>td,
	.accessory_2 table>tbody>tr:first-of-type>td,
	.accessory_3 table>tbody>tr:first-of-type>td,
	.accessory_4 table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	.accessory table td,
	.accessory table th {
		/* width: 50%; */
		height: 54px;
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 54px;
		border: 1px solid #282828;
	}

	.accessory table th {
		background: #0487FC;
		color: #fff;
		border-right: 1px solid #282828;
		border-bottom: 1px solid #0487fc;
	}

	.accessory table.expenditureTable tbody tr:last-child>td {
		font-weight: 800;
	}

	.accessory table.expenditureTable td,
	.accessory table.expenditureTable th {
		width: 50%;
	}

	.accessory table.incomeTable td,
	.accessory table.incomeTable th {
		width: 33%;
	}

	table>tbody>tr.trTwo>td:first-of-type,
	table>tbody>tr.trThree>td:first-of-type {
		font-size: 12px;
	}

	table.profitTableInfo>tbody>tr.trThree>td:first-of-type {
		font-size: 14px;
	}

	table>tbody>tr.trOne {
		font-size: 14px;
	}

	.accessory_1 table,
	.accessory_2 table,
	.accessory_3 table,
	.accessory_3 table {
		margin: 15px auto 0;
	}

	table.liaTableInfo tr {
		height: 34px;
	}

	.accessory_3 .title {
		margin: 50px 0 0 0;
	}

	.accessory_1 table>thead>tr>th,
	.accessory_2 table>thead>tr>th,
	.accessory_3 table>thead>tr>th,
	.accessory_4 table>thead>tr>th {
		font-size: 18px;
		color: #fff;
		line-height: 44px;
		border: 1px solid #000;
		border-bottom-color: #0478fc;
	}

	table.profitTableInfo tr {
		height: 38px;
	}

	table>tbody>tr.trThree>td:first-of-type {
		padding-left: 70px;
	}

	.baseInfo .icon2 {
		width: 50px;
		height: 48px;
	}

	.accessory_1 table>thead>tr,
	.accessory_2 table>thead>tr,
	.accessory_3 table>thead>tr,
	.accessory_4 table>thead>tr {
		background: #0487Fc;
		border: 1px solid #000;
	}

	.accessory_1 table>thead>tr>th,
	.accessory_2 table>thead>tr>th,
	.accessory_3 table>thead>tr>th,
	.accessory_4 table>thead>tr>th {
		border: 1px solid #000;
		border-bottom-color: #0487fc;
	}

	.accessory_1 table>tbody>tr>td:first-of-type,
	.accessory_2 table>tbody>tr>td:first-of-type,
	.accessory_3 table>tbody>tr>td:first-of-type,
	.accessory_4 table>tbody>tr>td:first-of-type {
		padding-left: 30px;
		text-align: left;
	}

	table.financeRatio tr {
		height: 43px;
	}


	/*  */

	.productSuggest .picIntroduce,
	.industrySuggest .picIntroduce,
	.industrySuggest1 .picIntroduce,
	.industrySuggest2 .picIntroduce {
		font-size: 18px;
		font-weight: 400;
		color: #121212;
		line-height: 34px;
		margin: 0 0 0 148px;
	}

	.productSuggest h3,
	.industrySuggest h3,
	.industrySuggest1 h3,
	.industrySuggest2 h3 {
		font-size: 30px;
		font-weight: bold;
		color: #121212;
		line-height: 50px;
		margin: 0 0 0 0px;
	}

	.productSuggest>div.content>p,
	.industrySuggest>div.content>p,
	.industrySuggest1>div.content>p,
	.industrySuggest2>div.content>p {
		font-size: 18px;
		font-weight: 400;
		color: #121212;
		line-height: 34px;
		padding: 27px 110px 0px 111px;
		text-indent: 36px;
	}

	.page-content {
		padding: 116px 110px 0 110px;
	}

	.productSuggest>div.content>p>span,
	.industrySuggest>div.content>p>span,
	.industrySuggest1>div.content>p>span,
	.industrySuggest2>div.content>p>span {
		line-height: 100%;
		margin-top: -4px;
		text-indent: 0px;
	}


	/* 计算公式 */

	.formula-container {
		padding: 0px 110px 0;
	}

	.formula-container>h3 {
		font-size: 22px;
		line-height: 1;
		color: #282828;
		margin-bottom: 30px;
		margin-top: 32px;
	}

	.formula-container>ul>li {
		overflow: hidden;
		margin-bottom: 6px;
	}

	.formula-container>ul>li>.formula-tit {
		width: 984px;
		float: left;
		margin-bottom: 4px;
	}

	.formula-container>ul>li>.formula-tit>p {
		line-height: 34px;
		color: #282828;
		font-size: 18px;
	}

	.formula-container>ul>li>.formula-tit>h4 {
		line-height: 34px;
		color: #282828;
		font-size: 20px;
	}

	.formula-container>ul>li>.formula-tit>h4>span {
		display: inline-block;
	}

	.formula-container>ul>li>p {
		font-size: 18px;
		line-height: 34px;
		color: #282828;
	}

	.computational .formula-container ul li p.dian {
		font-size: 23px;
		width: 12px;
		height: 16px;
		float: left;
		color: #0487Fc;
		margin: 5px 13px 0 0px;
		line-height: 100%;
	}


	/* 免责声明 */

	.page-last>img {
		width: 900px;
		height: 376px;
		margin: 0px auto 50px;
		display: block;
	}

	.flow-statement {
		width: 1008px;
		margin: 0 auto;
	}

	.flow-statement>img {
		display: block;
		width: 286px;
		margin: 0 auto 47px;
	}

	.flow-statement>ul>li>div.statement-tit {
		height: 20px;
		width: 428px;
		margin: 54px auto 35px;
		font-size: 0;
		position: relative;
	}

	.statement-tit>span.xian {
		/*width: 100%;*/
		width: 135px;
		height: 1px;
		display: block;
		/*float: left;*/
		position: absolute;
		top: 0;
		margin-top: 9.5px;
		background: #4393FF;
	}

	.statement-tit>span.xian:first-of-type {
		left: 2px;
	}

	.statement-tit>span.xian:last-of-type {
		right: 0;
	}

	.statement-tit>img {
		width: 12px;
		height: 12px;
		float: left;
		margin-top: 3px;
	}

	.statement-tit>img:first-of-type {
		margin-left: 135px;
	}

	.statement-tit>p {
		width: 136px;
		height: 20px;
		line-height: 20px;
		font-size: 20px;
		color: #282828;
		float: left;
		background: #FFF;
		text-align: center;
	}

	.statement-tit>p.dian {
		font-size: 23px;
		width: 12px;
		height: 16px;
		float: left;
		color: #0487Fc;
		margin: -2px 0 0 135px;
		line-height: 100%;
	}

	.statement-tit>p.dian_ {
		margin: -2px 0 0 0;
	}

	.statement-main {
		width: 977px;
		padding: 17px 20px 16px 20px;
		background: #E8F4FF;
		font-size: 16px;
		color: #282828;
		line-height: 30px;
		text-indent: 2em;
		text-align: justify;
	}

	.statement-main div {
		margin: 30px 0 0 118px;
		overflow: hidden;
	}

	.statement-main div img {
		float: left;
		width: 138px;
		height: 138px;
		margin: 0 36px 20px 0;
	}

	.statement-main div ul {
		margin: 4px 0 0 0;
	}

	.statement-main div ul li {
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 30px;
	}

	.statement-main div ul li span {
		width: 72px;
		text-indent: 0;
		text-align-last: justify;
		text-align: justify;
		text-justify: distribute-all-lines;
	}

	/* 五项得分建议 */

	.riskCont p,
	.skillCont p,
	.teamCont p {
		padding: 0 119px 5px 0;
		font-size: 18px;
		font-weight: 400;
		color: #121212;
		line-height: 34px;
		text-indent: 36px;
	}

	.productCont p,
	.product_Cont p {
		padding: 0 119px 0px 0;
		font-size: 16px;
		font-weight: 400;
		color: #121212;
		line-height: 32px;
		text-indent: 32px;
	}

	.riskCont,
	.product_Cont,
	.skillCont,
	.teamCont {
		margin: 0 0 0 110px;
	}

	.homePage h1 {
		float: left;
		margin: 0;
	}

	.homePage .logoBox {
		height: 96px;
		margin: 105px 0 0 107px;
		position: relative;
	}

	.homePage .logoBox .stock-logo {
		float: left;
		position: relative;
		padding-left: 48px;
	}

	.homePage .logoBox .stock-logo:before {
		content: '';
		width: 2px;
		height: 80px;
		position: absolute;
		/* background: #7F7F7F; */
		background: #feffff;
		margin: 0 23px;
		left: 0;
	}

	.stock-logo img {
		max-height: 72px;
	}

	p.secondaryaCategory {
		font-size: 20px !important;
		font-weight: bold !important;
		line-height: 2 !important;
	}

	.icon7 {
		width: 50px;
		height: 44px;
	}


	/* 软性指标 行业 */

	.teamScore .targetItem,
	.productScore .targetItem,
	.skillScore .targetItem,
	.riskManagementScore .targetItem,
	.industrySuggest .targetItem,
	.industrySuggest1 .targetItem,
	.industrySuggest2 .targetItem {
		margin: 20px 0 66px;
	}


	/* .productScore .targetItem {
        margin: 10px 0 28px 0;
    } */

	.industrySuggest .targetItem .industryCont .iconBg,
	.industrySuggest1 .targetItem .industryCont .iconBg,
	.industrySuggest2 .targetItem .industryCont .iconBg,
	.skillScore .targetItem .skillScoreCont .iconBg,
	.productScore .targetItem .productScoreCont .iconBg,
	.riskManagementScore .targetItem .riskManagementScoreCont .iconBg,
	.teamScore .targetItem .teamScoreCont .iconBg {
		width: 323px;
		height: 323px;
		position: relative;
		/* float: left; */
		display: inline-block;
		margin: 0 84px 0 0;
	}

	.industrySuggest .targetItem .industryCont .industryIconBg,
	.industrySuggest1 .targetItem .industryCont .industryIconBg,
	.industrySuggest2 .targetItem .industryCont .industryIconBg {
		background: url(../../assets/img/growthDiagnosis/industryIconBg.png)no-repeat center center;
		background-size: 100%;
	}

	.teamScore .targetItem .teamScoreCont .teamScoreIconBg {
		background: url(../../assets/img/growthDiagnosis/teamScoreIconBg.png)no-repeat center center;
		background-size: 100%;
	}

	.skillScore .targetItem .skillScoreCont .skillScoreIconBg {
		background: url(../../assets/img/growthDiagnosis/skillIconBg.png)no-repeat center center;
		background-size: 100%;
	}

	.productScore .targetItem .productScoreCont .productScoreIconBg {
		background: url(../../assets/img/growthDiagnosis/productScoreIconBg.svg)no-repeat center center;
		background-size: 100%;
	}

	.riskManagementScore .targetItem .riskManagementScoreCont .riskManagementScoreIconBg {
		background: url(../../assets/img/growthDiagnosis/riskManagementScoreIconBg.png)no-repeat center center;
		background-size: 100%;
	}

	.teamScore .teamScoreDes,
	.productScore .productScoreDes,
	.skillScore .skillScoreDes,
	.riskManagementScore .riskManagementScoreDes,
	.industrySuggest .industryDes,
	.industrySuggest1 .industryDes,
	.industrySuggest2 .industryDes {
		float: right;
		margin-right: 105px;
		margin-top: 28px;
	}

	.teamScore h5,
	.productScore h5,
	.riskManagementScore h5,
	.skillScore h5,
	.industrySuggest h5,
	.industrySuggest1 h5,
	.industrySuggest2 h5 {
		font-size: 24px;
		font-weight: bold;
		color: #121212;
		line-height: 100%;
	}

	.teamScore .teamScoreDes p,
	.skillScore .skillScoreDes p,
	.productScore .productScoreDes p,
	.riskManagementScore .riskManagementScoreDes p,
	.industrySuggest .industryDes p,
	.industrySuggest1 .industryDes p,
	.industrySuggest2 .industryDes p {
		width: 618px;
		font-size: 18px;
		font-weight: 400;
		color: #121212;
		line-height: 34px;
		text-indent: 36px;
	}

	.teamScore .teamScoreIconBg p,
	.skillScore .skillScoreIconBg p,
	.productScore .productScoreIconBg p,
	.riskManagementScore .riskManagementScoreIconBg p,
	.industrySuggest .industryIconBg p,
	.industrySuggest1 .industryIconBg p,
	.industrySuggest2 .industryIconBg p {
		font-size: 29px;
		font-weight: bold;
		color: #fff;
		line-height: 100%;
		position: absolute;
		top: 146px;
		left: 121px;
	}

	.productScore .productScoreIconBg p {
		font-size: 33px;
	}

	.teamScore .teamScoreSuggestion,
	.skillScore .skillScoreSuggestion,
	.riskManagementScore .riskManagementScoreSuggestion,
	.productScore .productScoreSuggestion,
	.industrySuggest .industrySuggestion,
	.industrySuggest1 .industrySuggestion,
	.industrySuggest2 .industrySuggestion {
		/* margin: 20px 0 0 0; */
	}

	.teamScore .teamScoreSuggestion>div:nth-child(1),
	.skillScore .skillScoreSuggestion>div:nth-child(1),
	.productScore .productScoreSuggestion>div:nth-child(1),
	.riskManagementScore .riskManagementScoreSuggestion>div:nth-child(1),
	.industrySuggest .industrySuggestion>div:nth-child(1),
	.industrySuggest1 .industrySuggestion>div:nth-child(1),
	.industrySuggest2 .industrySuggestion>div:nth-child(1) {
		height: 58px;
	}

	.teamScore .teamScoreSuggestion>div>span,
	.skillScore .skillScoreSuggestion>div>span,
	.productScore .productScoreSuggestion>div>span,
	.productSuggest .productScoreSuggestion>div>span,
	.riskManagementScore .riskManagementScoreSuggestion>div>span,
	.industrySuggest .industrySuggestion>div>span,
	.industrySuggest1 .industrySuggestion>div>span,
	.industrySuggest2 .industrySuggestion>div>span {
		font-size: 24px;
		font-weight: bold;
		color: #0478FC;
		margin-top: 27px;
	}


	/* .productSuggest .productScoreSuggestion>div>span {
        margin: -10px 0 0 0;
    } */

	.desLine {
		width: 154px;
		height: 4px;
		background: #0478FC;
		margin: 14px 0 35px 0;
	}

	.suggestIcon {
		width: 58px;
		height: 58px;
		margin-right: 0 !important;
		float: left;
	}


	/* 行业/产品图表 */

	.productSuggest h3,
	.industryTable h3 {
		font-size: 26px;
		font-weight: bold;
		color: #121212;
		padding: 0 110px;
		margin: 116px 0 43px 0;
		text-align: center;
		line-height: 100%;
	}

	.productSuggest h3 {
		margin: 56px 0 0px 0;
	}

	.productSuggest p,
	.industryTable p {
		padding: 0 135px;
		margin: 0 0 46px 0;
		font-size: 18px;
		font-weight: 400;
		color: #121212;
		line-height: 100%;
	}

	.productSuggest p {
		margin: 0 0 22px 0;
	}

	.productSuggest table,
	.industryTable table {
		width: 100%;
		padding: 0 135px;
		border-spacing: 0px;
		border: none;
	}

	.productSuggest table thead tr,
	.industryTable table thead tr {
		background: #0478fc;
		height: 50px;
		font-size: 20px;
		font-weight: bold;
		color: #FFFFFF;
		border: none;
	}

	.productSuggest table tbody tr td,
	.productSuggest table thead tr th,
	.industryTable table tbody tr td,
	.industryTable table thead tr th {
		width: 21%;
		line-height: 50px;
		text-align: center;
		font-size: 18px;
	}

	.productSuggest table tbody tr td:first-of-type,
	.productSuggest table thead tr th:first-of-type,
	.industryTable table tbody tr td:first-of-type,
	.industryTable table thead tr th:first-of-type {
		width: 16%;
	}

	.productSuggest table tbody tr,
	.industryTable table tbody tr {
		height: 50px;
	}

	.productSuggest table tbody tr:nth-child(even),
	.industryTable table tbody tr:nth-child(even) {
		background: #e1f3ff;
	}

	.productSuggest table tbody tr:nth-child(1) td:last-of-type,
	.productSuggest table tbody tr:nth-child(2) td:last-of-type,
	.productSuggest table tbody tr:nth-child(3) td:last-of-type,
	.productSuggest table tbody tr:nth-child(4) td:last-of-type,
	.industryTable table tbody tr:nth-child(1) td:last-of-type,
	.industryTable table tbody tr:nth-child(2) td:last-of-type,
	.industryTable table tbody tr:nth-child(3) td:last-of-type,
	.industryTable table tbody tr:nth-child(4) td:last-of-type {
		color: #FD3048;
	}

	.productSuggest table tbody tr td:last-of-type,
	.industryTable table tbody tr td:last-of-type {
		font-size: 18px;
		font-weight: bold;
		color: #FF961B;
		line-height: 50px;
	}

	#productCharts,
	#industryCharts {
		/* padding: 135px; */
		margin: 40px 135px 0;
		width: 970px;
		height: 400px;
		/* background: #dff3ff; */
	}


	/* 财务指标 */
	.financialConditionTitle .icon {
		width: 49px;
		height: 49px;
	}


	/*  */

	.table_explain {
		margin: 20px 110px 60px;
		/* width: 100%; */
		position: relative;
		overflow: hidden;
	}

	.table_explain h4 {
		font-size: 20px;
		font-weight: bold;
		color: #282828;
		margin: 16px 0 30px 0;
	}

	.grade {
		position: absolute;
		top: 0;
		right: 0;
		width: 132px;
	}

	.grade img.battery {
		margin-right: 6px;
		height: 34px;
		width: 70px;
	}

	.grade p {
		font-size: 20px;
		font-weight: bold;
		color: #282828;
		line-height: 34px;
	}

	#totalAssetsReturnOn,
	#saleRatio,
	#manageRatio,
	#researchRatio,
	#grossProfitRatio,
	#netProfiltRatio,
	#mainIncomeRatio,
	#totalProfitRatio,
	#netAssetRatio,
	#flowRation,
	#quickRatio,
	#ebitMultiple,
	#receivablesTurnoverRatio,
	#inventoryTurnoverRatio,
	#totalAssetsTurnoverRatio,
	#liabilitiesRatio {
		height: 340px;
		width: 820px;
		padding: 0 100px;
	}

	.table_explain .explainText p {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		text-indent: 36px;
		text-align: justify;
	}


	/* 财务结果 */

	.financialCondition ul.imgUl {
		margin: 40px 110px 0 110px;
	}

	.financialCondition ul.imgUl li {
		width: 450px;
		float: left;
		/* background: #f00; */
		position: relative;
	}

	.financialCondition ul.imgUl li:nth-child(odd) {
		margin: 0 120px 80px 0;
	}

	.financialCondition ul.imgUl li .liDivImg {
		width: 250px;
		height: 250px;
		margin: 0 100px 36px;
		background: url(../../assets/img/growthDiagnosis/financialResult_1.png)no-repeat center center;
		background-size: 100%;
		overflow: hidden;
	}

	.financialCondition ul.imgUl li h5 {
		font-size: 24px;
		font-weight: bold;
		color: #282828;
		line-height: 100%;
		margin: 0 0 22px 0;
		text-align: center;
	}

	.financialCondition ul.imgUl li>p {
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 34px;
	}

	.financialCondition .scoreLow {
		position: absolute;
		top: 290px;
		left: 282px;
		width: 22px;
		height: 19px;
	}

	.financialCondition ul.imgUl li div>p {
		font-size: 22px;
		font-weight: bold;
		color: #282828;
		margin: 110px 0 0 94px;
		line-height: 100%;
	}

	.financialCondition ul.imgUl li div>h6 {
		font-size: 16px;
		font-weight: 400;
		color: #282828;
		line-height: 100%;
		margin: 10px 0 0 108px;
	}

	.fontBold {
		font-weight: bold !important;
		float: right !important;
	}

	.pageNum {
		width: 57px;
		position: absolute;
		color: #000;
		line-height: 100%;
		font-size: 22px;
		left: 50%;
		margin-left: -27px;
		bottom: 37px;
	}

	/deep/ .circleComponent {
		width: 70px;
		height: 70px;
		float: left;
		margin: 0 20px 0 0;
	}
</style>