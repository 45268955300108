<template>
	<div id="app">
		<Header />
		<router-view />

		<div class="main information">
			<p class="title">
				<img src="../assets/img/guzhi/myValuationSele.png" alt="我的报告图标-心流">
				<span>我的报告</span>
			</p>
			<div class="content">
				<div class="headCategory">
					<div class="reportCatalog">
						<span class="select">专精特新</span>
					</div>
					<div class="statusBar">
						<div :class="['payStatus',state_payment?'flexible':'']" @click="state_payment=!state_payment">
							<p>{{pay_status[state_payment_index].state}}<i> > </i></p>
							<div v-if="state_payment">
								<!-- v-on:click.stop阻止事件冒泡  避免触发父级的点击事件 -->
								<span :class="{select:index==state_payment_index}" v-for="(item, index) in pay_status"
									 v-on:click.stop="payment(index)">{{item.state}}</span>
							</div>
						</div>
						<div class="search">
							<input type="text" placeholder="报告简称/全称" v-model="companyName" @focus="foucs" @blur="blur">
							<img :src="searchIcon" alt="搜索-心流" @click="search()">
						</div>
					</div>
				</div>
				<ul class="table_title">
					<li style="width:18%;">企业名称</li>
					<li style="width:20%;">所属行业</li>
					<li style="width:14%;">估值时间</li>
					<li style="width:5%;">金额</li>
					<li style="width:13%;">类型</li>
					<li style="width:10%;">状态</li>
					<li style="width:20%;">操作</li>
				</ul>

				<div class="none-show" v-if="noData">
					<img class="no-data-default" src="../assets/img/guzhi/value_report_lists_no.png" />
					<p>暂无您的估值记录</p>
				</div>

				<ul class="table_item" v-if="!noData">
					<li v-for="(item, index) in valuationList" :data-reportid="item.id" :data-type="item.type"
						:data-stage="item.stage" :data-versions="item.versions">
						<div class="company-name" title="">{{item.name?item.name:''}}</div>
						<div class="industryContent">{{item.industryName?item.industryName:''}}</div>
						<div class="valution-time" style="width:14%">{{item.createTime?item.createTime:''}}</div>
						<div class="valution-money" style="width:5%">{{item.payAmount?'￥'+item.payAmount:''}}</div>
						<div class="valuation-type" style="width:13%">{{item.type==2?'价值量化':item.type==7?'价值诊断':'价值追踪'}}
						</div>
						<div class="audit" style="width:10%">
							<span class="examineState" v-if="item.payStatus=='0'">待付款</span>
							<span class="examineState examineing"
								v-else-if="item.payStatus=='1'&&item.auditStatus=='2'">审核中</span>
							<span class="examineState isExamine"
								v-else-if="item.payStatus=='1'&&item.auditStatus=='1'">审核通过</span>
							<span class="examineState noExamine"
								v-else-if="item.payStatus=='1'&&item.auditStatus=='0'">审核未通过</span>
						</div>
						<div class="operationIcon" v-if="item.payStatus=='0'|| !item.payStatus">
							<a class="edit"
								@click.stop="modification(item.id,item.type,item.stage,item.versions,item.auditStatus)"
								v-preventReClick="5000">修改</a>
							<a class="del" @click="del(item.id,index)">删除</a>
						</div>
						<div class="operationIcon" v-else-if="item.payStatus=='1'&&item.auditStatus=='2'">
							<a
								@click.stop="getInfo(item.id,item.type,item.stage,item.auditStatus,item.versions)">查看信息</a>
						</div>
						<div class="operationIcon" v-else-if="item.payStatus=='1'&&item.auditStatus=='1'">
							<a
								@click.stop="getInfo(item.id,item.type,item.stage,item.auditStatus,item.versions)">查看信息</a>
							<a class="checkReport" target="_blank"
								@click="checkSummaryPage(item.id,item.type,item.versions)">查看报告</a>
						</div>
						<div class="operationIcon" v-else-if="item.payStatus=='1'&&item.auditStatus=='0'">
							<a class="edit"
								@click.stop="modification(item.id,item.type,item.stage,item.versions,item.auditStatus)"
								v-preventReClick="5000">修改</a>
							<a
								@click.stop="getInfo(item.id,item.type,item.stage,item.auditStatus,item.versions)">查看信息</a>
						</div>
					</li>
				</ul>
				<!--分页-->
				<div class="pageBox" v-if="!noData">
					<Page :current="current" :total="total" @on-change="handleChange"></Page>
				</div>
			</div>
		</div>

		<div class="mobile">
			<div class="statusBar">
				<div :class="['payStatus',state_payment?'flexible':'']" @click="state_payment=!state_payment">
					<p>{{pay_status[state_payment_index].state}}<i> > </i></p>
					<div v-if="state_payment">
						<!-- v-on:click.stop阻止事件冒泡  避免触发父级的点击事件 -->
						<span v-for="(item, index) in pay_status"
							 v-on:click.stop="payment(index)">{{item.state}}</span>
					</div>
				</div>
				<div class="search">
					<input type="text" placeholder="报告简称/全称" v-model="companyName" @focus="foucs" @blur="blur">
					<img :src="searchIcon" alt="搜索-心流" @click="search()">
				</div>
			</div>
			<ul>
				<li v-for="(item, index) in valuationList" :data-reportid="item.id" :data-type="item.type"
					:data-stage="item.stage" :data-versions="item.versions">
					<p>
						<span>{{item.createTime?item.createTime:''}}</span>
						<span class="condition" v-if="item.payStatus=='0'">待付款</span>
						<span class="condition examineing"
							v-else-if="item.payStatus=='1'&&item.auditStatus=='2'">审核中</span>
						<span class="condition isExamine"
							v-else-if="item.payStatus=='1'&&item.auditStatus=='1'">审核通过</span>
						<span class="condition noExamine"
							v-else-if="item.payStatus=='1'&&item.auditStatus=='0'">审核未通过</span>
					</p>
					<P>{{item.name?item.name:''}}</P>
					<P v-if="item.industryName">
						<img src="../assets/img/mobileClient/leibie.svg" alt="">
						<span>{{item.industryName?item.industryName:''}}</span>
					</P>
					<P>
						<img src="../assets/img/mobileClient/xingye.svg" alt="">
						<span>{{item.type==2?'价值量化':item.type==7?'价值诊断':'价值追踪'}}</span>
						<span>{{item.payAmount?'￥'+item.payAmount:''}}</span>
					</P>
					<p v-if="item.payStatus=='0'|| !item.payStatus">
						<a class="del" @click="del(item.id,index)">删除</a>
						<a class="edit"
							@click.stop="modification(item.id,item.type,item.stage,item.versions,item.auditStatus)"
							v-preventReClick="5000">修改</a>
					</p>
					<p v-else-if="item.payStatus=='1'&&item.auditStatus=='2'">
						<a @click.stop="getInfo(item.id,item.type,item.stage,item.auditStatus,item.versions)">查看信息</a>
					</p>
					<p v-else-if="item.payStatus=='1'&&item.auditStatus=='1'">
						<a @click.stop="getInfo(item.id,item.type,item.stage,item.auditStatus,item.versions)">查看信息</a>
						<a class="checkReport" target="_blank"
							@click="checkSummaryPage(item.id,item.type,item.versions)">查看报告</a>
					</p>
					<p v-else-if="item.payStatus=='1'&&item.auditStatus=='0'">
						<a class="edit"
							@click.stop="modification(item.id,item.type,item.stage,item.versions,item.auditStatus)"
							v-preventReClick="5000">修改</a>
						<a @click.stop="getInfo(item.id,item.type,item.stage,item.auditStatus,item.versions)">查看信息</a>
					</p>
				</li>
			</ul>
			<!-- <div class="loading" v-if="loading">
				<img src="../assets/img/mobileClient/loading.gif" alt="正在加载">
				<span>正在加载</span>
			</div> -->
			<div v-if="!loading" style="text-align: center;">没有更多数据了</div>
		</div>

		<Footer />

		<Modal v-model="myAlert" class-name="vertical-center-modal del" @on-ok="ok()">
			<img class='warning' src="../assets/img/remind-01@2x.png">
			<div>
				<p>确定要删除吗？</p>
				<p>删除后无法恢复这条估值记录</p>
			</div>
		</Modal>

		<Modal v-model="diagnosisAlert" title="提示:" class-name="vertical-center-modal newDiagnosis">
			<div>
				<p>企业价值诊断已全新改版，财务分析更全面（新增7个维度），请填写新版价值诊断</p>
			</div>
			<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
		</Modal>





	</div>
</template>

<script>
	import Header from '../common/header.vue';
	import Footer from '../common/footer.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex'
	export default {
		data() {
			return {
				searchIcon: require('../assets/img/guzhi/suosou.png'),
				state_payment: false, //支付状态  true显示3中支付状态  默认全部状态
				state_payment_index: 0,
				pay_status: [{
					state: '全部状态',
				}, {
					state: '未支付',
				}, {
					state: '已支付',
				}],
				noData: true, //默认展示
				statusCode: 3, // 支付状态  全部3  未支付0  已支付1
				valuationList: [],
				current: 1,
				total: 0,
				pageSize: 5,
				companyName: '',
				myAlert: false,
				reportId: '',
				liIndex: 0,
				diagnosisAlert: false, //跳转新版诊断的提示
				loading: true,
				scrollNum: 1
			}
		},
		components: {
			Header,
			Footer
		},
		computed: {
			...mapState(['traceYear'])
		},
		created() {
			this.list(1, 3);
		},
		mounted() {
			sessionStorage.removeItem('baseDate'); //清空诊断基准日
			// 监听滑动到底部
			var that = this;
			console.log(document.documentElement.clientWidth)
			window.addEventListener('scroll', () => {
				// scrollTop 滚动条滚动时，距离顶部的距离
				var scrollTop = document.documentElement.scrollTop;
				// windowHeight 可视区的高度
				var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
				// scrollHeight 滚动条的总高度
				var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

				// console.log(Math.round(scrollTop + windowHeight), scrollHeight)

				// 滚动条到底部的条件
				// if (Math.round(scrollTop + windowHeight) + 1 > scrollHeight - 50) {

				that.scrollNum++;
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
					// 执行相应代码或直接跳转到手机页面
					if (that.loading) {
						that.list(that.scrollNum, this.statusCode, 2);
					}
				}
				// }

			})

		},
		methods: {
			...mapMutations(['changeTraceYear']),
			payStatus: function() {
				this.state_payment = true;
				for (var i = 0; i < this.pay_status.length; i++) {
					this.pay_status[i].show = "";
				}
			},
			payment: function(index) {
				this.scrollNum = 1;
				this.current = 1;
				this.state_payment = !this.state_payment;
				this.state_payment_index = index;
				this.statusCode = index == 0 ? 3 : index == 1 ? 0 : 1;
				this.valuationList = [];
				this.list(this.current, this.statusCode, this.companyName)

			},
			list: function(pageNum, payStatus, equipment) { // equipment 1代表pc端   2代表移动端
				this.loading = true;
				var this_ = this;
				this.$post('flow/valuationReport/findValuationReport', qs.stringify({
						pageNum: pageNum, //当前页
						payStatus: payStatus, //全部3  未支付0  已支付1
						pageSize: 5, //每页条数
						tab: 6, //代表专精特新类型
						companyName: this.companyName //公司名称
					}))
					.then((res) => {
						if (res && res.data.content.records.length > 0) {
							this.noData = false;
							this.total = res.data.content.totalPage * 10;
							if (equipment == 2) {
								res.data.content.records.forEach(function(item, i) {
									this_.valuationList.push(item)
								})
							} else {
								this.valuationList = res.data.content.records;
							}
						} else {
							this.noData = true;
							if (equipment == 2) {
								this.loading = false;
							}
						}
					});
			},
			handleChange: function(page) {
				this.state_payment = false;
				this.current = page;
				this.list(page, this.statusCode)
			},
			foucs: function() {
				this.searchIcon = require("@/assets/img/guzhi/suosou2.png");
			},
			blur: function() {
				this.searchIcon = require("@/assets/img/guzhi/suosou.png");
			},
			search: function() {
				this.list(this.current, this.statusCode)
			},
			queryTraceReportById: function(id, type, auditStatus) { //id：价值追踪的报告id    type：0：修改，1：查看信息  auditStatus:审核状态
				this.$post('trace/traceRelation/getTraceListByTraceId', qs.stringify({
						traceReportId: id
					}))
					.then((res) => {
						console.log(res.data)
						this.changeTraceYear(res.data.content[0].year)
						if (type == 1) {
							this.$router.push({
								path: auditStatus==1?'examineInformation':'/auditStatus',
								query: {
									type: 5,
									reportId: res.data.content[0].valutaionReportId,
									traceReportId: res.data.content[0].traceReportId,
									year: res.data.content[0].year,
									auditStatus: auditStatus
								}
							})
						} else {
							this.$router.push({
								path: '/basicInformation',
								query: {
									stage: sessionStorage.stage,
									type: 5,
									reportId: res.data.content[0].valutaionReportId,
									traceReportId: res.data.content[0].traceReportId,
									year: res.data.content[0].year
								}
							})
						}
					});
			},
			modification: function(reportId, type, stage, versions, auditStatus) { //修改跳转基本信息
				sessionStorage.removeItem("isHistoryCache")
				sessionStorage.stage = stage;
				sessionStorage.reportType = type;
				sessionStorage.versions = versions ? versions : 1;
				console.log(versions, auditStatus)
				if (type == 5) {
					this.queryTraceReportById(reportId, 0)
				} else if (type == 7 && versions != 3 && auditStatus != '0') { //如果是旧版的诊断直接提示跳转到新版诊断的页面
					this.diagnosisAlert = true;
				} else {
					this.$router.push({
						path: '/basicInformation',
						query: {
							stage: stage,
							type: type,
							reportId: reportId
						}
					})

				}
			},
			getInfo: function(reportId, type, stage, auditStatus, versions) {
				sessionStorage.versions = versions ? versions : 1;
				if (type == 5) {
					this.queryTraceReportById(reportId, 1, auditStatus)
				} else {
					this.$router.push({
						path: auditStatus==1?'examineInformation':'/auditStatus',
						query: {
							stage: stage,
							type: type,
							reportId: reportId,
							auditStatus: auditStatus
						}
					})
				}

			},
			del: function(id, index) { //删除报告
				this.myAlert = true;
				this.reportId = id;
				this.liIndex = index;
			},
			ok: function() {
				this.$post('flow/valuationReport/deleteValuationReportById', qs.stringify({
						reportId: this.reportId
					}))
					.then((res) => {
						if ($(".content>ul.table_item>li").length = 0) {
							this.noData = true;
						}
						this.list(this.current, this.statusCode)
					});
			},
			submitDialog: function() {
				var this_ = this;
				this.diagnosisAlert = false;
				sessionStorage.versions = 3;
				setTimeout(function() {
					this_.$router.push({
						path: '/basicInformation',
						query: {
							stage: sessionStorage.stage,
							type: 7,
						}
					})
				}, 500)
			},
			checkSummaryPage: function(reportId, type, versions) { //查看报告（跳转到总结页）
				var query = {
					reportId: reportId,
					type: type,
					versions: versions ? versions : 1
				};
				if (type == 2) {
					var routeUrl = this.$router.resolve({
						path: '/valuationSummary',
						query: query
					})
				} else if (type == 5) {
					var routeUrl = this.$router.resolve({
						path: '/traceSummary',
						query: query
					})
				} else if (type == 7) {
					if (versions == 3) {
						var routeUrl = this.$router.resolve({
							path: '/diagnoseSummary',
							query: query
						})
					} else {
						var routeUrl = this.$router.resolve({
							path: '/diagnoseSummary2v',
							query: query
						})
					}
				}
				window.open(routeUrl.href, '_blank');

			}


		}
	}
</script>

<style scoped>
	* {
		box-sizing: content-box !important;
	}

	input {
		line-height: normal;
	}

	.main {
		width: 1200px;
		background: #fff;
		border-top: 1px solid #F1F1F1;
		margin: 0 auto 178px;
		/* overflow: hidden; */
	}

	.main>p.title {
		position: relative;
		height: 88px;
		line-height: 88px;
		margin-top: 50px;
		border-color: #e6e6e6;
	}

	.information p.title>img {
		width: 38px;
		height: 38px;
		vertical-align: middle;
		margin-right: 13px;
	}

	.information p.title>span {
		color: #282828;
		font-size: 18px;
		font-weight: bold;
	}


	.main .content {
		background: #fff;
		height: 76%;
	}

	.main .content>label {
		display: inline-block;
		width: 120px;
		height: 38px;
		line-height: 38px;
		border-radius: 6px;
		background: #4393FF;
		cursor: pointer;
	}

	.main .content>label img {
		width: 17px;
		height: 17px;
		margin: 10px 3px 0 18px;
	}


	.main .content .addGuZhi {
		color: #fff;
		text-align: center;
		vertical-align: top;
		font-size: 16px;
	}

	.main .content .headCategory {
		height: 63px;
		border-top: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
	}

	.main .content .headCategory .reportCatalog {
		float: left;
		line-height: 63px;
	}

	.main .content .headCategory .reportCatalog span {
		font-size: 16px;
		margin-right: 31px;
		cursor: pointer;
	}

	.main .content .headCategory .reportCatalog span:last-child {
		margin-right: 0;
	}

	.main .content .reportCatalog span.select {
		border-bottom: 3px solid #4393FF;
		color: #4393FF;
	}

	.main .content .headCategory .statusBar {
		float: right;
		font-size: 14px;
		position: relative;
	}

	.main .content .headCategory .statusBar .payStatus {
		width: 89px;
		background: #fff;
		padding: 0 21px;
		cursor: pointer;
		position: absolute;
		top: 26px;
		right: 259px;
	}

	.main .content .headCategory .statusBar .payStatus p {
		color: #4393FF;
	}

	.main .content .headCategory .statusBar .payStatus p i {
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		color: #CACACA;
		margin-left: 7px;
	}

	.main .content .headCategory .statusBar .payStatus span {
		margin: 2px 0;
		display: block;
	}

	.main .content .headCategory .statusBar .payStatus span.select {
		color: #4393FF;
	}

	.main .content .headCategory .statusBar .flexible {
		border: 1px solid rgba(202, 202, 202, 1);
		top: 14px;
		padding: 10px 21px;
	}

	.main .content .headCategory .statusBar .flexible p i {
		transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		-o-transform: rotate(-90deg);
	}

	.main .content .headCategory .statusBar .search {
		width: 184px;
		height: 36px;
		background: rgba(255, 255, 255, 1);
		border: 1PX solid rgba(226, 226, 226, 1);
		margin-top: 14px;
		line-height: 36px;
		padding: 0 13px;
	}

	.main .content .headCategory .statusBar .search img {
		position: absolute;
		top: 25px;
		right: 13px;
		cursor: pointer;
	}

	ul.table_title {
		height: 50px;
	}

	ul.table_title>li {
		float: left;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-size: 16px;
		color: rgba(255, 255, 255, 1);
		font-weight: bold;
		background-color: #4393FF;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	ul.table_item>li>div {
		float: left;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-size: 14px;
		font-weight: 400;
		color: rgba(40, 40, 40, 1);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	ul.table_item>li {
		border-bottom: 1px solid #DBDBDB;
	}

	ul.table_item>li .company-name {
		width: 18%;
		padding: 0 1%;
	}

	ul.table_item>li .industryContent {
		width: 20%;
		text-align: left;
	}

	ul.table_item>li .valution-money {
		width: 5%;
		text-align: left;
	}

	img.no-data-default {
		display: block;
		width: 26%;
		margin: 0 auto;
		margin-top: 10%;
	}

	.none-show>p {
		font-size: 20px;
		color: #0487FC;
		text-align: center;
		margin-top: 20px;
	}

	.main ul.table_title {
		margin-top: 30px;
	}

	.main ul.table_item li {
		height: 50px;
		overflow: hidden;
	}

	.main ul li.liSelect {
		/*border: 1px solid #FFC600;*/
		box-shadow: 0 0 10px 3px #cccccc70;
	}

	.main ul li.liSelect .baseName {
		color: #4393FF;
	}

	.main ul li .baseShow {
		display: inline-block;
		min-height: 95px;
		width: 85%;
	}

	.main ul li .baseShow .baseName {
		min-width: 36%;
		height: 37px;
		line-height: 37px;
		font-size: 16px;
		font-weight: bold;
		margin: 10px 0;
	}

	.examineState {
		width: 90px;
		height: 30px;
		line-height: 30px;
		border: 1px solid #4393FF;
		color: #4393FF;
		border-radius: 15px;
	}

	.isExamine {
		border: 1px solid #009933;
		color: #009933;
	}

	.noExamine {
		border: 1px solid #EB0000;
		color: #EB0000;
	}

	.examineing {
		border: 1px solid #ECC641;
		color: #ECC641;
	}

	.main ul li .operationIcon {
		display: inline-block;
		vertical-align: middle;
		width: 18%;
		text-align: right;
	}

	.main ul li .operationIcon a {
		width: 90px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		background: rgba(67, 147, 255, 1);
		border-radius: 4px;
		color: #fff;
		margin-left: 20px;
		cursor: pointer;
	}

	.main ul li .operationIcon a.edit {
		background: #FFA14C;
	}

	.main ul li .operationIcon a.del {
		background: red;
	}

	.main ul li .operationIcon a:first-of-type {
		margin-left: 0;
	}

	.main ul li .operationIcon img {
		margin-right: 15%;
		cursor: pointer;
	}

	.pageBox {
		text-align: center;
		margin-top: 40px;
	}

	.loading {
		text-align: center;
	}

	.loading img {
		width: 90px;
		height: 70px;
		vertical-align: middle;
	}

	.mobile .statusBar {
		width: 94%;
		margin: 15px auto;
	}

	.mobile .statusBar>div {
		display: inline-block;
	}

	.mobile .statusBar .payStatus {
		width: 100px;
		background: #fff;
		padding: 0 21px;
		cursor: pointer;
		position: absolute;
		top: 90px;
	}

	.mobile .statusBar .payStatus p {
		color: #4393FF;
	}

	.mobile .statusBar .payStatus p i {
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		color: #CACACA;
		margin-left: 7px;
	}

	.mobile .statusBar .payStatus span {
		margin: 7px 0;
		display: block;
	}

	.mobile .statusBar .payStatus span.select {
		color: #4393FF;
	}

	.mobile .statusBar .flexible {
		border: 1px solid rgba(202, 202, 202, 1);
		padding: 10px 21px;
		top: 76px;
	}

	.mobile .statusBar .flexible p i {
		transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		-o-transform: rotate(-90deg);
	}

	.mobile .search {
		width: 210px;
		height: 45px;
		background: rgba(255, 255, 255, 1);
		border: 1px solid #ddd;
		line-height: 45px;
		padding: 0 13px;
		position: relative;
		left: 54%;
	}

	.mobile .search img {
		position: absolute;
		top: 8px;
		right: 13px;
		cursor: pointer;
	}

	.mobile {
		display: none;
	}

	@media screen and (max-width: 750px) {
		#app {
			overflow: hidden;
		}

		.information {
			display: none;
		}

		.mobile {
			display: block;
		}

		.main {
			width: 100%;
			overflow-x: scroll;
		}

		.title {
			padding: 0 20px;
		}

		.information>div.valTit>p.title,
		.information>p.title {
			border: none;
		}

		.information p.title>span {
			font-size: 18PX;
		}

		.main .content .headCategory {
			height: 93px;
		}

		.main .content .headCategory .statusBar {
			font-size: 14PX;
			margin-top: 7px;
		}

		.main .content .headCategory .statusBar .payStatus {
			width: 170px;
		}

		.main .content .headCategory .statusBar .search {
			width: 250px;
			height: 56px;
			line-height: 56px;
		}

		.mobile .statusBar {
			padding-top: 20px;
		}

		.mobile .statusBar .payStatus {
			top: 100px;
		}

		.content {
			width: 1200PX;
			padding: 0 20px;
			font-size: 18PX;
		}

		.main .content .headCategory .reportCatalog span,
		ul.table_title>li {
			font-size: 16PX;
		}

		ul.table_item>li>div {
			font-size: 14PX;
			height: 50PX;
			line-height: 50PX;
		}

		ul.table_title>li,
		.main ul.table_item li {
			width: 100%;
			height: 50PX;
			line-height: 50PX;
		}

		.examineState {
			width: 90PX;
			height: 30PX;
			line-height: 30PX;
			border-radius: 15PX;
		}

		.main ul li .operationIcon a {
			width: 90PX;
			height: 30PX;
			line-height: 30PX;
			border-radius: 4PX;
		}

		.mobile>ul li {
			width: 94%;
			margin: 20px auto;
			padding: 15px 0;
			box-shadow: 0 0.106667rem 0.426667rem 0 rgb(0 0 0 / 23%);
			border-radius: 6px;
		}

		.mobile>ul li P {
			/* width: 100%; */
			font-size: 12PX;
			margin-bottom: 10px;
			padding: 0 20px 0 20px;
		}

		.mobile>ul li>p img {
			width: 25px;
			height: 25px;
			vertical-align: middle;
			margin-right: 15px;
		}

		.mobile>ul li>p:first-child {
			overflow: hidden;
			border-bottom: 1PX solid #F0F0F0;
			padding: 0 20px 10px 20px;
			margin-bottom: 0;
			/* margin: 0 20px 10px 20px; */
		}

		.mobile>ul li>p:first-child span:nth-of-type(1) {
			float: left;
			color: #999;
		}


		.mobile>ul li>p:first-child .condition {
			float: right;
			color: #0487FC;
			border: none;
		}

		.mobile>ul li>p:first-child span.examineing {
			color: #ECC641;
		}

		.mobile>ul li>p:first-child span.isExamine {
			color: #009933;
		}

		.mobile>ul li>p:first-child span.noExamine {
			color: #EB0000;
		}

		.mobile>ul li>p:nth-of-type(2) {
			margin-top: 15px;
			font-size: 16PX;
			font-weight: bold;
		}

		.mobile>ul li>p:nth-of-type(3) span,
		.mobile>ul li>p:nth-of-type(4) span {
			color: #666;
		}

		.mobile>ul li>p:nth-of-type(4),
		.mobile>ul li>p:nth-of-type(5) {
			overflow: hidden;
		}

		.mobile>ul li>p:nth-of-type(4) span:last-child {
			float: right;
			font-size: 14PX;
			font-weight: bold;
		}

		.mobile>ul li>p a {
			width: 130px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			color: #000;
			border: 1PX solid #999;
			cursor: pointer;
			font-size: 12PX;
			border-radius: 20px;
			float: right;
		}

		.mobile>ul li>p a:first-child {
			margin-left: 20px;

		}

		.mobile>ul li>p a:last-child {
			color: #fff;
			background: #0487FC;
		}



	}
</style>
