<template>
	<div id="">
		<Header />
		<uploadData />
		<navigation />
		<div class="information major-balance">
			<div>
				<p class="moduleName">
					<img src="../../assets/img/navIcon/financeSele.png" alt="心流">
					<span>{{this.$route.query.type==5?this.$route.query.year+'年财务指标':'财务指标'}}</span>
					<span v-if="this.$route.query.type==2" class="division">|</span>
					<span v-if="this.$route.query.type==2" class="">专业估值</span>
				</p>
				<!-- <div v-if="this.$route.query.type==2" class="down-up-container">
					<a href="/data/财务报表模板.xlsx" class="down-container">下载模板</a>
					<span class="up-container" @click="uploadInformation">
						<font>上传信息表</font>
					</span>
				</div> -->
			</div>

			<Modal v-model="myAlert" class-name="vertical-center-modal uploadFiles" :mask-closable="false">
				<p slot="header">
					<em></em>
					<span>上传文件</span>
				</p>
				<div class="file-container">
					<span class="up-container">
						<input type="file" name="file" id="fileupload" ref="file" @change="uploadFiles($event)">
						<font>上传信息表</font>
					</span>
					<p class="up-file">支持excel格式文件，填写财务数据时，<span class="red">请勿使用剪切（Ctrl+X）功能</span>，会导致表格公式出现错误。</p>
				</div>
				<div slot="footer"></div>
			</Modal>
			<Modal v-model="success" class-name="vertical-center-modal file_uccess" :mask-closable="false">
				<p slot="header">
					<em></em>
					<span>上传文件</span>
				</p>
				<div class="file-container">
					<img src="../../assets/img/up-success@2x.png">
					<p>报表上传成功，请再次确定财务信息！</p>
				</div>
				<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
			</Modal>

			<Modal v-model="error" class-name="vertical-center-modal" :mask-closable="false">
				<img class='warning' src="../../assets/img/remind-01@2x.png">
				<span>您选择的模板有误，请上传心流网提供的专业模板</span>
				<div slot="footer" class="ivu-sure" @click="wrongBtn">确定</div>
			</Modal>
			<div class="moduleMain">
				<financeNav />
				<div v-if="this.$route.query.type!=7" class="inputTabDesc">
					<p class="table-desc-tit">
						<img src="../../assets/img/guzhi/desc@2x.png" alt="资产负债表的填表说明-心流" />
						<span>填表说明</span>
					</p>
					<ul>
						<li>
							<span>1、资产负债表请务必按实际情况填报完整，各明细科目合计应与该一级科目填报数相等，所有者权益应等于总资产减去总负债。</span>
						</li>
						<li>
							<span>2、如企业2016年或2017年成立，则从该年填起；灰色为自动算出项，不可填写。</span>
						</li>
						<li>
							<span>3、选填项中，如果该科目在对应的会计年度存在余额，请如实填报。</span>
						</li>
						<li>
							<span>4、如果存在明细科目，本表没有对应科目的情况，请将合计数填在对应一级科目的其他处。</span>
						</li>
					</ul>
				</div>
				<div v-if="this.$route.query.type==7" class="inputTabDesc">
					<p class="table-desc-tit">
						<img src="../../assets/img/guzhi/desc@2x.png" alt="资产负债表的填表说明-心流" />
						<span>填表说明</span>
					</p>
					<ul>
						<li>
							<span>1、资产负债表请务必按实际情况填报完整，各明细科目合计应与该一级科目填报数相等，所有者权益应等于总资产减去总负债。</span>
						</li>
						<li>
							<span>2、从企业成立年份填起；灰色为自动算出项，不可填写。</span>
						</li>
						<li>
							<span>3、选填项中，如果该科目在对应的会计年度存在余额，请如实填报。</span>
						</li>
						<li>
							<span>4、如果存在明细科目，本表没有对应科目的情况，请将合计数填在对应一级科目的其他处。</span>
						</li>
					</ul>
				</div>
				<div class="table-main">
					<!-- 表名-->
					<div class="table-name">
						<span>资产负债表</span>
					</div>
					<!--单位简介-->
					<span class="unit">单位：元</span>
					<div :class="['table-container',this.$route.query.type==7?'diagnoseFinance':'']">
						<table class="table" id="tableOne" border="0" style="border-collapse: collapse;">
							<thead>
								<tr class="noInput">
									<th>项目</th>
									<th v-for="(item, index) in yearList">{{item}}</th>
								</tr>
							</thead>
							<tbody>
								<tr class="trOne even noInput">
									<td>流动资产:</td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trTwo" data-class="monetaryCapital">
									<td>货币资金</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.monetaryCapital[i]"
											@blur="((ev)=>{blur(ev,liabilities,'monetaryCapital',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('monetaryCapital',i)?formatCurrency.formateNum(liabilities.monetaryCapital[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="tradingAssets">
									<td>交易性金融资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.tradingAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'tradingAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('tradingAssets',i)?formatCurrency.formateNum(liabilities.tradingAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="derivativeAssets">
									<td>衍生金融资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.derivativeAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'derivativeAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('derivativeAssets',i)?formatCurrency.formateNum(liabilities.derivativeAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="billReceivable">
									<td>应收票据</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.billReceivable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'billReceivable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('billReceivable',i)?formatCurrency.formateNum(liabilities.billReceivable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo tipTerm" data-class="receivables">
									<td>应收账款</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.receivables[i]"
											@blur="((ev)=>{blur(ev,liabilities,'receivables',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('receivables',i)?formatCurrency.formateNum(liabilities.receivables[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="accountReceivable">
									<td>应收账款融资</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.accountReceivable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'accountReceivable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('accountReceivable',i)?formatCurrency.formateNum(liabilities.accountReceivable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="prepayment">
									<td>预付款项</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.prepayment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'prepayment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('prepayment',i)?formatCurrency.formateNum(liabilities.prepayment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherReceivables">
									<td>其他应收款</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherReceivables[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherReceivables',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherReceivables',i)?formatCurrency.formateNum(liabilities.otherReceivables[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo tipTerm" data-class="inventory">
									<td>存货</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.inventory[i]"
											@blur="((ev)=>{blur(ev,liabilities,'inventory',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('inventory',i)?formatCurrency.formateNum(liabilities.inventory[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="contractualAssets">
									<td>合同资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.contractualAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'contractualAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('contractualAssets',i)?formatCurrency.formateNum(liabilities.contractualAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="saleForAssets">
									<td>持有待售资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.saleForAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'saleForAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('saleForAssets',i)?formatCurrency.formateNum(liabilities.saleForAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="illiquidAssetsOne">
									<td>一年内到期的非流动资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.illiquidAssetsOne[i]"
											@blur="((ev)=>{blur(ev,liabilities,'illiquidAssetsOne',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('illiquidAssetsOne',i)?formatCurrency.formateNum(liabilities.illiquidAssetsOne[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherCurrentAssets">
									<td>
										其他流动资产
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>此处应填写未在本会计报表内统计的所有流动资产科目汇总金额。</p>
											</div>
										</span>
									</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherCurrentAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherCurrentAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherCurrentAssets',i)?formatCurrency.formateNum(liabilities.otherCurrentAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne total totalCurrentAssets" data-class="totalCurrentAssets">
									<td>流动资产合计</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalCurrentAssets[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalCurrentAssets',i)?formatCurrency.formateNum(liabilities.totalCurrentAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne noInput">
									<td>非流动资产:</td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trTwo" data-class="debtInvestment">
									<td>债权投资</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.debtInvestment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'debtInvestment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('debtInvestment',i)?formatCurrency.formateNum(liabilities.debtInvestment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherDebtInvestment">
									<td>其他债权投资</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherDebtInvestment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherDebtInvestment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherDebtInvestment',i)?formatCurrency.formateNum(liabilities.otherDebtInvestment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="longAeceivables">
									<td>长期应收款</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.longAeceivables[i]"
											@blur="((ev)=>{blur(ev,liabilities,'longAeceivables',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('longAeceivables',i)?formatCurrency.formateNum(liabilities.longAeceivables[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="longInvestment">
									<td>长期股权投资</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.longInvestment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'longInvestment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('longInvestment',i)?formatCurrency.formateNum(liabilities.longInvestment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherEquityInvestment">
									<td>其他权益工具投资</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherEquityInvestment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherEquityInvestment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherEquityInvestment',i)?formatCurrency.formateNum(liabilities.otherEquityInvestment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherIlliquidAssets">
									<td>其他非流动金融资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherIlliquidAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherIlliquidAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherIlliquidAssets',i)?formatCurrency.formateNum(liabilities.otherIlliquidAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="" investmentProperty>
									<td>投资性房地产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.investmentProperty[i]"
											@blur="((ev)=>{blur(ev,liabilities,'investmentProperty',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('investmentProperty',i)?formatCurrency.formateNum(liabilities.investmentProperty[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo tipTerm" data-class="fixedAssets">
									<td>
										固定资产
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>此处指固定资产期末净值。</p>
											</div>
										</span>
									</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.fixedAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'fixedAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('fixedAssets',i)?formatCurrency.formateNum(liabilities.fixedAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="constructionProjects">
									<td>在建工程</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.constructionProjects[i]"
											@blur="((ev)=>{blur(ev,liabilities,'constructionProjects',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('constructionProjects',i)?formatCurrency.formateNum(liabilities.constructionProjects[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="productiveAsset">
									<td>生产性生物资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.productiveAsset[i]"
											@blur="((ev)=>{blur(ev,liabilities,'productiveAsset',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('productiveAsset',i)?formatCurrency.formateNum(liabilities.productiveAsset[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="oilAndGasAssets">
									<td>油气资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.oilAndGasAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'oilAndGasAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('oilAndGasAssets',i)?formatCurrency.formateNum(liabilities.oilAndGasAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="rightAssets">
									<td>使用权资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.rightAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'rightAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('rightAssets',i)?formatCurrency.formateNum(liabilities.rightAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="netWorth">
									<td>无形资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.netWorth[i]"
											@blur="((ev)=>{blur(ev,liabilities,'netWorth',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('netWorth',i)?formatCurrency.formateNum(liabilities.netWorth[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="developExpense">
									<td>开发支出</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.developExpense[i]"
											@blur="((ev)=>{blur(ev,liabilities,'developExpense',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('developExpense',i)?formatCurrency.formateNum(liabilities.developExpense[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="goodwill">
									<td>商誉</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.goodwill[i]"
											@blur="((ev)=>{blur(ev,liabilities,'goodwill',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('goodwill',i)?formatCurrency.formateNum(liabilities.goodwill[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="longExpenses">
									<td>长期待摊费用</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.longExpenses[i]"
											@blur="((ev)=>{blur(ev,liabilities,'longExpenses',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('longExpenses',i)?formatCurrency.formateNum(liabilities.longExpenses[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="deferredAssets">
									<td>递延所得税资产</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'deferredAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('deferredAssets',i)?formatCurrency.formateNum(liabilities.deferredAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherNonCurrentAssets">
									<td>
										其他非流动资产
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>此处应填写未在本会计报表内统计的所有非流动资产科目汇总金额。</p>
											</div>
										</span>
									</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherNonCurrentAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherNonCurrentAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherNonCurrentAssets',i)?formatCurrency.formateNum(liabilities.otherNonCurrentAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne total totalAssets grey">
									<td></td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trOne total totalAssets grey">
									<td></td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trOne total totalAssets grey">
									<td></td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trOne total totalAssets grey">
									<td></td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trOne total totalAssets grey">
									<td></td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trOne total totalAssets grey">
									<td></td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trOne total totalNonCurrentAssets" data-class="totalNonCurrentAssets">
									<td>非流动资产合计</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalNonCurrentAssets[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalNonCurrentAssets',i)?formatCurrency.formateNum(liabilities.totalNonCurrentAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne total totalAssets grey">
									<td></td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<!--资产合计-->
								<tr class="trOne total totalAssets grey" data-class="totalAssets">
									<td>资产总计</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalAssets[i]" disabled>
										<span class="showInput">
											{{judgingCondition('totalAssets',i)?formatCurrency.formateNum(liabilities.totalAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
						<table class="table" id="tableTwo" border="0" style="border-collapse: collapse;">
							<thead>
								<tr class="noInput">
									<th>项目</th>
									<th v-for="(item, index) in yearList">{{item}}</th>
								</tr>
							</thead>
							<tbody>
								<tr class="trOne even noInput">
									<td>流动负债:</td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trTwo" data-class="shortLoan">
									<td>短期借款</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.shortLoan[i]"
											@blur="((ev)=>{blur(ev,liabilities,'shortLoan',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('shortLoan',i)?formatCurrency.formateNum(liabilities.shortLoan[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="transactionLiabilities">
									<td>交易性金融负债</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.transactionLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'transactionLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('transactionLiabilities',i)?formatCurrency.formateNum(liabilities.transactionLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="derivativeLiabilities">
									<td>衍生金融负债</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.derivativeLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'derivativeLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('derivativeLiabilities',i)?formatCurrency.formateNum(liabilities.derivativeLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="notesPayable">
									<td>应付票据</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.notesPayable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'notesPayable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('notesPayable',i)?formatCurrency.formateNum(liabilities.notesPayable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="accountsPayable">
									<td>应付账款</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.accountsPayable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'accountsPayable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('accountsPayable',i)?formatCurrency.formateNum(liabilities.accountsPayable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="advanceReceipt">
									<td>预收款项</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.advanceReceipt[i]"
											@blur="((ev)=>{blur(ev,liabilities,'advanceReceipt',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('advanceReceipt',i)?formatCurrency.formateNum(liabilities.advanceReceipt[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="contractualLiabilities">
									<td>合同负债</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.contractualLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'contractualLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('contractualLiabilities',i)?formatCurrency.formateNum(liabilities.contractualLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="payrollPayable">
									<td>应付职工薪酬</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.payrollPayable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'payrollPayable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('payrollPayable',i)?formatCurrency.formateNum(liabilities.payrollPayable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="taxPayable">
									<td>应交税费</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.taxPayable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'taxPayable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('taxPayable',i)?formatCurrency.formateNum(liabilities.taxPayable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherPayables">
									<td>其他应付款</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherPayables[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherPayables',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherPayables',i)?formatCurrency.formateNum(liabilities.otherPayables[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="saleForLiabilities">
									<td>持有待售负债</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.saleForLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'saleForLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('saleForLiabilities',i)?formatCurrency.formateNum(liabilities.saleForLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="illiquidLiabilitiesOne">
									<td>一年内到期的非流动负债</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.illiquidLiabilitiesOne[i]"
											@blur="((ev)=>{blur(ev,liabilities,'illiquidLiabilitiesOne',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('illiquidLiabilitiesOne',i)?formatCurrency.formateNum(liabilities.illiquidLiabilitiesOne[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherNonLiabilities">
									<td>
										其他流动负债
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>此处应填写未在本会计报表内统计的所有流动负债科目汇总金额。</p>
											</div>
										</span>
									</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherNonLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherNonLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherNonLiabilities',i)?formatCurrency.formateNum(liabilities.otherNonLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne total totalCurrentAssets" data-class="totalNonLiabilities">
									<td>流动负债合计</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalNonLiabilities[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalNonLiabilities',i)?formatCurrency.formateNum(liabilities.totalNonLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne noInput">
									<td>非流动负债:</td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trTwo" data-class="longLoan">
									<td>长期借款</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.longLoan[i]"
											@blur="((ev)=>{blur(ev,liabilities,'longLoan',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('longLoan',i)?formatCurrency.formateNum(liabilities.longLoan[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="bondsPayable">
									<td>应付债券</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPayable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'bondsPayable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('bondsPayable',i)?formatCurrency.formateNum(liabilities.bondsPayable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThtrr" data-class="bondsPreferredStock">
									<td>其中：优先股</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPreferredStock[i]"
											@blur="((ev)=>{blur(ev,liabilities,'bondsPreferredStock',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('bondsPreferredStock',i)?formatCurrency.formateNum(liabilities.bondsPreferredStock[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="bondsPerpetuaDebt">
									<td>永续债</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPerpetuaDebt[i]"
											@blur="((ev)=>{blur(ev,liabilities,'bondsPerpetuaDebt',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('bondsPerpetuaDebt',i)?formatCurrency.formateNum(liabilities.bondsPerpetuaDebt[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="leaseLiabilities">
									<td>租赁负债</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.leaseLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'leaseLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('leaseLiabilities',i)?formatCurrency.formateNum(liabilities.leaseLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="longPayment">
									<td>长期应付款</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.longPayment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'longPayment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('longPayment',i)?formatCurrency.formateNum(liabilities.longPayment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="estimatedLiabilities">
									<td>预计负债</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.estimatedLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'estimatedLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('estimatedLiabilities',i)?formatCurrency.formateNum(liabilities.estimatedLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="deferredIncome">
									<td>递延收益</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredIncome[i]"
											@blur="((ev)=>{blur(ev,liabilities,'deferredIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('deferredIncome',i)?formatCurrency.formateNum(liabilities.deferredIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="deferredLiability">
									<td>递延所得税负债</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredLiability[i]"
											@blur="((ev)=>{blur(ev,liabilities,'deferredLiability',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('deferredLiability',i)?formatCurrency.formateNum(liabilities.deferredLiability[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherFixedLiabilities">
									<td>
										其他非流动负债
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>此处应填写未在本会计报表内统计的所有非流动负债科目汇总金额。</p>
											</div>
										</span>
									</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherFixedLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherFixedLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherFixedLiabilities',i)?formatCurrency.formateNum(liabilities.otherFixedLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="totalFixedLiabilities">
									<td>非流动负债合计</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalFixedLiabilities[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalFixedLiabilities',i)?formatCurrency.formateNum(liabilities.totalFixedLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="totalLiabilities">
									<td>负债总计</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalLiabilities[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalLiabilities',i)?formatCurrency.formateNum(liabilities.totalLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne">
									<td>
										所有者权益(或股东权益):
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>是指所有者向企业投入的资本总和。</p>
											</div>
										</span>
									</td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trTwo" data-class="paidUpCapital">
									<td>实收资本(或股本)</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.paidUpCapital[i]"
											@blur="((ev)=>{blur(ev,liabilities,'paidUpCapital',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('paidUpCapital',i)?formatCurrency.formateNum(liabilities.paidUpCapital[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherEquity">
									<td>其他权益工具</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherEquity[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherEquity',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherEquity',i)?formatCurrency.formateNum(liabilities.otherEquity[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="equityPreferredStock">
									<td>其中：优先股</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.equityPreferredStock[i]"
											@blur="((ev)=>{blur(ev,liabilities,'equityPreferredStock',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('equityPreferredStock',i)?formatCurrency.formateNum(liabilities.equityPreferredStock[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="equityPerpetuaDebt">
									<td>永续债</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.equityPerpetuaDebt[i]"
											@blur="((ev)=>{blur(ev,liabilities,'equityPerpetuaDebt',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('equityPerpetuaDebt',i)?formatCurrency.formateNum(liabilities.equityPerpetuaDebt[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="capitalReserve">
									<td>资本公积</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.capitalReserve[i]"
											@blur="((ev)=>{blur(ev,liabilities,'capitalReserve',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('capitalReserve',i)?formatCurrency.formateNum(liabilities.capitalReserve[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="treasuryShare">
									<td>减：库存股</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.treasuryShare[i]"
											@blur="((ev)=>{blur(ev,liabilities,'treasuryShare',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('treasuryShare',i)?formatCurrency.formateNum(liabilities.treasuryShare[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="">
									<td>其他综合收益</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherIncome[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherIncome',i)?formatCurrency.formateNum(liabilities.otherIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="appropriativeReserve">
									<td>专项储备</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.appropriativeReserve[i]"
											@blur="((ev)=>{blur(ev,liabilities,'appropriativeReserve',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('appropriativeReserve',i)?formatCurrency.formateNum(liabilities.appropriativeReserve[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="earnedSurplus">
									<td>盈余公积</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.earnedSurplus[i]"
											@blur="((ev)=>{blur(ev,liabilities,'earnedSurplus',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('earnedSurplus',i)?formatCurrency.formateNum(liabilities.earnedSurplus[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="undistributedProfit">
									<td>未分配利润</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.undistributedProfit[i]"
											@blur="((ev)=>{blur(ev,liabilities,'undistributedProfit',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('undistributedProfit',i)?formatCurrency.formateNum(liabilities.undistributedProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="totalParentEquity">
									<td>
										归属于母公司所有者权益合计
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>是指公司集团的所有者权益中归属于母公司所有者权益的部分。</p>
											</div>
										</span>
									</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalParentEquity[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalParentEquity',i)?formatCurrency.formateNum(liabilities.totalParentEquity[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="minorityEquity">
									<td>
										少数股东权益
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>是指母公司以外的的人拥有的权益，此项针对合并报表企业，如果无少数股东权益或非合并报表企业，则填零。</p>
											</div>
										</span>
									</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.minorityEquity[i]"
											@blur="((ev)=>{blur(ev,liabilities,'minorityEquity',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('minorityEquity',i)?formatCurrency.formateNum(liabilities.minorityEquity[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="totalOwnershipInterest">
									<td>所有者权益（或股东权益)合计</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalOwnershipInterest[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalOwnershipInterest',i)?formatCurrency.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="totalLiabilitiesEquity">
									<td>负债和所有者权益(或股东权益)总计</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalLiabilitiesEquity[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalLiabilitiesEquity',i)?formatCurrency.formateNum(liabilities.totalLiabilitiesEquity[i],2):'-'}}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>


					<div class="mobileBalanceSheet">
						<ul>
							<li v-for="(item, index) in yearList">{{item}}</li>
							<!-- <li v-if="this.$route.query.type==7"></li> -->
						</ul>
						<table border="0" style="border-collapse: collapse;">
							<tbody>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">流动资产:</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">货币资金</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.monetaryCapital[i]"
											@blur="((ev)=>{blur(ev,liabilities,'monetaryCapital',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('monetaryCapital',i)?formatCurrency.formateNum(liabilities.monetaryCapital[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">交易性金融资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.tradingAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'tradingAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('tradingAssets',i)?formatCurrency.formateNum(liabilities.tradingAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">衍生金融资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.derivativeAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'derivativeAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('derivativeAssets',i)?formatCurrency.formateNum(liabilities.derivativeAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">应收票据</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.billReceivable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'billReceivable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('billReceivable',i)?formatCurrency.formateNum(liabilities.billReceivable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">应收账款</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.receivables[i]"
											@blur="((ev)=>{blur(ev,liabilities,'receivables',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('receivables',i)?formatCurrency.formateNum(liabilities.receivables[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">应收账款融资</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.accountReceivable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'accountReceivable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('accountReceivable',i)?formatCurrency.formateNum(liabilities.accountReceivable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">预付款项</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.prepayment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'prepayment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('prepayment',i)?formatCurrency.formateNum(liabilities.prepayment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其他应收款</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherReceivables[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherReceivables',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherReceivables',i)?formatCurrency.formateNum(liabilities.otherReceivables[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">存货</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.inventory[i]"
											@blur="((ev)=>{blur(ev,liabilities,'inventory',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('inventory',i)?formatCurrency.formateNum(liabilities.inventory[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">合同资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.contractualAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'contractualAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('contractualAssets',i)?formatCurrency.formateNum(liabilities.contractualAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">持有待售资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.saleForAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'saleForAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('saleForAssets',i)?formatCurrency.formateNum(liabilities.saleForAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">一年内到期的非流动资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.illiquidAssetsOne[i]"
											@blur="((ev)=>{blur(ev,liabilities,'illiquidAssetsOne',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('illiquidAssetsOne',i)?formatCurrency.formateNum(liabilities.illiquidAssetsOne[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">
										其他流动资产
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>此处应填写未在本会计报表内统计的所有流动资产科目汇总金额。</p>
											</div>
										</span>
									</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherCurrentAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherCurrentAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherCurrentAssets',i)?formatCurrency.formateNum(liabilities.otherCurrentAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">流动资产合计</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalCurrentAssets[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalCurrentAssets',i)?formatCurrency.formateNum(liabilities.totalCurrentAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">非流动资产:</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">债权投资</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.debtInvestment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'debtInvestment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('debtInvestment',i)?formatCurrency.formateNum(liabilities.debtInvestment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其他债权投资</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherDebtInvestment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherDebtInvestment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherDebtInvestment',i)?formatCurrency.formateNum(liabilities.otherDebtInvestment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">长期应收款</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.longAeceivables[i]"
											@blur="((ev)=>{blur(ev,liabilities,'longAeceivables',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('longAeceivables',i)?formatCurrency.formateNum(liabilities.longAeceivables[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">长期股权投资</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.longInvestment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'longInvestment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('longInvestment',i)?formatCurrency.formateNum(liabilities.longInvestment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其他权益工具投资</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherEquityInvestment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherEquityInvestment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherEquityInvestment',i)?formatCurrency.formateNum(liabilities.otherEquityInvestment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其他非流动金融资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherIlliquidAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherIlliquidAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherIlliquidAssets',i)?formatCurrency.formateNum(liabilities.otherIlliquidAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">投资性房地产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.investmentProperty[i]"
											@blur="((ev)=>{blur(ev,liabilities,'investmentProperty',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('investmentProperty',i)?formatCurrency.formateNum(liabilities.investmentProperty[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">
										固定资产
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>此处指固定资产期末净值。</p>
											</div>
										</span>
									</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.fixedAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'fixedAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('fixedAssets',i)?formatCurrency.formateNum(liabilities.fixedAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">在建工程</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.constructionProjects[i]"
											@blur="((ev)=>{blur(ev,liabilities,'constructionProjects',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('constructionProjects',i)?formatCurrency.formateNum(liabilities.constructionProjects[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">生产性生物资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.productiveAsset[i]"
											@blur="((ev)=>{blur(ev,liabilities,'productiveAsset',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('productiveAsset',i)?formatCurrency.formateNum(liabilities.productiveAsset[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">油气资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.oilAndGasAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'oilAndGasAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('oilAndGasAssets',i)?formatCurrency.formateNum(liabilities.oilAndGasAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">使用权资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.rightAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'rightAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('rightAssets',i)?formatCurrency.formateNum(liabilities.rightAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">无形资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.netWorth[i]"
											@blur="((ev)=>{blur(ev,liabilities,'netWorth',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('netWorth',i)?formatCurrency.formateNum(liabilities.netWorth[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">开发支出</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.developExpense[i]"
											@blur="((ev)=>{blur(ev,liabilities,'developExpense',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('developExpense',i)?formatCurrency.formateNum(liabilities.developExpense[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">商誉</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.goodwill[i]"
											@blur="((ev)=>{blur(ev,liabilities,'goodwill',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('goodwill',i)?formatCurrency.formateNum(liabilities.goodwill[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">长期待摊费用</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.longExpenses[i]"
											@blur="((ev)=>{blur(ev,liabilities,'longExpenses',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('longExpenses',i)?formatCurrency.formateNum(liabilities.longExpenses[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">递延所得税资产</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'deferredAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('deferredAssets',i)?formatCurrency.formateNum(liabilities.deferredAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">
										其他非流动资产
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>此处应填写未在本会计报表内统计的所有非流动资产科目汇总金额。</p>
											</div>
										</span>
									</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherNonCurrentAssets[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherNonCurrentAssets',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherNonCurrentAssets',i)?formatCurrency.formateNum(liabilities.otherNonCurrentAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">非流动资产合计</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalNonCurrentAssets[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalNonCurrentAssets',i)?formatCurrency.formateNum(liabilities.totalNonCurrentAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">资产总计</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.totalAssets[i]" disabled>
										<span class="showInput">
											{{judgingCondition('totalAssets',i)?formatCurrency.formateNum(liabilities.totalAssets[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">流动负债:</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">短期借款</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.shortLoan[i]"
											@blur="((ev)=>{blur(ev,liabilities,'shortLoan',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('shortLoan',i)?formatCurrency.formateNum(liabilities.shortLoan[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">交易性金融负债</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.transactionLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'transactionLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('transactionLiabilities',i)?formatCurrency.formateNum(liabilities.transactionLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">衍生金融负债</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.derivativeLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'derivativeLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('derivativeLiabilities',i)?formatCurrency.formateNum(liabilities.derivativeLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">应付票据</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.notesPayable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'notesPayable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('notesPayable',i)?formatCurrency.formateNum(liabilities.notesPayable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">应付账款</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.accountsPayable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'accountsPayable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('accountsPayable',i)?formatCurrency.formateNum(liabilities.accountsPayable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">预收款项</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.advanceReceipt[i]"
											@blur="((ev)=>{blur(ev,liabilities,'advanceReceipt',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('advanceReceipt',i)?formatCurrency.formateNum(liabilities.advanceReceipt[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">合同负债</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.contractualLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'contractualLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('contractualLiabilities',i)?formatCurrency.formateNum(liabilities.contractualLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">应付职工薪酬</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.payrollPayable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'payrollPayable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('payrollPayable',i)?formatCurrency.formateNum(liabilities.payrollPayable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">应交税费</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.taxPayable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'taxPayable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('taxPayable',i)?formatCurrency.formateNum(liabilities.taxPayable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其他应付款</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherPayables[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherPayables',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherPayables',i)?formatCurrency.formateNum(liabilities.otherPayables[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">持有待售负债</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.saleForLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'saleForLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('saleForLiabilities',i)?formatCurrency.formateNum(liabilities.saleForLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">一年内到期的非流动负债</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.illiquidLiabilitiesOne[i]"
											@blur="((ev)=>{blur(ev,liabilities,'illiquidLiabilitiesOne',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('illiquidLiabilitiesOne',i)?formatCurrency.formateNum(liabilities.illiquidLiabilitiesOne[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">
										其他流动负债
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>此处应填写未在本会计报表内统计的所有流动负债科目汇总金额。</p>
											</div>
										</span>
									</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherNonLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherNonLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherNonLiabilities',i)?formatCurrency.formateNum(liabilities.otherNonLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">流动负债合计</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalNonLiabilities[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalNonLiabilities',i)?formatCurrency.formateNum(liabilities.totalNonLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">非流动负债:</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">长期借款</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.longLoan[i]"
											@blur="((ev)=>{blur(ev,liabilities,'longLoan',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('longLoan',i)?formatCurrency.formateNum(liabilities.longLoan[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">应付债券</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPayable[i]"
											@blur="((ev)=>{blur(ev,liabilities,'bondsPayable',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('bondsPayable',i)?formatCurrency.formateNum(liabilities.bondsPayable[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其中：优先股</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPreferredStock[i]"
											@blur="((ev)=>{blur(ev,liabilities,'bondsPreferredStock',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('bondsPreferredStock',i)?formatCurrency.formateNum(liabilities.bondsPreferredStock[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">永续债</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.bondsPerpetuaDebt[i]"
											@blur="((ev)=>{blur(ev,liabilities,'bondsPerpetuaDebt',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('bondsPerpetuaDebt',i)?formatCurrency.formateNum(liabilities.bondsPerpetuaDebt[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">租赁负债</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.leaseLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'leaseLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('leaseLiabilities',i)?formatCurrency.formateNum(liabilities.leaseLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">长期应付款</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.longPayment[i]"
											@blur="((ev)=>{blur(ev,liabilities,'longPayment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('longPayment',i)?formatCurrency.formateNum(liabilities.longPayment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">预计负债</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.estimatedLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'estimatedLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('estimatedLiabilities',i)?formatCurrency.formateNum(liabilities.estimatedLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">递延收益</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredIncome[i]"
											@blur="((ev)=>{blur(ev,liabilities,'deferredIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('deferredIncome',i)?formatCurrency.formateNum(liabilities.deferredIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">递延所得税负债</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.deferredLiability[i]"
											@blur="((ev)=>{blur(ev,liabilities,'deferredLiability',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('deferredLiability',i)?formatCurrency.formateNum(liabilities.deferredLiability[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">
										其他非流动负债
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>此处应填写未在本会计报表内统计的所有非流动负债科目汇总金额。</p>
											</div>
										</span>
									</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherFixedLiabilities[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherFixedLiabilities',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherFixedLiabilities',i)?formatCurrency.formateNum(liabilities.otherFixedLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">非流动负债合计</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalFixedLiabilities[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalFixedLiabilities',i)?formatCurrency.formateNum(liabilities.totalFixedLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">负债总计</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalLiabilities[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalLiabilities',i)?formatCurrency.formateNum(liabilities.totalLiabilities[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">
										所有者权益(或股东权益):
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>是指所有者向企业投入的资本总和。</p>
											</div>
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">实收资本(或股本)</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.paidUpCapital[i]"
											@blur="((ev)=>{blur(ev,liabilities,'paidUpCapital',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('paidUpCapital',i)?formatCurrency.formateNum(liabilities.paidUpCapital[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其他权益工具</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherEquity[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherEquity',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherEquity',i)?formatCurrency.formateNum(liabilities.otherEquity[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其中：优先股</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.equityPreferredStock[i]"
											@blur="((ev)=>{blur(ev,liabilities,'equityPreferredStock',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('equityPreferredStock',i)?formatCurrency.formateNum(liabilities.equityPreferredStock[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">永续债</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.equityPerpetuaDebt[i]"
											@blur="((ev)=>{blur(ev,liabilities,'equityPerpetuaDebt',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('equityPerpetuaDebt',i)?formatCurrency.formateNum(liabilities.equityPerpetuaDebt[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">资本公积</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.capitalReserve[i]"
											@blur="((ev)=>{blur(ev,liabilities,'capitalReserve',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('capitalReserve',i)?formatCurrency.formateNum(liabilities.capitalReserve[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">减：库存股</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.treasuryShare[i]"
											@blur="((ev)=>{blur(ev,liabilities,'treasuryShare',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('treasuryShare',i)?formatCurrency.formateNum(liabilities.treasuryShare[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其他综合收益</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.otherIncome[i]"
											@blur="((ev)=>{blur(ev,liabilities,'otherIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherIncome',i)?formatCurrency.formateNum(liabilities.otherIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">专项储备</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.appropriativeReserve[i]"
											@blur="((ev)=>{blur(ev,liabilities,'appropriativeReserve',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('appropriativeReserve',i)?formatCurrency.formateNum(liabilities.appropriativeReserve[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">盈余公积</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.earnedSurplus[i]"
											@blur="((ev)=>{blur(ev,liabilities,'earnedSurplus',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('earnedSurplus',i)?formatCurrency.formateNum(liabilities.earnedSurplus[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">未分配利润</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.undistributedProfit[i]"
											@blur="((ev)=>{blur(ev,liabilities,'undistributedProfit',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('undistributedProfit',i)?formatCurrency.formateNum(liabilities.undistributedProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">
										归属于母公司所有者权益合计
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>是指公司集团的所有者权益中归属于母公司所有者权益的部分。</p>
											</div>
										</span>
									</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalParentEquity[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalParentEquity',i)?formatCurrency.formateNum(liabilities.totalParentEquity[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">
										少数股东权益
										<span class="desc-container">
											<span></span>
											<div class="desc-main">
												<span></span>
												<p>是指母公司以外的的人拥有的权益，此项针对合并报表企业，如果无少数股东权益或非合并报表企业，则填零。</p>
											</div>
										</span>
									</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="liabilities.minorityEquity[i]"
											@blur="((ev)=>{blur(ev,liabilities,'minorityEquity',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('minorityEquity',i)?formatCurrency.formateNum(liabilities.minorityEquity[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">所有者权益（或股东权益)合计</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalOwnershipInterest[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalOwnershipInterest',i)?formatCurrency.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">负债和所有者权益(或股东权益)总计</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="liabilities.totalLiabilitiesEquity[i]" disabled />
										<span class="showInput">
											{{judgingCondition('totalLiabilitiesEquity',i)?formatCurrency.formateNum(liabilities.totalLiabilitiesEquity[i],2):'-'}}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="saveBox">
				<span class="last lastStep" @click="lastStep">上一步</span>
				<span class="save saveNext" id="saveButton" @click="saveButton">保 存</span>
			</div>
		</div>
		<Footer />
		<router-view />

		<Modal v-model="financialTips" class-name="vertical-center-modal financialTips" :mask-closable='false'>
			<p slot="header" class="examine-tip">
				<span>提示</span>
			</p>
			<img src="../../assets/img/remind-tip.png">
			<div>
				<p>请核对以下报表项目数据是否正确:</p>
				<p>应收账款</p>
				<p>存货</p>
				<p>固定资产净值</p>
			</div>
			<div slot="footer" class="financialBtn">
				<span @click="correction">返回修改</span>
				<span @click="sure">确认提交</span>
			</div>
		</Modal>


		<Modal v-model="dataDiscrepancy" class-name="vertical-center-modal" :mask-closable="false">
			<img class='warning' src="../../assets/img/remind-01@2x.png">
			<span>{{dataDiscrepancyMsg}}</span>
			<div slot="footer" class="ivu-sure" @click="determine">确定</div>
		</Modal>


		<!-- 缓存提示框 -->
		<Modal v-model="cacheAlert" class-name="vertical-center-modal" :mask-closable="false"
			@on-cancel="clickCacheMaskJump">
			<img class='warning-cache' src="../../assets/img/caution.png">
			<span class="cache-title">您上次填写过估值报告，是否载入缓存信息？</span>
			<div slot="footer" class="ivu-no" @click="clickCacheTrueMaskJump">是</div>
			<div slot="footer" class="ivu-sure-cache" @click="clickCacheMaskJump">否</div>
		</Modal>
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import navigation from './navigation.vue';
	import uploadData from './uploadData.vue';
	import financeNav from './financeNav.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import '../../assets/css/myGuZhi.css';
	import '../../assets/libs/js/jquery-1.11.1.min.js';
	import '../../assets/js/balanceSheet.js';


	export default {
		data() {
			return {
				yearList: [],
				status: 2, // 判断当前页面是否可以修改  1代表不能修改   (1 审核通过  2 未填写完成  3 填写完成未支付)
				id: '',
				url: '', // 保存数据的接口路径
				financialTips: false,
				myAlert: false, //上传模板的弹框
				success: false, //上传模板成功的弹框
				error: false, //上传模板错误的弹框
				dataDiscrepancy: false, //资产总计和负债和所有者权益(或股东权益)总计每年的数据不相同的弹框提示
				dataDiscrepancyMsg: '',
				reportId: '',
				baseDate: '',
				liabilities: {
					monetaryCapital: [],
					tradingAssets: [],
					derivativeAssets: [],
					billReceivable: [],
					receivables: [],
					accountReceivable: [],
					prepayment: [],
					otherReceivables: [],
					inventory: [],
					contractualAssets: [],
					saleForAssets: [],
					illiquidAssetsOne: [],
					otherCurrentAssets: [],
					debtInvestment: [],
					otherDebtInvestment: [],
					longAeceivables: [],
					longInvestment: [],
					otherEquityInvestment: [],
					otherIlliquidAssets: [],
					investmentProperty: [],
					fixedAssets: [],
					constructionProjects: [],
					productiveAsset: [],
					oilAndGasAssets: [],
					rightAssets: [],
					netWorth: [],
					developExpense: [],
					goodwill: [],
					longExpenses: [],
					deferredAssets: [],
					otherNonCurrentAssets: [],
					shortLoan: [],
					transactionLiabilities: [],
					derivativeLiabilities: [],
					notesPayable: [],
					accountsPayable: [],
					advanceReceipt: [],
					contractualLiabilities: [],
					payrollPayable: [],
					taxPayable: [],
					otherPayables: [],
					saleForLiabilities: [],
					illiquidLiabilitiesOne: [],
					otherNonLiabilities: [],
					longLoan: [],
					bondsPayable: [],
					bondsPreferredStock: [],
					bondsPerpetuaDebt: [],
					leaseLiabilities: [],
					longPayment: [],
					estimatedLiabilities: [],
					deferredIncome: [],
					deferredLiability: [],
					otherFixedLiabilities: [],
					paidUpCapital: [],
					otherEquity: [],
					equityPreferredStock: [],
					equityPerpetuaDebt: [],
					capitalReserve: [],
					treasuryShare: [],
					otherIncome: [],
					appropriativeReserve: [],
					earnedSurplus: [],
					undistributedProfit: [],
					totalParentEquity: [],
					minorityEquity: [],
					totalCurrentAssets: [], //流动资产合计
					totalNonCurrentAssets: [], //非流动资产合计
					totalAssets: [], //资产总计
					totalNonLiabilities: [], //流动负债合计
					totalFixedLiabilities: [], //非流动负债合计
					totalLiabilities: [], //负债总计
					totalOwnershipInterest: [], //所有者权益（或股东权益)合计
					totalLiabilitiesEquity: [] //负债和所有者权益(或股东权益)总计
				},
				projectName: '',
				cacheAlert: false
			}
		},
		components: {
			Header,
			Footer,
			navigation,
			uploadData,
			financeNav
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			var this_ = this;
			this.pageModify('false');
			if (this.$route.query.type == 5) {
				this.getTraceBalanceSheet()
			} else {
				this.getBalanceSheet()
			}

			this.$nextTick(function() {
				// this_.setTdStyle()
			})

			window.addEventListener('scroll', this.handleScroll);

		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		updated() {
			if (this.status == 1) {
				document.querySelector('.uploading').setAttribute("class", "forbidden"); //禁止上传模板
				if (this.$refs.forbid) {
					this.$refs.forbid.forEach((item, i) => {
						item.disabled = "true";
						item.style.backgroundColor = '#fff';
						item.nextSibling.style.backgroundColor = '#fff';
						item.nextSibling.style.pointerEvents = 'none';
					})
				}
			} else {
				if (this.$refs.forbid) {
					this.$refs.forbid.forEach((item, i) => {
						item.disabled = "";
					})
				}
			}
		},
		methods: {
			...mapMutations(['pageModify']),
			handleScroll() {
				const navbar = document.getElementsByClassName('unit')[0];
				const sticky = navbar.offsetTop;

				if (window.pageYOffset >= sticky) {
					$('.table thead').addClass('fixed-thead');

					var len = $('#tableOne tbody tr').eq(0).find('td').length
					for (var i = 0; i < len; i++) {
						var width = $('#tableOne tbody tr').eq(0).find('td').eq(i).innerWidth()
						$('#tableOne thead tr').eq(0).find('th').eq(i).width(i == 0 ? width - 5 : width)
					}

					for (var i = 0; i < len; i++) {
						var width = $('#tableTwo tbody tr').eq(0).find('td').eq(i).innerWidth()
						$('#tableTwo thead tr').eq(0).find('th').eq(i).width(width)
					}
				} else {
					$('.table thead').removeClass('fixed-thead');
				}

			},
			judgingCondition: function(key, i, index) {
				if (this.liabilities[key][i] === 0) {
					return true
				} else if (this.liabilities[key][i] != 0 && this.liabilities[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			blur: function(e, data, name, num) { //data：资产负债表的字段   name：具体的科目名称   num：科目的具体下标
				e.currentTarget.nextSibling.style.display = 'block';
				e.currentTarget.style.borderColor = '#D5D5D5';
				var y = String(data[name][num]).indexOf("."); //获取小数点的位置
				var count = String(data[name][num]).length - y; //获取小数点后的个数
				this.pageModify('true');
				if (data[name][num]) {
					if (String(data[name][num]).indexOf(".") != -1) {
						this.$set(this.liabilities[name], num, Math.round(Number(data[name][num]) * 100) / 100) //保留两位
					} else {
						if (data[name][num].length > 15) { //判断数据的合理性
							this.$Message.error({
								background: true,
								content: '请输入合理数据'
							});
							this.$set(this.liabilities[name], num, '')
						} else {
							this.$set(this.liabilities[name], num, Number(data[name][num]))
						}
					}
				} else {
					this.$set(this.liabilities[name], num, '')
				}

				for (var i = 0; i < this.yearList.length; i++) {
					// 流动资产合计
					this.liabilities.totalCurrentAssets[i] = Number(this.liabilities.monetaryCapital[i]) + Number(this
							.liabilities
							.tradingAssets[i]) + Number(this.liabilities.derivativeAssets[i]) + Number(this.liabilities
							.billReceivable[i]) +
						Number(this.liabilities.receivables[i]) + Number(this.liabilities.accountReceivable[i]) +
						Number(this.liabilities
							.prepayment[i]) + Number(this.liabilities.otherReceivables[i]) + Number(this.liabilities
							.inventory[i]) + Number(this
							.liabilities.contractualAssets[i]) + Number(this.liabilities.saleForAssets[i]) + Number(
							this.liabilities
							.illiquidAssetsOne[i]) + Number(this.liabilities.otherCurrentAssets[i]);

					// 非流动资产:
					this.liabilities.totalNonCurrentAssets[i] = Number(this.liabilities.debtInvestment[i]) + Number(
							this.liabilities
							.otherDebtInvestment[i]) + Number(this.liabilities.longAeceivables[i]) + Number(this
							.liabilities
							.longInvestment[i]) + Number(this.liabilities.otherEquityInvestment[i]) + Number(this
							.liabilities
							.otherIlliquidAssets[i]) + Number(this.liabilities.investmentProperty[i]) + Number(this
							.liabilities
							.fixedAssets[i]) + Number(this.liabilities.constructionProjects[i]) + Number(this
							.liabilities.productiveAsset[
								i]) + Number(this.liabilities.oilAndGasAssets[i]) + Number(this.liabilities
							.rightAssets[i]) + Number(this
							.liabilities.netWorth[i]) + Number(this.liabilities.developExpense[i]) + Number(this
							.liabilities.goodwill[i]) +
						Number(this.liabilities.longExpenses[i]) + Number(this.liabilities.deferredAssets[i]) + Number(
							this.liabilities
							.otherNonCurrentAssets[i]);

					// 资产总计
					this.liabilities.totalAssets[i] = Number(this.liabilities.totalCurrentAssets[i]) + Number(this
						.liabilities.totalNonCurrentAssets[i]);

					// 流动负债合计
					this.liabilities.totalNonLiabilities[i] = Number(this.liabilities.shortLoan[i]) + Number(this
							.liabilities
							.transactionLiabilities[i]) + Number(this.liabilities.derivativeLiabilities[i]) + Number(
							this.liabilities
							.notesPayable[i]) + Number(this.liabilities.accountsPayable[i]) + Number(this.liabilities
							.advanceReceipt[i]) +
						Number(this.liabilities.contractualLiabilities[i]) + Number(this.liabilities.payrollPayable[
							i]) + Number(this
							.liabilities.taxPayable[i]) + Number(this.liabilities.otherPayables[i]) + Number(this
							.liabilities
							.saleForLiabilities[i]) + Number(this.liabilities.illiquidLiabilitiesOne[i]) + Number(this
							.liabilities
							.otherNonLiabilities[i]);

					// 非流动负债合计
					this.liabilities.totalFixedLiabilities[i] = Number(this.liabilities.longLoan[i]) + Number(this
						.liabilities
						.bondsPayable[i]) + Number(this.liabilities.bondsPreferredStock[i]) + Number(this
						.liabilities
						.bondsPerpetuaDebt[i]) + Number(this.liabilities.leaseLiabilities[i]) + Number(this
						.liabilities.longPayment[
							i]) + Number(this.liabilities.estimatedLiabilities[i]) + Number(this.liabilities
						.deferredIncome[i]) + Number(this
						.liabilities.deferredLiability[i]) + Number(this.liabilities.otherFixedLiabilities[i]);

					// 负债总计
					this.liabilities.totalLiabilities[i] = Number(this.liabilities.totalNonLiabilities[i]) + Number(
						this.liabilities.totalFixedLiabilities[i]);

					// 归属于母公司所有者权益合计
					this.liabilities.totalParentEquity[i] = Number(this.liabilities.paidUpCapital[i]) + Number(this
						.liabilities
						.otherEquity[i]) + Number(this.liabilities.equityPreferredStock[i]) + Number(this
						.liabilities
						.equityPerpetuaDebt[i]) + Number(this.liabilities.capitalReserve[i]) - Number(this
						.liabilities.treasuryShare[
							i]) + Number(this.liabilities.otherIncome[i]) + Number(this.liabilities
						.appropriativeReserve[i]) + Number(this.liabilities.earnedSurplus[i]) + Number(this
						.liabilities
						.undistributedProfit[i]);

					// 所有者权益（或股东权益)合计
					this.liabilities.totalOwnershipInterest[i] = Number(this.liabilities.totalParentEquity[i]) +
						Number(this.liabilities.minorityEquity[i]);

					// 负债和所有者权益(或股东权益)总计
					this.liabilities.totalLiabilitiesEquity[i] = Number(this.liabilities.totalLiabilities[i]) + Number(
						this.liabilities.totalOwnershipInterest[i]);

				}

				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0') || (this.$route
							.query.reportId && !this.id)) {
						this.method.caching("liabilitiesInfo", JSON.stringify(this.liabilities), this.$route.query.type, this
							.$route.query.stage, this)
					}
				}

			},
			focus: function(e) {

			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			lastStep: function() {
				if (this.$route.query.reportId) {
					if (this.$route.query.type == 5) {
						this.$router.push({
							path: '/softInformation',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId,
								traceReportId: this.$route.query.traceReportId,
								year: this.$route.query.year
							}
						})
					} else {
						this.$router.push({
							path: '/softInformation',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					}
				} else {
					this.$router.push({
						path: '/softInformation',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}
					})
				}
			},
			setTdStyle: function() {
				if (this.yearList.length == 2) {
					// $('.mobileBalanceSheet tr:not(.courseTitle )').find("input[data-type='number']").css({
					// 	'width':'100%'
					// })
					// $('.mobileBalanceSheet tr:not(.courseTitle )').find('span.showInput').css({
					// 	'width':'136px'
					// })
					$('.mobileBalanceSheet tr:not(.courseTitle )').find("td").css({
						'background': 'pink'
					})
					console.log('length==2')
					// $('.mobileBalanceSheet').css('background','pink')

				}
				console.log($('.mobileBalanceSheet'))
			},
			getBalanceSheet: function(close) {
				this.$Spin.show(); //全局加载中
				var this_ = this;
				var url = '';
				if (this.$route.query.type == 7 && sessionStorage.versions == 3) {
					url = this.projectName + 'financialLiabilitiesDetail/qryLiabilitesInfoV3';
				} else {
					url = this.projectName + 'financialLiabilitiesDetail/qryLiabilitesInfo';
				}
				this.$post(url, qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						reportType: this.$route.query.type,
						versions: sessionStorage.versions,
						stage: this.$route.query.stage,
					}))
					.then((res) => {
						this.$Spin.hide();
						var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
						if (this.$route.query.type == 5) {
							for (var i = 0; i < res.data.content.year.length; i++) {
								res.data.content.year[i] = res.data.content.year[i].replace("年", "-12-31");
							}
						}
						this.yearList = res.data.content.year;
						this.id = res.data.content.id;
						this.baseDate = res.data.content.baseDate
						this.contentLiabilities = res.data.content.liabilities
						this.isCacheValue = res.data.content.isCache
						if (this.isCacheValue == '0' && close == '0') {
							this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this)
						}
						if (res.data.content.liabilities) {
							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.cacheAlert = true;
								// 清除 0.00
								var dataKey = Object.keys(this.liabilities); //获取对象的 key
								dataKey.forEach((key, idx) => {
									this.yearList.forEach((item, i) => {
										this.liabilities[key].push("")
									})
								})

							} else { //正式数据
								var returnliabilitiesKey = Object.keys(JSON.parse(res.data.content
									.liabilities)); //后台数据返回的资产负债表对象的 key
								liabilitiesKey.forEach((elem, index) => {
									returnliabilitiesKey.forEach((item, i) => {
										this_.yearList.forEach((yearItem, y) => {
											if (elem == item) {
												this_.liabilities[elem][y] = JSON.parse(
													res.data.content.liabilities)[
													elem][y];
											} else if (!this_.liabilities[elem]) {
												if (this_.yearList.length == 2) {
													this_.liabilities[elem] = ['', '']
												} else if (this_.yearList.length == 3) {
													this_.liabilities[elem] = ['', '', '']
												}
											}
										})
									})
								})
							}
							console.log(this.liabilities)
						} else {
							var dataKey = Object.keys(this.liabilities); //获取对象的 key
							dataKey.forEach((key, idx) => {
								this.yearList.forEach((item, i) => {
									this.liabilities[key].push("")
								})
							})
						}

					});


			},
			getTraceBalanceSheet: function() { //价值追踪没有数据也会返回年份
				var this_ = this;
				this.$post(this.projectName + 'financialLiabilitiesDetail/qryLiabilitesInfo', qs.stringify({
						reportId: this.$route.query.reportId,
						traceReportId: this.$route.query.traceReportId,
						year: this.$route.query.year
					}))
					.then((res) => {
						console.log(res.data)
						for (var i = 0; i < res.data.content.financialLiabilitiesInfo.year.length; i++) {
							res.data.content.financialLiabilitiesInfo.year[i] = res.data.content
								.financialLiabilitiesInfo.year[i].replace("年", "-12-31");
						}
						this.yearList = res.data.content.financialLiabilitiesInfo.year;
						this.status = res.data.content.traceRelation.status;
						this.id = res.data.content.financialLiabilitiesInfo.id;
						var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key

						if (res.data.content.financialLiabilitiesInfo.liabilities) {
							var returnliabilitiesKey = Object.keys(JSON.parse(res.data.content
								.financialLiabilitiesInfo.liabilities)); //后台数据返回的资产负债表对象的 key
							liabilitiesKey.forEach((elem, index) => {
								returnliabilitiesKey.forEach((item, i) => {
									this_.yearList.forEach((yearItem, y) => {
										if (elem == item) {
											this_.liabilities[elem][y] = JSON.parse(res
												.data.content.financialLiabilitiesInfo
												.liabilities)[
												elem][y];
										} else if (!this_.liabilities[elem]) {
											if (this_.yearList.length == 2) {
												this_.liabilities[elem] = ['', '']
											} else if (this_.yearList.length == 3) {
												this_.liabilities[elem] = ['', '', '']
											}
										}
									})
								})
							})

						} else {
							var dataKey = Object.keys(this.liabilities); //获取对象的 key
							dataKey.forEach((key, idx) => {
								this.yearList.forEach((item, i) => {
									this.liabilities[key].push("")
								})
							})
						}

					});
			},
			determine: function() {
				this.dataDiscrepancy = false;
			},
			saveButton: function() {
				if (this.status == 1) {
					this.$router.push({
						path: '/profitStatement',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type,
							reportId: this.$route.query.reportId,
							traceReportId: this.$route.query.traceReportId,
							year: this.$route.query.year
						}
					})
				} else {
					var regex = /(\d+)年(\d+)月/;
					var match = this.baseDate.match(regex);

					if (this.$route.query.type == 2) {
						if (this.liabilities.totalAssets[0] != this.liabilities.totalLiabilitiesEquity[0]) {
							this.dataDiscrepancyMsg = '请核实' + this.yearList[0].substring(0, 4) + '年数据'
							this.dataDiscrepancy = true;
						} else if (this.liabilities.totalAssets[1] != this.liabilities.totalLiabilitiesEquity[1]) {
							this.dataDiscrepancyMsg = '请核实' + this.yearList[1].substring(0, 4) + '年数据'
							this.dataDiscrepancy = true;
						} else if (this.liabilities.totalAssets[2] != this.liabilities.totalLiabilitiesEquity[2]) {
							this.dataDiscrepancyMsg = '请核实' + this.yearList[2].substring(0, 4) + '年数据'
							this.dataDiscrepancy = true;
						} else if (match[2] == 12) {  //当基准日是12月时，最后两年必须填，否则前两年必须填
							if (!this.liabilities.totalAssets[1] || this.liabilities.totalAssets[1] == 0) {
								this.dataDiscrepancyMsg = "请准确填写" + this.yearList[1] + "数据"
								this.dataDiscrepancy = true;
							} else if (!this.liabilities.totalAssets[2] || this.liabilities.totalAssets[2] == 0) {
								this.dataDiscrepancyMsg = "请准确填写" + this.yearList[2] + "数据"
								this.dataDiscrepancy = true;
							}else{
								this.financialTips = true;
							}
						} else if (match[2] != 12) {
							if (!this.liabilities.totalAssets[0] || this.liabilities.totalAssets[0] == 0) {
								this.dataDiscrepancyMsg = "请准确填写" + this.yearList[0] + "数据"
								this.dataDiscrepancy = true;
							} else if (!this.liabilities.totalAssets[1] || this.liabilities.totalAssets[1] == 0) {
								this.dataDiscrepancyMsg = "请准确填写" + this.yearList[1] + "数据"
								this.dataDiscrepancy = true;
							}else{
								this.financialTips = true;
							}

						} else {
							this.financialTips = true;
						}
					} else if (this.$route.query.type == 7) {
						if (this.liabilities.totalAssets[1] != this.liabilities.totalLiabilitiesEquity[1]) {
							this.dataDiscrepancyMsg = '请核实' + this.yearList[1].substring(0, 4) + '年数据'
							this.dataDiscrepancy = true;
						} else {
							this.financialTips = true;
						}
					} else {
						this.financialTips = true;
					}
				}
			},
			sure: function() {
				console.log(this.liabilities)
				var this_ = this;
				
				this.liabilities.year=this.yearList
				var params = {
					reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					stage: this.$route.query.stage,
					id: this.id,
					liabilitiesInfo: JSON.stringify(this.liabilities)
				};

				if (this.$route.query.type == 5) {
					params.traceReportId = this.$route.query.traceReportId;
					params.year = this.$route.query.year;
					this.url = 'trace/financialLiabilitiesDetail/save';
				} else {
					params.reportType = this.$route.query.type;
					params.versions = sessionStorage.versions;
					if (this.$route.query.type == 7 && sessionStorage.versions == 3) {
						this.url = this.projectName + 'financialLiabilitiesDetail/saveV3';
					} else {
						this.url = this.projectName + 'financialLiabilitiesDetail/save';
					}
				}

				this.$post(this.url, qs.stringify(params))
					.then((res) => {
						if (res.data.code == 200) {
							this.financialTips = false;
							if (this.$route.query.type == 5) {
								setTimeout(function() {
									this_.$router.push({
										path: '/profitStatement',
										query: {
											stage: this_.$route.query.stage,
											type: this_.$route.query.type,
											reportId: this_.$route.query.reportId,
											traceReportId: this_.$route.query.traceReportId,
											year: this_.$route.query.year
										}
									})
								}, 500)
							} else {
								setTimeout(function() {
									this_.$router.push({
										path: '/profitStatement',
										query: {
											stage: this_.$route.query.stage,
											type: this_.$route.query.type,
											reportId: res.data.content.valuationReportId
										}
									})
								}, 500)
							}
						}
					});
			},
			correction: function() {
				var tipTerm = document.querySelectorAll('.tipTerm input')
				var this_ = this;
				this.financialTips = false;
				tipTerm.forEach(function(item, i) {
					item.style.borderColor = 'red';
				})
				tipTerm[0].focus()
			},
			uploadInformation: function() {
				this.myAlert = true;
			},
			uploadFiles: function(e) {
				var formData = new FormData();
				formData.append("file", e.target.files[0]);
				formData.append('reportId', this.$route.query.reportId ? this.$route.query.reportId : '');
				formData.append("stage", sessionStorage.getItem("stage"));
				if (sessionStorage.reportType == 7) {
					formData.append("reportType", sessionStorage.getItem("reportType"));
				}
				formData.append("versions", sessionStorage.getItem("versions") ? sessionStorage.getItem("versions") :
					1);
				this.$post(this.projectName + 'upload/uploadFile', formData)
					.then((res) => {
						if (res.data.code == 200) {
							this.myAlert = false;
							this.reportId = res.data.content;
							if (res.data.content != "非法的excel" && res.data.content != "模板已更新，请重新下载最新模板再上传！") {
								this.success = true;
							} else if (res.data.content == "非法的excel") {
								this.error = true;
							}
						}
					})
			},
			submitDialog: function() {
				this.success = false;
				this.$router.push({
					path: '/balanceSheet',
					query: {
						stage: sessionStorage.stage,
						type: sessionStorage.reportType,
						reportId: this.reportId
					}
				})
				this.$router.go(0)
			},
			wrongBtn: function() {
				this.error = false;
			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.getBalanceSheet();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this, 'getBalanceSheet')
				// this.getBalanceSheet();
			}
		}

	}
</script>

<style scoped>
	.mobileBalanceSheet {
		display: none;
	}

	.mobileBalanceSheet>ul li,
	.mobileBalanceSheet table td {
		font-size: 14PX;
	}

	.mobileBalanceSheet table {
		margin-top: 20px;
	}

	.mobileBalanceSheet .courseBold td {
		font-weight: bold;
	}

	.major-balance table>tbody>tr.trThtrr>td:first-of-type {
		padding-left: 53px;
	}

	.major-balance table>tbody>tr.trFour>td:first-of-type {
		padding-left: 94px;
	}

	@media screen and (max-width: 750px) {

		.table-container,
		.down-up-container {
			display: none;
		}

		.mobileBalanceSheet {
			display: block;
		}

		div.table-name>span {
			font-size: 16PX;
		}

		span.unit {
			font-size: 12PX;
		}

		.desc-container>span {
			width: 20px;
			height: 20px;
			background-size: 100%;
		}

		.desc-container .desc-main>p {
			font-size: 12PX;
		}
	}
</style>