import { render, staticRenderFns } from "./diagnoseSummary.vue?vue&type=template&id=fec5f9a6&scoped=true"
import script from "./diagnoseSummary.vue?vue&type=script&lang=js"
export * from "./diagnoseSummary.vue?vue&type=script&lang=js"
import style0 from "./diagnoseSummary.vue?vue&type=style&index=0&id=fec5f9a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fec5f9a6",
  null
  
)

export default component.exports