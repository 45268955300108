<template>
	<div>
		<Header />
		<uploadData />
		<navigation />
		<div class="guzhi_content">
			<p class="moduleName">
				<img src="../../assets/img/navIcon/guzhiReport_title.png" alt="心流">
				<span>{{this.$route.query.type==5?'价值追踪估值报告':this.$route.query.type==7?'诊断报告':'估值报告'}}</span>
			</p>
			<div class="moduleMain">
				<img src="../../assets/img/navIcon/guzhiReport_con.png" alt="线上估值-心流" />
				<div class="right_content">
					<div v-if="this.$route.query.type!=5&&finish">
						<!--上部标题-->
						<div class="right_top">
							<p class="report-type" v-if="this.$route.query.type!=5">
								<img src="../../assets/img/navIcon/icon_is_compelete.png" alt="线上估值-心流" />
								<span>{{this.$route.query.type==7?'企业价值诊断':this.$route.query.type==5?'企业价值追踪':'企业价值量化'}}</span>
							</p>
							<p class="report-cost" v-if="auditStatus==null">¥<span>{{price}}</span></p>
						</div>
						<div class="right_middle">
							<div class="middle middle_left" v-if="this.$route.query.type==7">
								<p>
									<span><img src="../../assets/img/guzhi/icon_valution_type_items.png">专业诊断报告</span>
								</p>
								<p>
									<span><img src="../../assets/img/guzhi/icon_valution_type_items.png">公司基本情况概览</span>
								</p>
								<p>
									<span><img src="../../assets/img/guzhi/icon_valution_type_items.png">六大维度全方位对企业综合评价</span>
								</p>
								<p>
									<span><img src="../../assets/img/guzhi/icon_valution_type_items.png">一键生成报告</span>
								</p>
							</div>
							<div class="middle middle_left" v-if="this.$route.query.type==2">
								<p>
									<span><img src="../../assets/img/guzhi/icon_valution_type_items.png">完整的财务预测</span>
								</p>
								<p>
									<span><img src="../../assets/img/guzhi/icon_valution_type_items.png">公司基本情况分析</span>
								</p>
								<p>
									<span>
										<img src="../../assets/img/guzhi/icon_valution_type_items.png">相对估值和绝对估值法相结合
									</span>
								</p>
								<p>
									<span><img src="../../assets/img/guzhi/icon_valution_type_items.png">可比公司分析</span>
								</p>
							</div>
							<div class="middle middle_right" v-if="this.$route.query.type==2">
								<p>
									<span>
										<img src="../../assets/img/guzhi/icon_valution_type_items.png">专业估值与建议
									</span>
								</p>
								<p>
									<span><img src="../../assets/img/guzhi/icon_valution_type_items.png">估值原理说明</span>
								</p>
								<p>
									<span><img src="../../assets/img/guzhi/icon_valution_type_items.png">一键生成报告</span>
								</p>
							</div>

						</div>
					</div>
					<div class="complete_info" v-if="this.$route.query.type!=5&&!finish">
						<h4>
							<img src="../../assets/img/navIcon/icon_is_compelete.png" alt="线上估值-心流" />
							<span>完善估值信息</span>
						</h4>
						<p>清晰详细的公司{{this.$route.query.type!=7?'估值':'诊断'}}报告是向潜在投资者，买家，利益相关者或员工传达您价值的最佳方式。</p>
						<p>您的资料未填写完成</p>
					</div>

					<div v-if="this.$route.query.type==5">
						<div class="right_top">
							<p class="report-type">
								<img src="../../assets/img/navIcon/icon_is_compelete.png" alt="线上估值-心流" />
								<span>企业价值追踪（{{title}})</span>
							</p>
						</div>
						<div class="right_middle">
							<div class="middle middle_left">
								<p>
									<span>
										<img src="../../assets/img/guzhi/icon_valution_type_items.png">股权价值动态追踪
									</span>
								</p>
								<p>
									<span>
										<img src="../../assets/img/guzhi/icon_valution_type_items.png">综合竞争力跟踪对比
									</span>
								</p>
								<p>
									<span>
										<img src="../../assets/img/guzhi/icon_valution_type_items.png">行业价值比率实时更新
									</span>
								</p>
							</div>
							<div class="middle middle_right">
								<p>
									<span>
										<img src="../../assets/img/guzhi/icon_valution_type_items.png">专精特新各个维度变化综合展示
									</span>
								</p>
								<p>
									<span>
										<img src="../../assets/img/guzhi/icon_valution_type_items.png">财务状况可视化解析
									</span>
								</p>
								<p>
									<span>
										<img src="../../assets/img/guzhi/icon_valution_type_items.png">盈利预期变化动态模拟
									</span>
								</p>
							</div>
						</div>
						<p class="no-finish" v-if="!finish">您的资料未填写完成</p>
						<div class="pay-container" v-if="finish&&auditStatus!='0'">
							<p class="pay-main">选择报告：
								<span>价值追踪报告</span>
							</p>
							<p class="pay-money">价格：
								<span class="costicon">￥</span>
								<span class="cost">{{price}}</span>
							</p>
						</div>
					</div>
					<!--底部按钮-->
					<div class="bottom_btns">
						<div v-if="auditStatus==null||auditStatus==''" class="btn btn_left" :data-href="hrefHtml"
							@click="btn_right">
							{{finish?'立即支付':'继续填写'}}
						</div>
						<div v-if="auditStatus==0&&auditStatus!=null&&auditStatus!=''" class="btn btn_left btn_examineFailed"
							@click="examineFailed" v-preventReClick="5000">
							修改完成重新提交审核
						</div>
					</div>


				</div>

			</div>
		</div>

		<Modal v-model="resubmitAlert" class-name="vertical-center-modal examineModa" @on-ok="ok" v-preventReClick="5000"
			:mask-closable="false">
			<p slot="header" class="examine-tip">
				<span>提示</span>
			</p>
			<img class='warning' src="../../assets/img/remind-tip.png">
			<p>确认修改完成，重新提交审核？</p>
		</Modal>
		<Modal v-model="paySuccess" class-name="vertical-center-modal paySuccess" @on-visible-change="modelPaySuccess"
			:mask-closable="false">
			<p slot="header">
				<img src="../../assets/img/guzhi/icon_pay.png" alt="付款-心流">
				<span>付款</span>
			</p>
			<img src="../../assets/img/guzhi/icon_pay_success.png" alt="付款成功-心流">
			<div class="hint">
				<p>恭喜您，付款成功！估值报告审核中，请耐心等待</p>
				<p>审核人员会在一个工作日内反馈审核结果。</p>
				<p>如有疑问请拨打客服电话：+86 (10)8444-6599</p>
			</div>
			<div slot="footer" class="ivu-sure" @click="jumpLists">确定</div>
		</Modal>

		<Modal v-model="payment" class-name="vertical-center-modal payment" @on-visible-change="modelSaveclose"
			:mask-closable="false">
			<p slot="header">
				<img src="../../assets/img/guzhi/icon_pay.png" alt="付款-心流">
				<span>付款</span>
			</p>
			<div class="hint">
				<!--报告类型-->
				<div class="type report_style">
					<img src="../../assets/img/guzhi/icon_report_style.png" alt="线上估值-心流" />
					<p>报告类型：{{this.$route.query.type==7?'企业价值量化':this.$route.query.type==5?'企业价值追踪':'企业价值量化'}}</p>
				</div>
				<!--报告价格-->
				<div class="type report_price">
					<img src="../../assets/img/guzhi/icon_price.png" alt="线上估值-心流" />
					<p>报告价格：<span>¥<span>{{price}}</span></span>
					</p>
				</div>
				<!--是否开发票-->
				<div class="type is_invoice">
					<img src="../../assets/img/guzhi/icon_isNeed_check.png" alt="线上估值的发票-心流" />
					<p>开发票:</p>
					<div class="radio yes" data-invoiceFlag="1">
						<input type='radio' id="yes-radio" name="" :checked="!noInvoice?'checked':''" @click="yesRadio" />
						<label for="yes-radio">是</label>
					</div>
					<div class="radio no" data-invoiceFlag="1">
						<input type='radio' id="no-radio" name="" :checked="noInvoice?'checked':''" @click="noRadio" />
						<label for="no-radio">否</label>
					</div>
				</div>
				<!--发票信息-->
				<div class="check_info" v-if="!noInvoice">
					<div class="check_title">
						<span></span>
						<span>电子普通发票</span>
						<span>（如需纸质普通发票或专用发票请联系客服，客服电话：+86 (10)8444-6599）</span>
					</div>
					<div class="needed invoice-title">
						<span>发票抬头</span>
						<input type="text" name="" id="invoice-title" value="" v-model="invoiceTitle" />
					</div>
					<div class="needed invoice-title">
						<span>纳税人识别号</span>
						<input type="text" name="" id="taxpayer-num" value="" v-model="taxpayerNum" />
					</div>
					<div class="needed invoice-title">
						<span>电子邮件</span>
						<input type="text" name="" id="email" value="" placeholder="推荐使用QQ邮箱" v-model="email" />
					</div>
					<div class="needed invoice-title">
						<span>手机号</span>
						<input type="text" name="" id="mobile" value="" placeholder="" v-model="phoneNo" />
					</div>
				</div>

			</div>
			<div slot="footer" class="pay_now" @click="saveInvoiceInfo">立即付款</div>
		</Modal>
		<Modal v-model="paymentCode" class-name="vertical-center-modal payment" :mask-closable="false"
			@on-visible-change="codeAlert">
			<p slot="header">
				<img src="../../assets/img/guzhi/icon_pay.png" alt="付款-心流">
				<span>付款</span>
			</p>
			<div class="qrcode" ref="qrCodeUrl"></div>
			<p style="text-align: center;">使用支付宝、微信扫码支付</p>
			<div class="payIcon">
				<img src="../../assets/img/guzhi/icon_alipay.png">
				<img src="../../assets/img/guzhi/icon_wechat.png">
			</div>
			<div slot="footer"></div>
		</Modal>



		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import navigation from './navigation.vue';
	import uploadData from './uploadData.vue';
	import qs from 'qs';
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import QRCode from 'qrcodejs2'
	import API_CONFIG from "../../../vue.config.js"

	export default {
		data() {
			return {
				finish: false, //必填项是否全部填写完成
				price: '', //价格
				auditStatus: null, //审核状态0：审核不通过 ， 1：审核通过 ， 2：审核中
				isTestAccount: null, //测试账号免支付
				title: '',
				tpPageFlag: false, //是否已经存在继续填写的页面(价值追踪)
				hrefHtml: '', //未填写完的跳转路径
				checkObj: [],
				resubmitAlert: false, //重新提交审核弹框
				paySuccess: false, //付款成功后的弹框
				payment: false, // 付款信息弹框
				paymentCode: false, //支付的二维码弹框
				noInvoice: false, //false 开发票   true 不开发票
				invoiceTitle: '', //发票抬头
				taxpayerNum: '', //纳税人识别号
				email: '', //电子邮件
				phoneNo: '', //手机号
				invoiceId: '', //发票id
				timer: null,
				projectName: ''
			}
		},
		components: {
			Header,
			Footer,
			navigation,
			uploadData
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			this.$Spin.show(); //全局加载中
			this.pageModify('false');
			if (this.$route.query.type == 5) {
				this.initTracePages();
			} else {
				this.showPageInfo();
			}
			this.getReportPrice();
			this.$Message.config({
				top: 200
			});

		},
		methods: {
			...mapMutations(['pageModify']),
			showPageInfo: function() {
				this.$post(this.projectName + 'pay/getToPayReportInfo', qs.stringify({
						pageType: 2,
						stage: sessionStorage.stage,
						reportId: this.$route.query.reportId
					}))
					.then((res) => {
						this.$Spin.hide(); //全局加载中
						if (res.data.content.check) {
							this.auditStatus = res.data.content.auditStatus;
							this.isTestAccount = res.data.content.isTestAccount
							var check = JSON.parse(res.data.content.check);
							console.log(check)

							if (check.baseInfoFlag == true && check.questionInfoFlag == true && check.liabilitiesInfoFlag ==
								true && check.profitInfoFlag == true) { //必填项都填写完成
								this.finish = true;
							} else {
								this.finish = false;
								if (!check.baseInfoFlag) { //基本信息
									this.hrefHtml = '/basicInformation';
								} else if (!check.questionInfoFlag) { //软性指标
									this.hrefHtml = '/softInformation';
								} else if (!check.liabilitiesInfoFlag) { //资产负债表
									this.hrefHtml = '/balanceSheet';
								} else if (!check.profitInfoFlag) { //利润表
									this.hrefHtml = '/profitStatement';
								}
								console.log(this.hrefHtml)
							}


						} else {
							this.hrefHtml = '/basicInformation';
						}


					});
			},
			initTracePages: function() {
				this.$post(this.projectName + 'pay/getToPayReportInfo', qs.stringify({
						pageType: 2,
						stage: 2,
						traceReportId: this.$route.query.traceReportId
					}))
					.then((res) => {
						this.$Spin.hide(); //全局加载中
						console.log(res.data)
						var data = res.data.content;
						this.auditStatus = data.traceReportAuditStatus;
						this.isTestAccount = res.data.content.isTestAccount
						for (var key in data) { //设置小标题的年份
							if (data[key] && data[key].check) {
								this.title += key + "年、";
								this.checkObj.push(JSON.parse(data[key].check))
							}
						}
						this.title = this.title.substring(0, this.title.length - 1);
						this.requiredFields()

					});
			},
			requiredFields: function(data) { //所有年份的必填项都填写完成（价值追踪）
				if (this.checkObj.every(checkObj => checkObj.baseInfoFlag == true)) { // 基本信息
					this.finish = true;
				} else {
					this.finish = false;
					this.hrefHtml = '/basicInformation';
					return false;
				}

				if (this.checkObj.every(checkObj => checkObj.questionInfoFlag == true)) { // 软性指标
					this.finish = true;
				} else {
					this.finish = false;
					this.hrefHtml = '/softInformation';
					return false;
				}

				if (this.checkObj.every(checkObj => checkObj.liabilitiesInfoFlag == true)) { // 资产负债表
					this.finish = true;
				} else {
					this.finish = false;
					this.hrefHtml = '/balanceSheet';
					return false;
				}
				if (this.checkObj.every(checkObj => checkObj.profitInfoFlag == true)) { // 利润表
					this.finish = true;
				} else {
					this.finish = false;
					this.hrefHtml = '/profitStatement';
					return false;
				}

			},
			getReportPrice: function() { //获取价格
				this.$post(this.domain.ajaxUrl + 'pay/getReportPrice', qs.stringify({
						reportId: this.$route.query.traceReportId ? this.$route.query.traceReportId : this
							.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.price = res.data.content;
					});
			},
			btn_right: function() {
				if (!this.finish) { //没有填写完成
					if (this.$route.query.type == 5) {
						this.$router.push({
							path: this.hrefHtml,
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId,
								traceReportId: this.$route.query.traceReportId,
								year: this.$route.query.year
							}
						})
					} else {
						this.$router.push({
							path: this.hrefHtml,
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					}
				} else { //必填项填写完成进行付款
					if (this.isTestAccount == true) { //测试账号免支付
						this.noPayment()
					} else {
						this.payment = true;
						this.queryInvoice();
					}

				}
			},
			noPayment: function() { //测试账号免支付   isTestAccount = true
				this.$post(this.domain.ajaxUrl + 'pay/testAccountPay', qs.stringify({
						reportId: this.$route.query.traceReportId ? this.$route.query.traceReportId : this
							.$route.query.reportId
					}))
					.then((res) => {
						this.paySuccess = true;
						if (this.$route.query.type == 5) {
							this.createTraceReport()
						}
					});
			},
			examineFailed: function() { //重新提交审核
				this.resubmitAlert = true;
			},
			ok: function() {
				this.$post(this.projectName + 'auditInfo/againAudit', qs.stringify({
						reportId: this.$route.query.traceReportId ? this.$route.query.traceReportId : this
							.$route.query.reportId
					}))
					.then((res) => {
						if (this.$route.query.type == 5) {
							this.createTraceReport()
						} else {
							this.jumpLists()
						}
					});
			},
			createTraceReport: function() { // 生成价值追踪报告
				this.$post(this.projectName + 'traceReport/createTraceReport', qs.stringify({
						reportId: this.$route.query.traceReportId,
						stage: this.$route.query.stage
					}))
					.then((res) => {
						console.log("生成价值追踪报告成功")
						this.jumpLists()
					});
			},
			jumpLists: function() {
				var this_ = this;
				this.paySuccess = false;
				setTimeout(function() {
					window.location.href = this.domain.testUrl + 'pages/myValuation.html';
				}, 500)
			},
			yesRadio: function() { //开发票
				this.noInvoice = false;
			},
			noRadio: function() { //不开发票
				this.noInvoice = true;
			},
			queryInvoice: function() { //查询发票信息
				this.$Spin.show(); //全局加载中
				this.$post(this.projectName + 'invoice/queryInvoiceByReportId', qs.stringify({
						valuationReportId: this.$route.query.traceReportId ? this.$route.query.traceReportId : this.$route
							.query.reportId
					}))
					.then((res) => {
						this.$Spin.hide();
						if (res.data.content) {
							this.invoiceTitle = res.data.content.invoiceTitle;
							this.taxpayerNum = res.data.content.taxpayerNum;
							this.email = res.data.content.email;
							this.phoneNo = res.data.content.phoneNo;
							this.invoiceId = res.data.content.id;
						}
					});
			},
			saveInvoiceInfo: function() { //保存发票信息
				var emailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/; //邮箱
				var phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/; //电话
				if (!this.noInvoice) {
					var params = {
						valuationReportId: this.$route.query.traceReportId ? this.$route.query.traceReportId : this
							.$route.query.reportId,
						invoiceTitle: this.invoiceTitle,
						taxpayerNum: this.taxpayerNum,
						email: this.email,
						phoneNo: this.phoneNo,
						id: this.invoiceId,
						invoiceFlag: !this.noInvoice ? '1' : '0' //1开发票   0不开发票
					}

					if (!this.invoiceTitle) {
						this.$Message.info('请输入发票抬头', 5);
						return false
					}
					if (!this.taxpayerNum) {
						this.$Message.info('请输入纳税人识别号', 5);
						return false
					}
					if (!this.email) {
						this.$Message.info('请输入电子邮件', 5);
						return false
					} else {
						if (!emailReg.test(this.email)) {
							this.$Message.info('请输入电子邮件', 5);
							return false
						}
					}
					if (!this.phoneNo) {
						this.$Message.info('请输入手机号', 5);
						return false
					} else {
						if (!phoneReg.test(this.phoneNo)) {
							this.$Message.info('请输入手机号', 5);
							return false;
						}
					}

				} else {
					var params = {
						valuationReportId: this.$route.query.traceReportId ? this.$route.query.traceReportId : this
							.$route.query.reportId,
						id: this.invoiceId,
						invoiceFlag: !this.noInvoice ? '1' : '0' //1开发票   0不开发票
					}
				}
				this.$post(this.projectName + 'invoice/insertOrUpdateInvoice', qs.stringify(params))
					.then((res) => {
						console.log(res.data)
						this.payment = false;
						this.paymentCode = true;
						this.creatQrCode()
					});
			},
			creatQrCode: function() { //生成支付的二维码
				$(".qrcode").html('');
				var urltest = API_CONFIG.devServer.proxy['/api'].target;
				var text = urltest + this.projectName + '/pay/qrcallback?reportId=' + (this.$route.query
					.traceReportId ? this
					.$route.query.traceReportId : this.$route.query.reportId);
				var qrcode = new QRCode(this.$refs.qrCodeUrl, {
					text: text,
					width: 262,
					height: 262,
					colorDark: "#000000",
					colorLight: "#ffffff",
					correctLevel: QRCode.CorrectLevel.H,
				})
				this.payIsSuccess()
			},
			payIsSuccess: function() { //支付信息--轮询
				var this_ = this;
				var id = this.$route.query.traceReportId ? this.$route.query.traceReportId : this.$route.query
					.reportId;
				this_.timer = setInterval(function() {
					this_.$post(this_.projectName + '/pay/autoFindOrder', qs.stringify({
							id: id
						}))
						.then((res) => {
							console.log(res.data)
							if (res.data == '1') { //订单状态为1表示支付成功 ，0未支付
								clearInterval(this_.timer); //关闭定时器
								this_.paymentCode = false;
								this_.paySuccess = true;
							}
						});
				}, 1000)
			},
			modelSaveclose: function() {
				this.noInvoice = false;
			},
			modelPaySuccess: function() { //监听付款成功的弹框
				if (!this.paySuccess) {
					window.location.href = this.domain.testUrl + 'pages/myValuation.html';
				}
			},
			codeAlert: function() { //监听付款码关闭的时候
				if (!this.paymentCode) {
					clearInterval(this.timer); //关闭定时器
				}
			}








		}

	}
</script>

<style scoped>
	.guzhi_content {
		width: 1200px;
		margin: 0 auto;
		margin-bottom: 128px;
	}

	.moduleMain {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.moduleMain>img {
		width: 382px;
		margin-right: 101px;
	}

	.complete_info {
		width: 377px;
	}

	.complete_info>h4 {
		font-size: 20px;
		color: #282828;
		font-weight: bold;
		line-height: 20px;
		margin-bottom: 51px;
	}

	.complete_info>h4 span {
		font-size: 20px;
		color: #282828;
		font-weight: bold;
		line-height: 20px;
	}

	.complete_info>h4>img {
		display: block;
		float: left;
		margin-right: 8px;
	}

	.complete_info>p {
		font-size: 14PX;
		color: #282828;
		line-height: 24px;
		margin-bottom: 30px;
	}

	.complete_info>p:last-child {
		color: #FF0014;
		margin-bottom: 38px;
	}

	.right_top>p:first-child {
		float: left;
	}

	.right_top>p:first-child>img {
		float: left;
		margin-right: 8px;
	}

	.right_top>p:first-child>span {
		font-size: 20px;
		color: #282828;
		line-height: 20px;
		font-weight: bold;
	}

	.right_top>p.report-cost {
		float: left;
		margin-left: 65px;
		font-size: 16px;
		color: #1459F4;
		line-height: 16px;
		margin-top: 2px;
	}

	.right_top>p.report-cost>span {
		margin-top: -14px;
		font-size: 30px;
		font-weight: bold;
		line-height: 30px;
	}

	.right_middle {
		width: 100%;
		padding-top: 38px;
		overflow: hidden;
	}

	.middle {
		float: left;
	}

	.middle>p {
		font-size: 14PX;
		color: rgba(40, 40, 40, 1);
		line-height: 14px;
		margin-bottom: 12px;
	}

	.middle>p>span>img {
		display: inline-block;
		margin-right: 5px;
	}

	.middle_left>p:last-child {
		margin-bottom: 0;
	}

	.middle_right {
		margin-left: 100px;
	}

	.middle_right>p:last-child {
		margin-bottom: 0;
	}

	.bottom_btns {
		margin-top: 40px;
	}


	.btn {
		float: left;
		width: 145px;
		height: 40px;
		border-radius: 6px;
		cursor: pointer;
		font-size: 16px;
		text-align: center;
		line-height: 40px;
	}

	.btn_left {
		background: #1459F4;
		font-size: 16px;
		text-align: center;
		color: rgba(255, 255, 255, 1);
		line-height: 40px;
	}

	.btn_right {
		margin-left: 36px;
		border: 1px solid #ECC641;
		color: rgba(236, 198, 65, 1);
		line-height: 38px;
	}

	.btn_examineFailed {
		width: 230px;
	}

	.pay-container p {
		padding-left: 6px;
		font-size: 14px;
		color: #282828;
		margin-top: 20px;
	}

	.pay-container p.pay-main>span {
		padding: 0 12px;
		height: 34px;
		line-height: 36px;
		border: 1px solid #1459F4;
		font-size: 14px;
		color: #1459F4;
	}

	.pay-container p.pay-money>span {
		color: #1459F4;
	}

	.pay-container p.pay-money>span.costicon {
		font-size: 16px;
		line-height: 14px;
	}


	.pay-container p.pay-money>span.cost {
		font-size: 22px;
		font-weight: bold;
		line-height: 15px;
	}

	p.no-finish {
		padding-left: 6px;
		font-size: 14px;
		color: #FF2F2F;
		margin-top: 20px;
	}

	p.examine-tip {
		height: 20px;
		border-left: 4px solid #1459F4;
	}

	p.examine-tip>span:first-of-type {
		float: left;
		line-height: 20px;
		color: #282828;
		font-size: 16px;
		margin-left: 8px;
	}

	@media screen and (max-width: 750px) {
		.moduleMain {
			flex-wrap: wrap;
		}

		.moduleMain>img {
			margin: 0 auto 20px;
		}

		.guzhi_content {
			width: 93%;
		}

		.guzhi_content .content {
			width: 100% !important;
			padding: 0 20px 30px;
		}

		.complete_info {
			width: 100%;
		}

		.complete_info>h4 {
			margin-bottom: 0;
		}

		.complete_info>h4 span,
		.right_top>p:first-child>span {
			line-height: 32px;
			font-size: 16PX;
		}

		.right_top>p.report-cost {
			margin-top: 10px;
		}

		.btn_left {
			width: 150px;
			height: 50px;
			line-height: 50px;
			font-size: 14PX;
		}

		.btn_examineFailed {
			width: 250px;
		}


		.right_middle {
			overflow: hidden;
		}

		p.no-finish {
			margin-top: 52%;
			font-size: 12PX;
		}

		.middle_right {
			width: 100%;
			margin-left: 0;
			margin-top: 10px;
		}

		.middle>p {
			line-height: 25px;
		}

	}
</style>