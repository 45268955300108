<template>
    <div>
        <div :id="id" style="height:300px"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'
export default ({
    props: [
        'id', 'chartData'
    ],
    data() {
        return {
            chartValue: {}
        }
    },
    mounted(){
        var title = "";
        var index = 0;
        switch (this.id) {
            case "PERatio" :
                title = "市盈率";
                index = 0;
                break;
            case "PBRatio" :
                title = "市净率";
                index = 1;
                break;
            case "salesRatio" :
                title = "市销率";
                index = 2;
                break;
            case "cityRate" :
                title = "企业价值/息税折旧摊销前利润";
                index = 3;
                break;
        }
        this.chartValue = {
            x: this.chartData.years,
            bardata: this.chartData.valuationMul[index].value,
            color: ["#0578FB", "#73E6E7", "#1B5BE1"],
            title: title,
            percentage: "",
        };
        this.initChart()
    },
    methods: {
        initChart: function(){
                this.chart = echarts.init(document.getElementById(this.id));
                this.setOptions(this.id, this.chartValue)
        },
        setOptions: function(id, data){
            var histogram = echarts.init(document.getElementById(id));
            var option = {
                color: ['#3398DB'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                title: {
                    text: data.title,
                    left: 'center',
                    padding: [10, 0]
                },
                animation: false,
                grid: {
                    left: 80,
                    right: 20
                },
                xAxis: [{
                    type: 'category',
                    data: data.x,
                    axisTick: {
                        alignWithLabel: true,
                        show: false
                    },
                    axisLabel: {
                        interval: 0,
                        formatter: function(params) {
                            var newParamsName = ""; // 最终拼接成的字符串
                            var paramsNameNumber = params.length; // 实际标签的个数
                            var provideNumber = 4; // 每行能显示的字的个数
                            var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                            /**
                             * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                             */
                            // 条件等同于rowNumber>1
                            if (paramsNameNumber > provideNumber) {
                                /** 循环每一行,p表示行 */
                                for (var p = 0; p < rowNumber; p++) {
                                    var tempStr = ""; // 表示每一次截取的字符串
                                    var start = p * provideNumber; // 开始截取的位置
                                    var end = start + provideNumber; // 结束截取的位置
                                    // 此处特殊处理最后一行的索引值
                                    if (p == rowNumber - 1) {
                                        // 最后一次不换行
                                        tempStr = params.substring(start, paramsNameNumber);
                                    } else {
                                        // 每一次拼接字符串并换行
                                        tempStr = params.substring(start, end) + "\n";
                                    }
                                    newParamsName += tempStr; // 最终拼成的字符串
                                }

                            } else {
                                // 将旧标签的值赋给新标签
                                newParamsName = params;
                            }
                            //将最终的字符串返回
                            return newParamsName

                        }
                    }

                }],
                yAxis: [{
                    type: 'value',
                    name: data.percentage,
                    interval: data.increase,
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: true
                    },
                    axisTick: {
                        show: true
                    }

                }],
                series: [{
                    type: 'bar',
                    barWidth: '40',
                    data: data.bardata,
                    itemStyle: {
                        normal: {
                            color: function(params) {
                                // build a color map as your need.
                                var colorList = data.color;
                                return colorList[params.dataIndex]
                            }
                        }
                    },
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: '#000'
                            },
                            formatter: function(p) {
                                return formatCurrency.formateNum(p.value.toString(),2);
                            }
                        }
                    },
                    markLine: {
                        label: {
                            position: "middle"
                        },
                        symbol: "none",
                        itemStyle: {
                            normal: {
                                borderWidth: 1,
                                lineStyle: {
                                    type: 'dash',
                                    color: '#333 ',
                                    width: 2,
                                }
                            },
                        },
                        data: data.markLinedata
                    }
                }]
            };
            histogram.setOption(option);
        }
    }
})
</script>
<style scoped>

</style>
